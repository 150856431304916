<template>
  <div>
    <v-snackbar v-model="snackbar" top :color="color" :timeout="-1">
      <span :style="{ color: textColor }">
        {{ $t(message) }}
      </span>

      <template #action>
        <v-btn :color="textColor" icon @click="closeSnackbar">
          <v-icon>fa-times</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as types from '@/stores/types';

/**
 * @group Common
 * Errors that come from apollo server are
 * display in snackbar
 */
export default {
  data() {
    return {
      timeout: 4000,
      message: '',
      snackbar: false,
      errorCounter: 0,
      color: 'error',
      textColor: 'white',
    };
  },

  created() {
    this.registerErrorHandler('INTERNAL_SERVER_ERROR', (error) => {
      const errorCode = error.exception.code;
      if (errorCode === 11000) {
        this.setError('email_not_available');
      } else {
        this.setError('internal_server_error');
      }
    });

    /**
     *  If user token is expired from server,
     *  we need to redirect the user to login page
     * */
    this.registerErrorHandler('FORBIDDEN', () => {
      this.logout();
    });

    this.registerErrorHandler('NETWORK_ERROR', () => {
      this.log('NETWORK_ERROR error from server');
      this.setError('NETWORK_ERROR');
    });

    this.registerErrorHandler('UNAUTHENTICATED', () => {
      this.log('UNAUTHENTICATED error from server');
      this.setError('wrong_email_pass');
    });

    this.registerErrorHandler('CARD_NOT_ADDED', () => {
      this.log('CARD_NOT_ADDED error from server');
      this.setError('CARD_NOT_ADDED');
    });

    this.registerErrorHandler('CARD_NOT_UPDATED', () => {
      this.log('CARD_NOT_UPDATED error from server');
      this.setError('CARD_NOT_UPDATED');
    });

    this.registerErrorHandler('ACCESS_RIGHT', () => {
      this.log('ACCESS_RIGHT error from server');
      this.setError('not_authorized');
    });

    this.registerErrorHandler('WRONG_PASSWORD', () => {
      this.log('WRONG_PASSWORD error from server');
      this.setError('wrong_given_password');
    });

    this.registerErrorHandler('EMAIL_ALREADY_REGISTERED', () => {
      this.log('EMAIL_ALREADY_REGISTERED error from server');
      this.setError('email_exist');
    });

    this.registerErrorHandler('EMAIL_NOT_VALIDATED', () => {
      this.log('EMAIL_NOT_VALIDATED error from server');
      this.setError('email_not_verified');
    });

    this.registerErrorHandler('CONFIRM_YOUR_EMAIL', () => {
      this.log('CONFIRM_YOUR_EMAIL error from server');
      this.setError('email_not_confirmed');
    });

    this.registerErrorHandler('USER_TEMP_EXIST', () => {
      this.log('USER_TEMP_EXIST error from server');
      this.setError('user_temp_already_invited');
    });

    this.registerErrorHandler('COULD_NOT_LOAD_CARD', () => {
      this.log('COULD_NOT_LOAD_CARD error from server');

      this.setError('could_not_load_card');
      this.$router.replace(`/${this.$route.name}/${this.$route.params.boxId}`);
    });

    this.registerErrorHandler('INVITE_USER_BY_NAME_DISALLOWED', () => {
      this.log('INVITE_USER_BY_NAME_DISALLOWED error from server');

      this.setError('INVITE_USER_BY_NAME_DISALLOWED');
    });

    this.registerErrorHandler('ATTACHMENT_EXCEEDS_ALLOWED_SIZE', () => {
      this.log('ATTACHMENT_EXCEEDS_ALLOWED_SIZE error from server');
      this.setError('attachments_exceeds_quota');
    });

    this.registerErrorHandler('SECOND_FACTOR_INVALID', () => {
      this.log('SECOND_FACTOR_INVALID from server');
      this.setError('second_factor_invalid');
    });

    this.registerErrorHandler('LDAP_NOT_CONFIGURED', () => {
      this.log('LDAP_NOT_CONFIGURED from server');
      this.setError('ldap_configuration_error');
    });

    this.registerErrorHandler('LDAP_NOT_ENABLED', () => {
      this.log('LDAP_NOT_ENABLED from server');
      this.setError('ldap_configuration_error');
    });

    this.registerErrorHandler('ONLY_FROM_AD', () => {
      this.log('ONLY_FROM_AD from server');
      this.setError('only_from_ad');
    });

    this.registerErrorHandler('USER_LDAP_NOT_ALLOWED', () => {
      this.log('USER_LDAP_NOT_ALLOWED from server');
      this.setError('user_ldap_not_allowed');
    });
  },

  computed: {
    ...mapGetters({
      error: types.GET_ERROR,
      getMessage: types.GET_MESSAGE,
    }),
  },

  watch: {
    error() {
      if (this.error !== '') {
        this.message = this.error;
        this.snackbar = true;
        this.errorCounter += 1;
        this.color = 'error';
        this.textColor = 'white';
        setTimeout(() => {
          this.errorCounter -= 1;
          if (this.errorCounter <= 0) {
            this.snackbar = false;
            this.setError('');
          }
        }, this.timeout);
        this.setError('');
      }
    },
    // TODO: In future we need to just use SET_MESSAGE to show the
    // messages to the client
    getMessage() {
      if (this.getMessage.message !== '') {
        const { type, message } = this.getMessage;
        this.message = message;
        this.snackbarColor(type);
        this.snackbar = true;
        this.errorCounter += 1;
        this.setError(this.message);
        setTimeout(() => {
          this.errorCounter -= 1;
          if (this.errorCounter <= 0) {
            this.snackbar = false;
            this.setError('');
          }
        }, this.timeout);
        this.setError('');
      }
    },
  },

  methods: {
    ...mapActions({
      setError: types.SET_ERROR,
      logout: types.LOGOUT_LOCAL,
    }),

    closeSnackbar() {
      this.setError('');
      this.snackbar = false;
    },

    snackbarColor(messageType) {
      switch (messageType) {
        case 'INFO':
          this.color = 'success';
          this.textColor = 'white';
          break;
        case 'WARNING':
          this.color = 'warning';
          this.textColor = 'black';
          break;
        default:
          this.color = 'error';
          this.textColor = 'white';
      }
    },

    log(message) {
      this.$debugger('wError.vue', message);
    },
  },
};
</script>
