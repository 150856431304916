import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import box from './stores/modules/box';
import workflow from './stores/modules/workflow';
import card from './stores/modules/card';
import attachment from './stores/modules/attachment';
import checklist from './stores/modules/checklist';
import label from './stores/modules/label';
import category from './stores/modules/category';
import setting from './stores/modules/setting';
import labelView from './stores/modules/labelView';
import cardDate from './stores/modules/cardDate';
import description from './stores/modules/description';
import calendar from './stores/modules/calendar';
import user from './stores/modules/user';
import filter from './stores/modules/filter';
import sidebar from './stores/modules/sidebar';
import boxMembers from './stores/modules/boxMembers';
import breadcrumb from './stores/modules/breadcrumb';
import property from './stores/modules/property';
import cardMembers from './stores/modules/cardMembers';
import avatar from './stores/modules/avatar';
import template from './stores/modules/template';
import loader from './stores/modules/loader';
import boxRole from './stores/modules/boxRole';
import snackbar from './stores/modules/snackbar';
import adminPanel from './stores/modules/adminPanel';
import userTemp from './stores/modules/userTemp';
import passwordRecovery from './stores/modules/passwordRecovery';
import cardComments from './stores/modules/cardComments';
import multiCardSelect from './stores/modules/multiCardSelect';
import stateManagement from './stores/modules/stateManagement';
import userSpecificBoxSettings from './stores/modules/userSpecificBoxSettings';
import allCards from './stores/modules/allCards';
import customDescription from './stores/modules/customDescription';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    box,
    workflow,
    card,
    attachment,
    checklist,
    label,
    category,
    setting,
    labelView,
    cardDate,
    description,
    calendar,
    user,
    filter,
    sidebar,
    boxMembers,
    breadcrumb,
    property,
    cardMembers,
    avatar,
    template,
    loader,
    boxRole,
    snackbar,
    adminPanel,
    userTemp,
    passwordRecovery,
    cardComments,
    multiCardSelect,
    stateManagement,
    userSpecificBoxSettings,
    allCards,
    customDescription,
  },
  plugins: [
    /**
     * storing avatar object in browser's cache
     */
    createPersistedState({
      paths: ['avatar'],
    }),
  ],
});

export default store;
