import gql from 'graphql-tag';

// ----------------QUERIES------------------

export const GET_DESCRIPTION = gql`
  query getDesc($id: ID!) {
    getCardById(id: $id) {
      description
    }
  }
`;

// ----------------MUTATIONS------------------

export const UPDATE_DESCRIPTION = gql`
  mutation($id:ID!, $description: String) {
    updateDescription(id:$id, description: $description) {
      id
    }
  }
`;

// ----------------SUBSCRIPTIONS------------------

export const SUBSCRIBE_DESCRIPTION_CHANGED = gql`
subscription {
  descriptionChanged {
    id
    description
  }
}
`;
