<template>
  <v-col cols="12" class="d-flex flex-wrap">
    <h2 v-if="!isEditMode">{{ group.name }}</h2>
    <v-text-field
      v-model="name"
      :rules="[() => !!name || $t('require_box_group_name') ]"
      :label="$t('subtitle_box_group')"
      :id="group.id"
      solo
      autofocus
      v-if="isEditMode"
      @keyup.enter="update()"
      @keyup.esc="switchEditMode()"
    >
      <template #append>
        <v-icon
          color="warning"
          @click="switchEditMode()"
        >
          {{ $vuetify.theme.dark ? 'far fa-times-circle' : 'fa-times-circle' }}
        </v-icon>
      </template>
      <template #append-outer>
        <v-icon
          color="primary"
          @click="update()"
        >
         fa-save
        </v-icon>
      </template>
    </v-text-field>
    <v-spacer></v-spacer>
     <v-btn class="ml-3" @click="switchEditMode()" icon>
      <v-icon small color="icon">fa-edit</v-icon>
    </v-btn>
    <v-btn class="ml-3" @click="removeGroup(group.id)" icon>
      <v-icon small color="red">fa-trash</v-icon>
    </v-btn>
  </v-col>
</template>
<script>
import { mapActions } from 'vuex';
import * as types from '@/stores/types';
import colorController from '@/scripts/colorController';

/**
 * @group Box
 *  A component containing the (accepted) Boxes with
 *  edit and delete feature for each Box
 * @module components/box/wBoxes.vue
 *
 * @param {Array} boxes - The Boxes details
 */
export default {
  name: 'wGroup',

  props: {
    group: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isEditMode: false,
      name: '',
    };
  },

  mounted() {
    if (this.group?.name) {
      this.name = this.group.name;
    }
  },

  methods: {
    ...mapActions({
      deleteGroup: types.DELETE_BOX_GROUP,
      fetchBoxes: types.FETCH_BOXES,
      updateGroup: types.UPDATE_BOX_GROUP,
    }),

    /**
     * @vuese
     * Determines whether the color of the text
     * should be black or white
     */
    fontColor(color) {
      if (colorController.shouldIUseDark(color)) {
        return 'black';
      }
      return 'white';
    },

    async removeGroup(groupId) {
      await this.deleteGroup({ groupId });
      await this.fetchBoxes();
    },

    async update() {
      if (!this.name) {
        return;
      }
      const newGroup = {
        groupId: this.group.id,
        name: this.name,
      };

      const result = await this.updateGroup(newGroup);
      if (result.type !== 'INFO') {
        this.name = this.group.name;
      }
      this.isEditMode = false;
    },

    switchEditMode() {
      if (!this.name) {
        this.name = this.group.name;
      }
      this.isEditMode = !this.isEditMode;
    },
  },
};
</script>
