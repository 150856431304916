import gql from 'graphql-tag';
import { MUTATION_MESSAGE } from './fragments';

// ----------------QUERIES------------------

export const GET_USER_SPECIFIC_BOX_SETTINGS = gql`
query getUserSpecificBoxSettings($boxId: ID!){
  getUserSpecificBoxSettings(boxId: $boxId) {
    color
    isMuted
    selectedTemplate
  }
}
`;

// ----------------MUTATIONS------------------

export const MUTE_BOX = gql`
  mutation($boxId: ID!, $isMuted: Boolean!) {
    toggleMuteBox(boxId: $boxId, isMuted: $isMuted) {
      ...mutationMessage
    }
  }
  ${MUTATION_MESSAGE.mutationMessage}
`;

export const CHANGE_BOX_COLOR = gql`
  mutation($boxId: ID!, $color: String!) {
    changeBoxColor(boxId: $boxId, color: $color) {
      ...mutationMessage
    }
  }
  ${MUTATION_MESSAGE.mutationMessage}
`;

export const SAVE_SELECTED_TEMPLATE = gql`
  mutation($boxId: ID!, $templateId: ID) {
    saveSelectedTemplate(boxId: $boxId, templateId: $templateId)
  }
`;
