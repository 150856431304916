/* eslint-disable no-param-reassign */
import Vue from 'vue';

const clickOutside = {
  bind(el, { value, modifiers }) {
    const listener = (event) => {
      if (el.dataset.remove !== 'true' && !el.contains(event.target)) {
        value();
      }
      if (el.dataset.remove === 'true') {
        document.removeEventListener('click', listener, modifiers.unstopped);
      }
    };

    document.addEventListener('click', listener, modifiers.unstopped);
  },
  unbind(el) {
    el.dataset.remove = true;
  },
};

/**
 * Binds a function to a click outside of the element that the directive is on
 *
 * @value The function to call on outside click
 * @param unstopped Modifier; If the click should also be listened to when it
 *  was stopped before reaching the document base
 */
Vue.directive('w-click-outside', clickOutside);
