import * as types from '../types';

const getters = {
  [types.GET_IS_CARD_MULTISELECT_ON]: ({ isSelectionModeOn }) => isSelectionModeOn,
  [types.GET_SELECTED_CARDS]: ({ selectedCards }) => selectedCards,
  [types.GET_SELECTED_CARDS_UPDATE]: ({ selectedCardsUpdateTracker }) => selectedCardsUpdateTracker,
};

const mutations = {
  [types.MUTATE_ADD_CARD_TO_SELECTION]: (state, cardId) => {
    const current = state;
    current.selectedCards.add(cardId);
    current.selectedCardsUpdateTracker += 1;
  },

  [types.MUTATE_ADD_CARDS_TO_SELECTION]: (state, cardIds) => {
    const current = state;
    (cardIds || []).forEach((cardId) => current.selectedCards.add(cardId));
    current.selectedCardsUpdateTracker += 1;
  },

  [types.MUTATE_TOGGLE_MULTISELECT]: (state) => {
    const current = state;
    current.isSelectionModeOn = !current.isSelectionModeOn;
    if (!current.isSelectionModeOn) {
      current.selectedCards = new Set();
    }
  },

  [types.MUTATE_REMOVE_CARD_FROM_SELECTION]: (state, cardId) => {
    const current = state;
    current.selectedCards.delete(cardId);
    current.selectedCardsUpdateTracker += 1;
  },

  [types.MUTATE_REMOVE_CARDS_FROM_SELECTION]: (state, cardIds) => {
    const current = state;
    (cardIds || []).forEach((cardId) => current.selectedCards.delete(cardId));
    current.selectedCardsUpdateTracker += 1;
  },

  [types.MUTATE_TOGGLE_CARD_MULTISELECTED]: (state, cardId) => {
    const current = state;
    if (current.selectedCards.has(cardId)) {
      current.selectedCards.delete(cardId);
    } else {
      current.selectedCards.add(cardId);
    }
    current.selectedCardsUpdateTracker += 1;
  },

  [types.MUTATE_CLEAR_MULTISELECT]: (state) => {
    const current = state;
    current.isSelectionModeOn = false;
    current.selectedCards = new Set();
  },
};

const actions = {
  [types.MULTISELECT_ADD_WORKFLOW_COLUMN]: ({ commit, getters: globalGetters }, columnId) => {
    const columns = globalGetters.GET_ALL_WORKFLOWS;
    const { groups } = (columns.find(({ id }) => id === columnId) || {});

    // Extract cards out of all groups in the workflow
    const cardsInGroups = (groups.map(({ cards }) => cards)).flat();

    // Extract cards out of workflow
    const column = (columns.find(({ id }) => id === columnId) || {});
    const cards = [...column.cards];

    // Add cards from groups in one array
    cards.push(...cardsInGroups);

    // Map out the CardIds
    const cardIds = (cards || []).map(({ id }) => id);

    commit(types.MUTATE_ADD_CARDS_TO_SELECTION, cardIds);
  },

  [types.MULTISELECT_ADD_WORKFLOW_EXPANDED]: ({ commit, getters: globalGetters }) => {
    const column = globalGetters.GET_WORKFLOW;
    const { groups } = column;
    const cards = [...column.cards];

    // Extract cards out of all groups in the workflow
    const cardsInGroups = (groups.map(({ cards: c }) => c)).flat();

    // Add cards from groups in one array
    cards.push(...cardsInGroups);

    // Map out the CardIds
    const cardIds = (cards || []).map(({ id }) => id);

    commit(types.MUTATE_ADD_CARDS_TO_SELECTION, cardIds);
  },

  [types.MULTISELECT_ADD_CATEGORY_COLUMN]: ({ commit, getters: globalGetters }, columnId) => {
    const columns = globalGetters.GET_ALL_CATEGORIES;
    const { cards } = (columns.find(({ id }) => id === columnId) || {});
    const cardIds = (cards || []).map(({ id }) => id);
    commit(types.MUTATE_ADD_CARDS_TO_SELECTION, cardIds);
  },

  [types.MULTISELECT_ADD_LABEL_COLUMN]: ({ commit, getters: globalGetters }, columnId) => {
    const columns = globalGetters.GET_ALL_LABELS;

    const { cards } = (columns.find(({ id }) => id === columnId) || {});
    const cardIds = (cards || []).map(({ id }) => id);
    commit(types.MUTATE_ADD_CARDS_TO_SELECTION, cardIds);
  },

  [types.MULTISELECT_REMOVE_WORKFLOW_COLUMN]: ({ commit, getters: globalGetters }, columnId) => {
    const columns = globalGetters.GET_ALL_WORKFLOWS;
    const { groups } = (columns.find(({ id }) => id === columnId) || {});

    // Extract cards out of all groups in the workflow
    const cardsInGroups = (groups.map(({ cards }) => cards)).flat();

    // Extract cards out of workflow
    const column = (columns.find(({ id }) => id === columnId) || {});
    const cards = [...column.cards];

    // Add cards from groups in one array
    cards.push(...cardsInGroups);

    // Map out the CardIds
    const cardIds = (cards || []).map(({ id }) => id);

    commit(types.MUTATE_REMOVE_CARDS_FROM_SELECTION, cardIds);
  },

  [types.MULTISELECT_REMOVE_WORKFLOW_EXPANDED]: ({ commit, getters: globalGetters }) => {
    const column = globalGetters.GET_WORKFLOW;
    const { groups } = column;
    const cards = [...column.cards];

    // Extract cards out of all groups in the workflow
    const cardsInGroups = (groups.map(({ cards: c }) => c)).flat();

    // Add cards from groups in one array
    cards.push(...cardsInGroups);

    // Map out the CardIds
    const cardIds = (cards || []).map(({ id }) => id);

    commit(types.MUTATE_REMOVE_CARDS_FROM_SELECTION, cardIds);
  },

  [types.MULTISELECT_REMOVE_CATEGORY_COLUMN]: ({ commit, getters: globalGetters }, columnId) => {
    const columns = globalGetters.GET_ALL_CATEGORIES;
    const { cards } = (columns.find(({ id }) => id === columnId) || {});
    const cardIds = (cards || []).map(({ id }) => id);
    commit(types.MUTATE_REMOVE_CARDS_FROM_SELECTION, cardIds);
  },

  [types.MULTISELECT_REMOVE_LABEL_COLUMN]: ({ commit, getters: globalGetters }, columnId) => {
    const columns = globalGetters.GET_ALL_LABELS;
    const { cards } = (columns.find(({ id }) => id === columnId) || {});
    const cardIds = (cards || []).map(({ id }) => id);
    commit(types.MUTATE_REMOVE_CARDS_FROM_SELECTION, cardIds);
  },

  [types.ADD_CARD_TO_MULTISELECT]: ({ commit }, cardId) => {
    commit(types.MUTATE_ADD_CARDS_TO_SELECTION, cardId);
  },

  [types.REMOVE_CARD_FROM_MULTISELECT]: ({ commit }, cardId) => {
    commit(types.MUTATE_REMOVE_CARD_FROM_SELECTION, cardId);
  },

  [types.TOGGLE_CARD_MULTISELECTED]: ({ commit }, cardId) => {
    commit(types.MUTATE_TOGGLE_CARD_MULTISELECTED, cardId);
  },

  [types.TOGGLE_MULTISELECT]: ({ commit }) => {
    commit(types.MUTATE_TOGGLE_MULTISELECT);
  },
};

const state = {
  selectedCards: new Set(),
  // https://github.com/vuejs/vue/issues/2410#issuecomment-318487855
  selectedCardsUpdateTracker: 1,
  isSelectionModeOn: false,
};

export default {
  getters,
  mutations,
  actions,
  state,
};
