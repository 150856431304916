<template>
  <v-breadcrumbs
    :items="breadcrumb"
    :class="$vuetify.breakpoint.xsOnly ? 'pl-0' : ''"
  >
    <template v-slot:item="{ item }">
      <router-link
        :to="item.path"
        :class="isDisabled(item.disabled)"
        class="link"
      >
        <span
          :class="{
            'font-weight-medium subtitle-1': $vuetify.breakpoint.smOnly,
            'font-weight-medium body-1': $vuetify.breakpoint.xsOnly,
            'white--text': ($vuetify.theme.dark && item.disabled),
            'icon--text text--lighten-1': ($vuetify.theme.dark && !item.disabled)}"
        >
          {{ $t(item.name) }}
        </span>
      </router-link>
    </template>

    <template v-slot:divider>
      <v-icon class="pt-1">
        fa-arrow-right
      </v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { mapGetters } from 'vuex';
import * as types from '@/stores/types';

/**
 * @group Common
 *
 * Breadcrumb on toolbar
 */
export default {
  name: 'WBreadcrumbs',

  computed: {
    ...mapGetters({
      breadcrumb: types.GET_BREADCRUMB,
    }),
  },

  methods: {
    isDisabled(disabled) {
      if (disabled) {
        return 'disabled';
      }
      return null;
    },
  },
};
</script>

<style scoped>
.link {
  text-decoration: none;
}

.disabled {
  pointer-events: none;
  cursor: default;
  color: black;
}
</style>
