import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import {
  split, ApolloLink, concat,
} from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import VueApollo from 'vue-apollo';
import { errorLink } from './middlewares/errorHandlers';

const isSecure = window.location.protocol === 'https:';

const HTTP_PROTOCOL = `http${isSecure ? 's' : ''}:`;
const WS_PROTOCOL = `ws${isSecure ? 's' : ''}:`;
const PORT = isSecure ? 443 : 3000;
const BASE_URL = `${window.location.hostname}:${PORT}`;

const clientVersion = process.env.VUE_APP_CLIENT_VERSION;

let route;

/**
 * Change the current route. Used for updating the boxId that is sent to the backend
 *
 * @param {Route} newRoute The new route
 */
const changeRoute = (newRoute) => {
  route = newRoute;
};

let versionMismatchHandler;

/**
 * Registers a handler that will be called if the version between front
 * and backend do not match.
 *
 * @param {Function} handler The handler that is called on version mismatch
 */
const registerMismatchHandler = (handler) => {
  versionMismatchHandler = handler;
};

/**
 * Mixes in the box id into the variables so that it can be used to filter on the server side
 */
const boxIdLink = new ApolloLink(
  (operation, forward) => {
    if (route && route.params.boxId) {
      const op = operation;
      op.variables = { boxId: route.params.boxId, ...op.variables };
    }
    return forward(operation);
  },
);

/**
 * Detects a version mismatch between front and backend.
 */
const mismatchDetectorLink = (op, next) => next(op).map((data) => {
  const { response: { headers } } = op.getContext();
  if (headers && headers.get('version-mismatch')) {
    versionMismatchHandler();
  }
  return data;
});

// // Setup ApolloClient
/**
 * Using apollo-upload-client instead of
 * apollo-http-link since we want to upload
 * files and apollo-upload-client already has
 * apollo-http-link built in, thus we only
 * use the upload client package
 */
const uploadLink = concat(
  mismatchDetectorLink,
  createUploadLink({
    uri: `${HTTP_PROTOCOL}//${BASE_URL}/graphql`,
    credentials: 'include',
    headers: {
      'Client-Version': clientVersion,
    },
  }),
);

// Create the subscription websocket link
const wsLink = new WebSocketLink({
  uri: `${WS_PROTOCOL}//${BASE_URL}/graphql`,
  options: {
    reconnect: true,
    lazy: true,
  },
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = errorLink.concat(split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition'
      && operation === 'subscription';
  },
  concat(boxIdLink, wsLink),
  uploadLink,
));

// Create the apollo client
const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions: {
    /**
     * To revert back to original code,
     * set fetchPolicy to network-only
     */
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

const apolloProvider = new VueApollo(
  {
    defaultClient: apolloClient,
  },
);

export {
  apolloProvider,
  apolloClient,
  wsLink,
  changeRoute,
  registerMismatchHandler,
};
