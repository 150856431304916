// eslint-disable-next-line no-control-regex
export const EMAIL_REGEX = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

/**
 * The Regex for setting a password
 *
 * Rules are:
 *
 * 1. Minimum 8 characters
 * 2. At least one lowercase alphabet
 * 3. At least one uppercase alphabet
 * 4. At least one number
 * 5. At least one special character
 */
export const PASSWORD_REGEX = /^(?=.*?[A-ZÀ-Ý])(?=.*?[a-zà-ÿ])(?=.*?[0-9])(?=.*?[^A-ZÀ-Ýa-zà-ÿ0-9]).{8,200}$/;

/**
 * Regex for unsigned integers
 */
export const UNSIGNED_INT_REGEX = /^\+?(0|[1-9]\d*)$/;

/**
 * Regex for signed AND unsigned integers (no decimels)
 */
export const INTEGER_REGEX = /^-?\d+$/;

/**
 * Indicates a "No Template/Keine Vorlage" selection
 */
export const NO_VALUE = '-1';

/**
 * Central name for the column expanded view
 */
export const COLUMN_EXPANDED = 'ColumnExpanded';

/**
 * File attempted to preview has an unknown
 * mime type and cannot be displayed
 */
export const MIME_TYPE_UNKNOWN = 'MimeType Unknown';

/**
 * Download route for files
 */
export const DOWNLOAD_ROUTE = '/download?attachment';

/**
 * The number of activities to fetch for the box
 * at a time
 */
export const NUMBER_OF_ACTIVITIES_FOR_BOX = 10;

/**
 * The number of activities to fetch for the card
 * at a time
 */
export const NUMBER_OF_ACTIVITIES_FOR_CARD = 5;

/**
 * The number of cards to fetch at a time for the
 * all cards page
 */
export const NUMBER_OF_CARDS_FOR_BOX = 50;

/**
 * The default value for attachment size limit. This value
 * denotes that there is no limit set for attachment size per card
 */
export const DEFAULT_ATTACHMENTS_STORAGE_SIZE = 0;

/**
 * The number to use to convert bytes to OR from megabytes
 */
export const ONE_BYTE_IN_ONE_MEGA_BYTE = 1000000;

/**
 * Name of the About Us route
 */
export const ABOUT_US = 'AboutUs';

/**
 * Supported Languages in Pushbox
 */
export const LANGUAGES = [
  {
    name: 'English',
    acronym: 'EN',
    value: 'en',
  },
  {
    name: 'Deutsch',
    acronym: 'DE',
    value: 'de',
  },
];

/**
 * Regex to find mentioned usernames inside text (for comments)
 */
export const MENTION_NOTIFY_REGEX = /\B@(?!(?:[a-z0-9.]*_){3})(?!(?:[a-z0-9_]*\.){3})[._a-z0-9]{3,20}\b/g;

/**
* Regex to extract extension given a filen
*/
export const EXTRACT_EXTENSION_REGEX = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim;
