<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <span class="title font-weight-regular">
            <v-icon left small color="icon" class="mb-1">
              fa-lock
            </v-icon>
            {{ $t("change_password") }}
          </span>

          <slot name="close"></slot>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="message">
      <v-col cols="12">
        <span class="subtitle-2 font-weight-regular">
          {{ message }}
        </span>
      </v-col>
    </v-row>

    <v-form>
      <v-row>
        <v-col cols="12">
          <v-text-field
            @keyup.enter="updatePassword()"
            v-model="oldPassword"
            :error-messages="oldPasswordErrors"
            :type="show ? 'text' : 'password'"
            :placeholder="$t('old_password')"
            solo
            dense
          >
            <template #append>
              <v-icon @click="show = !show" size="16">
                {{ show ? "fa-eye" : "fa-eye-slash" }}
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row class="d-flex flex-column">
        <v-col cols="12">
          <v-text-field
            @keyup.enter="updatePassword()"
            v-model="newPassword"
            :error-messages="newPasswordErrors"
            :type="show ? 'text' : 'password'"
            :placeholder="$t('password')"
            solo
            dense
          >
            <template #append>
              <v-icon @click="show = !show" size="16">
                {{ show ? "fa-eye" : "fa-eye-slash" }}
              </v-icon>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon color="icon" size="18" right v-on="on">fa-info-circle</v-icon>
                </template>
                <span v-html="$t('password_hint')" />
              </v-tooltip>
            </template>
          </v-text-field>
          <v-text-field
            @keyup.enter="updatePassword()"
            v-model="repeatPassword"
            :error-messages="repeatPasswordErrors"
            :type="show ? 'text' : 'password'"
            :placeholder="$t('repeat_password')"
            solo
            dense
          >
            <template #append>
              <v-icon @click="show = !show" size="16">
                {{ show ? "fa-eye" : "fa-eye-slash" }}
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn
            text
            color="green"
            @click="updatePassword()"
            :loading="loading"
            :disabled="loading"
          >
            {{ $t("update") }}
          </v-btn>

          <v-btn text color="warning" @click="clearForm()">
            {{ $t("clear_form") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import { PASSWORD_REGEX } from '@/scripts/common';
import { CHANGE_PASSWORD } from '@/stores/types';

/**
 * @group UserAccount
 * This is the security tab where the user
 * can change their password if they want
 */
export default {
  name: 'WChangePassword',

  props: {
    message: {
      type: String,
      default: () => '',
    },
  },

  data() {
    return {
      show: '',
      loading: false,
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
    };
  },

  validations: {
    oldPassword: {
      required,
    },

    newPassword: {
      required,
      matches(value) {
        return PASSWORD_REGEX.test(value);
      },
    },

    repeatPassword: {
      required,
      sameAs: sameAs('newPassword'),
    },
  },

  computed: {
    oldPasswordErrors() {
      const errors = [];

      if (!this.$v.oldPassword.$dirty) return errors;

      if (!this.$v.oldPassword.required) errors.push(this.$t('required_field'));

      return errors;
    },

    newPasswordErrors() {
      const errors = [];

      if (!this.$v.newPassword.$dirty) return errors;

      if (!this.$v.newPassword.required) errors.push(this.$t('required_field'));
      if (!this.$v.newPassword.matches) errors.push(this.$t('password_requirements'));

      return errors;
    },

    repeatPasswordErrors() {
      const errors = [];

      if (!this.$v.repeatPassword.$dirty) return errors;

      if (!this.$v.repeatPassword.required) errors.push(this.$t('required_field'));
      if (!this.$v.repeatPassword.sameAs) errors.push(this.$t('repeat_password_error'));

      return errors;
    },
  },

  methods: {
    ...mapActions({
      changePassword: CHANGE_PASSWORD,
    }),

    /**
     * @vuese
     *
     * - Updates the password
     * - Returns if form is invalid
     * - Shows a loading icon in the button
     * - Clears the form
     */
    async updatePassword() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.loading = true;

      const result = await this.changePassword({
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      });

      if (result.type === 'INFO') {
        // Signal an emit for the expired
        // password dialog so that it closes
        this.$emit('close');
      }

      this.loading = false;
      this.clearForm();
    },

    /**
     * @vuese
     *
     * Clears the form and resets
     * the validation errors
     */
    clearForm() {
      this.oldPassword = '';
      this.newPassword = '';
      this.repeatPassword = '';

      this.$v.$reset();
    },
  },
};
</script>
