import DateFormat from '@/lib/dateFormat';
/**
 * create console.debug that just show in dev mode.
 * Timestamp is generated as well
 *
 * @param {string} filename
 * @param {string} message
 */
export const debugging = (filename: string, message: string) => {
  if (process.env.VUE_APP_LOGGING === 'ON') {
    const df = new DateFormat();
    const timestamp = df.dateTime(undefined, 'MMMM do yyyy, h:mm:ss:SSS a');
    console.debug(`${filename}, message: ${message}, timestamp: ${timestamp}`);
  }
};
