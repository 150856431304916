<template>
  <wLoading v-if="isPageLoading" />
  <v-container v-else>
    <v-slide-y-transition leave-absolute tag="div">
      <wPendingBoxes :pending-boxes="filteredPendingBoxes" />
    </v-slide-y-transition>

    <v-divider v-if="filteredPendingBoxes.length !== 0"></v-divider>

    <v-slide-y-transition leave-absolute tag="div">
      <wBoxes
        :archived-boxes="filteredArchivedBoxes"
        :unarchived-boxes="filteredUnarchivedBoxes"
        :groups="groups"
      />
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import * as types from '@/stores/types';
import { SUBSCRIBE_ADDED, SUBSCRIBE_REMOVED, SUBSCRIBE_UPDATED } from '@/queries/box';
import { SUBSCRIBE_ARCHIVED_BOX, SUBSCRIBE_UNARCHIVED_BOX } from '@/queries/boxArchive';
import {
  SUBSCRIBE_SELF_ADDED_AS_COLLABORATOR,
  SUBSCRIBE_SELF_REMOVED_AS_COLLABORATOR,
  SUBSCRIBE_BOX_INVITATION_ACCEPTED,
} from '@/queries/boxMembers';
import { SUBSCRIBE_USER_DELETED } from '@/queries/user';
import wPendingBoxes from '@/components/box/wPendingBoxes.vue';
import wBoxes from '@/components/box/wBoxes.vue';
import wLoading from '@/components/common/wLoading.vue';

/**
 * @group Box
 *  The main body for the box component that
 * lists the pending and accepted boxes of
 * a certain user
 * @module components/box/wBody.vue
 *
 */
export default {
  name: 'WBoxBody',

  components: {
    wPendingBoxes,
    wBoxes,
    wLoading,
  },

  created() {
    if (this.isAuthenticated) {
      this.fetchBoxes();
      this.fetchPendingBoxes();
    }
  },

  watch: {
    isAuthenticated(authenticated) {
      if (authenticated) {
        this.fetchBoxes();
        this.fetchPendingBoxes();
      }
    },
  },

  computed: {
    ...mapGetters({
      searching: types.GET_SEARCH_BOX,
      boxes: types.GET_BOXES,
      pendingBoxes: types.GET_PENDING_BOXES,
      isPageLoading: types.GET_PAGE_LOADING,
      isAuthenticated: types.GET_USER_AUTHENTICATION_STATUS,
      groups: types.GET_BOX_GROUPS,
    }),

    // return filtered result based on search
    filteredPendingBoxes() {
      if (this.searching) {
        return this.pendingBoxes.filter((box) => box.name.toLowerCase().includes(this.searching.toLowerCase()));
      }
      return this.pendingBoxes;
    },

    filteredUnarchivedBoxes() {
      if (this.searching) {
        return this.boxes.filter(
          (box) => box.box.name.toLowerCase().includes(this.searching.toLowerCase()) && !box.box.isArchive,
        );
      }
      return this.boxes.filter((box) => !box.box.isArchive);
    },

    filteredArchivedBoxes() {
      if (this.searching) {
        return this.boxes.filter(
          (box) => box.box.name.toLowerCase().includes(this.searching.toLowerCase()) && box.box.isArchive,
        );
      }
      return this.boxes.filter((box) => box.box.isArchive);
    },
  },

  apollo: {
    $subscribe: {
      // When BOX is ADDED
      boxAdded: {
        query: SUBSCRIBE_ADDED,
        // Result hook
        result({ data }) {
          const box = data.boxAdded;
          this.addSubscription(box);
        },
        skip() {
          return this.skipOnIncomplete();
        },
      },

      boxUpdated: {
        query: SUBSCRIBE_UPDATED,
        // Result hook
        result({ data }) {
          const box = data.boxUpdated;
          this.updateSubscription(box);
        },
        skip() {
          return this.skipOnIncomplete();
        },
      },

      boxRemoved: {
        query: SUBSCRIBE_REMOVED,
        // Result hook
        result(response) {
          const box = response.data.boxRemoved;
          this.deleteSubscription(box);
        },
        skip() {
          return this.skipOnIncomplete();
        },
      },

      boxArchived: {
        query: SUBSCRIBE_ARCHIVED_BOX,
        // Result hook
        result({ data }) {
          const { box } = data.boxArchived;
          this.archiveBoxSubscription(box);
        },
        skip() {
          return this.skipOnIncomplete();
        },
      },
      boxUnarchived: {
        query: SUBSCRIBE_UNARCHIVED_BOX,
        // Result hook
        result({ data }) {
          const { box } = data.boxUnarchived;
          this.unarchiveBoxSubscription(box);
        },
        skip() {
          return this.skipOnIncomplete();
        },
      },

      invitedMemberToTheBox: {
        query: SUBSCRIBE_SELF_ADDED_AS_COLLABORATOR,
        // Result hook
        result({ data }) {
          const box = data.invitedMemberToTheBox;
          this.mutateInvitedAsCollaborater(box);
        },
        skip() {
          return this.skipOnIncomplete();
        },
      },

      removedMemberFromTheBox: {
        query: SUBSCRIBE_SELF_REMOVED_AS_COLLABORATOR,
        // Result hook
        result({ data }) {
          const boxId = data.removedMemberFromTheBox;
          this.removedAsCollab(boxId);
        },
        skip() {
          return this.skipOnIncomplete();
        },
      },

      boxInvitationAccepted: {
        query: SUBSCRIBE_BOX_INVITATION_ACCEPTED,
        result({ data }) {
          const boxId = data.boxInvitationAccepted;
          this.mutateInvitationAccepted(boxId);
        },
        skip() {
          return this.skipOnIncomplete();
        },
      },

      userDeleted: {
        query: SUBSCRIBE_USER_DELETED,
        result({ data }) {
          const { userDeleted } = data;

          // Remove pending boxes of deleted user's invitation
          this.mutateSubscribePendingBoxesUserDeleted(userDeleted);
        },
      },
    },
  },

  methods: {
    ...mapActions({
      fetchBoxes: types.FETCH_BOXES,
      fetchPendingBoxes: types.FETCH_PENDING_BOXES,
      addSubscription: types.SUBSCRIBE_ADDED_BOX,
      updateSubscription: types.SUBSCRIBE_UPDATED_BOX,
      deleteSubscription: types.SUBSCRIBE_DELETED_BOX,
      addedAsCollab: types.SUBSCRIBE_ADDED_AS_COLLABORATOR,
      removedAsCollab: types.SUBSCRIBE_REMOVED_AS_COLLABORATOR,
      archiveBoxSubscription: types.SUBSCRIBE_ARCHIVED_BOX,
      unarchiveBoxSubscription: types.SUBSCRIBE_UNARCHIVED_BOX,
    }),

    ...mapMutations({
      mutateInvitedAsCollaborater: types.MUTATE_INVITED_AS_COLLABORATOR,
      mutateInvitationAccepted: types.MUTATE_INVITATION_ACCEPTED,
      mutateSubscribePendingBoxesUserDeleted: types.MUTATE_SUBSCRIBE_PENDING_BOXES_USER_DELETED,
    }),

    skipOnIncomplete() {
      return !this.isAuthenticated;
    },
  },
};
</script>
