import Vue from 'vue';
// Text Editor plugin
import wysiwyg from 'vue-wysiwyg';
import 'vue-wysiwyg/dist/vueWysiwyg.css';
// To sanitize user input values
import VueSanitize from 'vue-sanitize';

const config = {
  hideModules: { image: true, code: true },
};

Vue.use(wysiwyg, config);
Vue.use(VueSanitize);
