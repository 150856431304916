import { apolloClient } from '@/apolloConfig';
import * as queries from '@/queries/passwordRecovery';
import * as types from '../types';

const getters = {
  [types.GET_TOKEN_IS_VALID]: ({ tokenIsValid }) => tokenIsValid,
};

const mutations = {
  [types.MUTATE_FETCH_TOKEN_VALID]: (state, isValid) => {
    const current = state;
    current.tokenIsValid = isValid;
  },
};

const actions = {
  [types.FETCH_VERIFY_TOKEN]: ({ commit }, token) => {
    apolloClient.query({
      query: queries.GET_RECOVERY_TOKEN_VALID,
      variables: { token },
    }).then(({ data, loading }) => {
      commit(types.MUTATE_FETCH_TOKEN_VALID, data.recoveryTokenIsValid);
      commit(types.MUTATE_PAGE_LOADING, loading);
    }).catch((e) => { console.error(e); });
  },

  [types.SEND_RECOVERY_EMAIL]: async ({ commit }, email) => {
    await apolloClient.mutate({
      mutation: queries.CREATE_RECOVERY_REQUEST,
      variables: { email },
    }).then(({ data }) => {
      commit(types.MUTATE_MESSAGE, data.createRecoveryRequest);
    }).catch((err) => console.error(err));
  },

  [types.RECOVER_PASSWORD]: async ({ commit }, { token, newPassword }) => {
    await apolloClient.mutate({
      mutation: queries.PERFORM_RECOVERY,
      variables: { token, newPassword },
    }).then(({ data }) => {
      const { message, user } = data.performRecovery;
      if (user) {
        commit(types.MUTATE_LOGIN, user);
      }
      commit(types.MUTATE_MESSAGE, message);
    }).catch((err) => console.error(err));
  },
};

const state = {
  tokenIsValid: true,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
