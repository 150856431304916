import gql from 'graphql-tag';
import { CARD_FRAGMENT } from './fragments';

// ----------------QUERIES------------------

export const GET_ALL = gql`
  query($boxId: ID){
    getWorkflowsByBoxId(boxId: $boxId) {
      id
      name
      cards{
        ...card
      }

      groups {
        id
        name
        cards {
          ...card
        }
      }

      propertiesSum {
        name
        total
      }
    }
  }
  ${CARD_FRAGMENT.cardOverview}
`;

export const GET_WORKFLOW_NAMES_IDS_CARD_IDS = gql`
   query($boxId: ID){
    getWorkflowsByBoxId(boxId: $boxId) {
      id
      name
      cards {
        id
      }
    }
  }
`;

export const GET_WORKFLOW_NAMES_IDS = gql`
   query($boxId: ID){
    getWorkflowsByBoxId(boxId: $boxId) {
      id
      name
    }
  }
`;

export const GET_WORKFLOW = gql`
  query($workflowId: ID){
    getWorkflow(workflowId: $workflowId) {
      id
      name
      cards{
        ...cardInExpandedView
      }

      groups {
        id
        name
        cards {
          ...cardInExpandedView
        }
      }
    }
  }
  ${CARD_FRAGMENT.cardOverviewOnColumnExpansion}
`;

// ----------------MUTATIONS------------------

export const ADD = gql`
  mutation($name: String!, $boxId: ID!) {
    addWorkflow(name: $name, boxId: $boxId){
      id
      name
    }
  }
`;

export const UPDATE = gql`
  mutation($id: ID!,$name: String!) {
    updateWorkflow(id: $id, name: $name){
      id
      name
    }
  }
`;

export const DELETE = gql`
  mutation($id: ID!) {
    removeWorkflow(id: $id){
      id
      name
    }
  }
`;

export const MOVE_CARD = gql`
 mutation($from: ID, $to: ID!, $card: ID!, $position: Int) {
    moveCardInWorkflow(
      from: $from,
      to: $to,
      card: $card,
      position: $position,
    ) {
      from
    }
  }
`;
export const MOVE_WORKFLOW = gql`
 mutation($box: ID!, $newPosition: Int!, $workflowId: ID!, $oldPosition: Int!) {
    moveWorkflowInBox(
      box: $box,
      newPosition: $newPosition,
      workflowId: $workflowId,
      oldPosition: $oldPosition,
    )
  }
`;

// ----------------SUBSCRIPTIONS------------------

export const SUBSCRIBE_ADDED = gql`
  subscription {
    workflowAdded {
      id
      boxId
      name
      cards {
        id
        title
      }

      groups {
        id
        name
        cards {
          id
          title
        }
      }
    }
  }
`;

export const SUBSCRIBE_UPDATED = gql`
  subscription {
    workflowUpdated {
      id
      name
      boxId
    }
  }
`;

export const SUBSCRIBE_WORKFLOW_PROPERTY_SUM_UPDATE = gql`
  subscription {
    propertiesSumUpdated {
      id
      propertiesSum {
        name
        total
      }
    }
  }
`;

export const SUBSCRIBE_DELETE = gql`
  subscription {
    workflowRemoved {
      id
      boxId
    }
  }
`;

export const SUBSCRIBE_MOVE_CARD = gql`
  subscription {
    cardMoved {
      from
      to
      card {
        ...card
      }
      position
    }
  }
  ${CARD_FRAGMENT.cardOverview}
`;

// We dont need too much card info for this subscription
export const SUBSCRIBE_MOVE_CARD_EXPANDED_COLUMN = gql`
  subscription {
    cardMoved {
      from
      to
      card {
        ...cardInExpandedView
      }
      position
    }
  }
  ${CARD_FRAGMENT.cardOverviewOnColumnExpansion}
`;

export const SUBSCRIBE_MOVE_WORKFLOW = gql`
  subscription {
    workflowMoved {
      box
      newPosition
      oldPosition
    }
  }
`;
