import Vue from 'vue';
import './plugins/vue-wysiwyg';
import tinymce from 'vue-tinymce-editor';
import VueI18n from 'vue-i18n';
import Vuelidate from 'vuelidate';
import '@fortawesome/fontawesome-free/css/all.css'; // Ensure you are using css-loader
import VueApollo from 'vue-apollo';
import axios from 'axios';
import moment from 'moment';
import Sortable from 'sortablejs/modular/sortable.core.esm'; // Core SortableJS (without default plugins)
import { apolloProvider } from '@/apolloConfig';
import wError from '@/components/common/wError.vue';
import { debugging } from '@/scripts/debugger';
import DateFormat from '@/lib/dateFormat';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import { registerErrorHandler } from './middlewares/errorHandlers';
import '@/middlewares/vueDirectives';

Vue.config.productionTip = false;

Vue.use(VueApollo);
Vue.use(Vuelidate);

const isSecure = window.location.protocol === 'https:';

const HTTP_PROTOCOL = `http${isSecure ? 's' : ''}:`;
const PORT = isSecure ? 443 : 3000;
const BASE_URL = `${window.location.hostname}:${PORT}`;

// Create base for Axios request to download files
const base = axios.create({
  baseURL: `${HTTP_PROTOCOL}//${BASE_URL}`,
});

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.component('wError', wError);
Vue.component('tinymce', tinymce);

Vue.mixin({
  methods: {
    registerErrorHandler,
  },
});

// Allowing Axios in this.$http globally
Vue.prototype.$http = base;

// Use moment throughout the project
Vue.prototype.$moment = moment;

// Enable using Sortable globally
Vue.prototype.$sortable = Sortable;

// Inject debugging func to vue
Vue.prototype.$debugger = debugging;

// Create instance of date format
const dateFormat = new DateFormat();
// inject the dateFormat to Vue
Vue.prototype.$dateFormat = dateFormat;

// Add a global truncate filter to use everywhere
Vue.filter('truncate', (text, maxLength) => text.slice(0, maxLength) + (maxLength < text.length ? '...' : ''));

new Vue({
  apolloProvider,
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
