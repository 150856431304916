import { apolloClient } from '@/apolloConfig';
import {
  ADD, GET, UPDATE_TITLE, UPDATE_SUBTITLE,
  DELETE, COMPLETE_COPY, NOTIFY_WATCHERS_ON_CHANGE, BULK_DELETE,
} from '@/queries/card';
import {
  PIN_CARD, UNPIN_CARD,
} from '@/queries/cardPin';
import * as types from '../types';

/**
 * GETTERS
 */
const getters = {
  [types.GET_SELECTED_CARD]: (state) => state.selectedCard,
  [types.GET_CARD_HEADER]: (state) => state.header,
  [types.GET_CARD_PANEL]: (state) => state.panel,
  [types.GET_CARD_WAS_MODIFIED]: (state) => state.wasModified,
  [types.GET_TITLE_DIRTY]: (state) => state.titleDirty,
  [types.GET_SUBTITLE_DIRTY]: (state) => state.subtitleDirty,
};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_CARD_DIALOG]: (state, card) => {
    const current = state;
    current.selectedCard = card;
  },

  [types.MUTATE_CARD_HEADER]: (state, header) => {
    const current = state;
    current.header = header;
  },

  [types.MUTATE_CARD_HEADER_WORKFLOW]: (state, cardMoved) => {
    const current = state;
    if (current.selectedCard.id === cardMoved.card.id && current.selectedCard.isOpen) {
      current.header.workflow = cardMoved.to;
    }
  },

  [types.MUTATE_CARD_HEADER_CATEGORY]: (state, cardMoved) => {
    const current = state;
    if (current.selectedCard.id === cardMoved.card.id && current.selectedCard.isOpen) {
      current.header.category = cardMoved.to;
    }
  },

  [types.MUTATE_UPDATE_CARD_TITLE]: (state, title) => {
    const current = state;
    current.header.title = title;
  },

  [types.MUTATE_UPDATE_CARD_SUBTITLE]: (state, subtitle) => {
    const current = state;
    current.header.subtitle = subtitle;
  },

  [types.MUTATE_UPDATE_CARD_DIALOG_WORKFLOW]: (state, workflow) => {
    const { selectedCard } = state;
    selectedCard.workflowId = workflow;
  },

  [types.MUTATE_UPDATE_CARD_DIALOG_CATEGORY]: (state, category) => {
    const { selectedCard } = state;
    selectedCard.categoryId = category;
  },

  [types.MUTATE_ATTACHMENTS]: (state, attachments) => {
    const current = state;
    current.attachments = attachments;
  },

  [types.MUTATE_UPDATE_CARD_HEADER]: (state, {
    id, title, subtitle,
  }) => {
    const current = state;
    const { header } = current;
    header.id = id;
    header.title = title;
    header.subtitle = subtitle;
  },

  [types.MUTATE_CARD_PANEL]: (state, panel) => {
    const current = state;
    current.panel = panel;
  },

  [types.MUTATE_CARD_WAS_MODIFIED]: (state, wasModified) => {
    const current = state;
    current.wasModified = wasModified;
  },

  [types.MUTATE_SUBSCRIBE_PIN_CARD]: (state, cardPinned) => {
    const current = state;
    const { selectedCard } = current;
    const { cardId } = cardPinned;
    if (selectedCard.id === cardId) {
      selectedCard.isPinned = true;
    }
  },

  [types.MUTATE_SUBSCRIBE_UNPIN_CARD]: (state, cardUnpinned) => {
    const current = state;
    const { selectedCard } = current;
    const { cardId } = cardUnpinned;
    if (selectedCard.id === cardId) {
      selectedCard.isPinned = false;
    }
  },

  [types.MUTATE_TOGGLE_TITLE_DIRTY]: (state, isEditing) => {
    const current = state;
    current.titleDirty = isEditing;
  },

  [types.MUTATE_TOGGLE_SUBTITLE_DIRTY]: (state, isEditing) => {
    const current = state;
    current.subtitleDirty = isEditing;
  },
};

/**
 * ACTIONS
 */
const actions = {
  [types.ADD_CARD]: (_, {
    title,
    box,
    workflow,
    group,
    category,
    labels,
    template = '-1',
    onTop,
  }) => {
    if (box === undefined && title === undefined) {
      throw new Error('Error boxId and card title are mandatory');
    }
    apolloClient
      .mutate({
        mutation: ADD,
        variables: {
          title,
          box,
          workflow,
          group,
          category,
          labels,
          template,
          onTop,
        },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.CARD_DIALOG]: ({ commit }, card) => {
    if (card.id !== '' && card.isOpen !== false) {
      apolloClient.query({
        query: GET,
        variables: {
          id: card.id,
        },
      })
        .then(({ data }) => {
          const getCard = data.getCardById;
          if (card.index) { getCard.index = Number(card.index); }
          commit(types.MUTATE_CARD_HEADER, getCard);
          commit(types.MUTATE_CARD_DIALOG, {
            ...card,
            isTemplate: getCard.isTemplate,
            isArchive: getCard.isArchive,
            isPinned: getCard.isPinned,
          });
          commit(types.MUTATE_FETCH_CARD_LABELS, getCard);
          commit(types.MUTATE_FETCH_DESCRIPTION, getCard.description);
          commit(types.MUTATE_FETCH_CUSTOM_DESCRIPTIONS, getCard.customDescriptions);
          commit(types.MUTATE_FETCH_CHECKLISTS, getCard.checklists);
          commit(types.MUTATE_ATTACHMENTS, getCard.attachments);
          commit(types.MUTATE_CARD_MEMBERS, getCard.members);
          commit(types.MUTATE_CARD_DATE, getCard.dates);
          commit(types.MUTATE_FETCH_PROPERTIES, getCard.properties);
          commit(types.MUTATE_CARD_COMMENTS, getCard.comments);
        }).catch(() => {
          commit(types.MUTATE_CARD_DIALOG, {
            id: '',
            workflowId: '',
            categoryId: '',
            isOpen: false,
            isTemplate: false,
            isArchive: false,
            isPinned: false,
          });
        });
    } else {
      const header = {
        title: 'Unknown Title',
        subtitle: '',
      };
      commit(types.MUTATE_CARD_HEADER, header);
      commit(types.MUTATE_CARD_DIALOG, card);
      commit(types.MUTATE_CLEAR_COMMENTS);
    }
  },

  [types.UPDATE_CARD_TITLE]: ({ commit, state }, title) => {
    apolloClient.mutate({
      mutation: UPDATE_TITLE,
      variables: {
        id: state.selectedCard.id,
        title,
      },
    })
      .then(({ data }) => {
        const updatedTitle = data.updateCard.title;
        commit(types.MUTATE_UPDATE_CARD_TITLE, updatedTitle);
      })
      .catch((err) => {
        console.error(err);
      });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.UPDATE_CARD_SUBTITLE]: ({ commit, state }, subtitle) => {
    apolloClient.mutate({
      mutation: UPDATE_SUBTITLE,
      variables: {
        id: state.selectedCard.id,
        title: state.header.title,
        subtitle,
      },
    })
      .then(({ data }) => {
        const updatedSubtitle = data.updateCard.subtitle;
        commit(types.MUTATE_UPDATE_CARD_SUBTITLE, updatedSubtitle);
      })
      .catch((err) => {
        console.error(err);
      });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.DELETE_CARD]: (_, cardId) => {
    apolloClient
      .mutate({
        mutation: DELETE,
        variables: {
          cardId,
        },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.UPDATE_CARD_DIALOG_WORKFLOW]: ({ commit }, workflow) => {
    commit(types.MUTATE_UPDATE_CARD_DIALOG_WORKFLOW, workflow);
  },

  [types.UPDATE_CARD_DIALOG_CATEGORY]: ({ commit }, category) => {
    commit(types.MUTATE_UPDATE_CARD_DIALOG_CATEGORY, category);
  },

  [types.SUBSCRIBE_CARD_HEADER_UPDATE]: ({ commit }, card) => {
    commit(types.MUTATE_UPDATE_CARD_HEADER, card);
  },

  [types.COPY_CARD_COMPLETE]: ({ commit }, {
    cardId, index, boxId, route,
  }) => {
    apolloClient.mutate({
      mutation: COMPLETE_COPY,
      variables: {
        cardId,
        index,
        boxId,
        route,

      },
    }).then(({ data }) => {
      const { type, message } = data.completeCopyCard;
      commit(types.MUTATE_MESSAGE, { type, message });
    })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.UPDATE_CARD_PANEL]: ({ commit }, panel) => {
    commit(types.MUTATE_CARD_PANEL, panel);
  },

  [types.NOTIFY_CARD_WATCHERS_ON_CHANGE]: ({ commit }, cardId) => {
    apolloClient.mutate({
      mutation: NOTIFY_WATCHERS_ON_CHANGE,
      variables: {
        cardId,
      },
    }).catch((e) => { console.error(e); });

    commit(types.MUTATE_CARD_WAS_MODIFIED, false);
  },

  [types.BULK_DELETE_CARDS]: ({ commit }, cardIds) => {
    apolloClient.mutate({
      mutation: BULK_DELETE,
      variables: {
        cardIds,
      },
    }).then(({ data }) => {
      const { type, message } = data.deleteCards;
      commit(types.MUTATE_MESSAGE, { type, message });
    }).catch((e) => console.error(e));
  },

  [types.PIN_CARD_ACTION]: ({ commit }, cardId) => {
    apolloClient
      .mutate({
        mutation: PIN_CARD,
        variables: {
          cardId,
        },
      })
      .then(({ data }) => {
        const { type, message } = data.pinCard;
        commit(types.MUTATE_MESSAGE, { type, message });
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.UNPIN_CARD_ACTION]: ({ commit }, cardId) => {
    apolloClient
      .mutate({
        mutation: UNPIN_CARD,
        variables: {
          cardId,
        },
      })
      .then(({ data }) => {
        const { type, message } = data.unpinCard;
        commit(types.MUTATE_MESSAGE, { type, message });
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.SUBSCRIBE_PIN_CARD]: ({ commit }, card) => {
    commit(types.MUTATE_SUBSCRIBE_PIN_CARD, card);
  },

  [types.SUBSCRIBE_UNPIN_CARD]: ({ commit }, card) => {
    commit(types.MUTATE_SUBSCRIBE_UNPIN_CARD, card);
  },
};

/**
 * STATES
 */
const state = {
  cards: [],
  selectedCard: {
    id: '',
    workflowId: '',
    categoryId: '',
    isOpen: false,
    isTemplate: false,
    isArchive: false,
    isPinned: false,
  },
  /**
   * Everything needed in the card header
   * can be found here
   */
  header: {},
  panel: [1],
  wasModified: false,
  titleDirty: false,
  subtitleDirty: false,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
