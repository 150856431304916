<template>
  <div>
    <v-menu top offset-y nudge-left="30" transition="slide-y-transition">
      <template v-slot:activator="{ on }">
        <v-btn
          :color="boxColor ? `${fontColor(boxColor)}` : 'icon'"
          v-on="on"
          @click.stop
          @mousedown.stop
          icon
        >
          <v-icon small> fa-bars </v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item v-if="!box.isArchive" @click.stop="showUpdateDialog()" @mousedown.stop>
          <v-list-item-icon class="mr-3">
            <v-icon color="icon" small>fa-pen</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Edit</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="!box.isArchive" @click.stop="copyBoxDialog = true" @mousedown.stop>
          <v-list-item-icon class="mr-3">
            <v-icon color="icon" small>fa-copy</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("copy") }}</v-list-item-title>
        </v-list-item>

        <!--Archive box-->
        <v-list-item
          v-if="role.box.write && !box.isArchive"
          @click.stop="promptForArchive(true)"
          @mousedown.stop
        >
          <v-list-item-icon class="mr-3">
            <v-icon color="icon" small>fa-archive</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("archive") }}</v-list-item-title>
        </v-list-item>

        <!--Unarchive box-->
        <v-list-item
          v-if="role.box.write && box.isArchive"
          @click.stop="promptForArchive(false)"
          @mousedown.stop
        >
          <v-list-item-icon class="mr-3">
            <v-icon color="warning" small>fa-archive</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("unarchive") }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click.stop="() => $router.push(`/boxSettings/${boxId}`)">
          <v-list-item-icon class="mr-3">
            <v-icon color="icon" small>fa-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("settings") }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click.stop="deleteDialog()" @mousedown.stop v-if="role.box.remove">
          <v-list-item-icon class="mr-3">
            <v-icon color="error" small>fa-trash</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Dialog box for Update the Box -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card color="card">
        <v-card-title class="title title-blue font-weight-bold mb-2">
          {{ $t("title_editBox") }}
        </v-card-title>

        <v-card-text>
          <v-row v-if="role.box.write">
            <v-col>
              <span class="font-weight-medium">
                {{ $t("box_name") }}
              </span>
              <v-text-field
                v-model="name"
                :label="$t('subtitle_Box')"
                :rules="[() => !!name || $t('require_Name')]"
                required
                @keyup.enter="updateBox"
                autofocus
                solo
              />
            </v-col>
          </v-row>
          <v-divider />
          <v-row align="center" justify="center">
            <v-col cols="10">
              <span class="font-weight-medium" :class="!$vuetify.theme.dark ? 'black--text' : ''">
                {{ $t("box_color_picker") }}
              </span>
            </v-col>
            <v-col cols="2" class="text-end">
              <v-switch v-model="useColorPicker"></v-switch>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col sm="8" cols="12" class="text-center pa-0">
              <v-color-picker
                v-model="editColor"
                hide-canvas
                hide-inputs
                hide-mode-switch
                mode="hexa"
                :swatches="swatches"
                show-swatches
                flat
                class="box-color-picker"
                :class="!useColorPicker ? 'box-color-picker-disabled' : ''"
                :disabled="!useColorPicker"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text color="button_as_text" @click="updateBox()">
            {{ $t("update") }}
          </v-btn>
          <v-btn text color="warning" @click="dialog = false">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog to copy the box -->
    <v-dialog v-model="copyBoxDialog" max-width="500px">
      <v-card class="mx-auto" width="500" min-width="300" color="card">
        <v-card-title>
          {{ $t("copy_box") }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            solo
            clearable
            hide-details
            @keyup.enter="copyButton"
            v-model="copyName"
            :label="$t('copy_name')"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            :loading="boxCopiedStatus"
            :disabled="!copyName || boxCopiedStatus"
            text
            color="button_as_text"
            @click="copyButton"
          >
            {{ $t("copy") }}
          </v-btn>
          <v-btn text color="warning" @click="copyBoxDialog = false">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <WDeleteConfirmDialog
      ref="deleteDialog"
      @deleteItem="deleteBox"
      :labelName="$t('subtitle_Box')"
      :value="box.name"
      :title="$t('title_deleteBox', { name: box.name })"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as types from '@/stores/types';
import colorController from '@/scripts/colorController';
import WDeleteConfirmDialog from '@/components/common/wDeleteConfirmDialog.vue';

/**
 * @group Box
 * This component is contain the edit and delete box
 */
export default {
  name: 'WBoxMenu',

  components: {
    WDeleteConfirmDialog,
  },

  props: {
    // id and name of the box
    box: {
      type: Object,
      required: true,
    },
    boxColor: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      dialog: false,
      name: '',
      copyName: '',
      boxId: '',
      copyBoxDialog: false,
      boxCopiedStatus: false,
      editColor: '#F44336',
      useColorPicker: false,
      swatches: [
        ['#F44336', '#E91E63', '#550000'],
        ['#9C27B0', '#673AB7', '#3F51B5'],
        ['#2196F3', '#03A9F4', '#00BCD4'],
        ['#009688', '#4CAF50', '#CDDC39'],
        ['#FFC107', '#795548', '#607D8B'],
      ],
    };
  },

  mounted() {
    this.boxId = this.box.id;
    this.name = this.box.name;
    this.editColor = this.boxColor;
  },

  computed: {
    ...mapGetters({
      user: types.GET_USER,
    }),

    role() {
      if (this.user.id) {
        const member = this.box.members.find(({ user }) => (this.user || {}).id === user.id);

        if (member) {
          return member.role;
        }
      }

      // If no member was found due to unsynchronized data
      // return a default role to avoid crashing
      const defaultRole = {
        box: {
          write: false,
        },
      };

      return defaultRole;
    },

    isBoxNameDirty() {
      return this.name !== this.box.name;
    },

    isColorPickerDirty() {
      return this.boxColor !== this.editColor;
    },
  },

  methods: {
    ...mapActions({
      removeBox: types.DELETE_BOX,
      editBox: types.UPDATE_BOX,
      copyBox: types.COPY_BOX,
      colorBox: types.CHANGE_BOX_COLOR,
      archiveBox: types.ARCHIVE_BOX,
      unarchiveBox: types.UNARCHIVE_BOX,
    }),

    /**
     * @vuese
     * Determines whether the color of the text
     * should be black or white
     */
    fontColor(color) {
      if (colorController.shouldIUseDark(color)) {
        return 'black';
      }
      return 'white';
    },

    async copyButton() {
      if (!this.copyName) return;

      this.boxCopiedStatus = true;

      await this.copyBox({
        boxId: this.boxId,
        newName: this.copyName,
      });

      this.boxCopiedStatus = false;
      this.copyBoxDialog = false;
    },

    /**
     * @vuese
     * This method is to update all available
     * box settings for the user.
     *
     * - Updates the name of the box if the user changed it
     * AND has the right to change the name
     * - Updates the color of the box if the user changed it
     */
    updateBox() {
      if (this.role.box.write && this.isBoxNameDirty) {
        // The box name is mandatory for update
        if (this.name === '') {
          return;
        }

        const box = {
          id: this.boxId,
          name: this.name,
        };
        this.editBox(box);
      }

      if (this.useColorPicker) {
        if (this.isColorPickerDirty) {
          this.colorBox({
            boxId: this.boxId,
            // Firefox always sends an object and Chrome sends
            // a string, therefore we have to check accordingly
            color:
              typeof this.editColor === 'object' && this.editColor.hex !== ''
                ? this.editColor.hex
                : this.editColor,
          });
        }
      } else if (this.boxColor) {
        this.colorBox({
          boxId: this.boxId,
          color: '',
        });
      }

      this.dialog = false;
    },

    deleteDialog() {
      this.$refs.deleteDialog.show();
    },

    /**
     * @vuese
     * This method is to delete the Box
     */
    deleteBox() {
      if (this.box.id) {
        this.removeBox(this.box.id);
      }
    },

    /**
     * @vuese
     * This method is to show dialogue to edit Box
     */
    showUpdateDialog() {
      this.useColorPicker = !!this.boxColor;
      this.dialog = true;
    },

    /**
     * @vuese
     * Open dialog to archive/ unarchive the box and If 'yes' is selected, archive the box
     */

    promptForArchive(archive) {
      this.$confirm(
        archive ? this.$t('box_archive_confirm_message') : this.$t('box_unarchive_confirm_message'),
        {
          buttonTrueText: archive ? this.$t('archive') : this.$t('unarchive'),
          buttonFalseText: this.$t('cancel'),
          color: 'info',
          icon: 'fa-exclamation-triangle',
          title: this.$t('delete_confirm_title'),
        },
      ).then(async (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }

        if (this.box.id) {
          if (archive) {
            this.archiveBox(this.box.id);
          } else {
            this.unarchiveBox(this.box.id);
          }
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.box-color-picker {
  background-color: var(--v-column-base);
}

.box-color-picker-disabled {
  opacity: 0.3;
}
</style>
