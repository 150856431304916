import { apolloClient } from '@/apolloConfig';
import * as queries from '@/queries/label';
import { addCardsToColumns, filterCardsFromColumns } from '@/scripts/helper';
import * as types from '../types';

/**
 * ----------------------------GETTERS------------------------------------
 */
const getters = {
  [types.GET_CARDS_GROUP_BY_LABELS]: (state) => state.labels,
};

/**
 * ----------------------------MUTATIONS-----------------------------------
 */
const mutations = {
  [types.MUTATE_FETCH_CARDS_GROUP_BY_LABELS]: (state, labels) => {
    const current = state;
    current.labels = labels;
  },

  [types.MUTATE_SUBSCRIBE_LABEL_ADDED_TO_LABELVIEW]: (state, label) => {
    const current = state;
    current.labels = [...current.labels, label];
  },

  [types.MUTATE_SUBSCRIBE_LABEL_UPDATED_IN_LABELVIEW]: (state, {
    id, name, color, icon,
  }) => {
    const current = state;
    const { labels } = current;
    const index = labels.findIndex((l) => l.id === id);
    if (index !== -1) {
      labels[index].id = id;
      labels[index].name = name;
      labels[index].icon = icon;
      labels[index].color = color;
    }

    // Update the labels inside the card summary
    labels[index].cards.forEach((card, cardIndex) => {
      const labelIndex = card.labels.findIndex((l) => l.id === id);
      if (labelIndex !== -1) {
        labels[index].cards[cardIndex].labels[labelIndex].id = id;
        labels[index].cards[cardIndex].labels[labelIndex].name = name;
        labels[index].cards[cardIndex].labels[labelIndex].icon = icon;
        labels[index].cards[cardIndex].labels[labelIndex].color = color;
      }
    });
  },

  [types.MUTATE_SUBSCRIBE_LABEL_DELETED_FROM_LABELVIEW]: (state, label) => {
    const current = state;
    const { labels } = current;
    const index = labels.findIndex((l) => l.id === label.id);
    if (index !== -1) {
      labels.splice(index, 1);
    }
    // Delete the labels inside the card summary
    labels.forEach((thisLabel, labelIndex) => {
      thisLabel.cards.forEach((card, cardIndex) => {
        const innerLabelIndex = card.labels.findIndex((l) => l.id === label.id);
        if (innerLabelIndex !== -1) {
          labels[labelIndex].cards[cardIndex].labels.splice(innerLabelIndex, 1);
        }
      });
    });
  },

  [types.MUTATE_SUBSCRIBE_ADD_CARDS_IN_LABELVIEW]: (state, cards) => {
    const current = state;
    current.labels = addCardsToColumns(current.labels, cards, 'labels', false);
  },

  [types.MUTATE_SUBSCRIBE_ADD_CARD_IN_LABELVIEW]: (state, card) => {
    const current = state;
    const { labels } = current;
    card.labels.forEach((label) => {
      const index = labels.findIndex((l) => l.id === label.id);
      if (index !== -1) {
        if (!card.labelPosition || card.labelPosition < 0) {
          labels[index].cards = [...labels[index].cards, card];
        } else {
          labels[index].cards.splice(card.labelPosition, 0, card);
        }
      }
    });
  },

  [types.MUTATE_SUBSCRIBE_UPDATE_CARD_IN_LABELVIEW]: (state, card) => {
    if (card.isTemplate) {
      return;
    }

    const current = state;
    const { labels } = current;
    labels.forEach((label, index) => {
      const labelIndex = card.labels.findIndex((l) => l.id === label.id);
      const cardIndex = label.cards.findIndex((c) => c.id === card.id);

      /**
       * Remove the card
       */
      if (labelIndex === -1 && cardIndex !== -1) {
        labels[index].cards.splice(cardIndex, 1);
      }

      /**
       * Update the card
       */
      if (labelIndex !== -1 && cardIndex !== -1) {
        labels[index].cards.splice(cardIndex, 1, card);
      }

      /**
       * Add the card to label column if card is not exist in column
       */
      if (labelIndex !== -1 && cardIndex === -1) {
        labels[index].cards = [...labels[index].cards, card];
      }
    });
  },

  [types.MUTATE_SUBSCRIBE_REMOVED_CARD_FROM_LABELVIEW]: (state, removedCards) => {
    const current = state;

    current.labels = filterCardsFromColumns(current.labels, removedCards, 'labels', false);
  },

  [types.MUTATE_SUBSCRIBE_MOVE_CARD_BETWEEN_LABELS]: (state, cardMoved) => {
    const current = state;
    const { labels } = current;
    const {
      from,
      to,
      card,
      position,
    } = cardMoved;
    // TODO: NEED TO REFACTOR. Code duplication to remove the card inside labels
    labels.forEach((label) => {
      const { cards } = label;
      if (label.id === from) {
        const index = cards.findIndex((c) => c.id === card.id);
        if (index !== -1) {
          cards.splice(index, 1);
        }
      }
      if (label.id === to) {
        const index = cards.findIndex((c) => c.id === card.id);
        if (index !== -1) {
          cards.splice(index, 1);
        }
      }
    });
    labels.forEach((label) => {
      const { cards } = label;
      if (label.id === to) {
        cards.splice(position, 0, card);
      }
    });
  },

  [types.MUTATE_LABEL_PROPERTY_SUM_UPDATE]: (state, updatedPropertiesSum) => {
    const current = state;

    const index = current.labels.findIndex((w) => w.id === updatedPropertiesSum.id);

    if (index !== -1) {
      const oldSize = current.labels[index].propertiesSum ? current.labels[index].propertiesSum.length : 0;
      current.labels[index].propertiesSum.splice(0, oldSize);

      updatedPropertiesSum.propertiesSum.map((property, idx) => current.labels[index].propertiesSum.splice(idx, 0, property));
    }
  },
};

/**
 * ---------------------ACTIONS-------------------------------
 */
const actions = {
  [types.FETCH_CARDS_GROUP_BY_LABELS]: async ({ commit }, boxId) => apolloClient.query({
    query: queries.GET_ALL_CARDS,
    variables: {
      box: boxId,
    },
  }).then(({ data, loading }) => {
    commit(types.MUTATE_FETCH_CARDS_GROUP_BY_LABELS, data.getCardsGroupByLabel);
    // Loading switch to false in order to show the label page
    commit(types.MUTATE_PAGE_LOADING, loading);
  }),

  [types.MOVE_CARD_IN_LABEL]: ({ dispatch }, {
    from, to, card, position,
  }) => {
    apolloClient
      .mutate({
        mutation: queries.MOVE_CARD,
        variables: {
          from,
          to,
          card,
          position,
        },
      })
      .catch((err) => {
        console.error(err);
      });
    dispatch(types.NOTIFY_CARD_WATCHERS_ON_CHANGE, card);
  },

  [types.SUBSCRIBE_LABEL_ADDED_TO_LABELVIEW]: ({ commit }, label) => {
    commit(types.MUTATE_SUBSCRIBE_LABEL_ADDED_TO_LABELVIEW, label);
  },

  [types.SUBSCRIBE_LABEL_UPDATED_IN_LABELVIEW]: ({ commit }, label) => {
    commit(types.MUTATE_SUBSCRIBE_LABEL_UPDATED_IN_LABELVIEW, label);
  },

  [types.SUBSCRIBE_LABEL_DELETED_FROM_LABELVIEW]: ({ commit }, label) => {
    commit(types.MUTATE_SUBSCRIBE_LABEL_DELETED_FROM_LABELVIEW, label);
  },

  [types.SUBSCRIBE_ADD_CARD_IN_LABELVIEW]: ({ commit }, card) => {
    commit(types.MUTATE_SUBSCRIBE_ADD_CARD_IN_LABELVIEW, card);
  },

  [types.SUBSCRIBE_UPDATE_CARD_IN_LABELVIEW]: ({ commit }, card) => {
    commit(types.MUTATE_SUBSCRIBE_UPDATE_CARD_IN_LABELVIEW, card);
  },

  [types.SUBSCRIBE_REMOVED_CARD_FROM_LABELVIEW]: ({ commit }, card) => {
    commit(types.MUTATE_REMOVE_CARDS_FROM_SELECTION, card.map(({ id }) => id));
    commit(types.MUTATE_SUBSCRIBE_REMOVED_CARD_FROM_LABELVIEW, card);
  },

  [types.SUBSCRIBE_MOVE_CARD_BETWEEN_LABELS]: ({ commit }, card) => {
    commit(types.MUTATE_SUBSCRIBE_MOVE_CARD_BETWEEN_LABELS, card);
  },

  [types.ARCHIVE_CARD_FROM_LABELVIEW]: ({ commit }, cards) => {
    commit(types.MUTATE_REMOVE_CARDS_FROM_SELECTION, cards.map(({ id }) => id));
    commit(types.MUTATE_SUBSCRIBE_REMOVED_CARD_FROM_LABELVIEW, cards);
  },

  [types.UNARCHIVE_CARD_IN_LABELVIEW]: ({ commit }, cards) => {
    commit(types.MUTATE_SUBSCRIBE_ADD_CARDS_IN_LABELVIEW, cards);
  },

  [types.SUBSCRIBE_LABEL_PROPERTY_SUM_UPDATE]: ({ commit }, updatedPropertiesSum) => {
    commit(types.MUTATE_LABEL_PROPERTY_SUM_UPDATE, updatedPropertiesSum);
  },

};

/**
 * STATES
 */
const state = {
  labels: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
