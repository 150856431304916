import { apolloClient } from '@/apolloConfig';
import {
  DELETE_FILE,
  UPLOAD_FILES,
} from '@/queries/attachment';
import Vue from 'vue';
import * as types from '../types';

/**
 * GETTERS
 */
const getters = {
  [types.GET_ATTACHMENTS]: (state) => state.attachments,
  [types.GET_ATTACHMENTS_DIRTY]: (state) => state.attachDirty,
  [types.GET_TRIGGER_ATTACHMENTS_UPLOAD]: (state) => state.triggerAttachUpload,
};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_ATTACHMENTS]: (state, attachments) => {
    const current = state;
    current.attachments = attachments;
  },

  [types.MUTATE_ATTACHMENTS_DELETED_USER]: (state, deletedUser) => {
    const current = state;
    const { attachments } = current;

    /**
     * Set the "isDeleted" flag to true
     * for a deleted user on subscription
     * to change their avatar and name
     */
    attachments.forEach((attachment) => {
      if (attachment.user && attachment.user.id === deletedUser.id) {
        Vue.set(attachment.user, 'isDeleted', true);
      }
    });
  },

  [types.MUTATE_TOGGLE_ATTACH_DIRTY]: (state, isUploading) => {
    const current = state;
    current.attachDirty = isUploading;
  },

  [types.MUTATE_TRIGGER_ATTACH_UPLOAD]: (state, isUploading) => {
    const current = state;
    current.triggerAttachUpload = isUploading;
  },
};

/**
 * ACTIONS
 */
const actions = {
  // Attachments
  [types.UPLOAD_FILES]: ({ commit }, { cardId, files }) => {
    apolloClient.mutate({
      mutation: UPLOAD_FILES,
      variables: {
        cardId,
        files,
      },
    })
      .catch((err) => {
        console.error(err);
      });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.DELETE_FILE]: ({ commit }, { attachment, cardId }) => {
    apolloClient.mutate({
      mutation: DELETE_FILE,
      variables: {
        id: attachment.id,
        fileId: attachment.fileId.id,
        file: attachment.file,
        cardId,
      },
    })
      .catch((err) => {
        console.error(err);
      });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.ATTACHMENT_CHANGED_SUBSCRIPTION]: ({ commit }, attachments) => {
    commit(types.MUTATE_ATTACHMENTS, attachments);
  },

  [types.TOGGLE_ATTACH_DIRTY]: ({ commit }, isUploading) => {
    commit(types.MUTATE_TOGGLE_ATTACH_DIRTY, isUploading);
  },

  [types.TRIGGER_ATTACH_UPLOAD]: ({ commit }, isUploading) => {
    commit(types.MUTATE_TRIGGER_ATTACH_UPLOAD, isUploading);
  },
};

/**
 * STATES
 */
const state = {
  attachments: [],
  attachDirty: false,
  triggerAttachUpload: false,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
