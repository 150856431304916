import { apolloClient } from '@/apolloConfig';
import { REMOVE_CARD_COMMENT, ADD_CARD_COMMENT, EDIT_CARD_COMMENT } from '@/queries/cardComment';
import Vue from 'vue';
import * as types from '../types';

/**
 * GETTERS
 */
const getters = {
  [types.GET_CARD_COMMENTS]: (state) => state.cardComments,
};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_CARD_COMMENTS]: (state, cardComments) => {
    const current = state;
    current.cardComments = cardComments;
  },

  [types.MUTATE_SUBSCRIBE_ADDED_CARD_COMMENT]: (state, newComment) => {
    const current = state;
    current.cardComments = [...current.cardComments, newComment.comment];
  },

  [types.MUTATE_SUBSCRIBE_REMOVED_CARD_COMMENT]: (state, removedComment) => {
    const current = state;

    const index = current.cardComments.findIndex(
      (comment) => comment.id === removedComment.comment.id,
    );

    if (index !== -1) {
      current.cardComments.splice(index, 1);
    }
  },

  [types.MUTATE_SUBSCRIBE_EDITED_CARD_COMMENT]: (state, editedComment) => {
    const current = state;

    const index = current.cardComments.findIndex(
      (comment) => comment.id === editedComment.comment.id,
    );

    if (index !== -1) {
      current.cardComments.splice(index, 1, editedComment.comment);
    }
  },

  [types.MUTATE_CLEAR_COMMENTS]: (state) => {
    const current = state;
    const { cardComments } = current;

    cardComments.splice(0, cardComments.length);
  },

  [types.MUTATE_SUBSCRIBE_COMMENT_DELETED_USER]: (state, deletedUser) => {
    const current = state;
    const { cardComments } = current;

    cardComments.forEach((comment) => {
      if (comment.user.id === deletedUser.id) {
        Vue.set(comment.user, 'isDeleted', true);
      }
    });
  },
};

/**
 * ACTIONS
 */
const actions = {
  [types.ADD_CARD_COMMENT]: (_, { cardId, message }) => {
    apolloClient
      .mutate({
        mutation: ADD_CARD_COMMENT,
        variables: {
          cardId,
          message,
        },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.EDIT_CARD_COMMENT]: (_, { cardId, commentId, message }) => {
    apolloClient
      .mutate({
        mutation: EDIT_CARD_COMMENT,
        variables: {
          cardId,
          commentId,
          message,
        },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.REMOVE_CARD_COMMENT]: (_, { cardId, commentId }) => {
    apolloClient
      .mutate({
        mutation: REMOVE_CARD_COMMENT,
        variables: {
          cardId,
          commentId,
        },
      })
      .catch((err) => {
        console.error(err);
      });
  },
};

/**
 * STATES
 */
const state = {
  cardComments: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
