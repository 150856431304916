<template>
  <v-toolbar
    :extended="extended"
    max-width="100%"
    color="toolbar"
    dense
    class="v-toolbar-inside-box"
    style="background-color: transparent !important"
  >
    <template v-if="extended" #extension>
      <div class="d-flex flex-grow-1 justify-space-around">
        <slot name="filter" />

        <template v-if="$vuetify.breakpoint.smAndDown">
          <slot name="calendar-month-changer" />
        </template>
      </div>
    </template>

    <!-- Page title -->
    <v-toolbar-title class="title flex-shrink-0">
      <wBreadcrumb />
    </v-toolbar-title>

    <v-spacer />

    <template v-if="$vuetify.breakpoint.mdAndUp">
      <slot name="calendar-month-changer" />
    </template>

    <v-toolbar-items class="mt-2">
      <!-- Placeholder for search Text field -->
      <slot name="search" />
    </v-toolbar-items>

    <!-- SHOW BOX USERS -->
    <wBoxUsers
      :boxMembers="getBoxMembers"
      v-if="$route.params.boxId && $vuetify.breakpoint.smAndUp"
      :maxVisibleUser="5"
    />

    <v-divider
      v-if="$route.params.boxId && $vuetify.breakpoint.smAndUp"
      vertical
      class="ml-1"
    ></v-divider>

    <v-icon> fa-pipe </v-icon>

    <v-tooltip v-if="displayMultiselectButtons" bottom>
      <template #activator="{ on }">
        <v-btn
          icon
          :disabled="!selectedCardsCount"
          color="error"
          v-on="on"
          @click="deleteAllSelected"
        >
          <v-icon small> fa-trash </v-icon>
        </v-btn>
      </template>
      {{ $t("delete_all_selected_cards") }}
    </v-tooltip>
    <v-tooltip v-if="displayMultiselectButtons" bottom>
      <template #activator="{ on }">
        <v-btn
          icon
          :disabled="!selectedCardsCount"
          color="warning"
          v-on="on"
          @click="archiveAllSelected"
        >
          <v-icon small> fa-archive </v-icon>
        </v-btn>
      </template>
      {{ $t("archive_all_selected_cards") }}
    </v-tooltip>

    <w-unarchive-dialog
      :selected-cards="Array.from(allSelectedCards)"
      v-if="displayMultiselectButtons && isAllCardsView()"
    >
      <template #open="{ clickFunction }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              icon
              :disabled="!selectedCardsCount"
              color="warning"
              v-on="on"
              @click="clickFunction"
            >
              <v-icon small> fa-folder-open </v-icon>
            </v-btn>
          </template>
          {{ $t("unarchive_all_selected_cards") }}
        </v-tooltip>
      </template>
    </w-unarchive-dialog>

    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn v-on="on" icon v-if="displayMultiSelectToggle" @click="toggleMultiSelect">
          <v-icon small v-if="!multiselectOn"> fa-check-double </v-icon>
          <v-avatar
            v-else
            size="38"
            :color="$vuetify.theme.dark ? 'card darken-1' : 'card darken-2'"
            class="font-weight-bold"
          >
            {{ selectedCardsCount }}
          </v-avatar>
        </v-btn>
      </template>
      {{ $t("toggle_multi_select") }}
    </v-tooltip>

    <v-btn
      icon
      v-if="$route.params.boxId && $route.name !== 'BoxSettings'"
      @click="extended = !extended"
    >
      <v-icon small> fa-filter </v-icon>
    </v-btn>

    <router-link
      v-if="$route.params.boxId"
      tag="button"
      :to="`/boxSettings/${$route.params.boxId}`"
    >
      <v-btn icon>
        <v-icon :small="$vuetify.breakpoint.xsOnly"> fa-cog </v-icon>
      </v-btn>
    </router-link>

    <v-btn icon v-if="!sidebar && boxId" @click="sidebar = !sidebar">
      <v-icon :small="$vuetify.breakpoint.xsOnly">fa-bars</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
/**
 * @group Common
 * Placeholder for view controllers such as search and title
 */
import { mapGetters, mapActions } from 'vuex';
import {
  GET_SELECTED_BOX,
  UPDATE_SIDEBAR_ACTIVE,
  GET_SIDEBAR_ACTIVE,
  TOGGLE_MULTISELECT,
  GET_IS_CARD_MULTISELECT_ON,
  GET_SELECTED_CARDS_UPDATE,
  GET_SELECTED_CARDS,
  BULK_DELETE_CARDS,
  ARCHIVE_CARDS_ACTION,
  UNARCHIVE_CARDS_ACTION,
  GET_COLLABORATORS_FOR_BOX,
  FETCH_BOX_MEMBERS,
} from '@/stores/types';
import wBreadcrumb from '@/components/common/wBreadcrumb.vue';
import wUnarchiveDialog from '@/components/common/wUnarchiveDialog.vue';
import wBoxUsers from '@/components/box/wBoxUsers.vue';

export default {
  name: 'WToolbar',

  components: {
    wBreadcrumb,
    wUnarchiveDialog,
    wBoxUsers,
  },

  created() {
    this.boxId = this.$route.params.boxId;
    if (this.boxId) {
      this.fetchBoxMembers(this.boxId);
    }
  },

  data() {
    return {
      boxId: '',
      extended: this.$vuetify.breakpoint.smAndDown,
    };
  },

  computed: {
    ...mapGetters({
      box: GET_SELECTED_BOX,
      getSidebar: GET_SIDEBAR_ACTIVE,
      multiselectOn: GET_IS_CARD_MULTISELECT_ON,
      selectedCards: GET_SELECTED_CARDS,
      selectedCardsUpdated: GET_SELECTED_CARDS_UPDATE,
      getBoxMembers: GET_COLLABORATORS_FOR_BOX,
    }),

    selectedCardsCount() {
      return this.selectedCardsUpdated && this.selectedCards.size;
    },

    allSelectedCards() {
      return this.selectedCardsUpdated && this.selectedCards;
    },

    sidebar: {
      get() {
        return this.getSidebar;
      },
      set(isActive) {
        this.setSidebar(isActive);
      },
    },

    displayMultiselectButtons() {
      return (
        !this.box.isArchive
        && this.$route.params.boxId
        && this.$route.name !== 'BoxSettings'
        && this.multiselectOn
      );
    },

    displayMultiSelectToggle() {
      return (
        !this.box.isArchive
        && this.$route.params.boxId
        && !['BoxSettings', 'Calendar'].includes(this.$route.name)
      );
    },
  },

  methods: {
    ...mapActions({
      setSidebar: UPDATE_SIDEBAR_ACTIVE,
      toggleMultiSelect: TOGGLE_MULTISELECT,
      deleteCards: BULK_DELETE_CARDS,
      archiveCards: ARCHIVE_CARDS_ACTION,
      unarchiveCards: UNARCHIVE_CARDS_ACTION,
      fetchBoxMembers: FETCH_BOX_MEMBERS,
    }),

    /**
     * @vuese
     * This method is to check if current view is "allcards"
     */

    isAllCardsView() {
      return this.$route.name === 'AllCards';
    },

    deleteAllSelected() {
      this.$confirm(this.$t('delete_bulk_confirm', { count: this.selectedCardsCount }), {
        buttonTrueText: this.$t('delete'),
        buttonFalseText: this.$t('cancel'),
        color: 'error',
        icon: 'fa-exclamation-triangle',
        title: this.$t('delete_confirm_title'),
      }).then((isConfirmed) => {
        if (!isConfirmed) {
          return;
        }

        this.deleteCards(Array.from(this.selectedCards));
        this.toggleMultiSelect();
      });
    },

    archiveAllSelected() {
      this.$confirm(this.$t('archive_bulk_confirm', { count: this.selectedCardsCount }), {
        buttonTrueText: this.$t('archive'),
        buttonFalseText: this.$t('cancel'),
        color: 'warning',
        icon: 'fa-exclamation-triangle',
        title: this.$t('confirm_title'),
      }).then((isConfirmed) => {
        if (!isConfirmed) {
          return;
        }

        this.archiveCards(Array.from(this.selectedCards));
        this.toggleMultiSelect();
      });
    },
  },
};
</script>
