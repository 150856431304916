<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <v-card class="dialog-card">
      <v-card-title class="headline dialog-title">
        <v-icon class="mr-2" style="color: #ffffff">fa-exclamation-triangle</v-icon>
        {{ $t("delete_confirm_title") }}
      </v-card-title>
      <!--- v-card content -->
      <v-card-text class="dialog-content">
        <div style="padding-top: 10px">
          <p style="font-size: 1.15rem" v-html="title"></p>

          <v-row no-gutters>
            <v-col cols="auto" style="padding-top: 10px">
              <span style="font-size: 1.15rem">{{ $t("item_name_to_delete") }} </span>
            </v-col>
            <v-col style="padding-left: 5px">
              <v-text-field
                v-model="name"
                :rules="nameRules"
                :label="labelName"
                solo
                autofocus
                outlined
                required
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="text-end">
          <v-btn color="primary" text class="my-1" @click="hide"> {{ $t("cancel") }}</v-btn>
          <v-btn color="error" :disabled="!valid" text class="my-1" @click="deleteItem">
            {{ $t("delete") }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'WDeleteConfirmDialog',

  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    labelName: {
      type: String,
      default: '',
      required: true,
    },
    value: {
      type: String,
      default: '',
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      name: '',
      nameRules: [
        (v) => !!v || this.$t('name_is_required'),
        (v) => v === this.value || this.$t('name_not_match'),
      ],
    };
  },

  computed: {
    valid() {
      return this.name === this.value;
    },
  },

  methods: {
    show() {
      this.dialog = true;
    },
    hide() {
      this.name = '';
      this.dialog = false;
    },
    deleteItem() {
      if (this.valid) {
        // call the parent's component delete method
        this.$emit('deleteItem');
        this.hide();
      }
    },
  },
};
</script>
<style scoped>
.dialog-card {
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.dialog-title {
  background-color: #f50707;
  color: white;
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.dialog-content {
  padding: 24px;
}
</style>
