import { apolloClient } from '@/apolloConfig';
import {
  GET_CARD_MEMBERS,
  REMOVE_CARD_MEMBER,
  ADD_CARD_MEMBERS,
} from '@/queries/cardMembers';
import * as types from '../types';

/**
 * GETTERS
 */
const getters = {
  [types.GET_CARD_MEMBERS]: (state) => state.cardMembers,
};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_CARD_MEMBERS]: (state, cardMembers) => {
    const current = state;
    current.cardMembers = cardMembers;
  },

  [types.MUTATE_SUBSCRIBE_CARD_MEMBERS_USER_DELETED]: (state, deletedUser) => {
    const current = state;

    current.cardMembers = current.cardMembers.filter((user) => user.id !== deletedUser.id);
  },
};

/**
 * ACTIONS
 */
const actions = {
  [types.FETCH_CARD_MEMBERS]: ({ commit }, cardId) => {
    apolloClient
      .query({
        query: GET_CARD_MEMBERS,
        variables: {
          cardId,
        },
      })
      .then(({ data }) => {
        commit(types.MUTATE_CARD_MEMBERS, data.getCardMembers);
      })
      .catch((err) => {
        console.error({ err });
      });
  },

  [types.REMOVE_CARD_MEMBER]: ({ commit }, { cardId, userId }) => {
    apolloClient
      .mutate({
        mutation: REMOVE_CARD_MEMBER,
        variables: {
          cardId,
          userId,
        },
      })
      .then(() => {
        // commit(types.MUTATE_CARD_MEMBERS, data.
      })
      .catch((err) => {
        console.error({ err });
      });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.ADD_CARD_MEMBERS]: ({ commit }, { cardId, users }) => {
    apolloClient
      .mutate({
        mutation: ADD_CARD_MEMBERS,
        variables: {
          cardId,
          users,
        },
      })
      .then(() => {
        // commit(types.MUTATE_CARD_MEMBERS, data.
      })
      .catch((err) => {
        console.error({ err });
      });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.SUBSCRIBE_CARD_MEMBERS_CHANGED]: ({ commit }, members) => {
    commit(types.MUTATE_CARD_MEMBERS, members);
  },
};

/**
 * STATES
 */
const state = {
  cardMembers: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
