<template>
  <v-card-actions>
    <!-- Decline Invitation -->
    <v-btn
      fab
      small
      :color="$vuetify.theme.dark ? 'red darken-1' : 'error'"
      class="ml-2"
      @click.stop="confirmDeclineInvitation()"
    >
      <v-icon size="19">fa-trash</v-icon>
    </v-btn>

    <v-spacer></v-spacer>

    <!-- Accept Invitation -->
    <v-btn
      fab
      small
      color="success"
      class="mr-2"
      @click.stop="acceptInvitation({ boxId: box.id })"
    >
      <v-icon size="20">fa-check</v-icon>
    </v-btn>
  </v-card-actions>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as types from '@/stores/types';

/**
 * @group Box
 * This component is contain the edit and delete box
 */
export default {
  name: 'WBoxInvitationControl',

  props: {
    // id and name of the box
    box: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
    }),
  },

  methods: {
    ...mapActions({
      acceptInvitation: types.ACCEPT_BOX_INVITATION,
      declineInvitation: types.DECLINE_BOX_INVITATION,
    }),

    /**
     * @vuese
     * This method is to decline the invitation
     */
    confirmDeclineInvitation() {
      this.$confirm(this.$t('title_decline_inviation'), {
        buttonTrueText: this.$t('decline'),
        buttonFalseText: this.$t('cancel'),
        color: 'error',
        icon: 'fa-exclamation-triangle',
        title: this.$t('delete_confirm_title'),
      })
        .then((isConfirmed) => {
          if (!isConfirmed) {
            return;
          }
          if (this.box.id) {
            this.declineInvitation({ boxId: this.box.id });
          }
        });
    },
  },
};

</script>
