<template>
        <v-menu
        :nudge-width="200"
        v-model="menu"
        offset-y
        transition="slide-y-transition"

      >
        <template v-slot:activator="{ on }">
          <v-btn slot="activator" icon color="white" v-on="on">
            <v-icon>fa-th</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list v-for="(view, i) in views" :key="i">
            <v-list-item
              @click="routeTo(view.page)"
              :class="isActivePage(view.page) ? 'active' : ''"
              >

              <v-list-item-avatar>
                <v-icon>{{ view.icon }}</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ $t(view.title) }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t(view.description) }}</v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
</template>

<script>
export default {
  name: 'WBoxViewMenu',

  data() {
    return {
      views: [
        {
          title: 'workflow',
          description: 'workflow_description',
          icon: 'fa-columns',
          page: 'workflow',
        },
        {
          title: 'category',
          description: 'category_description',
          icon: 'fa-shapes',
          page: 'category',
        },
        {
          title: 'label',
          description: 'label_description',
          icon: 'fa-tag',
          page: 'label',
        },
        {
          title: 'calendar',
          description: 'calendar_description',
          icon: 'fa-calendar-alt',
          page: 'calendar',
        },
        {
          title: 'all_cards',
          description: 'cards_description',
          icon: 'fa-asterisk',
          page: 'allCards',
        },
      ],
      menu: '',
    };
  },

  methods: {
    /**
     * @vuese
     * Navigate to different page view
     * @arg Page name
     */
    routeTo(page) {
      const { boxId } = this.$route.params;
      const currentPage = this.$router.history.current.name;

      if (page.toLowerCase() !== currentPage.toLowerCase()) {
        this.$router.push(`/${page}/${boxId}`);
      }
    },

    isActivePage(page) {
      const currentPage = this.$router.history.current.name;
      return page.toLowerCase() === currentPage.toLowerCase();
    },
  },
};
</script>

<style>
.active {
  background: var(--v-card-darken1);
}
</style>
