import * as types from '@/stores/types';

/**
 * ACTIONS
 */
const actions = {
  [types.CLEAN_VIEW_STATE]: async ({ commit }) => {
    // Workflow
    commit(types.MUTATE_FETCH_WORKFLOW, []);
    commit(types.MUTATE_FETCH_WORKFLOW_NAME_IDS_CARD_IDS, []);

    // Category
    commit(types.MUTATE_FETCH_CATEGORY, []);
    commit(types.MUTATE_FETCH_CATEGORY_NAME_IDS_CARD_IDS, []);

    // Label
    commit(types.MUTATE_FETCH_BOX_LABELS, []);

    // Calender
    commit(types.MUTATE_FETCH_CARDS_CALENDAR_VIEW, []);

    // Box
    commit(types.MUTATE_FETCH_COLLABORATORS_FOR_BOX, []);

    // Card
    commit(types.MUTATE_CARD_DIALOG, {});
    commit(types.MUTATE_CARD_MEMBERS, []);
    commit(types.MUTATE_FETCH_DESCRIPTION, '');
    commit(types.MUTATE_CARD_DATE, []);
    commit(types.MUTATE_FETCH_PROPERTIES, []);
    commit(types.MUTATE_ATTACHMENTS, []);
    commit(types.MUTATE_FETCH_CHECKLISTS, []);
    commit(types.MUTATE_FETCH_CARD_LABELS, []);

    commit(types.MUTATE_CARD_COMMENTS, []);

    // User Specific Box Setting
    commit(types.MUTATE_USER_SPECIFIC_BOX_SETTINGS, {});
  },
};

export default {
  actions,
};
