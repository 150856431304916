import * as types from '@/stores/types';
import { apolloClient } from '@/apolloConfig';
import {
  FETCH_USER_TEMP_BY_BOX,
  DELETE_USER_TEMP_FROM_BOX,
  FETCH_USERS_TEMP,
  INVITE_USER_TO_PUSHBOX,
  DELETE_USER_TEMP_FROM_PUSHBOX,
  RESEND_INVITATION,
  VERIFY_TOKEN,
  RESEND_INVITATION_FROM_BOX,
} from '@/queries/userTemp';

/**
 * GETTERS
 */
const getters = {
  [types.GET_USER_TEMP]: (state) => state.usersTemp,
  [types.GET_INVITE_LOADING]: (state) => state.loading,
  [types.GET_TOKEN_VERIFICATION]: (state) => state.emailFromToken,
};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_FETCH_USER_TEMP]: (state, newUsers) => {
    const current = state;
    current.usersTemp = newUsers;
  },

  [types.MUTATE_DELETE_USER_TEMP]: (state, deletedUser) => {
    const current = state;
    const index = current.usersTemp.findIndex((u) => u.id === deletedUser.id);
    if (index !== -1) {
      current.usersTemp.splice(index, 1);
    }
  },

  [types.MUTATE_USERS_INVITED_BY_EMAIL]: (state, users) => {
    const current = state;
    current.usersTemp = [...current.usersTemp, ...users];
  },

  [types.MUTATE_ADD_NEW_USER]: (state, user) => {
    const current = state;
    current.usersTemp = [...current.usersTemp, user];
  },

  [types.MUTATE_UPDATE_USER_TEMP]: (state, user) => {
    const current = state;
    const index = current.usersTemp.findIndex((u) => u.id === user.id);
    if (index !== -1) {
      current.usersTemp.splice(index, 1, user);
    }
  },

  [types.MUTATE_INVITATION_LOADING]: (state, loading) => {
    const current = state;
    current.loading = loading;
  },

  [types.MUTATE_TOKEN_VERIFICATION]: (state, email) => {
    const current = state;
    current.emailFromToken = email;
  },

  [types.DELETE_USER_TEMP_BY_EMAIL]: (state, email) => {
    const current = state;
    const index = current.usersTemp.findIndex((u) => u.email === email);
    if (index !== -1) {
      current.usersTemp.splice(index, 1);
    }
  },
};

/**
 * Actions
 */
const actions = {
  [types.FETCH_USER_TEMP_BY_BOX]: async ({ commit }, boxId) => new Promise((resolve, reject) => {
    apolloClient.query({
      query: FETCH_USER_TEMP_BY_BOX,
      variables: { boxId },
    })
      .then(({ data }) => {
        commit(types.MUTATE_FETCH_USER_TEMP, data.getUsersTempByBoxId);
        resolve();
      })
      .catch(() => {
        reject();
      });
  }),

  [types.FETCH_USERS_TEMP]: async ({ commit }) => new Promise((resolve, reject) => {
    apolloClient.query({
      query: FETCH_USERS_TEMP,
    })
      .then(({ data }) => {
        commit(types.MUTATE_FETCH_USER_TEMP, data.getAllUsersTemp);
        resolve();
      })
      .catch(() => {
        reject();
      });
  }),

  [types.DELETE_USER_TEMP_FROM_BOX]: async ({ commit }, { id, boxId }) => {
    await apolloClient.mutate({
      mutation: DELETE_USER_TEMP_FROM_BOX,
      variables: { id, boxId },
    })
      .then(({ data }) => {
        commit(types.MUTATE_DELETE_USER_TEMP, data.deletePendingUserFromBox);
      });
  },

  [types.INVITE_USER_TEMP_TO_PUSHBOX]: async ({ commit }, email) => {
    await apolloClient.mutate({
      mutation: INVITE_USER_TO_PUSHBOX,
      variables: {
        email,
      },
    })
      .then(({ data, loading }) => {
        const { inviteToPushbox } = data;
        commit(types.MUTATE_ADD_NEW_USER, inviteToPushbox);
        commit(types.MUTATE_INVITATION_LOADING, loading);
      }).catch((e) => {
        if (e) {
          commit(types.MUTATE_INVITATION_LOADING, false);
        }
      });
  },

  [types.DELETE_USER_TEMP_FROM_PUSHBOX]: async ({ commit }, id) => {
    await apolloClient.mutate({
      mutation: DELETE_USER_TEMP_FROM_PUSHBOX,
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        const { deleteUseTempFromPushbox } = data;
        commit(types.MUTATE_DELETE_USER_TEMP, deleteUseTempFromPushbox);
      })
      .catch((err) => {
        commit(types.MUTATE_MESSAGE, err.message);
      });
  },

  [types.RESEND_INVITATION]: async ({ commit }, id) => {
    await apolloClient.mutate({
      mutation: RESEND_INVITATION,
      variables: {
        id,
      },
    })
      .then(({ data, loading }) => {
        const { resendInvitation } = data;
        commit(types.MUTATE_UPDATE_USER_TEMP, resendInvitation);
        commit(types.MUTATE_INVITATION_LOADING, loading);
      })
      .catch((err) => {
        if (err) {
          commit(types.MUTATE_INVITATION_LOADING, false);
        }
      });
  },

  [types.VERIFY_TOKEN]: async ({ commit }, token) => new Promise((resolve, reject) => {
    apolloClient.mutate({
      mutation: VERIFY_TOKEN,
      variables: { token },
    })
      .then(({ data }) => {
        const { verifySignupToken } = data;
        commit(types.MUTATE_TOKEN_VERIFICATION, verifySignupToken);
        if (!verifySignupToken) {
          commit(types.MUTATE_MESSAGE, { type: 'ERROR', message: 'token_not_valid' });
        }
        resolve();
      })
      .catch(() => {
        reject();
      });
  }),

  [types.RESEND_INVITATIONS_BY_EMAIL_FROM_BOX]: async ({ commit }, { id, boxId }) => {
    await apolloClient.mutate({
      mutation: RESEND_INVITATION_FROM_BOX,
      variables: { id, boxId },
    })
      .then(({ data, loading }) => {
        const { resendInvitationFromBox } = data;
        commit(types.MUTATE_UPDATE_USER_TEMP, resendInvitationFromBox);
        commit(types.MUTATE_INVITATION_LOADING, loading);
      })
      .catch((err) => {
        if (err) {
          commit(types.MUTATE_INVITATION_LOADING, false);
        }
      });
  },

  [types.SUBSCRIBE_SIGNUP_WITH_TOKEN]: async ({ commit }, { email, member }) => {
    commit(types.DELETE_USER_TEMP_BY_EMAIL, email);
    commit(types.MUTATE_USER_SIGNED_UP, member);
  },
};

/**
 * States
 */
const state = {
  usersTemp: [],
  loading: false,
  emailFromToken: '',
};

export default {
  state,
  mutations,
  actions,
  getters,
};
