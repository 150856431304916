<template>
  <v-footer padless class="px-2" color="column">
    <!-- <div
      class="subtitle-2 font-weight-regular"
      :class="$vuetify.breakpoint.xsOnly ? 'caption' : ''"
    >
      Icons made by
      <a
        href="https://www.flaticon.com/authors/freepik"
        title="Freepik"
      >Freepik</a>
      from
      <a
        href="https://www.flaticon.com/"
        title="Flaticon"
      >www.flaticon.com</a>
    </div> -->
    <v-container class="pt-0">
      <v-row>
        <v-col cols="12">
          <v-row
            v-if="!isPublic"
            align="center"
            :justify="$vuetify.breakpoint.smAndUp && isPublic ? 'space-between' : 'center'"
            class="my-1"
          >
            <v-col md="3" sm="4" cols="10">
              <v-img
                :aspect-ratio="$vuetify.breakpoint.mdAndUp ? '5.5' : '4.5'"
                contain
                :src="require('@/assets/partners/wobe.svg')"
              />
            </v-col>

            <v-col md="3" sm="4" cols="10">
              <v-img
                :aspect-ratio="$vuetify.breakpoint.mdAndUp ? '5.5' : '4.5'"
                contain
                :src="require('@/assets/partners/kn-logo.png')"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-8"></v-divider>

          <v-row class="my-1" justify="center">
            <span
              class="subtitle-2 font-weight-regular"
              :class="$vuetify.breakpoint.xsOnly ? 'caption' : ''"
            >
              Build-No: {{ version.substring(0, 5) }} - &copy; {{ new Date().getFullYear() }}
              <a href="https://www.wobe-systems.com/" target="_blank">wobe-systems GmbH</a>
            </span>
            <v-divider class="mx-2" vertical></v-divider>
            <span
              class="subtitle-2 font-weight-regular"
              :class="$vuetify.breakpoint.xsOnly ? 'caption' : ''"
            >
              <a @click="showImpressum = true">Impressum</a>
            </span>
             <v-divider class="mx-2" vertical></v-divider>
              <span
                class="subtitle-2 font-weight-regular"
                :class="$vuetify.breakpoint.xsOnly ? 'caption' : ''"
              >
              <router-link to="/aboutUs">{{$t("about_us")}}</router-link>
            </span>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <wImpressum :show-impressum="showImpressum" @close="showImpressum = false" />
  </v-footer>
</template>

<script>
import wImpressum from '@/components/common/legalDialogs/wImpressum.vue';

export default {
  name: 'WFooter',

  components: {
    wImpressum,
  },

  data() {
    return {
      version: process.env.VUE_APP_CLIENT_VERSION,
      showImpressum: false,
    };
  },

  computed: {
    isPublic() {
      return window.location.href.includes('public');
    },
  },
};
</script>
