import { apolloClient } from '@/apolloConfig';
import {
  ADD_PROPERTY_TO_CARD,
  UPDATE_PROPERTY_TO_CARD,
  ADD_ITEM_TO_PROPERTY,
  UPDATE_PROPERTY_NAME,
  UPDATE_SELECTED_ITEM,
  DELETE_PROPERTY_FROM_CARD,
  DELETE_ITEM_FROM_PROPERTY,
  FETCH_PROPERTIES_FOR_CARD,
} from '@/queries/property';
import * as types from '../types';

/**
 * GETTERS
 */
const getters = {
  [types.GET_PROPERTIES_FOR_CARD]: ({ properties }) => properties,
  [types.GET_PROPERTY_DIRTY_FOR_CARD]: ({ propertyDirty }) => propertyDirty,
};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_FETCH_PROPERTIES]: (state, newProperties) => {
    const current = state;
    current.properties = newProperties;
  },

  [types.MUTATE_SUBSCRIBE_PROPERTY_ADDED]: (state, newProperty) => {
    const current = state;
    current.properties = [...current.properties, newProperty];
  },

  [types.MUTATE_SUBSCRIBE_PROPERTY_DELETED]: (state, deletedProperty) => {
    const current = state;
    const position = current.properties.findIndex((prop) => prop.id === deletedProperty.id);
    current.properties.splice(position, 1);
  },

  [types.MUTATE_SUBSCRIBE_PROPERTY_UPDATED]: (state, updatedProperty) => {
    const current = state;
    const position = current.properties.findIndex((prop) => prop.id === updatedProperty.id);
    current.properties.splice(position, 1, updatedProperty);
  },

  [types.MUTATE_TOGGLE_PROPERTY_DIRTY]: (state, isEditing) => {
    const current = state;
    current.propertyDirty = isEditing;
  },
};

/**
 * ACTIONS
 */
const actions = {
  [types.ADD_PROPERTY_TO_CARD]: ({ commit }, {
    cardId, name, type, value, addInTotal, items,
  }) => {
    apolloClient.mutate({
      mutation: ADD_PROPERTY_TO_CARD,
      variables: {
        cardId, name, type, value, addInTotal, items,
      },
    }).catch((err) => { console.error(err); });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.UPDATE_PROPERTY_TO_CARD]: ({ commit }, {
    cardId, propertyId, name, type, value, addInTotal, items,
  }) => {
    apolloClient.mutate({
      mutation: UPDATE_PROPERTY_TO_CARD,
      variables: {
        cardId, propertyId, name, type, value, addInTotal, items,
      },
    }).catch((err) => { console.error(err); });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.ADD_ITEM_TO_PROPERTY]: ({ commit }, { cardId, propertyId, newItem }) => {
    apolloClient.mutate({
      mutation: ADD_ITEM_TO_PROPERTY,
      variables: { cardId, propertyId, newItem },
    }).catch((err) => { console.error(err); });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.UPDATE_PROPERTY_NAME]: ({ commit }, property) => {
    apolloClient.mutate({
      mutation: UPDATE_PROPERTY_NAME,
      variables: property,
    }).catch((err) => { console.error(err); });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.UPDATE_SELECTED_ITEM]: ({ commit }, updatedProp) => {
    apolloClient.mutate({
      mutation: UPDATE_SELECTED_ITEM,
      variables: updatedProp,
    }).catch((err) => { console.error(err); });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.DELETE_PROPERTY_FROM_CARD]: ({ commit }, { cardId, propertyId }) => {
    apolloClient.mutate({
      mutation: DELETE_PROPERTY_FROM_CARD,
      variables: { cardId, propertyId },
    }).catch((err) => { console.error(err); });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.DELETE_ITEM_FROM_PROPERTY]: ({ commit }, { cardId, propertyId, itemId }) => {
    apolloClient.mutate({
      mutation: DELETE_ITEM_FROM_PROPERTY,
      variables: { cardId, propertyId, itemId },
    }).catch((err) => { console.error(err); });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.FETCH_PROPERTIES_FOR_CARD]: ({ commit }, cardId) => {
    apolloClient.query({
      query: FETCH_PROPERTIES_FOR_CARD,
      variables: { id: cardId },
    }).then(({ data }) => {
      commit(types.MUTATE_FETCH_PROPERTIES, data.getCardById.properties);
    }).catch((err) => { console.error(err); });
  },

  [types.SUBSCRIBE_PROPERTY_ADDED]: ({ commit }, property) => {
    commit(types.MUTATE_SUBSCRIBE_PROPERTY_ADDED, property);
  },

  [types.SUBSCRIBE_PROPERTY_DELETED]: ({ commit }, property) => {
    commit(types.MUTATE_SUBSCRIBE_PROPERTY_DELETED, property);
  },

  [types.SUBSCRIBE_PROPERTY_UPDATED]: ({ commit }, property) => {
    commit(types.MUTATE_SUBSCRIBE_PROPERTY_UPDATED, property);
  },
};

/**
 * STATES
 */
const state = {
  properties: [],
  propertyDirty: false,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
