import gql from 'graphql-tag';
import { CARD_DATES } from './fragments';

// ----------------QUERIES------------------

export const GET_DATE = gql`
  query getCardById($id: ID!) {
    getCardById(id: $id) {
      dates {
        ...date
      }
    }
  }
  ${CARD_DATES.date}
`;

// ----------------MUTATIONS------------------

export const CHANGE_DATE = gql`
  mutation($id: ID!, $date: inputDate) {
    changeDateOfCard(id: $id, date: $date) {
      id
      dates {
        ...date
      }
    }
  }
  ${CARD_DATES.date}
`;

export const DELETE_DATE = gql`
  mutation($id: ID!, $card: ID!) {
    deleteDateOfCard(id: $id, card: $card) {
      id
      dates {
        ...date
      }
    }
  }
  ${CARD_DATES.date}
`;

// ----------------SUBSCRIPTIONS------------------

export const SUBSCRIBE_DATE_CHANGED = gql`
subscription {
  cardDateChanged {
    id
    dates {
      ...date
    }
  }
}
${CARD_DATES.date}
`;
