<template>
  <div>
    <slot name="open" :clickFunction="activate" />
    <w-unarchive-workflow-selector
      v-model="workflow"
      @accept="unarchiveAllSelected"
      @cancel="displayDialog = false"
      :open="displayDialog"
    />
  </div>
</template>

<script>
import wUnarchiveWorkflowSelector from '@/components/allCards/wUnarchiveWorkflowSelector.vue';
import { mapActions } from 'vuex';
import * as types from '@/stores/types';
import { NO_VALUE } from '@/scripts/common';

export default {
  props: {
    selectedCards: {
      default: () => [],
      type: Array,
    },

    isCardDialogOpened: {
      default: false,
      type: Boolean,
    },
  },

  components: {
    wUnarchiveWorkflowSelector,
  },

  data() {
    return {
      workflow: NO_VALUE,
      displayDialog: false,
    };
  },

  computed: {
    cardsString() {
      return this.selectedCards.length > 1
        ? this.$t('delete_bulk_confirm', { count: this.selectedCardsCount })
        : this.$t('card_unarchive_confirm_message');
    },
  },

  methods: {
    ...mapActions({
      unarchiveCards: types.UNARCHIVE_CARDS_ACTION,
      closeCard: types.CARD_DIALOG,
    }),

    unarchiveAllSelected() {
      this.unarchiveCards({ cardIds: this.selectedCards, workflow: this.workflow });
      this.displayDialog = false;
      if (this.isCardDialogOpened) {
        this.closeCard({ isOpen: false });
        this.$router.replace(this.$route.path.substr(0, this.$route.path.lastIndexOf('/')));
      }
    },

    activate() {
      this.displayDialog = true;
    },
  },
};
</script>
