import * as types from '@/stores/types';
import { apolloClient } from '@/apolloConfig';
import {
  GET_ADMIN_CONFIG, GET_GENERAL_CONFIG, VERIFY_ADMIN, SET_ADMIN_CONFIG,
} from '@/queries/adminPanel';
import { FETCH_USER_STATISTICS } from '@/queries/user';

const getters = {
  [types.GET_LDAP_CONFIG]: ({ ldapConfig }) => ldapConfig,
  [types.GET_GENERAL_CONFIG]: ({ generalConfig }) => generalConfig,
  [types.GET_ADMIN_AUTHENTICATION_STATUS]: ({ isAdminAuthenticated }) => isAdminAuthenticated,
  [types.GET_USER_STATISTICS]: ({ userStatistics }) => userStatistics,
};

const mutations = {
  [types.MUTATE_FETCH_GENERAL_CONFIG]: (state, {
    isRegistrationAllowed,
    isInviteByNameAllowed,
    adLoginEnabled,
    forceTwoFactor,
    attachmentSizePerCard,
    passwordExpiry,
    alertMessage,
  }) => {
    const { generalConfig } = state;
    generalConfig.isRegistrationAllowed = isRegistrationAllowed;
    generalConfig.isInviteByNameAllowed = isInviteByNameAllowed;
    generalConfig.attachmentSizePerCard = attachmentSizePerCard;
    generalConfig.passwordExpiry = passwordExpiry;
    generalConfig.adLoginEnabled = adLoginEnabled;
    generalConfig.forceTwoFactor = forceTwoFactor;
    generalConfig.alertMessage = alertMessage;
  },

  [types.MUTATE_SUBSCRIBE_GENERAL_CONFIG_CHANGED]: (state, {
    isRegistrationAllowed,
    isInviteByNameAllowed,
    attachmentSizePerCard,
    adLoginEnabled,
    forceTwoFactor,
    passwordExpiry,
    alertMessage,
  }) => {
    const { generalConfig } = state;
    generalConfig.isRegistrationAllowed = isRegistrationAllowed;
    generalConfig.isInviteByNameAllowed = isInviteByNameAllowed;
    generalConfig.attachmentSizePerCard = attachmentSizePerCard;
    generalConfig.passwordExpiry = passwordExpiry;
    generalConfig.adLoginEnabled = adLoginEnabled;
    generalConfig.forceTwoFactor = forceTwoFactor;
    generalConfig.alertMessage = alertMessage;
  },

  [types.MUTATE_FETCH_LDAP_CONFIG]: (state, {
    adminGroup,
    userGroup,
    baseDN,
    emailDomains,
    domainController,
    userIdentifier,
    serviceAccount,
    whitelistedIp,
  }) => {
    const { ldapConfig } = state;
    ldapConfig.adminGroup = adminGroup;
    ldapConfig.userGroup = userGroup;
    ldapConfig.baseDN = baseDN;
    if (ldapConfig.emailDomains !== null) {
      ldapConfig.emailDomains = emailDomains;
    }
    ldapConfig.domainController = domainController;
    ldapConfig.userIdentifier = userIdentifier;
    ldapConfig.serviceAccount = serviceAccount;
    ldapConfig.whitelistedIp = whitelistedIp;
  },

  [types.MUTATE_SUBSCRIBE_LDAP_CONFIG_CHANGED]: (state, {
    adminGroup,
    userGroup,
    baseDN,
    emailDomains,
    domainController,
    userIdentifier,
    serviceAccount,
    whitelistedIp,
  }) => {
    const { ldapConfig } = state;
    ldapConfig.adminGroup = adminGroup;
    ldapConfig.userGroup = userGroup;
    ldapConfig.baseDN = baseDN;
    if (ldapConfig.emailDomains !== null) {
      ldapConfig.emailDomains = emailDomains;
    }
    ldapConfig.domainController = domainController;
    ldapConfig.userIdentifier = userIdentifier;
    ldapConfig.serviceAccount = serviceAccount;
    ldapConfig.whitelistedIp = whitelistedIp;
  },

  [types.MUTATE_ADMIN_AUTHENTICATION_STATUS]: (state, status) => {
    state.isAdminAuthenticated = status;
  },

  [types.MUTATE_USER_STATISTICS]: (state, {
    totalUsers, totalUserTemps, userRegistrations,
  }) => {
    const { userStatistics } = state;
    userStatistics.totalUsers = totalUsers;
    userStatistics.totalUserTemps = totalUserTemps;
    userStatistics.userRegistrations = userRegistrations;
  },
};

const actions = {
  [types.FETCH_ADMIN_CONFIG]: async ({ commit }) => {
    apolloClient.query({
      query: GET_ADMIN_CONFIG,
    }).then(({ data, loading }) => {
      const { getAdminConfig } = data;
      commit(types.MUTATE_FETCH_LDAP_CONFIG, getAdminConfig.ldapConfig);
      commit(types.MUTATE_FETCH_GENERAL_CONFIG, getAdminConfig.generalConfig);
      commit(types.MUTATE_PAGE_LOADING, loading);
    }).catch((err) => { console.error(err); });
  },

  [types.FETCH_GENERAL_CONFIG]: async ({ commit }) => new Promise((resolve, reject) => {
    apolloClient.query({
      query: GET_GENERAL_CONFIG,
    }).then(({ data }) => {
      const { getGeneralConfig } = data;
      commit(types.MUTATE_FETCH_GENERAL_CONFIG, getGeneralConfig);
      resolve(getGeneralConfig);
    }).catch((err) => {
      reject(err);
    });
  }),

  [types.VERIFY_ADMIN]: async ({ commit }, password) => new Promise((resolve, reject) => {
    apolloClient.mutate({
      mutation: VERIFY_ADMIN,
      variables: { password },
    }).then(({ data }) => {
      const { verifyAdmin } = data;

      if (verifyAdmin) {
        commit(types.MUTATE_ADMIN_AUTHENTICATION_STATUS, verifyAdmin);

        resolve(verifyAdmin);
      }
    }).catch((err) => {
      reject(err);
    });
  }),

  [types.UPDATE_ADMIN_CONFIG]: async ({ commit }, newValues) => {
    apolloClient.mutate({
      mutation: SET_ADMIN_CONFIG,
      variables: { newValues },
    }).then(({ data }) => {
      const { changeAdminConfig } = data;
      commit(types.MUTATE_MESSAGE, changeAdminConfig);
    }).catch((err) => { console.error(err); });
  },

  [types.SUBSCRIBE_LDAP_CONFIG_CHANGED]: async ({ commit }, changedConfig) => {
    commit(types.MUTATE_SUBSCRIBE_LDAP_CONFIG_CHANGED, changedConfig);
  },

  [types.FETCH_USER_STATISTICS]: async ({ commit }) => new Promise((resolve, reject) => {
    apolloClient.query({
      query: FETCH_USER_STATISTICS,
    }).then(({ data }) => {
      const { getUserStatistics } = data;

      commit(types.MUTATE_USER_STATISTICS, getUserStatistics);

      resolve();
    }).catch((err) => {
      reject(err);
    });
  }),
};

const state = {
  ldapConfig: {
    adminGroup: '',
    userGroup: '',
    baseDN: [],
    emailDomains: [],
    domainController: '',
    userIdentifier: '',
    serviceAccount: '',
    whitelistedIp: '',
  },
  generalConfig: {
    isRegistrationAllowed: true,
    isInviteByNameAllowed: false,
    adLoginEnabled: false,
    forceTwoFactor: false,
    attachmentSizePerCard: 0,
    passwordExpiry: 0,
    alertMessage: {
      show: false,
      messages: [
        {
          locale: 'en',
          message: '',
        },
      ],
    },
  },
  isAdminAuthenticated: false,
  userStatistics: {
    totalUsers: 0,
    totalUserTemps: 0,
    userRegistrations: [],
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
