<template>
  <v-tooltip open-delay="600" top>
    <template v-slot:activator="{ on }">
      <template v-if="isDeleted">
        <v-avatar :size="size" v-on="on">
          <v-img :src="require('@/assets/deleted_user.png')" alt="avatar" class="elevation-5" />
        </v-avatar>
      </template>

      <template v-else>
        <v-avatar
          v-if="image !== ''"
          :size="size"
          v-on="on"
          :style="setStyle ? `margin-right: 8px;` : ''"
        >
          <v-img :src="image" alt="avatar" class="elevation-5">
            <slot name="overlay" />
          </v-img>
        </v-avatar>

        <v-avatar
          v-else
          :color="initialsBackgroundColor()"
          :size="size"
          v-on="on"
          :style="setStyle ? `background-color: ${getVuetifyColorHex}; margin-right: 8px;` : ''"
        >
          <span
            style="cursor: default"
            :class="initialsClass()"
            :style="setStyle ? 'color: white; font-size: 0.75rem;' : ''"
          >
            {{ initials() }}
          </span>

          <slot name="overlay" />
        </v-avatar>
      </template>
    </template>
    <span v-html="tooltipText"></span>
  </v-tooltip>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as types from '@/stores/types';
import colors from 'vuetify/lib/util/colors';

/**
 * @group UserAccount
 * Avatar component contains the user's profile pic
 * add Avatar component to display the profile pic
 */
export default {
  name: 'WAvatar',

  props: {
    imageChanged: {
      type: String,
      default: '',
    },
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    userId: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 35,
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },

    /**
     * For unknown reasons when tagging users in the comments,
     * the avatar does not render the background color.
     * Therefore we use this prop to add raw CSS to force
     * the background color to show
     */
    setStyle: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      avatars: types.GET_AVATAR,
      avatarExpiryCheckArr: types.GET_AVATAR_EXPIRY_CHECK,
    }),

    /**
     * @vuese
     * Computes initials from fullname
     */
    tooltipText() {
      // Show 'Deleted User' if user no long exists
      if (this.isDeleted) {
        return this.$t('deleted_user');
      }

      let text = this.firstName;
      if (this.lastName.length > 0) {
        text += ` ${this.lastName}`;
      }
      return text;
    },

    getVuetifyColorHex() {
      const color = colors[this.initialsBackgroundColor()].base;

      return color;
    },
  },

  created() {
    // After creating Avatar component, fetch profile image if it's available for the user
    this.fetchImage();
  },

  data() {
    return {
      expiryTime: 30 * 60 * 1000, // Expire Threshold is now 30 minutes
      image: '',
      colorList: [
        'red',
        'pink',
        'purple',
        'indigo',
        'blue',
        'cyan',
        'teal',
        'green',
        'lime',
        'amber',
        'orange',
        'brown',
        'blue-grey',
        'light-blue',
      ],
    };
  },

  // User can change avatar anytime.
  // Image data also can be changed anytime for the same reason.
  watch: {
    avatars() {
      this.fetchImage();
    },

    userId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchImage();
      }
    },

    imageChanged(newImage) {
      if (!newImage) {
        this.image = '';
      } else {
        this.image = newImage;
      }
    },
  },

  methods: {
    ...mapActions({
      getAvatarByUserId: types.GET_AVATAR_BY_USER_ID,
      avatarExpiryCheckAction: types.AVATAR_EXPIRY_CHECK,
    }),

    /**
     * @vuese
     * Initialize  class  for <v-avatar> component
     * @returns {String} class string based on avatar size.
     */

    initialsClass() {
      // show bigger text if avatar size is big
      if (this.size > 100) {
        return 'white--text display-3';
      }

      // show smaller text if avatar is too small
      if (this.size <= 26) {
        return 'white--text caption';
      }

      // show normal size text otherwise
      return 'white--text title-2';
    },

    /**
     * @vuese
     * Initializes Background Color of avatar
     * with the initials of the the user
     *
     * @returns {String} a Color form colorList(defined on component) based on the
     * firstname and lastname
     */

    initialsBackgroundColor() {
      let colorIndex = 0;
      if (this.firstName.length > 0 && this.lastName.length > 0) {
        const len = this.colorList.length;
        const char1 = this.firstName[0].toUpperCase().charCodeAt();
        const char2 = this.lastName[0].toUpperCase().charCodeAt();
        colorIndex = (char1 * 26 + char2) % len;
      }
      return this.colorList[colorIndex];
    },

    /**
     * @vuese
     * Computes initials from fullname
     */
    initials() {
      let intls = '';
      if (this.firstName.length <= 0 || this.lastName.length <= 0) intls = '';
      else intls = this.firstName[0].toUpperCase() + this.lastName[0].toUpperCase();
      return intls;
    },

    /**
     * @vuese
     * Fetchs the image from the server when the avatar component is loaded
     * @arg avatarId
     */
    async fetchImage() {
      // Try to find avatar image from persisted state
      const avatar = this.avatars.find((x) => x.userId === this.userId);
      let data = null;
      let prevTime = null;
      if (avatar !== undefined) {
        data = avatar.imageData;
        prevTime = avatar.updatedAt;
      }

      if (avatar && avatar.userId) {
        if (data) {
          this.image = `data:image/png;base64,${data}`;
        } else {
          this.image = '';
        }
        // Avatar image is available on cache for this userId and show immediately

        // But... need to update cached images if it is old by fetching from database
        // Also, Logged in users don't need to update cached images frequently after each expiryTime
        // Try to find if the logged in user already updated cached image based on expiryTime
        const isFirstTime = this.avatarExpiryCheckArr.find((x) => x === this.userId) === undefined;
        if (isFirstTime) {
          // Image for this userId never updated before, need to check how old it is
          const difference = this.$dateFormat.getISODate() - prevTime;
          if (difference > this.expiryTime) {
            // Image is old, need to check in database for new image
            this.getAvatarByUserId(this.userId);

            // Add this user into array to stop redundant fetch from database
            this.avatarExpiryCheckAction(this.userId);
          }
        }
      } else {
        // Avatar image is not available for the user.
        this.image = '';
        if (this.userId) {
          this.getAvatarByUserId(this.userId);
        }
      }
    },
  },
};
</script>
