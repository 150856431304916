import * as types from '@/stores/types';
import { apolloClient } from '@/apolloConfig';
import { GET_ALL_CARDS_BY_BOX, ARCHIVE_CARDS, UNARCHIVE_CARDS } from '@/queries/cardArchive';
import { NUMBER_OF_CARDS_FOR_BOX } from '@/scripts/common';
/**
 * GETTERS
 */
const getters = {
  [types.GET_ALL_CARDS]: (state) => state.allCards,
  [types.GET_ALL_CARDS_TOTAL_COUNT]: (state) => state.totalCount,
};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_FETCH_ALL_CARDS]: (state, data) => {
    const current = state;
    current.allCards.push(...data.node);
    current.totalCount = data.totalCount;
  },

  [types.MUTATE_SUBSCRIBE_ARCHIVE_UNARCHIVE_CARDS]: (state, cards) => {
    const current = state;
    (cards || []).map((card) => {
      const vueCard = current.allCards.find((x) => x.id === card.id);
      if (vueCard) {
        vueCard.isArchive = card.isArchive;
      }
      return card;
    });
  },

  [types.MUTATE_ALLCARDS_CARD_ADDED]: (state, card) => {
    const current = state;
    current.allCards.push(card);
  },

  [types.MUTATE_ALLCARDS_CARD_UPDATED]: (state, card) => {
    const current = state;
    const index = current.allCards.findIndex((x) => x.id === card.id);
    if (index >= 0) {
      current.allCards.splice(index, 1, card);
    }
  },

  [types.MUTATE_ALLCARDS_CARD_REMOVED]: (state, cards) => {
    const current = state;
    const cardIds = new Set();
    cards.forEach((card) => {
      cardIds.add(card.id.toString());
    });
    current.allCards = (current.allCards || []).filter(({ id }) => !cardIds.has(id.toString()));
  },

  [types.MUTATE_CLEAR_ALL_CARDS]: (state) => {
    const current = state;
    current.allCards = [];
    current.totalCount = 0;
  },
};

/**
 * ACTIONS
 */
const actions = {
  [types.FETCH_CARDS_BY_BOXID]: ({ commit, getters: globalGetters }, box) => new Promise((resolve, reject) => {
    if (box === undefined) {
      throw new Error('BoxID is not available.');
    }

    const allCards = globalGetters.GET_ALL_CARDS;

    apolloClient
      .query({
        query: GET_ALL_CARDS_BY_BOX,
        variables: {
          box,
          offset: allCards.length,
          count: NUMBER_OF_CARDS_FOR_BOX,
        },
      })
      .then(({ data, loading }) => {
        // Mutate the fetched data in the store

        commit(types.MUTATE_FETCH_ALL_CARDS, data.getCardsByBoxId);

        // Loading switch to false in order to show the workflow page
        commit(types.MUTATE_PAGE_LOADING, loading);

        // Resolve promise for Vue component to move ahead
        resolve(true);
      })
      .catch((err) => reject(err));
  }),

  [types.ARCHIVE_CARDS_ACTION]: ({ commit }, cardIds) => {
    apolloClient
      .mutate({
        mutation: ARCHIVE_CARDS,
        variables: {
          cardIds,
        },
      })
      .then(({ data }) => {
        const { type, message } = data.archiveCards;
        commit(types.MUTATE_MESSAGE, { type, message });
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.UNARCHIVE_CARDS_ACTION]: ({ commit }, { cardIds, workflow }) => {
    apolloClient
      .mutate({
        mutation: UNARCHIVE_CARDS,
        variables: {
          cardIds,
          workflow,
        },
      })
      .then(({ data }) => {
        const { type, message } = data.unarchiveCards;
        commit(types.MUTATE_MESSAGE, { type, message });
      })
      .catch((err) => {
        console.error(err);
      });
    commit(types.MUTATE_CLEAR_MULTISELECT);
  },

  [types.SUBSCRIBE_ARCHIVE_CARDS]: ({ commit }, cards) => {
    commit(types.MUTATE_SUBSCRIBE_ARCHIVE_UNARCHIVE_CARDS, cards);
  },

  [types.SUBSCRIBE_UNARCHIVE_CARDS]: ({ commit }, cards) => {
    commit(types.MUTATE_SUBSCRIBE_ARCHIVE_UNARCHIVE_CARDS, cards);
  },

  [types.SUBSCRIBE_ALLCARDS_CARD_ADDED]: ({ commit }, card) => {
    commit(types.MUTATE_ALLCARDS_CARD_ADDED, card);
  },

  [types.SUBSCRIBE_ALLCARDS_CARD_UPDATED]: ({ commit }, card) => {
    commit(types.MUTATE_ALLCARDS_CARD_UPDATED, card);
  },

  [types.SUBSCRIBE_ALLCARDS_CARD_REMOVED]: ({ commit }, cards) => {
    commit(types.MUTATE_ALLCARDS_CARD_REMOVED, cards);
  },

  [types.CLEAR_ALL_CARDS]: ({ commit }) => {
    commit(types.MUTATE_CLEAR_ALL_CARDS);
  },

};

/**
 * STATES
 */
const state = {
  // All workflows in a box
  allCards: [],
  totalCount: 0,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
