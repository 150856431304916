import {
  format, parseISO, isValid, isBefore, subDays,
  formatDistanceToNow, fromUnixTime, differenceInDays,
} from 'date-fns/esm';
import { enGB, de } from 'date-fns/esm/locale';

// Date and time formats
export const DATE_TIME_FORMAT = 'MMMM do yyyy, h:mm:ss a';
export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_FORMAT_REVERSE = 'dd.MM.yyyy';
export const DATE_FORMAT_REVERSE_WITH_TIME = 'dd.MM.yyyy - HH:mm:ss';
export const DATE_FORMAT_DAY = 'EEEE, MMMM do yyyy';

export default class DateFormat {
  private locale: any = enGB;

  private locales: any = { enGB, de }

  constructor(locale: string = 'en') {
    this.locale = this.locales[locale];
  }

  /**
   * Set the Locale
   *
   * @param {string} locale
   * @memberof DateFormat
   */
  setLocale(locale: string) {
    this.locale = this.locales[locale];
  }

  /**
   *get the date time format
   *
   * @param {string} [date=new Date().toISOString()]
   * @param {string} [formatStr=DATE_FORMAT]
   * @returns {string}
   * @memberof DateFormat
   */
  dateTime(
    date: string = new Date().toISOString(),
    formatStr: string = DATE_FORMAT,
  ): string {
    try {
      const formattedDate = format(parseISO(date), formatStr, {
        locale: this.locale,
      });
      return formattedDate;
    } catch (error) {
      throw error.message;
    }
  }

  /**
   * Convert date to distance to
   *
   * @param {Date} date
   * @returns {string}
   * @memberof DateFormat
   */
  distanceTo(date: Date|number|string): string {
    const distance = formatDistanceToNow(
      new Date(date),
      { locale: this.locale },
    );
    return distance;
  }

  /**
   * Convert date to distance to with suffix
   *
   * @param {Date} date
   * @returns {string}
   * @memberof DateFormat
   */
  distanceToWithSuffix(date: Date|number|string): string {
    const distance = formatDistanceToNow(
      new Date(date),
      { locale: this.locale, addSuffix: true },
    );
    return distance;
  }

  /**
   * Get date in ISO format
   *
   * @param {Date} [date=new Date()]
   * @returns {string}
   * @memberof DateFormat
   */
  getISODate(date: Date = new Date()): string {
    if (date instanceof Date) {
      return date.toISOString();
    }
    throw new Error('the date is not type of Date');
  }

  /**
   * Validate Date and time.
   *
   * @param {string} date
   * @param {string} formatStr
   * @returns {boolean}
   * @memberof DateFormat
   */
  validateDate(date: string, formatStr?: string): boolean {
    let validateDate = date;
    if (formatStr) {
      validateDate = this.dateTime(date, formatStr);
    }
    return isValid(new Date(validateDate));
  }

  /**
   * Check is date before the given date
   *
   * @param {(string | Date | number)} date
   * @param {(string | Date | number)} dateToCompare
   * @returns {boolean}
   * @memberof DateFormat
   */
  isBefore(date: string | Date | number, dateToCompare: string | Date | number): boolean {
    const res = isBefore(new Date(date), new Date(dateToCompare));
    return res;
  }

  /**
   * subtract the days from given date
   *
   * @param {(Date | string | number)} date
   * @param {number} numberOfDays
   * @returns {Date}
   * @memberof DateFormat
   */
  subDaysFromDate(date: Date | string | number, numberOfDays: number): Date {
    const result: Date = subDays(new Date(date), numberOfDays);
    return result;
  }

  /**
   * Convert the unit time to the date
   *
   * @param {number} unitDate
   * @returns {Date}
   * @memberof DateFormat
   */
  convertUnixTimeToDate(unitDate: number): Date {
    try {
      const result = fromUnixTime(unitDate);
      return result;
    } catch (e) {
      throw e.message;
    }
  }

  /**
   * Returns the days until the current day
   *
   * @param {(Date | number | string)} date - The date in
   * past that needs to be compared against today
   *
   * @returns {number} - Number of days until today
   * @memberof DateFormat
   */
  daysUntilToday(date: Date | number | string): number {
    const today : Date = new Date();
    const earlierDate: Date = new Date(date);
    const differnce : number = differenceInDays(today, earlierDate);

    return differnce;
  }
}
