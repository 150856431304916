import gql from 'graphql-tag';
import { ATTACHMENT_FRAGMENT } from './fragments';

// ----------------QUERIES------------------

export const GET_ATTACHMENTS = gql`
  query getAttachments($id: ID!) {
    getAttachments(id: $id) {
      ...attachment
    }
  }
  ${ATTACHMENT_FRAGMENT.attachment}
`;

// ----------------MUTATIONS------------------

export const UPLOAD_FILES = gql`
mutation uploadFiles($cardId: ID!, $files: [Upload]!) {
    uploadFiles(cardId: $cardId, files: $files)
  }
`;

export const DELETE_FILE = gql`
mutation deleteFile($id:ID!, $fileId: ID!, $file: String!, $cardId: ID!) {
  deleteFile(id: $id, fileId: $fileId, file: $file, cardId: $cardId)
}
`;

// ----------------SUBSCRIPTIONS------------------

export const SUBSCRIBE_ATTACHMENT_ADDED = gql`
subscription attachmentsChanged($cardId: ID!){
  attachmentsChanged(cardId: $cardId) {
    ...attachment
  }
}
${ATTACHMENT_FRAGMENT.attachment}
`;
