export default {

  /**
   * Retrieves the data for the current box from a store array.
   * This array is expected to contain arrays where the first element is the boxId
   * and the second one the associated value.
   * Requires vue context injection (use as method)
   * @param {Object} storeArray The store array
   * @param {String} specificBox The box id to use, default is current box id
   */
  mapToBoxId(storeArray, specificBox = undefined) {
    if (!storeArray) {
      return undefined;
    }
    const boxId = specificBox || this.boxId;
    const pos = storeArray.findIndex(
      (element) => element[0] === boxId,
    );
    if (pos === -1) {
      return undefined;
    }
    return storeArray[pos][1];
  },
};
