<template>
  <v-dialog v-model="isOpen" max-width="800px">
    <v-card>
      <v-card-text class="pt-4 pb-0">
        <w-list-picker
          has-no-empty-option
          :list="workflows"
          no-data="empty_workflow"
          selector-string="select_workflow"
          v-model="workflow"
        >
          <template #no-data>
            <router-link
              v-if="$route.params.boxId"
              tag="button"
              :to="`/workflow/${$route.params.boxId}`"
            >
              <v-btn color="primary" small>
                {{ $t("go_to_workflow") }}
              </v-btn>
            </router-link>
          </template>
        </w-list-picker>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="warning" @click="cancel">{{ $t("cancel") }}</v-btn>
        <v-btn text color="icon" @click="accept">{{ $t("unarchive") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import wListPicker from '@/components/common/wListPicker.vue';
import { mapGetters, mapActions } from 'vuex';
import * as types from '@/stores/types';
import { NO_VALUE } from '@/scripts/common';

/**
 * @group AllCards
 *
 * Display selector for the workflow where the cards are unarchived to.
 */
export default {
  name: 'WUnarchiveWorkflowSelector',

  props: {
    value: {
      required: true,
      type: String,
    },
    open: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      workflow: NO_VALUE,
    };
  },

  components: {
    wListPicker,
  },

  created() {
    this.getWorkflow(this.$route.params.boxId);
  },

  computed: {
    ...mapGetters({
      workflows: types.GET_ALL_WORKFLOWS_NAME_ID_CARD_IDS,
    }),

    selectedWorkflow: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },

    isOpen: {
      get() {
        return this.open;
      },
      set() {
        this.$emit('cancel');
      },
    },
  },

  methods: {
    ...mapActions({
      getWorkflow: types.FETCH_WORKFLOWS_NAMES_IDS,
    }),

    accept() {
      this.selectedWorkflow = this.workflow;
      this.$emit('accept');
    },

    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
