import gql from 'graphql-tag';
import {
  MUTATION_MESSAGE,
  CARD_FRAGMENT,
  CARD_CHUNK_FRAGMENT,
} from './fragments';

// --------- QUERIES ----------
export const GET_ALL_CARDS_BY_BOX = gql`
  query($box: ID!, $offset: Int!, $count: Int!) {
    getCardsByBoxId(box: $box, offset: $offset, count: $count) {
      ...cardChunk
    }
  }
  ${CARD_CHUNK_FRAGMENT.cardChunk}
`;

// --------- MUTATIONS ---------

export const ARCHIVE_CARDS = gql`
  mutation($cardIds: [ID!]!) {
    archiveCards(cardIds: $cardIds){
      ...mutationMessage
    }
  }
  ${MUTATION_MESSAGE.mutationMessage}
`;

export const UNARCHIVE_CARDS = gql`
  mutation($cardIds: [ID!]!, $workflow: ID!) {
    unarchiveCards(cardIds: $cardIds, workflow: $workflow){
      ...mutationMessage
    }
  }
  ${MUTATION_MESSAGE.mutationMessage}
`;

// ----------------SUBSCRIPTIONS------------------

export const SUBSCRIBE_ARCHIVED = gql`
subscription {
  cardsArchived {
    id
    workflow
    workflowGroup
    category
    box
    labels {
      id
    }
    isArchive
  }
}`;

export const SUBSCRIBE_UNARCHIVED = gql`
subscription {
  cardsUnarchived {
    ...cardSummary
  }
}
${CARD_FRAGMENT.card}
`;
