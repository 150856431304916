import {
  UPDATE_SIDEBAR_ACTIVE,
  MUTATE_SIDEBAR_ACTIVE,
  GET_SIDEBAR_ACTIVE,
} from '@/stores/types';

const getters = {
  [GET_SIDEBAR_ACTIVE]: (state) => state.sidebarActive,
};

const mutations = {
  [MUTATE_SIDEBAR_ACTIVE]: (state, isActive) => {
    const current = state;
    current.sidebarActive = isActive;
  },
};

const actions = {
  [UPDATE_SIDEBAR_ACTIVE]: ({ commit }, isActive) => {
    commit('MUTATE_SIDEBAR_ACTIVE', isActive);
  },
};

const state = {
  sidebarActive: false,
};

export default {
  state,
  getters,
  mutations,
  actions,
};
