import { apolloClient } from '@/apolloConfig';
import {
  ADD, REMOVE, UPDATE, GET_ALL, GET_ALL_INVITATIONS,
  COPY_BOX,
  GET_BOX_SETTINGS_BY_ID,
  UPDATE_BOX_SETTINGS,
  ADD_BOX_GROUP,
  DELETE_BOX_GROUP,
  MOVE_BOX_BETWEEN_GROUPS,
  UPDATE_BOX_GROUP_POSITION,
  UPDATE_BOX_POSITION,
  UPDATE_BOX_GROUP_NAME,
  CREATE_API,
  FETCH_BOX_API_FLAG,
  REMOVE_API,
  GET_BOX_DETAILS_BY_ID,
} from '@/queries/box';
import {
  ARCHIVE_BOX,
  UNARCHIVE_BOX,
} from '@/queries/boxArchive';
import { MUTE_BOX, CHANGE_BOX_COLOR } from '@/queries/userSpecificBoxSettings';
import * as types from '../types';
/**
 * GETTERS
 */
const getters = {
  [types.GET_BOXES]: (state) => state.boxes,
  [types.GET_PENDING_BOXES]: (state) => state.pendingBoxes,
  [types.GET_SEARCH_BOX]: (state) => state.searchBox,
  [types.GET_SELECTED_BOX]: (state) => state.selectedBox,
  [types.GET_BOX_MEMBERS]: (state) => state.members,
  [types.GET_BOX_SETTINGS]: (state) => state.settings,
  [types.GET_BOX_GROUPS]: (state) => state.groups,
  [types.GET_BOX_API_FLAG]: (state) => state.doesBoxHaveAPI,
};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_PENDING_BOXES]: (state, pendingBoxes) => {
    const current = state;
    current.pendingBoxes = pendingBoxes;
  },

  [types.MUTATE_FETCH_BOXES]: (state, payload) => {
    const current = state;
    current.boxes = payload.boxes;
    current.groups = payload.boxGroups;
  },

  [types.MUTATE_SEARCH_BOX]: (state, payload) => {
    const current = state;
    current.searchBox = payload;
  },

  [types.MUTATE_SELECTED_BOX]: (state, payload) => {
    const current = state;
    current.selectedBox = payload;
  },

  [types.MUTATE_ADD_BOX]: (state, box) => {
    const current = state;
    current.boxes = [...current.boxes, box];
  },

  [types.MUTATE_UPDATE_BOX]: (state, box) => {
    const current = state;
    const { boxes, groups } = current;
    const index = boxes.findIndex(({ box: { id } }) => id === box.id);

    if (index !== -1) {
      const updatedBoxObject = boxes[index];
      updatedBoxObject.box = box;
      current.boxes.splice(index, 1, updatedBoxObject);
    } else {
      groups.forEach((group, i) => {
        const boxIndex = group.boxes.findIndex(({ box: { id } }) => id === box.id);

        if (boxIndex !== -1) {
          groups[i].boxes[boxIndex].box = box;
        }
      });
    }
  },

  [types.MUTATE_DELETE_BOX]: (state, box) => {
    const current = state;
    const index = current.boxes.findIndex(({ box: { id } }) => id === box.id);
    if (index !== -1) {
      current.boxes.splice(index, 1);
    }
  },

  [types.MUTATE_ARCHIVE_UNARCHIVE_BOX]: (state, { boxId, isArchive }) => {
    const current = state;

    // search in the boxes
    const index = current.boxes.findIndex(({ box: { id } }) => id === boxId);
    if (index !== -1) {
      const updateBoxObj = current.boxes[index];
      updateBoxObj.box.isArchive = isArchive;
      current.boxes.splice(index, 1, updateBoxObj);
    } else {
      // search in box groups
      current.groups.forEach((group, i) => {
        const boxIndex = group.boxes.findIndex(({ box }) => box.id === boxId);
        if (boxIndex !== -1) {
          const updateBoxObj = current.groups[i].boxes[boxIndex];

          updateBoxObj.box.isArchive = isArchive;
          // remove from the groups
          current.groups[i].boxes.splice(boxIndex, 1);

          // add in boxes
          current.boxes.push(updateBoxObj);
        }
      });
    }

    // check if selected box is archived/ unarchived

    if (current.selectedBox && current.selectedBox.id === boxId) {
      current.selectedBox.isArchive = isArchive;
    }
  },

  [types.MUTATE_ADDED_AS_COLLABORATOR]: (state, box) => {
    const current = state;
    if (current.boxes.findIndex(({ box: { id } }) => box.id === id) === -1) {
      current.boxes = [...current.boxes, { box, isMuted: false }];
    }
  },

  [types.MUTATE_INVITED_AS_COLLABORATOR]: (state, box) => {
    const current = state;
    if (current.pendingBoxes.findIndex(({ id }) => box.id === id) === -1) {
      current.pendingBoxes = [...current.pendingBoxes, box];
    }
  },

  [types.MUTATE_INVITATION_ACCEPTED]: (state, boxId) => {
    const current = state;
    const { boxes, pendingBoxes } = current;

    // Find out the index of the box in Pending Boxes
    const indexInPendingBoxes = pendingBoxes.findIndex((box) => box.id === boxId);

    if (indexInPendingBoxes !== -1) {
      // Add box in fetched box list
      boxes.push({ box: pendingBoxes[indexInPendingBoxes], isMuted: false });

      // Remove box from pending box list
      pendingBoxes.splice(indexInPendingBoxes, 1);
    }
  },

  [types.MUTATE_REMOVED_AS_COLLABORATOR]: (state, boxId) => {
    const current = state;
    const { boxes } = current;
    const index = boxes.findIndex(({ box }) => box.id === boxId);
    if (index !== -1) {
      boxes.splice(index, 1);
    }
  },

  [types.MUTATE_REMOVED_AS_PENDING_COLLABORATOR]: (state, boxId) => {
    const current = state;
    const { pendingBoxes } = current;
    const index = pendingBoxes.findIndex((box) => box.id === boxId);
    if (index !== -1) {
      pendingBoxes.splice(index, 1);
    }
  },

  [types.MUTATE_MUTE_BOX]: (state, { boxId, isMuted }) => {
    const current = state;
    const index = current.boxes.findIndex(({ box }) => box.id === boxId);

    if (index !== -1) {
      current.boxes.splice(index, 1, { ...current.boxes[index], isMuted });
    } else {
      current.groups.forEach((group, i) => {
        const boxIndex = group.boxes.findIndex(({ box }) => box.id === boxId);
        if (boxIndex !== -1) {
          current.groups[i].boxes[boxIndex].isMuted = isMuted;
        }
      });
    }
  },

  [types.MUTATE_COLOR_BOX]: (state, { boxId, color }) => {
    const current = state;
    const index = current.boxes.findIndex(({ box }) => box.id === boxId);

    if (index !== -1) {
      current.boxes.splice(index, 1, { ...current.boxes[index], color });
    } else {
      current.groups.forEach((group, i) => {
        const boxIndex = group.boxes.findIndex(({ box }) => box.id === boxId);
        if (boxIndex !== -1) {
          current.groups[i].boxes[boxIndex].color = color;
        }
      });
    }
  },

  [types.MUTATE_SUBSCRIBE_PENDING_BOXES_USER_DELETED]: (state, deletedUser) => {
    const current = state;

    const pendingBoxesToRemove = [];
    current.pendingBoxes.forEach((box) => {
      const found = box.members.find(
        ({ role, user }) => role.name === 'admin' && user.id === deletedUser.id,
      );

      if (found) pendingBoxesToRemove.push(box.id);
    });

    current.pendingBoxes = current.pendingBoxes.filter(
      (box) => !pendingBoxesToRemove.includes(box.id),
    );
  },

  [types.MUTATE_FETCH_BOX_SETTINGS_BY_ID]: (state, boxSetting) => {
    const current = state;
    const setting = boxSetting;
    if (boxSetting.hideChecklistsBadge === undefined || boxSetting.hideChecklistsBadge === null) {
      setting.hideChecklistsBadge = true;
    }
    if (boxSetting.hidePropertiesBadge === undefined || boxSetting.hidePropertiesBadge === null) {
      setting.hidePropertiesBadge = true;
    }

    if (boxSetting.hideMembersOnColumnView === undefined
      || boxSetting.hideMembersOnColumnView === null) {
      setting.hideMembersOnColumnView = true;
    }

    if (boxSetting.hideMembersOnHorizontalView === undefined
      || boxSetting.hideMembersOnHorizontalView === null) {
      setting.hideMembersOnHorizontalView = true;
    }

    if (boxSetting.hideSubtitle === undefined
      || boxSetting.hideSubtitle === null) {
      setting.hideSubtitle = true;
    }

    current.settings = setting;
  },

  [types.MUTATE_NEW_BOX_GROUP]: (state, payload) => {
    const current = state;
    current.groups.push(payload);
  },

  [types.MUTATE_DELETE_BOX_GROUP]: (state, payload) => {
    const current = state;
    current.groups = current.groups.filter((g) => g.id.toString() !== payload.toString());
  },

  [types.MUTATE_UPDATE_BOX_GROUP]: (state, payload) => {
    const current = state;
    const { groupId, name } = payload;
    const index = current.groups.findIndex((group) => group.id === groupId);
    if (index !== -1) {
      current.groups.splice(index, 1, { ...current.groups[index], name });
    }
  },

  [types.MUTATE_BOX_API_FLAG]: (state, payload) => {
    state.doesBoxHaveAPI = payload;
  },
};

/**
 * ACTIONS
 */
const actions = {
  [types.FETCH_PENDING_BOXES]: ({ commit }) => {
    apolloClient
      .query({
        query: GET_ALL_INVITATIONS,
      })
      .then(({ data }) => {
        commit(types.MUTATE_PENDING_BOXES, data.getAllInvitations);
      })
      .catch((err) => {
        console.error({ err });
      });
  },

  [types.FETCH_BOXES]: ({ commit }) => {
    apolloClient
      .query({
        query: GET_ALL,
      })
      .then(({ data, loading }) => {
        commit(types.MUTATE_FETCH_BOXES, data.getAllBoxes);
        // Loading switch to false in order to show the Box
        commit(types.MUTATE_PAGE_LOADING, loading);
      })
      .catch((err) => {
        console.error({ err });
      });
  },

  [types.ADD_BOX]: (_, name) => {
    apolloClient
      .mutate({
        mutation: ADD,
        variables: {
          name,
        },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.DELETE_BOX]: (_, id) => {
    apolloClient
      .mutate({
        mutation: REMOVE,
        variables: {
          id,
        },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.UPDATE_BOX]: (_, { id, name }) => {
    apolloClient
      .mutate({
        mutation: UPDATE,
        variables: {
          id,
          name,
        },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.COPY_BOX]: ({ commit }, { boxId, newName }) => new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: COPY_BOX,
        variables: {
          boxId,
          newName,
        },
      })
      .then(({ data }) => {
        const { copyBox } = data;
        commit(types.MUTATE_MESSAGE, copyBox);
        resolve();
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  }),

  [types.MUTE_BOX]: ({ commit }, { boxId, isMuted }) => {
    apolloClient
      .mutate({
        mutation: MUTE_BOX,
        variables: {
          boxId,
          isMuted,
        },
      })
      .then(({ data }) => {
        if (data.toggleMuteBox.type === 'ERROR') {
          commit(types.MUTATE_MESSAGE, data.toggleMuteBox);
        }
      })
      .catch((e) => console.error(e));
    commit(types.MUTATE_MUTE_BOX, { boxId, isMuted: !isMuted });
  },

  [types.CHANGE_BOX_COLOR]: ({ commit }, { boxId, color }) => {
    apolloClient
      .mutate({
        mutation: CHANGE_BOX_COLOR,
        variables: {
          boxId,
          color,
        },
      })
      .then(({ data }) => {
        if (data.changeBoxColor.type === 'ERROR') {
          commit(types.MUTATE_MESSAGE, data.changeBoxColor);
        } else {
          commit(types.MUTATE_COLOR_BOX, { boxId, color });
        }
      })
      .catch((e) => console.error(e));
    commit(types.MUTATE_COLOR_BOX, { boxId, color: '' });
  },

  [types.SEARCH_BOX]: ({ commit }, search) => {
    commit(types.MUTATE_SEARCH_BOX, search);
  },

  [types.SET_SELECTED_BOX]: async ({ commit }, box) => {
    commit(types.MUTATE_SELECTED_BOX, box);
  },

  [types.SUBSCRIBE_ADDED_BOX]: ({ commit }, box) => {
    commit(types.MUTATE_ADD_BOX, box);
  },

  [types.SUBSCRIBE_UPDATED_BOX]: ({ commit }, box) => {
    commit(types.MUTATE_UPDATE_BOX, box);
  },

  [types.SUBSCRIBE_DELETED_BOX]: ({ commit }, box) => {
    commit(types.MUTATE_DELETE_BOX, box);
  },

  [types.SUBSCRIBE_ARCHIVED_BOX]: ({ commit }, boxId) => {
    commit(types.MUTATE_ARCHIVE_UNARCHIVE_BOX, { boxId, isArchive: true });
  },

  [types.SUBSCRIBE_UNARCHIVED_BOX]: ({ commit }, boxId) => {
    commit(types.MUTATE_ARCHIVE_UNARCHIVE_BOX, { boxId, isArchive: false });
  },

  [types.SUBSCRIBE_ADDED_AS_COLLABORATOR]: ({ commit }, box) => {
    commit(types.MUTATE_ADDED_AS_COLLABORATOR, box);
  },

  [types.SUBSCRIBE_REMOVED_AS_COLLABORATOR]: ({ commit }, boxId) => {
    commit(types.MUTATE_REMOVED_AS_COLLABORATOR, boxId);
    commit(types.MUTATE_REMOVED_AS_PENDING_COLLABORATOR, boxId);
  },

  [types.FETCH_BOX_SETTINGS_BY_ID]: ({ commit }, boxId) => {
    apolloClient
      .query({
        query: GET_BOX_SETTINGS_BY_ID,
        variables: {
          id: boxId,
        },
      })
      .then(({ data }) => {
        commit(types.MUTATE_FETCH_BOX_SETTINGS_BY_ID, data.getBoxById.settings);
      })
      .catch((err) => {
        console.error({ err });
      });
  },

  [types.UPDATE_BOX_SETTINGS_BY_ID]: ({ commit }, dataSettings) => {
    apolloClient
      .mutate({
        mutation: UPDATE_BOX_SETTINGS,
        variables: {
          boxId: dataSettings.boxId,
          settings: dataSettings.settings,
        },
      })
      .then(() => {
        commit(types.MUTATE_FETCH_BOX_SETTINGS_BY_ID, dataSettings.settings);
      })
      .catch((err) => {
        console.error({ err });
      });
  },

  [types.NEW_BOX_GROUP]: ({ commit }, name) => {
    apolloClient
      .mutate({
        mutation: ADD_BOX_GROUP,
        variables: {
          name,
        },
      })
      .then(({ data }) => {
        if (data && data.addBoxGroup) {
          commit(types.MUTATE_NEW_BOX_GROUP, data.addBoxGroup);
        }
      });
  },

  [types.DELETE_BOX_GROUP]: ({ commit }, {
    groupId,
  }) => new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: DELETE_BOX_GROUP,
        variables: {
          groupId,
        },
      })
      .then(({ data }) => {
        if (data.deleteBoxGroup.id !== '0') {
          commit(types.MUTATE_DELETE_BOX_GROUP, data.deleteBoxGroup.id);
        }

        resolve(data);
      })
      .catch((e) => reject(e));
  }),

  [types.CHANGE_BOX_POSITION]: (_, {
    newIndex, oldIndex, groupId,
  }) => new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: UPDATE_BOX_POSITION,
        variables: {
          newIndex,
          oldIndex,
          groupId,
        },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => reject(e));
  }),

  [types.MOVE_BOX_BETWEEN_GROUPS]: (_, {
    boxId, oldGroupId, newGroupId, newPosition,
  }) => new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: MOVE_BOX_BETWEEN_GROUPS,
        variables: {
          boxId,
          oldGroupId,
          newGroupId,
          newPosition,
        },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => reject(e));
  }),

  [types.UPDATE_BOX_GROUP_POSITION]: (_, {
    groupId, position,
  }) => new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: UPDATE_BOX_GROUP_POSITION,
        variables: {
          groupId,
          position,
        },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => reject(e));
  }),

  [types.UPDATE_BOX_GROUP]: ({ commit }, {
    groupId, name,
  }) => new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: UPDATE_BOX_GROUP_NAME,
        variables: {
          groupId,
          name,
        },
      })
      .then(({ data }) => {
        commit(types.MUTATE_UPDATE_BOX_GROUP, { groupId, name });
        resolve(data.updateBoxGroupName);
      })
      .catch((e) => reject(e));
  }),

  [types.CREATE_API]: ({ commit }, id) => new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: CREATE_API,
        variables: {
          id,
        },
      })
      .then(({ data: { createAPI } }) => {
        const insecureKey = createAPI;

        commit(types.MUTATE_BOX_API_FLAG, true);

        resolve(insecureKey);
      })
      .catch((err) => {
        console.error(err);

        reject(err);
      });
  }),

  [types.FETCH_BOX_API_FLAG]: ({ commit }, id) => new Promise((resolve, reject) => {
    apolloClient
      .query({
        query: FETCH_BOX_API_FLAG,
        variables: {
          id,
        },
      })
      .then(({ data: { getBoxAPIFlag } }) => {
        commit(types.MUTATE_BOX_API_FLAG, getBoxAPIFlag);

        resolve(true);
      })
      .catch((err) => {
        console.error(err);

        reject(err);
      });
  }),

  [types.REMOVE_API]: ({ commit }, id) => new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: REMOVE_API,
        variables: {
          id,
        },
      })
      .then(() => {
        commit(types.MUTATE_BOX_API_FLAG, false);

        resolve(true);
      })
      .catch((err) => {
        console.error(err);

        reject(err);
      });
  }),

  [types.ARCHIVE_BOX]: ({ commit }, boxId) => new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: ARCHIVE_BOX,
        variables: {
          boxId,
        },
      })
      .then(({ data }) => {
        const { type, message } = data.archiveBox;
        commit(types.MUTATE_MESSAGE, { type, message });

        resolve(true);
      })
      .catch((err) => {
        console.error(err);

        reject(err);
      });
  }),

  [types.UNARCHIVE_BOX]: ({ commit }, boxId) => new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: UNARCHIVE_BOX,
        variables: {
          boxId,
        },
      })
      .then(({ data }) => {
        const { type, message } = data.unarchiveBox;
        commit(types.MUTATE_MESSAGE, { type, message });

        resolve(true);
      })
      .catch((err) => {
        console.error(err);

        reject(err);
      });
  }),

  [types.FETCH_SELECTED_BOX]: ({ commit }, boxId) => new Promise((resolve, reject) => {
    apolloClient
      .query({
        query: GET_BOX_DETAILS_BY_ID,
        variables: {
          id: boxId,
        },
      })
      .then(({ data }) => {
        commit(types.MUTATE_SELECTED_BOX, data.getBoxById);

        resolve(true);
      })
      .catch((err) => {
        console.error(err);

        reject(err);
      });
  }),
};

/**
 * STATES
 */
const state = {
  boxes: [],
  pendingBoxes: [],
  searchBox: '',
  selectedBox: '',
  members: [],
  settings: {},
  groups: [],
  doesBoxHaveAPI: false,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
