import * as types from '@/stores/types';
import { apolloClient } from '@/apolloConfig';
import {
  GET_ALL,
  ADD,
  UPDATE,
  MOVE_CARD,
  DELETE,
  MOVE_WORKFLOW,
  GET_WORKFLOW_NAMES_IDS,
  GET_WORKFLOW_NAMES_IDS_CARD_IDS,
  GET_WORKFLOW,
} from '@/queries/workflow';
import { addCardsToColumns, filterCardsFromColumns } from '@/scripts/helper';
import {
  mutations as groupMutations,
  actions as groupActions,
  getters as groupGetters,
} from './group';

/**
 * GETTERS
 */
const getters = {
  [types.GET_ALL_WORKFLOWS]: (state) => state.workflows,
  [types.GET_ALL_WORKFLOWS_NAME_ID_CARD_IDS]: (state) => state.workflowsNameIdsCardIds,
  [types.GET_ACTIVE_WORKFLOW]: (state) => state.activeWorkflow,
  [types.GET_WORKFLOW]: (state) => state.workflow,

  /**
   * GROUPS
   *
   * - All getter for groups are written here
   */
  ...groupGetters,
};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_SINGLE_WORKFLOW]: (state, workflow) => {
    const current = state;
    current.workflow = workflow;
  },

  [types.MUTATE_FETCH_WORKFLOW]: (state, workflows) => {
    const current = state;
    current.workflows = workflows;
  },
  [types.MUTATE_FETCH_WORKFLOW_NAME_IDS_CARD_IDS]: (state, workflows) => {
    const current = state;
    current.workflowsNameIdsCardIds = workflows;
  },

  [types.MUTATE_ADD_WORKFLOW]: (state, workflowAdded) => {
    const current = state;
    const index = current.workflows.findIndex((w) => w.id === workflowAdded.id);

    if (index === -1) {
      // copying object to create a new reference in memory
      const workflowAddedCopy = JSON.parse(JSON.stringify(workflowAdded));

      current.workflows = [...current.workflows, workflowAdded];
      current.workflowsNameIdsCardIds = [...current.workflowsNameIdsCardIds, workflowAddedCopy];
    }
  },

  [types.MUTATE_UPDATE_WORKFLOW]: (state, workflowUpdated) => {
    const current = state;
    const index = current.workflows.findIndex((w) => w.id === workflowUpdated.id);
    const index2 = current.workflowsNameIdsCardIds.findIndex((w) => w.id === workflowUpdated.id);
    if (index !== -1) {
      current.workflows[index].name = workflowUpdated.name;
    }

    if (index2 !== -1) {
      current.workflowsNameIdsCardIds[index2].name = workflowUpdated.name;
    }
  },

  [types.MUTATE_WORKFLOW_PROPERTY_SUM_UPDATE]: (state, updatedPropertiesSum) => {
    const current = state;
    const index = current.workflows.findIndex((w) => w.id === updatedPropertiesSum.id);

    if (index !== -1) {
      const oldSize = current.workflows[index].propertiesSum ? current.workflows[index].propertiesSum.length : 0;

      // check for undefined when workflow has no cards the property sum will be undefined
      if (current.workflows[index].propertiesSum) current.workflows[index].propertiesSum.splice(0, oldSize);

      // eslint-disable-next-line array-callback-return
      updatedPropertiesSum.propertiesSum.map((property, idx) => {
        // check for undefined when workflow has no cards the property sum will be undefined
        if (current.workflows[index].propertiesSum) current.workflows[index].propertiesSum.splice(idx, 0, property);
      });
    }
  },

  [types.MUTATE_SUBSCRIBE_SINGLE_WORKFLOW]: (state, workflowUpdated) => {
    const current = state;
    current.workflow.name = workflowUpdated.name;
  },

  [types.MUTATE_SUBSCRIBE_SINGLE_WORKFLOW_CARD_ADDED]: (state, cardAdded) => {
    const current = state;
    const { workflow } = current;
    const { workflowPosition } = cardAdded;

    // check if there is group in card
    if (cardAdded.workflowGroup) {
      // check the groups of that workflow
      const pos = workflow.groups.findIndex((g) => g.id === cardAdded.workflowGroup);
      if (pos >= 0) {
        const group = workflow.groups[pos];

        if (/^\d+$/.test(workflowPosition)) {
          group.cards.splice(workflowPosition, 0, cardAdded);
        } else {
          group.cards.push(cardAdded);
        }
      }
    } else if (/^\d+$/.test(workflowPosition)) {
      /**
       * check if @param workflowPosition
       * is an unsigned integer, then splice on top
       */
      workflow.cards.splice(workflowPosition, 0, cardAdded);
    } else {
      workflow.cards.push(cardAdded);
    }
  },

  [types.MUTATE_SUBSCRIBE_SINGLE_WORKFLOW_CARDS_UNARCHIVED]: (state, cardsUnarchived) => {
    const current = state;
    const { workflow } = current;

    workflow.cards.push(...cardsUnarchived);
  },

  [types.MUTATE_SUBSCRIBE_SINGLE_WORKFLOW_CARD_REMOVED]: (state, cardsRemoved) => {
    const current = state;
    const { cards } = current.workflow;

    // Separate cards in that are in groups
    const cardsInGroup = cardsRemoved.filter((card) => card.workflowGroup !== null);

    // Remove cards from workflow
    cards.splice(
      0,
      cards.length,
      ...cards.filter((card) => !cardsRemoved.some((removedCard) => removedCard.id === card.id)),
    );

    // Remove deleted cards from groups
    if (cardsInGroup) {
      current.workflow.groups = filterCardsFromColumns(
        current.workflow.groups,
        cardsInGroup,
        'workflowGroup',
      );
    }
  },

  [types.MUTATE_SUBSCRIBE_SINGLE_WORKFLOW_CARD_MOVED]: (state, cardMoved) => {
    const current = state;
    const { workflow } = current;
    const { cards, groups } = workflow;
    const {
      card, position, to, from,
    } = cardMoved;

    // Card is insert from a group
    if (!from) {
      // Remove card from groups
      groups.every((group) => {
        const { cards: groupCards } = group;
        const index = groupCards.findIndex((c) => c.id === card.id);

        if (index !== -1) {
          groupCards.splice(index, 1);
          return false;
        }

        return true;
      });
    } else {
      // Remove card from the workflow
      const index = cards.findIndex((c) => c.id === card.id);

      // Either remove card from the list if found
      // Or do nothing if card is coming from new list
      if (index !== -1) {
        cards.splice(index, 1);
      }
    }

    // Add card in the list if need be
    if (workflow.id === to) {
      cards.splice(position, 0, card);
    }
  },

  [types.MUTATE_SUBSCRIBE_SINGLE_WORKFLOW_CARD_UPDATED]: (state, cardUpdated) => {
    const current = state;
    const { workflow } = current;
    const { cards, groups } = workflow;

    // Check if card has a group
    if (cardUpdated.workflowGroup) {
      groups.every((group) => {
        if (group.id === cardUpdated.workflowGroup) {
          const index = group.cards.findIndex((c) => c.id === cardUpdated.id);

          group.cards.splice(index, 1, cardUpdated);
          return false;
        }

        return true;
      });
    } else {
      const index = cards.findIndex((c) => c.id === cardUpdated.id);

      if (index !== -1) {
        cards.splice(index, 1, cardUpdated);
      }
    }
  },

  [types.MUTATE_DELETE_WORKFLOW]: (state, workflowRemoved) => {
    const current = state;
    const index = current.workflows.findIndex((w) => w.id === workflowRemoved.id);
    const index2 = current.workflowsNameIdsCardIds.findIndex((w) => w.id === workflowRemoved.id);
    if (index !== -1) {
      current.workflows.splice(index, 1);
    }

    if (index2 !== -1) {
      current.workflowsNameIdsCardIds.splice(index2, 1);
    }
  },

  [types.MUTATE_ADD_CARDS_TO_WORKFLOW]: (state, cards) => {
    const current = state;

    current.workflows = addCardsToColumns(current.workflows, cards, 'workflow');
    current.workflowsNameIdsCardIds = addCardsToColumns(
      current.workflowsNameIdsCardIds,
      cards,
      'workflow',
    );
  },
  [types.MUTATE_ADD_CARD_TO_WORKFLOW]: (state, card) => {
    const current = state;
    const { workflows, workflowsNameIdsCardIds } = current;
    const { workflowPosition } = card;

    for (let i = 0; i < workflows.length; i += 1) {
      if (workflows[i].id === card.workflow) {
        // check if there is group in card
        if (card.workflowGroup) {
          // check the groups of that workflow
          const pos = workflows[i].groups.findIndex((g) => g.id === card.workflowGroup);
          if (pos >= 0) {
            const group = workflows[i].groups[pos];

            if (/^\d+$/.test(workflowPosition)) {
              group.cards.splice(workflowPosition, 0, card);

              if (workflowsNameIdsCardIds.length === workflows.length) {
                workflowsNameIdsCardIds[i].cards.splice(workflowPosition, 0, card.id);
              }
            } else {
              group.cards.push(card);

              if (workflowsNameIdsCardIds.length === workflows.length) {
                workflowsNameIdsCardIds[i].cards.push({ id: card.id });
              }
            }
          }
          break;
        }
        // if there is no group then add to workflow
        /**
         * check if @param workflowPosition
         * is an unsigned integer, then splice on top
         */
        if (/^\d+$/.test(workflowPosition)) {
          workflows[i].cards.splice(workflowPosition, 0, card);

          if (workflowsNameIdsCardIds.length === workflows.length) {
            workflowsNameIdsCardIds[i].cards.splice(workflowPosition, 0, card.id);
          }
        } else {
          workflows[i].cards.push(card);

          if (workflowsNameIdsCardIds.length === workflows.length) {
            workflowsNameIdsCardIds[i].cards.push({ id: card.id });
          }
        }

        // card was either spliced
        // or pushed so we break loop
        break;
      }
    }
  },

  [types.MUTATE_UPDATE_CARD_IN_WORKFLOW]: (state, cardUpdated) => {
    if (cardUpdated.isTemplate) {
      return;
    }
    const current = state;
    const { workflows, workflowsNameIdsCardIds } = current;
    let isCardSpliced = false;

    workflows.every((workflow, i) => {
      const { cards, groups } = workflow;

      // Check if card has a group
      if (cardUpdated.workflowGroup) {
        groups.every((group) => {
          if (group.id === cardUpdated.workflowGroup) {
            const index = group.cards.findIndex((c) => c.id === cardUpdated.id);

            group.cards.splice(index, 1, cardUpdated);
            isCardSpliced = true;

            // stop looping the groups
            return false;
          }

          // continue looping the groups
          return true;
        });
      } else {
        const index = cards.findIndex((c) => c.id === cardUpdated.id);

        if (index !== -1) {
          isCardSpliced = true;
          cards.splice(index, 1, cardUpdated);

          if (workflowsNameIdsCardIds.length === workflows.length) {
            workflowsNameIdsCardIds[i].cards.splice(index, 1, cardUpdated.id);
          }
        }
      }

      if (isCardSpliced) return false;

      return true;
    });
  },

  [types.MUTATE_DELETE_CARD_FROM_WORKFLOW]: (state, cardsRemoved) => {
    const current = state;

    // Separate cards in the workflow and group
    const cardsInWorkflow = cardsRemoved.filter((card) => card.workflowGroup === null);
    const cardsInGroup = cardsRemoved.filter((card) => card.workflowGroup !== null);

    // Remove deleted cards from workflow
    current.workflows = filterCardsFromColumns(current.workflows, cardsInWorkflow, 'workflow');
    current.workflowsNameIdsCardIds = filterCardsFromColumns(
      current.workflowsNameIdsCardIds,
      cardsRemoved,
      'workflow',
    );

    // Remove deleted cards from groups
    if (cardsInGroup) {
      current.workflows.forEach((workflow) => {
        // eslint-disable-next-line no-param-reassign
        workflow.groups = filterCardsFromColumns(workflow.groups, cardsInGroup, 'workflowGroup');
      });
    }
  },

  [types.MUTATE_SUBSCRIBE_UNPIN_CARD]: (state, cardUnpinned) => {
    const current = state;
    const { workflows, workflow: selectedWorkflow } = current;
    const { workflowId, cardId } = cardUnpinned;

    // find workflow where card needs to be pinned
    const workflow = workflows.find((w) => w.id === workflowId);

    if (!workflow) return;

    const card = workflow.cards.find((c) => c.id === cardId);
    if (card) card.isPinned = false;

    // update the selected workflow if it is the same
    if (selectedWorkflow.id === workflow.id) {
      selectedWorkflow.cards = workflow.cards;
    }
  },

  [types.MUTATE_SUBSCRIBE_PIN_CARD]: (state, cardPinned) => {
    const current = state;
    const { workflow: selectedWorkflow, workflows, workflowsNameIdsCardIds } = current;
    const { workflowId, cardId } = cardPinned;

    // find workflow where card needs to be pinned
    const workflow = workflows.find((w) => w.id === workflowId);

    if (!workflow) return;

    // find workflow in name-id array where card needs to be pinned
    const workflowsNameIdsCardId = workflowsNameIdsCardIds.find((w) => w.id === workflowId);

    // we find only index one time because both arrays are copies of each other
    const index = workflow.cards.findIndex((c) => c.id === cardId);

    if (index !== -1) {
      // remove card from the arrays
      const [card] = workflow.cards.splice(index, 1);

      if (workflowsNameIdsCardId && workflowsNameIdsCardId.cards) {
        workflowsNameIdsCardId.cards?.splice(index, 1);
      }

      card.isPinned = true;

      // add card to top of both arrays
      workflow.cards.unshift(card);
      if (workflowsNameIdsCardId && workflowsNameIdsCardId.cards) {
        workflowsNameIdsCardId.cards?.unshift({
          id: card.id,
        });
      }
    } else {
      // remove card from groups
      workflow.groups.every((group) => {
        const idx = group.cards.findIndex((c) => c.id === cardId);

        if (idx !== -1) {
          const [card] = group.cards.splice(idx, 1);

          card.isPinned = true;

          workflow.cards.unshift(card);
          if (workflowsNameIdsCardId && workflowsNameIdsCardId.cards) {
            workflowsNameIdsCardId.cards?.unshift({
              id: card.id,
            });
          }

          // Stop looping the groups
          return false;
        }
        return true;
      });
    }

    // update the selected workflow if it is the same
    if (selectedWorkflow.id === workflow.id) {
      selectedWorkflow.groups = workflow.groups;
      selectedWorkflow.cards = workflow.cards;
    }
  },

  [types.MUTATE_SUBSCRIBE_MOVE_CARD]: (state, cardMoved) => {
    const current = state;
    const { workflows, workflowsNameIdsCardIds } = current;
    const {
      from, to, card, position,
    } = cardMoved;
    let groupSpliced = false;

    workflows.every((workflow, i) => {
      const { cards, groups } = workflow;

      // Card is inserted from a group
      if (!from) {
        // Remove card from groups
        groups.every((group) => {
          const { cards: groupCards } = group;
          const index = groupCards.findIndex((c) => c.id === card.id);

          if (index !== -1) {
            groupCards.splice(index, 1);

            // Stop looping the groups
            groupSpliced = true;
            return false;
          }

          return true;
        });
      } else if (workflow.id === from) {
        const index = cards.findIndex((c) => c.id === card.id);

        if (index !== -1) {
          cards.splice(index, 1);
          if (workflowsNameIdsCardIds.length === workflows.length) {
            workflowsNameIdsCardIds[i].cards?.splice(index, 1);
          }

          return false;
        }
      }

      if (groupSpliced) return false;

      return true;
    });

    workflows.every((workflow, i) => {
      const { cards } = workflow;

      if (workflow.id === to) {
        cards.splice(position, 0, card);

        if (workflowsNameIdsCardIds.length === workflows.length) {
          workflowsNameIdsCardIds[i].cards.splice(position, 0, { id: card.id });
        }

        return false;
      }

      return true;
    });
  },

  [types.MUTATE_SUBSCRIBE_MOVE_WORKFLOW]: (state, workflowMoved) => {
    const current = state;
    const { workflows, workflowsNameIdsCardIds } = current;
    const { newPosition, oldPosition } = workflowMoved;

    const workflow = workflows[oldPosition];
    workflows.splice(oldPosition, 1);
    workflows.splice(newPosition, 0, workflow);

    workflowsNameIdsCardIds.splice(oldPosition, 1);
    workflowsNameIdsCardIds.splice(newPosition, 0, workflow);
  },

  [types.MUTATE_ACTIVE_WORKFLOW]: (state, { boxId, newActiveWorkflow }) => {
    const current = state;
    const pos = current.activeWorkflow.findIndex((boxToContent) => boxToContent[0] === boxId);
    if (pos === -1) {
      current.activeWorkflow = [...current.activeWorkflow, [boxId, newActiveWorkflow]];
    } else {
      current.activeWorkflow[pos].splice(1, 1, newActiveWorkflow);
    }
  },

  [types.MUTATE_CARD_MEMBER_CHANGED_IN_WORKFLOW]: (state, updatedData) => {
    const current = state;
    const { workflows } = current;
    let workflowIndex;
    let cardIndex;

    // Card members changed in Column View
    workflows.forEach((workFlow, i) => {
      const pos = workFlow.cards.findIndex((card) => card.id === updatedData.cardId);
      if (pos !== -1) {
        workflowIndex = i;
        cardIndex = pos;
      }
    });

    workflows[workflowIndex].cards[cardIndex].members = updatedData.cardMembersChanged;

    // Card members changed in Horizontal View
    const { workflow } = current;
    if (workflow.cards) {
      const pos = workflow.cards.findIndex((card) => card.id === updatedData.cardId);
      if (pos !== -1) {
        workflow.cards[pos].members = updatedData.cardMembersChanged;
      }
    }
  },

  /**
   * GROUPS
   *
   * - All mutations for groups are found in this file
   */
  ...groupMutations,
};

/**
 * ACTIONS
 */
const actions = {
  [types.FETCH_SINGLE_WORKFLOW]: ({ commit }, workflowId) => new Promise((resolve, reject) => {
    if (workflowId === undefined) {
      throw new Error('Workflow ID is not available.');
    }
    apolloClient
      .query({
        query: GET_WORKFLOW,
        variables: { workflowId },
      })
      .then(({ data, loading }) => {
        // Mutate the fetched data in the store
        commit(types.MUTATE_SINGLE_WORKFLOW, data.getWorkflow);

        // Loading switch to false in order to show the workflow page
        commit(types.MUTATE_PAGE_LOADING, loading);

        // Resolve promise for Vue component to move ahead
        resolve(true);
      })
      .catch((err) => reject(err));
  }),

  [types.FETCH_WORKFLOW]: async ({ commit }, boxId) => {
    if (boxId === undefined) {
      throw new Error('Box id is empty.');
    }
    const { data, loading } = await apolloClient.query({
      query: GET_ALL,
      variables: { boxId },
    });
    commit(types.MUTATE_FETCH_WORKFLOW, data.getWorkflowsByBoxId);
    // Loading switch to false in order to show the workflow page
    commit(types.MUTATE_PAGE_LOADING, loading);
  },

  [types.FETCH_WORKFLOWS_WITH_ID_NAME_CARD_IDS]: async ({ commit }, boxId) => {
    if (boxId === undefined) {
      throw new Error('Box id is empty.');
    }
    const { data, loading } = await apolloClient.query({
      query: GET_WORKFLOW_NAMES_IDS_CARD_IDS,
      variables: { boxId },
    });
    commit(types.MUTATE_FETCH_WORKFLOW_NAME_IDS_CARD_IDS, data.getWorkflowsByBoxId);
    // Loading switch to false in order to show the workflow page
    commit(types.MUTATE_PAGE_LOADING, loading);
  },

  [types.FETCH_WORKFLOWS_NAMES_IDS]: ({ commit }, boxId) => {
    if (boxId === undefined) {
      throw new Error('Box id is empty.');
    }
    apolloClient
      .query({
        query: GET_WORKFLOW_NAMES_IDS,
        variables: { boxId },
      })
      .then(({ data, loading }) => {
        commit(types.MUTATE_FETCH_WORKFLOW_NAME_IDS_CARD_IDS, data.getWorkflowsByBoxId);
        // Loading switch to false in order to show the workflow page
        commit(types.MUTATE_PAGE_LOADING, loading);
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.ADD_WORKFLOW]: (_, { name, boxId }) => {
    if (boxId === undefined && name === undefined) {
      throw new Error('Error boxId and workload name are mandatory');
    }
    apolloClient
      .mutate({
        mutation: ADD,
        variables: {
          name,
          boxId,
        },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.UPDATE_WORKFLOW]: (_, { id, name }) => {
    if (id === undefined || name === undefined) {
      throw new Error('Error workflow Id and workload name are mandatory');
    }
    apolloClient
      .mutate({
        mutation: UPDATE,
        variables: {
          id,
          name,
        },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.MOVE_CARD_IN_WORKFLOW]: ({ dispatch }, {
    from, to, card, position,
  }) => {
    apolloClient
      .mutate({
        mutation: MOVE_CARD,
        variables: {
          from,
          to,
          card,
          position,
        },
      })
      .catch((err) => {
        console.error(err);
      });
    dispatch(types.NOTIFY_CARD_WATCHERS_ON_CHANGE, card);
  },

  [types.MOVE_WORKFLOW_IN_BOX]: (_, {
    box, newPosition, workflowId, oldPosition,
  }) => {
    apolloClient
      .mutate({
        mutation: MOVE_WORKFLOW,
        variables: {
          box,
          newPosition,
          workflowId,
          oldPosition,
        },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.SUBSCRIBE_WORKFLOW_ADD]: ({ commit }, newWorkFlow) => {
    commit(types.MUTATE_ADD_WORKFLOW, newWorkFlow);
  },

  [types.SUBSCRIBE_WORKFLOW_UPDATE]: ({ commit }, workFlow) => {
    commit(types.MUTATE_UPDATE_WORKFLOW, workFlow);
  },

  [types.SUBSCRIBE_WORKFLOW_PROPERTY_SUM_UPDATE]: ({ commit }, updatedPropertiesSum) => {
    commit(types.MUTATE_WORKFLOW_PROPERTY_SUM_UPDATE, updatedPropertiesSum);
  },

  [types.SUBSCRIBE_WORKFLOW_DELETE]: ({ commit }, workFlow) => {
    commit(types.MUTATE_DELETE_WORKFLOW, workFlow);
  },

  [types.DELETE_WORKFLOW]: (_, workFlow) => {
    apolloClient
      .mutate({
        mutation: DELETE,
        variables: { id: workFlow },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.ADD_CARD_TO_WORKFLOW]: ({ commit }, card) => {
    commit(types.MUTATE_ADD_CARD_TO_WORKFLOW, card);
  },

  [types.UPDATE_CARD_IN_WORKFLOW]: ({ commit }, card) => {
    commit(types.MUTATE_UPDATE_CARD_IN_WORKFLOW, card);
  },

  [types.DELETE_CARD_FROM_WORKFLOW]: ({ commit }, card) => {
    commit(
      types.MUTATE_REMOVE_CARDS_FROM_SELECTION,
      card.map(({ id }) => id),
    );
    commit(types.MUTATE_DELETE_CARD_FROM_WORKFLOW, card);
  },
  [types.ARCHIVE_CARD_FROM_WORKFLOW]: ({ commit }, cards) => {
    commit(
      types.MUTATE_REMOVE_CARDS_FROM_SELECTION,
      cards.map(({ id }) => id),
    );
    commit(types.MUTATE_DELETE_CARD_FROM_WORKFLOW, cards);
  },
  [types.UNARCHIVE_CARD_IN_WORKFLOW]: ({ commit }, cards) => {
    commit(types.MUTATE_ADD_CARDS_TO_WORKFLOW, cards);
  },

  [types.SUBSCRIBE_MOVE_CARD]: ({ commit }, card) => {
    commit(types.MUTATE_SUBSCRIBE_MOVE_CARD, card);
    commit(types.MUTATE_CARD_HEADER_WORKFLOW, card);
  },

  [types.SUBSCRIBE_PIN_CARD]: ({ commit }, card) => {
    commit(types.MUTATE_SUBSCRIBE_PIN_CARD, card);
  },

  [types.SUBSCRIBE_UNPIN_CARD]: ({ commit }, card) => {
    commit(types.MUTATE_SUBSCRIBE_UNPIN_CARD, card);
  },

  [types.SUBSCRIBE_CARD_MEMBER_CHANGED_IN_WORKFLOW]: ({ commit }, updatedData) => {
    commit(types.MUTATE_CARD_MEMBER_CHANGED_IN_WORKFLOW, updatedData);
  },

  [types.SUBSCRIBE_MOVE_WORKFLOW]: ({ commit }, workflow) => {
    commit(types.MUTATE_SUBSCRIBE_MOVE_WORKFLOW, workflow);
  },

  [types.SET_ACTIVE_WORKFLOW]: ({ commit }, [boxId, newActiveWorkflow]) => {
    commit(types.MUTATE_ACTIVE_WORKFLOW, { boxId, newActiveWorkflow });
  },

  /**
   * GROUPS
   *
   * - All actions for groups are written here
   */
  ...groupActions,
};

/**
 * STATES
 */
const state = {
  // All workflows in a box
  workflows: [
    {
      workflow: {
        cards: [],
      },
    },
  ],
  // All workflows with name, id, card ids in a box
  workflowsNameIdsCardIds: [
    {
      workflow: {
        cards: [],
      },
    },
  ],

  activeWorkflow: [],
  activeGroup: [],

  // Single workflow with cards
  workflow: {},
};

export default {
  state,
  mutations,
  actions,
  getters,
};
