import gql from 'graphql-tag';
import { MUTATION_MESSAGE } from './fragments';

// --------- QUERIES ----------

export const GET_ALL_TEMPLATES_BY_BOX = gql`
  query($box: ID!) {
    getTemplatesForBox(box: $box) {
      id
      title
    }
  }
`;

// --------- MUTATIONS ---------

export const DELETE_TEMPLATE = gql`
  mutation($id: ID!) {
    deleteTemplate(id: $id)
  }
`;

export const CREATE_TEMPLATE = gql`
  mutation($name: String!, $box: ID!) {
    createTemplate(name: $name, box: $box)
  }
`;

export const TEMPLATE_FROM_CARD = gql`
  mutation($id: ID!) {
    templateFromCard(id: $id) {
      ...mutationMessage
    }
  }
  ${MUTATION_MESSAGE.mutationMessage}
`;

// ------- SUBSCRIPTIONS --------

export const TEMPLATE_ADDED = gql`
subscription {
  templateAdded {
    id
    title
  }
}
`;

export const TEMPLATE_DELETED = gql`
subscription {
  templateDeleted {
    id
    title
  }
}
`;
