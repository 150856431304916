import gql from 'graphql-tag';
import { CARD_FRAGMENT } from './fragments';

// ----------------MUTATIONS------------------
export const ADD_GROUP_IN_WORKFLOW = gql`
  mutation($workflowId:ID!, $name: String!) {
    addGroupInWorkflow(workflowId:$workflowId, name:$name)
  }
`;

export const UPDATE_GROUP_NAME = gql`
  mutation($workflowId: ID!, $groupId:ID!, $name: String!) {
    updateGroupName(workflowId:$workflowId, groupId:$groupId, name:$name)
  }
`;

export const REMOVE_GROUP_FROM_WORKFLOW = gql`
mutation($workflowId: ID!, $groupId: ID!) {
  removeGroupFromWorkflow(workflowId:$workflowId, groupId:$groupId)
}
`;

export const MOVE_CARD_IN_GROUP = gql`
mutation($fromGroupId: ID, $toGroupId: ID!, $cardId: ID!, $position: Int!) {
  moveCardInGroup(fromGroupId: $fromGroupId, toGroupId: $toGroupId, cardId: $cardId, position: $position)
}
`;

export const UPDATE_GROUP_POSITION = gql`
mutation updateGroupPosition($workflowId: ID!, $groupId: ID!, $position: Int!) {
  updateGroupPosition(workflowId: $workflowId, groupId: $groupId, position: $position)
}
`;

// ----------------SUBSCRIPTIONS------------------
export const SUBSCRIBE_GROUP_ADDED = gql`
  subscription {
    groupAdded {
      group {
        id
        name
      }

      workflow {
        id
        boxId
      }
    }
  }
`;

export const SUBSCRIBE_GROUP_UPDATED = gql`
  subscription {
    groupUpdated {
      group {
        id
        name
      }

      workflow {
        id
        boxId
      }
    }
  }
`;

export const SUBSCRIBE_GROUP_REMOVED = gql`
  subscription {
    groupRemoved {
      group {
        id
        name
      }

      workflow {
        id
        boxId
      }
    }
  }
`;

export const SUBSCRIBE_GROUP_MOVED = gql`
  subscription {
    groupMoved {
      group {
        id
        name
      }

      workflow {
        id
        boxId
      }
      position
    }
  }
`;

export const SUBSCRIBE_MOVE_CARD_GROUP_EXPANDED_COLUMN = gql`
  subscription {
    cardMovedInGroup {
      fromGroupId
      toGroupId
      card {
        ...cardInExpandedView
      }
      position
    }
  }
  ${CARD_FRAGMENT.cardOverviewOnColumnExpansion}
`;

export const SUBSCRIBE_MOVE_CARD_GROUP = gql`
  subscription {
    cardMovedInGroup {
      fromGroupId
      toGroupId
      card {
        ...card
      }
      position
    }
  }
  ${CARD_FRAGMENT.cardOverview}
`;
