import { apolloClient } from '@/apolloConfig';
import {
  GET_ALL_TEMPLATES_BY_BOX,
  DELETE_TEMPLATE,
  CREATE_TEMPLATE,
  TEMPLATE_FROM_CARD,
} from '@/queries/template';
import * as types from '../types';

const getters = {
  [types.GET_TEMPLATES]: (state) => state.templates,
  [types.GET_ACTIVE_TEMPLATE]: (state) => state.activeTemplate,
};

const mutations = {
  [types.MUTATE_FETCH_TEMPLATES_FOR_BOX]: (state, templates) => {
    const current = state;
    current.templates = templates;
  },

  [types.MUTATE_SUBSCRIBE_TEMPLATE_UPDATED]: (state, template) => {
    const { templates } = state;
    const position = templates.findIndex((templ) => templ.id === template.id);
    if (position !== -1) {
      templates.splice(position, 1, { id: template.id, title: template.title });
    }
  },

  [types.MUTATE_SUBSCRIBE_TEMPLATE_ADDED]: (state, newTemplate) => {
    const current = state;
    current.templates = [...current.templates, newTemplate];
  },

  [types.MUTATE_SUBSCRIBE_TEMPLATE_REMOVED]: (state, template) => {
    const { templates } = state;
    const position = templates.findIndex((templ) => templ.id === template.id);
    if (position !== -1) {
      templates.splice(position, 1);
    }
  },

  [types.MUTATE_ACTIVE_TEMPLATE]: (state, { boxId, newActiveTemplate }) => {
    const current = state;
    const pos = current.activeTemplate.findIndex((boxToContent) => boxToContent[0] === boxId);

    if (pos === -1) {
      current.activeTemplate = [...current.activeTemplate, [boxId, newActiveTemplate]];
    } else {
      current.activeTemplate[pos].splice(1, 1, newActiveTemplate);
    }
  },
};

const actions = {
  [types.FETCH_TEMPLATES_FOR_BOX]: ({ commit }, box) => {
    apolloClient.query({
      query: GET_ALL_TEMPLATES_BY_BOX,
      variables: {
        box,
      },
    })
      .then(({ data }) => {
        commit(types.MUTATE_FETCH_TEMPLATES_FOR_BOX, data.getTemplatesForBox);
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.DELETE_TEMPLATE]: (_, template) => {
    apolloClient.mutate({
      mutation: DELETE_TEMPLATE,
      variables: {
        id: template,
      },
    });
  },

  [types.CREATE_TEMPLATE]: (_, { name, box }) => {
    apolloClient.mutate({
      mutation: CREATE_TEMPLATE,
      variables: {
        box,
        name,
      },
    });
  },

  [types.SUBSCRIBE_TEMPLATE_UPDATED]: ({ commit }, template) => {
    commit(types.MUTATE_SUBSCRIBE_TEMPLATE_UPDATED, template);
  },

  [types.SUBSCRIBE_TEMPLATE_ADDED]: ({ commit }, template) => {
    commit(types.MUTATE_SUBSCRIBE_TEMPLATE_ADDED, template);
  },

  [types.SUBSCRIBE_TEMPLATE_REMOVED]: ({ commit }, template) => {
    commit(types.MUTATE_SUBSCRIBE_TEMPLATE_REMOVED, template);
  },

  [types.CREATE_TEMPLATE_FROM_CARD]: async ({ commit }, card) => {
    await apolloClient.mutate({
      mutation: TEMPLATE_FROM_CARD,
      variables: {
        id: card,
      },
    }).then(({ data }) => {
      const { type, message } = data.templateFromCard;
      commit(types.MUTATE_MESSAGE, { type, message });
    });
  },

  [types.SET_ACTIVE_TEMPLATE]: ({ commit }, [boxId, newActiveTemplate]) => {
    commit(types.MUTATE_ACTIVE_TEMPLATE, { boxId, newActiveTemplate });
  },

};

const state = {
  templates: [],
  activeTemplate: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
