import * as types from '@/stores/types';
import { apolloClient } from '@/apolloConfig';
import {
  GET_USER_SPECIFIC_BOX_SETTINGS, SAVE_SELECTED_TEMPLATE,
} from '@/queries/userSpecificBoxSettings';

/**
 * GETTERS
 */
const getters = {
  [types.GET_USER_SPECIFIC_BOX_SETTINGS]: (state) => state.userSpecificBoxSettings,
};

/**
  * MUTATIONS
  */
const mutations = {
  [types.MUTATE_USER_SPECIFIC_BOX_SETTINGS]: (state, getUserSpecificBoxSettings) => {
    const current = state;
    current.userSpecificBoxSettings = getUserSpecificBoxSettings;
  },
};

/**
  * ACTIONS
  */
const actions = {
  [types.FETCH_USER_SPECIFIC_BOX_SETTINGS]: async ({ commit }, boxId) => {
    await apolloClient.query({
      query: GET_USER_SPECIFIC_BOX_SETTINGS,
      variables: {
        boxId,
      },
    })
      .then(({ data }) => {
        const { getUserSpecificBoxSettings } = data;
        if (!getUserSpecificBoxSettings?.selectedTemplate) return;
        const templateId = getUserSpecificBoxSettings.selectedTemplate;

        commit(types.MUTATE_ACTIVE_TEMPLATE, { boxId, newActiveTemplate: templateId });
        commit(types.MUTATE_USER_SPECIFIC_BOX_SETTINGS, getUserSpecificBoxSettings);
      });
  },

  [types.SAVE_SELECTED_TEMPLATE]: async (_, { boxId, templateId }) => {
    await apolloClient.mutate({
      mutation: SAVE_SELECTED_TEMPLATE,
      variables: {
        boxId,
        templateId,
      },
    }).catch((err) => console.error(err));
  },
};

/**
   * STATE
   */
const state = {
  userSpecificBoxSettings: {},
};

export default {
  state,
  mutations,
  actions,
  getters,
};
