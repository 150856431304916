import gql from 'graphql-tag';
import { CARD_FRAGMENT } from './fragments';

// ----------------QUERIES------------------

export const GET_ALL = gql`
  query($box: ID!){
    getDisplayCardsByBoxId(box: $box) {
      ...card
    }
  }
  ${CARD_FRAGMENT.cardOverview}
`;

// ----------------SUBSCRIPTIONS------------------

export const SUBSCRIBE_DATE_CHANGED = gql`
subscription {
  cardDateChanged {
    ...card
  }
}
${CARD_FRAGMENT.cardOverview}
`;
