import gql from 'graphql-tag';
import { USER_INFO, MUTATION_MESSAGE, BOX_FRAGMENT } from './fragments';

// ----------------QUERIES--------------------
export const FETCH_USER = gql`
  query {
    getUser {
      ...user
    }
  }
  ${USER_INFO.user}
`;

export const FETCH_IS_USERNAME_IN_USE = gql`
  query ($username: String!){
    getIsUsernameInUse(username: $username)
  }
`;

export const FETCH_ALL_USERS = gql`
  query {
    getAllUsers {
      ...publicUser
    }
  }
  ${USER_INFO.publicUser}
`;

export const FETCH_ALL_USERS_FOR_ADMIN = gql`
  query {
    getAllUsersForAdmin {
      ...userForAdmin
    }
  }
  ${USER_INFO.userForAdmin}
`;

export const VALIDATE_USER = gql`
query {
  getUser {
    id
  }
}
`;

export const FETCH_DELETE_REPORT = gql`
  query {
    requestToDeleteAccount {
      ...box
    }
  }
  ${BOX_FRAGMENT.box}
`;

export const FETCH_USER_STATISTICS = gql`
  query {
    getUserStatistics {
      totalUsers
      totalUserTemps
      userRegistrations {
        date
        count
      }
    }
  }
`;

// ----------------MUTATIONS------------------
export const LOGIN = gql`
  mutation($email: String!, $password: String!, $rememberMe: Boolean, $secondFactor: String, $isADLogin: Boolean) {
    login(email: $email, password: $password, rememberMe: $rememberMe, secondFactor: $secondFactor, isADLogin: $isADLogin){
      ...user
      ...mutationMessage
    }
  }
  ${USER_INFO.user}
  ${MUTATION_MESSAGE.mutationMessage}
`;

export const LOGOUT = gql`
  mutation {
    logout
  }
`;

export const EDIT = gql`
  mutation($firstname: String, $lastname: String) {
    editUserProfile(firstname: $firstname, lastname: $lastname) {
      ...user
    }
  }
  ${USER_INFO.user}
`;

export const ADD_MISSING_USERNAME = gql`
mutation ($username: String!) {
  addMissingUsername(username: $username) {
    ...mutationMessage
  }
}
${MUTATION_MESSAGE.mutationMessage}
`;

export const CHANGE_PASSWORD = gql`
mutation ($oldPassword: String!, $newPassword: String!) {
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
    ...mutationMessage
  }
}
${MUTATION_MESSAGE.mutationMessage}
`;

export const DELETE_ACCOUNT = gql`
mutation ($password: String!) {
  deleteAccount(password: $password) {
    ...on User {
      ...user
    }
    ...on MutationMessage {
      ...mutationMessage
    }
  }
}
${USER_INFO.user}
${MUTATION_MESSAGE.mutationMessage}
`;

// ----------------Subscriptions------------------

export const SUBSCRIBE_USER_ADDED = gql`
  subscription {
    userAdded {
      ...publicUser
    }
  }
  ${USER_INFO.publicUser}
`;

export const SUBSCRIBE_USER_DELETED = gql`
  subscription {
    userDeleted {
      ...publicUser
    }
  }
  ${USER_INFO.publicUser}
`;
