<template>
  <v-dialog transition="slide-y-reverse-transition" v-model="menu" fullscreen
    persistent>
    <v-card class="about-us-menu">
      <v-card-title>
        <v-spacer></v-spacer>
        <!-- CLOSE THE DIALOG -->
        <v-btn icon @click="$emit('close')">
          <v-icon color="white">
            fa-times
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="d-flex flex-column">
        <p @click="$emit('close'); isAuthenticated ? $router.push('/') : $router.push('/login')"
          class="menu-item display-3 white--text text-uppercase slide-in-blurred-top mb-8">
          Home
        </p>

        <hr class="menu-divider mr-10 slide-in-blurred-top-line mb-8" />

        <div class="slide-in-blurred-top-2">
          <wLocalization>
            <template
              #languageSwitcher="{ selectedLanguage, languages, changeLanguage }">
              <div class="d-flex">
                <p class="menu-item display-3 white--text text-uppercase">
                  {{ selectedLanguage }}
                </p>

                <v-speed-dial class="ml-2" v-model="fab" direction="right"
                  transition="slide-x-transition">
                  <template v-slot:activator>
                    <v-btn class="mt-1" x-large icon>
                      <v-icon color="white">
                        fa-chevron-right
                      </v-icon>
                    </v-btn>
                  </template>

                  <span v-for="(language, index) in languages" :key="index"
                    class="menu-item display-3 white--text text-uppercase mb-4 mr-6"
                    @click="changeLanguage(language)">{{ language.acronym
                    }}</span>
                </v-speed-dial>
              </div>
            </template>
          </wLocalization>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import wLocalization from '@/components/common/wLocalization.vue';
import { mapGetters } from 'vuex';
import { GET_USER_AUTHENTICATION_STATUS } from '../../stores/types';

/**
 * @group AboutUs
 *
 * This component is the overlay menu inside the About Us
 * page. It holds routes to other pages and the ability
 * to switch the language of the app
 */

export default {
  name: 'WOverlayMenu',

  components: {
    wLocalization,
  },

  props: {
    menu: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fab: false,
    };
  },

  computed: {
    ...mapGetters({
      isAuthenticated: GET_USER_AUTHENTICATION_STATUS,
    }),
  },
};
</script>

<style lang="css">
.menu-divider {
  border: 0.5px solid white;
  width: 40%;
}
</style>
