import * as types from '@/stores/types';
import { apolloClient } from '@/apolloConfig';
import { GET_AVATAR_BY_USER_ID, UPLOAD_AVATAR, DELETE_AVATAR } from '@/queries/avatar';

/**
 * GETTERS
 */
const getters = {
  [types.GET_AVATAR]: (state) => state.avatars,
};

/**
 * MUTATIONS
 */
const mutations = {
  /**
   * As we need to invalidate cached data after a certain expiryTime,
   * we need to add a timestamp to track how old is this data.
   * Currently we are storing MilliSeconds with each image data.
   */
  [types.MUTATE_AVATAR]: (state, { userId, imageData }) => {
    const current = state;

    if (userId) {
      current.avatars = current.avatars.filter((x) => x.userId !== userId);
      current.avatars.push({ userId, imageData, updatedAt: new Date().toISOString() });
    }
  },

  [types.MUTATE_CLEAN_AVATARS]: (state) => {
    const current = state;
    current.avatars.splice(0, current.avatars.length);
  },

  [types.MUTATE_SUBSCRIBE_AVATAR_ADDED]: (state, { userId, imageData }) => {
    const current = state;
    current.avatars = current.avatars.filter((x) => x.userId !== userId);
    current.avatars.push({ userId, imageData, updatedAt: new Date().toISOString() });
  },

  [types.MUTATE_AVATAR_DELETE]: (state, userId) => {
    const current = state;
    current.avatars = current.avatars.filter((x) => x.userId !== userId);
  },

  [types.MUTATE_SUBSCRIBE_AVATAR_DELETED]: (state, userId) => {
    const current = state;
    current.avatars = current.avatars.filter((x) => x.userId !== userId);
  },
};

/**
 * Actions
 */

const actions = {
  [types.GET_AVATAR_BY_USER_ID]: async ({ commit }, userId) => {
    await apolloClient
      .query({
        query: GET_AVATAR_BY_USER_ID,
        variables: {
          userId,
        },
      })
      .then(({ data }) => {
        const {
          getAvatarByUserId: { id, avatarId },
        } = data;

        commit(types.MUTATE_AVATAR, {
          userId: id,
          imageData: avatarId,
        });
      })
      .catch((err) => {
        console.error({ err });
      });
  },

  [types.UPLOAD_AVATAR]: async ({ commit }, avatar) => {
    apolloClient
      .mutate({
        mutation: UPLOAD_AVATAR,
        variables: {
          avatar,
        },
      })
      .then(({ data }) => {
        commit(types.MUTATE_MESSAGE, data.uploadAvatar);
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.SUBSCRIBE_AVATAR_ADDED]: ({ commit }, { userId, avatar }) => {
    commit(types.MUTATE_SUBSCRIBE_AVATAR_ADDED, { userId, imageData: avatar });
  },

  [types.DELETE_AVATAR]: async () => {
    await apolloClient
      .mutate({
        mutation: DELETE_AVATAR,
      })
      .catch((err) => console.error(err));
  },

  [types.SUBSCRIBE_AVATAR_DELETED]: ({ commit }, userId) => {
    commit(types.MUTATE_SUBSCRIBE_AVATAR_DELETED, userId);
  },
};
/**
 * States
 */

const state = {
  avatars: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
