import gql from 'graphql-tag';
import { MUTATION_MESSAGE } from './fragments';

// ----------------QUERIES------------------

export const GET = gql`
  query {
    getUserSetting {
      locale
      darkTheme
    }
  }
`;

// ----------------MUTATIONS------------------
export const UPDATE = gql`
  mutation($locale: String, $darkTheme: Boolean) {
    updateUserSetting(locale: $locale, darkTheme: $darkTheme){
      locale
      darkTheme
    }
  }
`;

export const CHANGE_TWO_FACTOR_AUTH_SETTING = gql`
mutation ($newTwoFactorAuth: Boolean!) {
  setTwoFactorAuth(newTwoFactorAuth: $newTwoFactorAuth) {
    ...mutationMessage
  }
}
${MUTATION_MESSAGE.mutationMessage}
`;
