import gql from 'graphql-tag';
import { CUSTOM_DESCRIPTION_FRAGMENT } from './fragments';

export const FETCH_CUSTOM_DESCRIPTIONS = gql`
  query getCardById($id: ID!) {
    getCardById(id: $id) {
      customDescriptions {
        ...customDescription
      }
    }
  }
  ${CUSTOM_DESCRIPTION_FRAGMENT.customDescription}
`;

export const ADD_CUSTOM_DESCRIPTION = gql`
  mutation addCustomDescription($cardId: ID!, $title: String!) {
    addCustomDescriptionToCard(cardId: $cardId, title: $title)
  }
`;

export const DELETE_CUSTOM_DESCRIPTION = gql`
  mutation deleteCustomDescription($cardId: ID!, $customDescriptionId: ID!) {
    deleteCustomDescription(cardId: $cardId, customDescriptionId: $customDescriptionId)
  }
`;

export const UPDATE_CUSTOM_DESCRIPTION_TITLE = gql`
  mutation updateCustomDescriptionTitle($cardId: ID!, $customDescriptionId: ID!, $title: String!) {
    updateCustomDescriptionTitle(
      cardId: $cardId
      customDescriptionId: $customDescriptionId
      title: $title
    )
  }
`;

export const UPDATE_DESCRIPTION_IN_CUSTOM_DESCRIPTION = gql`
  mutation updateDescriptionInCustomDescription(
    $cardId: ID!
    $customDescriptionId: ID!
    $description: String!
  ) {
    updateDescriptionInCustomDescription(
      cardId: $cardId
      customDescriptionId: $customDescriptionId
      description: $description
    )
  }
`;

export const SUBSCRIBE_CUSTOM_DESCRIPTION_ADDED = gql`
  subscription customDescriptionAdded($cardId: ID!) {
    customDescriptionAdded(cardId: $cardId) {
      ...customDescription
    }
  }
  ${CUSTOM_DESCRIPTION_FRAGMENT.customDescription}
`;

export const SUBSCRIBE_CUSTOM_DESCRIPTION_UPDATED = gql`
  subscription customDescriptionUpdated($cardId: ID!) {
    customDescriptionUpdated(cardId: $cardId) {
      ...customDescription
    }
  }
  ${CUSTOM_DESCRIPTION_FRAGMENT.customDescription}
`;

export const SUBSCRIBE_CUSTOM_DESCRIPTION_TEXT_UPDATED = gql`
  subscription customDescriptionTextUpdated($cardId: ID!) {
    customDescriptionTextUpdated(cardId: $cardId) {
      ...customDescription
    }
  }
  ${CUSTOM_DESCRIPTION_FRAGMENT.customDescription}
`;

export const SUBSCRIBE_CUSTOM_DESCRIPTION_DELETED = gql`
  subscription customDescriptionDeleted($cardId: ID!) {
    customDescriptionDeleted(cardId: $cardId) {
      ...customDescription
    }
  }
  ${CUSTOM_DESCRIPTION_FRAGMENT.customDescription}
`;
