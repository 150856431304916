import * as types from '@/stores/types';

const getters = {
  [types.GET_PAGE_LOADING]: (state) => state.isPageLoading,
};

const mutations = {
  [types.MUTATE_PAGE_LOADING]: (state, loading) => {
    const current = state;
    current.isPageLoading = loading;
  },
};

/**
 * ACTIONS
 */
const actions = {
  [types.PAGE_LOADING]: ({ commit }, loading) => {
    commit(types.MUTATE_PAGE_LOADING, loading);
  },
};

/**
 * STATES
 */
const state = {
  isPageLoading: true,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
