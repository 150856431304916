import * as types from '@/stores/types';
import { apolloClient } from '@/apolloConfig';
import {
  UPDATE_GROUP_NAME,
  REMOVE_GROUP_FROM_WORKFLOW,
  MOVE_CARD_IN_GROUP,
  ADD_GROUP_IN_WORKFLOW,
  UPDATE_GROUP_POSITION,
} from '@/queries/group';
import groupController from '@/scripts/groupController';

/**
 * This file is an extension to workflow.js
 * store module
 */

/**
 * GETTERS
 */
export const getters = {
  [types.GET_ACTIVE_GROUP_IN_WORKFLOW]: (state) => state.activeGroup,
};

export const mutations = {
  [types.MUTATE_SUBSCRIBE_ADD_GROUP_EXPANDED_COLUMN]: (state, { group }) => {
    const current = state;
    const { workflows, workflow } = current;

    // add workflow in expanded column view's workflow
    // incase user is viewing a single workflow
    groupController.addNewGroupToList(workflow.groups, group);

    // add group in the workflow array by finding it
    // incase user is viewing the columns
    workflows.every((_workflow) => {
      if (_workflow.id === workflow.id) {
        const { groups } = _workflow;

        groupController.addNewGroupToList(groups, group);

        return false;
      }
      return true;
    });
  },

  [types.MUTATE_SUBSCRIBE_ADD_GROUP]: (state, { group, workflow: workflowOfTheGroup }) => {
    const current = state;
    const { workflows, workflow } = current;

    // add group in the workflow array by finding it
    // incase user is viewing the columns
    workflows.every((_workflow) => {
      if (_workflow.id === workflowOfTheGroup.id) {
        const { groups } = _workflow;

        groupController.addNewGroupToList(groups, group);

        return false;
      }
      return true;
    });

    // add workflow in expanded column view's workflow
    // incase user is viewing a single workflow
    groupController.addNewGroupToList(workflow.groups, group);
  },

  [types.MUTATE_SUBSCRIBE_UPDATE_GROUP_NAME_EXPANDED_COLUMN]: (state, { group }) => {
    const current = state;
    const { workflows, workflow } = current;

    // update group in the workflow array by finding it
    // incase user is viewing the columns
    workflows.every((_workflow) => {
      if (_workflow.id === workflow.id) {
        const { groups } = _workflow;

        groupController.updateGroupName(groups, group);

        return false;
      }
      return true;
    });

    // update workflow in expanded column view's workflow
    // incase user is viewing a single workflow
    groupController.updateGroupName(workflow.groups, group);
  },

  [types.MUTATE_SUBSCRIBE_UPDATE_GROUP_NAME]: (state, { group }) => {
    const current = state;
    const { workflows, workflow } = current;

    // update group in the workflow array by finding it
    // incase user is viewing the columns
    workflows.every((_workflow) => {
      if (_workflow.id === workflow.id) {
        const { groups } = _workflow;

        groupController.updateGroupName(groups, group);

        return false;
      }
      return true;
    });

    // update workflow in expanded column view's workflow
    // incase user is viewing a single workflow
    groupController.updateGroupName(workflow.groups, group);
  },

  [types.MUTATE_SUBSCRIBE_REMOVE_GROUP_EXPANDED_COLUMN]: (state, { group }) => {
    const current = state;
    const { workflow, workflows } = current;

    // remove group in the workflow array by finding it
    // incase user is viewing the columns
    workflows.every((_workflow) => {
      if (_workflow.id === workflow.id) {
        const { groups } = _workflow;

        groupController.removeGroupFromList(_workflow, groups, group);

        return false;
      }
      return true;
    });

    // remove  workflow in expanded column view's workflow
    // incase user is viewing a single workflow
    groupController.removeGroupFromList(workflow, workflow.groups, group);
  },

  [types.MUTATE_SUBSCRIBE_MOVE_GROUP_EXPANDED_COLUMN]: (state, { group, position }) => {
    const current = state;
    const { workflow, workflows } = current;

    // move group in the workflow array by finding it
    // incase user is viewing the columns
    workflows.every((_workflow) => {
      if (_workflow.id === workflow.id) {
        const { groups } = _workflow;

        groupController.moveGroupFromList(groups, group, position);

        return false;
      }
      return true;
    });

    // move group workflow in expanded column view's workflow
    // incase user is viewing a single workflow
    groupController.moveGroupFromList(workflow.groups, group, position);
  },

  [types.MUTATE_SUBSCRIBE_REMOVE_GROUP]: (state, { group, workflow: workflowOfTheGroup }) => {
    const current = state;
    const { workflows, workflow } = current;

    // remove group in the workflow array by finding it
    // incase user is viewing the columns
    workflows.every((_workflow) => {
      if (_workflow.id === workflowOfTheGroup.id) {
        const { groups } = _workflow;

        groupController.removeGroupFromList(_workflow, groups, group);

        return false;
      }
      return true;
    });

    // remove group in workflow in expanded column view's workflow
    // incase user is viewing a single workflow
    groupController.removeGroupFromList(workflow, workflow.groups, group);
  },

  [types.MUTATE_SUBSCRIBE_MOVE_GROUP]: (
    state,
    { group, workflow: workflowOfTheGroup, position },
  ) => {
    const current = state;
    const { workflows, workflow } = current;

    // move group in the workflow array by finding it
    // incase user is viewing the columns
    workflows.every((_workflow) => {
      if (_workflow.id === workflowOfTheGroup.id) {
        const { groups } = _workflow;

        groupController.moveGroupFromList(groups, group, position);

        return false;
      }
      return true;
    });

    // move group in workflow in expanded column view's workflow
    // incase user is viewing a single workflow
    groupController.moveGroupFromList(workflow.groups, group, position);
  },

  [types.MUTATE_SUBSCRIBE_GROUP_CARD_MOVE_EXPANDED_COLUMN]: (state, cardMovedInGroup) => {
    const current = state;
    const { workflow } = current;
    const { cards, groups } = workflow;
    const {
      card, position, toGroupId, fromGroupId,
    } = cardMovedInGroup;

    // Remove card from group
    groupController.removeCardFromGroup(fromGroupId, cards, groups, card);

    // Add card to a group
    groupController.addCardToGroup(toGroupId, groups, position, card);
  },

  [types.MUTATE_SUBSCRIBE_GROUP_CARD_MOVE]: (state, cardMovedInGroup) => {
    const current = state;
    const { workflows } = current;
    const {
      card, position, toGroupId, fromGroupId,
    } = cardMovedInGroup;

    workflows.every((workflow) => {
      const { cards, groups } = workflow;

      // Remove card from group/workflow
      return groupController.removeCardFromGroup(fromGroupId, cards, groups, card);
    });

    workflows.every((workflow) => {
      const { groups } = workflow;

      // Add card to a group
      return groupController.addCardToGroup(toGroupId, groups, position, card);
    });
  },

  [types.MUTATE_ACTIVE_GROUP_IN_WORKFLOW]: (state, { workflowId, newActiveGroup }) => {
    const current = state;
    const pos = current.activeGroup.findIndex((workflowToContent) => workflowToContent[0] === workflowId);
    if (pos === -1) {
      current.activeGroup = [...current.activeGroup, [workflowId, newActiveGroup]];
    } else {
      current.activeGroup[pos].splice(1, 1, newActiveGroup);
    }
  },
};

export const actions = {
  [types.ADD_GROUP_IN_WORKFLOW]: ({ commit }, { workflowId, name }) => {
    apolloClient
      .mutate({
        mutation: ADD_GROUP_IN_WORKFLOW,
        variables: {
          workflowId,
          name,
        },
      })
      .catch((err) => {
        commit(types.MUTATE_MESSAGE, { type: 'ERROR', message: 'internal_server_error' });
        console.error(err);
      });
  },

  [types.UPDATE_GROUP_NAME]: ({ commit }, { workflowId, groupId, name }) => {
    apolloClient
      .mutate({
        mutation: UPDATE_GROUP_NAME,
        variables: {
          workflowId,
          groupId,
          name,
        },
      })
      .catch((err) => {
        commit(types.MUTATE_MESSAGE, { type: 'ERROR', message: 'internal_server_error' });
        console.error(err);
      });
  },

  [types.REMOVE_GROUP_FROM_WORKFLOW]: ({ commit }, { workflowId, groupId }) => {
    apolloClient
      .mutate({
        mutation: REMOVE_GROUP_FROM_WORKFLOW,
        variables: {
          workflowId,
          groupId,
        },
      })
      .catch((err) => {
        commit(types.MUTATE_MESSAGE, { type: 'ERROR', message: 'internal_server_error' });
        console.error(err);
      });
  },

  [types.MOVE_CARD_IN_GROUP]: ({ commit }, {
    fromGroupId, toGroupId, cardId, position,
  }) => {
    apolloClient
      .mutate({
        mutation: MOVE_CARD_IN_GROUP,
        variables: {
          fromGroupId,
          toGroupId,
          cardId,
          position,
        },
      })
      .catch((err) => {
        commit(types.MUTATE_MESSAGE, { type: 'ERROR', message: 'internal_server_error' });
        console.error(err);
      });
  },

  [types.UPDATE_GROUP_POSITION]: ({ commit }, {
    workflowId, groupId, position,
  }) => {
    apolloClient.mutate({
      mutation: UPDATE_GROUP_POSITION,
      variables: {
        workflowId, groupId, position,
      },
    }).catch((err) => {
      commit(types.MUTATE_MESSAGE, { type: 'ERROR', message: 'internal_server_error' });
      console.error(err);
    });
  },

  [types.SET_ACTIVE_GROUP_IN_WORKFLOW]: ({ commit }, [workflowId, newActiveGroup]) => {
    commit(types.MUTATE_ACTIVE_GROUP_IN_WORKFLOW, { workflowId, newActiveGroup });
  },

};
