import gql from 'graphql-tag';

// ----------------QUERIES------------------

// ----------------MUTATIONS------------------

export const ADD_CARD_COMMENT = gql`
  mutation($cardId: ID!, $message: String!) {
    addCardComment(cardId: $cardId, message: $message) {
        id
    }
  }
`;

export const EDIT_CARD_COMMENT = gql`
  mutation($cardId: ID!, $commentId: ID!, $message: String!) {
    editCardComment(cardId: $cardId, commentId: $commentId, message: $message) {
        id
    }
  }
`;

export const REMOVE_CARD_COMMENT = gql`
  mutation($cardId: ID!, $commentId: String!) {
    removeCardComment(cardId: $cardId, commentId: $commentId) {
      id
    }
  }
`;

// ----------------SUBSCRIPTIONS------------------

export const SUBSCRIBE_CARD_COMMENT_ADDED = gql`
subscription ($cardId: ID!){
  cardCommentAdded(cardId: $cardId) {
    id
    comment {
      id
      message
      timestamp
      user {
        id
        firstname
        lastname
      }
      isEdited
    }
  }
}
`;

export const SUBSCRIBE_CARD_COMMENT_REMOVED = gql`
subscription ($cardId: ID!){
  cardCommentRemoved(cardId: $cardId) {
    id
    comment {
      id
    }
  }
}
`;

export const SUBSCRIBE_CARD_COMMENT_EDITED = gql`
subscription ($cardId: ID!){
  cardCommentEdited(cardId: $cardId) {
    id
    comment {
      id
      message
      timestamp
      user {
        id
        firstname
        lastname
      }
      isEdited
    }
  }
}
`;
