import gql from 'graphql-tag';
import {
  MUTATION_MESSAGE,
} from './fragments';

// ----------------QUERIES--------------------

export const GET_AVATAR_BY_USER_ID = gql`
  query getAvatarByUserId($userId: ID!) {
    getAvatarByUserId(userId: $userId) {
      id
      isDeleted
      avatarId
    }
  }
`;

// ----------------MUTATION--------------------
export const UPLOAD_AVATAR = gql`
  mutation($avatar: Upload!) {
    uploadAvatar(avatar: $avatar) {
      ...mutationMessage
    }
  }
  ${MUTATION_MESSAGE.mutationMessage}
`;

export const DELETE_AVATAR = gql`
  mutation {
    deleteAvatar
  }
`;

// ----------------Subscriptions------------------
export const SUBSCRIBE_AVATAR_ADDED = gql`
  subscription {
    avatarAdded {
      id
      avatarId
    }
  }
`;

export const SUBSCRIBE_AVATAR_DELETED = gql`
  subscription {
    avatarDeleted {
      id
    }
  }
`;
