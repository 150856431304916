import * as types from '@/stores/types';
import { apolloClient } from '@/apolloConfig';
import {
  FETCH_USER,
  FETCH_ALL_USERS,
  LOGIN,
  LOGOUT,
  EDIT,
  VALIDATE_USER,
  ADD_MISSING_USERNAME,
  FETCH_IS_USERNAME_IN_USE,
  CHANGE_PASSWORD,
  DELETE_ACCOUNT,
  FETCH_DELETE_REPORT,
  FETCH_ALL_USERS_FOR_ADMIN,
} from '@/queries/user';
import { SIGNUP_TEMP, SIGNUP_WITH_TOKEN } from '@/queries/userTemp';

/**
 * GETTERS
 */
const getters = {
  [types.GET_USER]: (state) => state.user,
  [types.GET_USER_AUTHENTICATION_STATUS]: (state) => state.isAuthenticated,
  [types.GET_ALL_USERS]: (state) => state.allUsers,
  [types.GET_ALL_USERS_FOR_ADMIN]: (state) => state.usersForAdmin,
  [types.GET_AVATAR_EXPIRY_CHECK]: (state) => state.avatarExpiryCheck,
  [types.GET_TWO_FACTOR_INFO]: ({ twoFactor }) => twoFactor,
};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_LOGIN]: (state, user) => {
    const current = state;
    current.user = user;
    current.isAuthenticated = true;
    current.error = '';
  },

  [types.MUTATE_EDIT_USER]: (state, updatedUser) => {
    const current = state;
    current.user.firstname = updatedUser.firstname;
    current.user.lastname = updatedUser.lastname;
  },

  [types.MUTATE_FETCH_ALL_USERS]: (state, users) => {
    const current = state;
    current.allUsers = users;
  },

  [types.MUTATE_USERS_FOR_ADMIN]: (state, users) => {
    const current = state;
    current.usersForAdmin = users;
  },

  [types.MUTATE_SUBSCRIBE_USER_ADDED]: (state, user) => {
    const current = state;
    current.allUsers = [...current.allUsers, user];
  },

  [types.MUTATE_IS_AUTHENTICATED]: (state, status) => {
    const current = state;
    current.isAuthenticated = status;
  },
  [types.MUTATE_AVATAR_EXPIRY_CHECK]: (state, userId) => {
    const current = state;
    current.avatarExpiryCheck.push(userId);
  },
  [types.MUTATE_USERNAME]: (state, username) => {
    const current = state;
    current.user.username = username;
  },

  [types.MUTATE_SUBSCRIBE_USER_DELETED]: (state, deletedUser) => {
    const current = state;

    current.allUsers = current.allUsers.filter((user) => user.id !== deletedUser.id);
  },
  [types.MUTATE_START_TWO_FACTOR]: (state, {
    email, password, rememberMe, isADLogin,
  }) => {
    const { twoFactor } = state;
    twoFactor.isActive = true;
    twoFactor.email = email;
    twoFactor.password = password;
    twoFactor.rememberMe = rememberMe;
    twoFactor.isADLogin = isADLogin;
  },
  [types.MUTATE_RESET_TWO_FACTOR]: (state) => {
    const { twoFactor } = state;
    twoFactor.isActive = false;
    twoFactor.email = '';
    twoFactor.password = '';
    twoFactor.rememberMe = false;
    twoFactor.isADLogin = false;
  },
};

/**
 * Actions
 */
const actions = {
  [types.SIGNUP_TEMP]: async ({ commit }, { email, locale }) => {
    await apolloClient
      .mutate({
        mutation: SIGNUP_TEMP,
        variables: {
          email,
          locale,
        },
      })
      .then(({ data }) => {
        commit(types.MUTATE_MESSAGE, data.tempSignup);
      })
      .catch((err) => {
        commit(types.MUTATE_MESSAGE, err);
      });
  },

  [types.SIGNUP_WITH_TOKEN]: async (
    { commit },
    {
      firstname, lastname, token, password, locale, username,
    },
  ) => {
    await apolloClient
      .mutate({
        mutation: SIGNUP_WITH_TOKEN,
        variables: {
          firstname,
          lastname,
          password,
          locale,
          username,
          token,
        },
      })
      .then(({ data }) => {
        commit(types.MUTATE_LOGIN, data.signupWithToken);
        commit(types.MUTATE_UPDATE_SETTING, data.signupWithToken.setting);
      })
      .catch((e) => {
        const errorMessage = e.message.replace('GraphQL error:', '').trim();
        commit(types.MUTATE_MESSAGE, { type: 'ERROR', message: errorMessage });
      });
  },

  [types.LOGIN]: ({ commit }, {
    email, password, rememberMe, secondFactor, isADLogin,
  }) => new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: LOGIN,
        variables: {
          email,
          password,
          rememberMe,
          secondFactor,
          isADLogin,
        },
      })
      .then(({ data }) => {
        if (data.login.type) {
          commit(types.MUTATE_START_TWO_FACTOR, {
            email, password, rememberMe, isADLogin,
          });
        } else {
          commit(types.MUTATE_LOGIN, data.login);
          commit(types.MUTATE_UPDATE_SETTING, data.login.setting);
          commit(types.MUTATE_RESET_TWO_FACTOR);
        }
        resolve();
      })
      .catch((err) => reject(err));
  }),

  /**
   * When we need to get the cookie
   * deleted, we need to tell the
   * server to do it for us since
   * we cannot access the cookie
   * from the client
   */
  [types.LOGOUT]: async ({ commit }) => {
    await apolloClient
      .mutate({
        mutation: LOGOUT,
      })
      .then(() => {
        commit(types.MUTATE_IS_AUTHENTICATED, false);
        commit(types.MUTATE_CLEAN_AVATARS);
      });
  },

  /**
   * On a FORBIDDEN error from the backend,
   * the cookie is already destroyed anyways
   * so we logout locally without sending
   * any requests to the backed
   */
  [types.LOGOUT_LOCAL]: ({ commit }) => {
    commit(types.MUTATE_IS_AUTHENTICATED, false);
    commit(types.MUTATE_CLEAN_AVATARS);
  },

  /**
   * Get user info when
   * valid cookie exists
   */
  [types.FETCH_USER]: async ({ commit }) => new Promise((resolve, reject) => {
    apolloClient
      .query({
        query: FETCH_USER,
      })
      .then(({ data }) => {
        commit(types.MUTATE_LOGIN, data.getUser);
        commit(types.MUTATE_UPDATE_SETTING, data.getUser.setting);
        resolve(data.getUser.setting);
      })
      .catch((err) => {
        commit(types.MUTATE_IS_AUTHENTICATED, false);
        reject(err);
      });
  }),

  [types.EDIT_USER]: async ({ commit }, { firstname, lastname }) => {
    await apolloClient
      .mutate({
        mutation: EDIT,
        variables: {
          firstname,
          lastname,
        },
      })
      .then(({ data }) => {
        commit(types.MUTATE_EDIT_USER, data.editUserProfile);
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.FETCH_ALL_USERS]: async ({ commit }) => {
    await apolloClient
      .query({
        query: FETCH_ALL_USERS,
      })
      .then((response) => {
        const { getAllUsers } = response.data;
        commit(types.MUTATE_FETCH_ALL_USERS, getAllUsers);
      })
      .catch((err) => console.error(err));
  },

  [types.FETCH_ALL_USERS_FOR_ADMIN]: async ({ commit }) => {
    await apolloClient
      .query({
        query: FETCH_ALL_USERS_FOR_ADMIN,
      })
      .then((response) => {
        const { getAllUsersForAdmin } = response.data;
        commit(types.MUTATE_USERS_FOR_ADMIN, getAllUsersForAdmin);
      })
      .catch((err) => console.error(err));
  },

  [types.SUBSCRIBE_USER_ADDED]: async ({ commit }, user) => {
    commit(types.MUTATE_SUBSCRIBE_USER_ADDED, user);
  },

  [types.VALIDATE_USER_TOKEN]: async ({ commit }) => {
    await apolloClient
      .query({
        query: VALIDATE_USER,
      })
      .then(() => {
        commit(types.MUTATE_IS_AUTHENTICATED, true);
      })
      .catch(() => {
        commit(types.MUTATE_IS_AUTHENTICATED, false);
      });
  },

  [types.AVATAR_EXPIRY_CHECK]: ({ commit }, userId) => {
    commit(types.MUTATE_AVATAR_EXPIRY_CHECK, userId);
  },

  [types.ADD_MISSING_USERNAME]: async ({ commit }, username) => {
    const { data } = await apolloClient
      .mutate({
        mutation: ADD_MISSING_USERNAME,
        variables: {
          username,
        },
      })
      .catch((e) => console.error(e));
    commit(types.MUTATE_MESSAGE, data.addMissingUsername);
    if (data.addMissingUsername.type === 'INFO') commit(types.MUTATE_USERNAME, username);
    return data.addMissingUsername.type;
  },

  [types.FETCH_IS_USERNAME_IN_USE]: async (_, username) => {
    const { data } = await apolloClient
      .query({
        query: FETCH_IS_USERNAME_IN_USE,
        variables: {
          username,
        },
      })
      .catch((e) => console.error(e));
    return data;
  },

  [types.CHANGE_PASSWORD]: (
    { commit },
    { oldPassword, newPassword },
  ) => new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: CHANGE_PASSWORD,
        variables: {
          oldPassword,
          newPassword,
        },
      })
      .then(({ data }) => {
        commit(types.MUTATE_MESSAGE, data.changePassword);
        resolve(data.changePassword);
      })
      .catch((e) => reject(e));
  }),

  [types.DELETE_ACCOUNT]: ({ commit }, { password }) => new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: DELETE_ACCOUNT,
        variables: {
          password,
        },
      })
      .then(({ data }) => {
        const { deleteAccount } = data;

        if (deleteAccount.type) {
          commit(types.MUTATE_MESSAGE, deleteAccount);
        }

        resolve(deleteAccount);
      })
      .catch((e) => reject(e));
  }),

  [types.FETCH_BEFORE_DELETE_REPORT]: () => new Promise((resolve, reject) => {
    apolloClient
      .query({
        query: FETCH_DELETE_REPORT,
      })
      .then(({ data }) => {
        const { requestToDeleteAccount } = data;

        resolve(requestToDeleteAccount);
      })
      .catch((e) => reject(e));
  }),

  [types.SUBSCRIBE_USER_ACCOUNT_DELETION]: async ({ commit }, userDeleted) => {
    // Change comments to 'Deleted User'
    commit(types.MUTATE_SUBSCRIBE_COMMENT_DELETED_USER, userDeleted);

    // Change attachments to 'Deleted User'
    commit(types.MUTATE_ATTACHMENTS_DELETED_USER, userDeleted);

    // Remove user from box members
    commit(types.MUTATE_SUBSCRIBE_BOX_MEMBERS_USER_DELETED, userDeleted);

    // Remove user from all users
    commit(types.MUTATE_SUBSCRIBE_USER_DELETED, userDeleted);

    // Remove user from card members
    commit(types.MUTATE_SUBSCRIBE_CARD_MEMBERS_USER_DELETED, userDeleted);

    // Remove user from card member filter
    commit(types.MUTATE_SUBSCRIBE_FILTER_CARD_MEMBERS_USER_DELETE, userDeleted);
  },
};

/**
 * States
 */
const state = {
  user: {
    username: '',
  },
  isAuthenticated: false,
  allUsers: [],
  usersForAdmin: [], // List of users for admin panel
  avatarExpiryCheck: [],
  twoFactor: {
    email: '',
    rememberMe: false,
    password: '',
    isActive: false,
    isADLogin: false,
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
