const groupController = {
/**
 * Adds a new group inside a list of groups
 *
 * @param {Array} groups List of groups in a workflow
 * @param {Object} group The new group added
 */
  addNewGroupToList(groups, group) {
  // Add an empty cards array to allow cards to
  // be dragged in this group
    const cards = [];
    Object.defineProperty(group, 'cards', {
      value: cards,
      writable: true,
      configurable: true,
      enumerable: true,
    });

    // Push the new group to the existing group list
    groups.push(group);
  },

  /**
   * Updates the name of a group in a list of groups
   *
   * @param {Array} groups List of groups in a workflow
   * @param {Object} group The updated group
   *
   * @returns {Boolean} false to stop the .every() loop
   */
  updateGroupName(groups, group) {
  // Find the group that needs to be updated
    const foundGroup = groups.find((g) => g.id === group.id);

    // Update the name if found
    if (foundGroup) {
      foundGroup.name = group.name;
      return false;
    }

    return true;
  },

  /**
   * Removes @param group from a list @param groups in @param workflow.
   * Also takes the cards from the removed group and puts them
   * in @param workflow
   *
   * @param {Object} workflow The workflow the groups belong to
   * @param {Array} groups The groups from which a group needs to be
   * removed from
   * @param {Object} group The group that needs to be removed
   */
  removeGroupFromList(workflow, groups, group) {
  // Find the group that needs to be removed
    const foundGroup = groups.find((g) => g.id === group.id);
    const indexOfGroup = groups.findIndex((g) => g.id === group.id);

    // Remove group from the list
    groups.splice(indexOfGroup, 1);

    // Extract the cards from the group (if any)
    // And append them to the workflow
    const { cards } = foundGroup;
    if (cards) workflow.cards.push(...cards);
  },

  /**
   * Moves @param group in the list @param groups in workflow.
   *
   * @param {Array} groups The groups from which a group needs to be
   * moved from
   * @param {Object} group The group that needs to be moved
   */
  moveGroupFromList(groups, group, position) {
    const movedGroup = groups.find((g) => g.id === group.id);
    const indexOfGroup = groups.findIndex((g) => g.id === group.id);
    // Remove group from the list
    groups.splice(indexOfGroup, 1);
    // Append group into the list in specified position
    groups.splice(position, 0, movedGroup);
  },

  /**
   * Attempts to remove a card from a workflow or from its groups
   *
   * @param {String} fromGroupId The ID of the group from which the
   * card is coming from. Is NULL or "" if coming from a workflow
   * @param {Array} cards List of cards from which the card may need to be
   * removed from
   * @param {Array} groups List of groups from whose cards the card
   * may need to be removed from
   * @param {Object} card The card that has to be removed
   *
   * @returns {Boolean} false to stop the .every() loop
   */
  removeCardFromGroup(fromGroupId, cards, groups, card) {
    // Card is inserted from a workflow
    if (!fromGroupId) {
      return !groupController.removeCardFromList(card, cards);
    }

    // Remove card from a group
    return groups.every((group) => {
      const { cards: groupCards } = group;

      return !groupController.removeCardFromList(card, groupCards);
    });
  },

  /**
   * Adds a card to a group by traversing a list of groups
   *
   * @param {String} toGroupId The ID of the group to which the card needs
   * to be added to
   * @param {Array} groups List of groups in one of which the card may need
   * to be inserted in
   * @param {Number} position The index at which the card needs to be spliced in
   * @param {Object} card The card that needs to be added
   *
   * @returns {Boolean} false to stop the .every() loop
   */
  addCardToGroup(toGroupId, groups, position, card) {
    let isCardSpliced = false;

    groups.every((group) => {
      const { cards: groupCards } = group;
      if (group.id === toGroupId) {
        groupCards.splice(position, 0, card);
        isCardSpliced = true;

        // stop looping groups
        return false;
      }

      // continue looping groups
      return true;
    });

    if (isCardSpliced) return false;

    return true;
  },

  /**
   * Splices a card out of a list
   *
   * @param {Object} card The card needs to be removed
   * @param {Array} cards The list of cards from which
   * the card has to be removed
   */
  removeCardFromList(card, cards) {
    const index = cards.findIndex((c) => c.id === card.id);

    if (index !== -1) {
      cards.splice(index, 1);

      return true;
    }
    return false;
  },
};

export default groupController;
