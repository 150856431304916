<template>
  <v-app-bar class="aboutus-header" color="transparent" flat hide-on-scroll app absolute>
    <v-spacer></v-spacer>

    <!-- PUSHBOX LOGO -->
    <router-link tag="button" :to="isAuthenticated ? '/' : '/login'" class="mt-2">
      <v-tooltip bottom open-delay="200">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-img :src="require('@/assets/logo.svg')" aspect-ratio="2" contain />
          </v-btn>
        </template>
        <span>Home</span>
      </v-tooltip>
    </router-link>

    <v-img
      :src="getProperLogo"
      alt="alt"
      :max-width="$vuetify.breakpoint.smAndUp ? '180' : '150'"
      max-height="60"
      class="mx-2 mt-1"
    />

    <v-spacer></v-spacer>

    <!-- BUTTON FOR DIALOG -->
    <v-btn icon>
      <v-icon :color="$vuetify.theme.dark ? 'icon' : 'icon darken-2'" @click="menu = true">
        fa-bars
      </v-icon>
    </v-btn>

    <!-- DIALOG FOR ROUTES TO OTHER PAGES -->
    <wOverlayMenu :menu="menu" @close="menu=false" />
  </v-app-bar>
</template>

<script>
/* eslint-disable global-require */
import { mapGetters } from 'vuex';
import * as types from '@/stores/types';
import wOverlayMenu from '@/components/aboutUs/wOverlayMenu.vue';

/**
 * @group AboutUs
 *
 * This component is the alternative Header for the
 * about us page. This component is outside the
 * v-content in the App.vue file as recommended
 * by vuetify
 */
export default {
  name: 'WAboutUsHeader',

  components: {
    wOverlayMenu,
  },

  data() {
    return {
      menu: false,
    };
  },

  computed: {
    ...mapGetters({
      isAuthenticated: types.GET_USER_AUTHENTICATION_STATUS,
    }),

    getProperLogo() {
      if (this.$vuetify.theme.dark) return require('@/assets/pushbox-white.svg');

      return require('@/assets/pushbox.svg');
    },
  },
};
</script>

<style lang="css" scoped>
.aboutus-header {
    background-color: transparent !important;
    border-color: unset !important;
}
</style>
