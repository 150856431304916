<template>
  <v-dialog
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    persistent
    v-model="showImpressum"
  >
    <v-card>
      <v-card-text class="card-body-light black--text my-5">
        <div>
          <div id="c29" class=" frame frame-default frame-type-text frame-layout-0 ">
            <header class="stdheader ">
              <h1 class="single mainline ">Impressum</h1>
            </header>
            <br />
            <p><strong>Angaben gemäß § 5 TMG</strong></p>
            <p>pushbox ist ein Angebot von:</p>
            <p>
              <strong>Kieler Zeitung Verlags- und Druckerei KG-GmbH &amp; Co.</strong><br />
              Fleethörn 1/7<br />
              24103 Kiel<br />
              Telefon: +49 (0) 431 / 9030<br />
              E-Mail:
              <a
                href="javascript:linkTo_UnCryptMailto('nbjmup+wfsusjfcAljfmfs.obdisjdiufo\/ef');"
                title="vertrieb@kieler-nachrichten.de"
                >vertrieb(at)kieler-nachrichten.de</a
              >
            </p>
            <p>
              Umsatzsteuer-IdNr.: DE 134838752<br />
              Registergericht: Amtsgericht Kiel<br />
              Registernummer: HRA 121
            </p>
            <p>
              Komplementärin: Kieler Zeitung Verwaltungs GmbH<br />
              Registergericht: Amtsgericht Kiel<br />
              Registernummer: HRB 875
            </p>
            <p>Geschäftsführer: Sven Fricke</p>
            <p>
              Chefredakteur: Christian Longardt<br />
              (verantwortlich nach dem Rundfunkstaatsvertrag)
            </p>
            <p>
              Betrieblicher Datenschutzbeauftragter: Michael Braun<br />
              E-Mail: datenschutz@kieler-nachrichten.de
            </p>
            <p>Nachrichtenagenturen: dpa, RND, AFP, Reuters</p>
            <p>
              Leistungsschutzrecht:
              <a
                href="https://www.kn-online.de/Portal-Service/Leistungsschutzrecht"
                title="mehr erfahren Sie hier"
                target="_blank"
                >mehr erfahren Sie hier</a
              >
            </p>
            <p>Sowie:</p>
            <p>
              <strong>wobe-systems GmbH</strong><br />
              Edisonstr. 3<br />
              24145&nbsp;Kiel<br />
              Telefon: +49(0)431 / 382165.50<br />
              Telefax: +49(0)431 / 382165.99<br />
              E-Mail:
              <a
                href="javascript:linkTo_UnCryptMailto('nbjmup+jogpAxpcf.tztufnt\/dpn');"
                title="info@wobe-systems.com"
                >info(at)wobe-systems.com</a
              >
            </p>
            <p>
              Registernummer: HRB 5783<br />
              Registergericht: Amtsgericht Kiel
            </p>
            <p>
              Vertreten durch die Geschäftsführer:<br />
              Oliver Dissars e.Kfm.<br />
              Maik Wojcieszak e.Kfm.<br /><br />
              Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br />
              DE 214 698 597
            </p>
          </div>
          <div id="c34" class=" frame frame-default frame-type-text frame-layout-0 ">
            <p>
              <strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV </strong><br />
              Oliver Dissars e.Kfm.<br />
              &nbsp;
            </p>
          </div>
          <div id="c90" class=" frame frame-default frame-type-text frame-layout-0 ">
            <p><strong>Alternative Streitbeilegung</strong></p>
            <p>
              Die Europäische Kommission stellt eine Plattform für die außergerichtliche
              Online-Streitbeilegung (OS-Plattform) bereit, aufrufbar unter
              <a href="http://ec.europa.eu/odr" target="_blank">http://ec.europa.eu/odr</a>.<br />
              Wir sind bereit, an Streitbeilegungsverfahren vor Verbraucherschlichtungsstellen
              teilzunehmen.
            </p>
          </div>
          <div id="c36" class=" frame frame-default frame-type-text frame-layout-0 ">
            <p><br /><strong>Haftungsausschluss (Disclaimer) </strong></p>
            <p>
              <strong>Haftung für Inhalte </strong><br />
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
              nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
              Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
              Informationen zu überwachen oder nach Umständen zu forschen, die auf eine
              rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
              Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
              Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
              konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
              Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>
            <p>
              <strong>Haftung für Links </strong><br />
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir
              keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
              übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
              der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
              Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der
              verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
              zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
              entfernen.
            </p>
            <p>
              <strong>Urheberrecht </strong><br />
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
              unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung
              und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
              dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit
              die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
              gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
              werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </p>
          </div>
          <div id="c50" class=" frame frame-default frame-type-text frame-layout-0 ">
            <p>Quelle: <a href="https://www.e-recht24.de" target="_blank">eRecht24</a></p>
          </div>
          <div id="c61" class=" frame frame-default frame-type-text frame-layout-0 ">
            <p>
              <br /><strong>Konzeption, Text, Gestaltung &amp; Programmierung</strong><br />
              HOCHZWEI – büro für visuelle kommunikation gmbh &amp; co. kg<br />
              Fördepromenade 16-18 - Sonwik<br />
              24944 Flensburg<br />
              E-Mail:
              <a
                href="javascript:linkTo_UnCryptMailto('nbjmup+jogpAipdiaxfj\/ef');"
                title="Nehmen Sie Kontakt mit HOCHZWEI GmbH &amp; Co. KG auf"
                >info@hoch2.de</a
              ><br />
              Internet:
              <a
                href="http://www.hochzwei.de"
                title="HOCHZWEI GmbH &amp; Co. KG - Ihre Werbeagentur für Flensburg, Husum,
                Schleswig, Neumünster, Rendsburg, Pinneberg und Lübeck"
                target="_blank"
                >www.hochzwei.de</a
              >
            </p>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="$emit('close')" text color="button_as_text" block>
          {{ $t("confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'WImpressum',

  props: {
    showImpressum: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
