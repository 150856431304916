import { apolloClient } from '@/apolloConfig';
import {
  FETCH_CUSTOM_DESCRIPTIONS,
  ADD_CUSTOM_DESCRIPTION,
  DELETE_CUSTOM_DESCRIPTION,
  UPDATE_CUSTOM_DESCRIPTION_TITLE,
  UPDATE_DESCRIPTION_IN_CUSTOM_DESCRIPTION,
} from '@/queries/customDescription';
import * as types from '../types';

/**
 * GETTERS
 */

const getters = {
  [types.GET_CUSTOM_DESCRIPTIONS_FOR_CARD]: ({ customDescriptions }) => customDescriptions,
  [types.GET_DIRTY_CUSTOM_DESCRIPTIONS]: ({ dirtyCustomDescriptions }) => dirtyCustomDescriptions,
  [types.GET_CUSTOM_DESCRIPTION_SHOW_TEXT_AREA]: ({ showTextArea }) => showTextArea,
};

/**
 * MUTATIONS
 */

const mutations = {
  [types.MUTATE_FETCH_CUSTOM_DESCRIPTIONS]: (state, newCustomDescriptions) => {
    const current = state;
    current.customDescriptions = newCustomDescriptions;
  },

  [types.MUTATE_SUBSCRIBE_CUSTOM_DESCRIPTION_ADDED]: (state, newCustomDescription) => {
    const current = state;
    current.customDescriptions.push(newCustomDescription);
  },

  [types.MUTATE_SUBSCRIBE_CUSTOM_DESCRIPTION_DELETED]: (state, deletedCustomDescription) => {
    const current = state;
    const position = current.customDescriptions.findIndex(
      (prop) => prop.id === deletedCustomDescription.id,
    );
    current.customDescriptions.splice(position, 1);
  },

  [types.MUTATE_SUBSCRIBE_CUSTOM_DESCRIPTION_UPDATED]: (state, updatedCustomDescription) => {
    const current = state;
    const customDescription = current.customDescriptions.find(
      (prop) => prop.id === updatedCustomDescription.id,
    );
    if (customDescription) {
      customDescription.title = updatedCustomDescription.title;
    }
  },

  [types.MUTATE_SUBSCRIBE_CUSTOM_DESCRIPTION_TEXT_UPDATED]: (state, updatedCustomDescription) => {
    const current = state;
    const customDescription = current.customDescriptions.find(
      (prop) => prop.id === updatedCustomDescription.id,
    );
    if (customDescription) {
      customDescription.description = updatedCustomDescription.description;
    }
  },

  [types.MUTATE_TOGGLE_CUSTOM_DESCRIPTION_DIRTY]: (state, { id, dirty }) => {
    const current = state;
    if (dirty) {
      if (!current.dirtyCustomDescriptions.includes(id)) {
        current.dirtyCustomDescriptions.push(id);
      }
    } else {
      const index = current.dirtyCustomDescriptions.findIndex(
        (customDescriptionId) => customDescriptionId === id,
      );

      if (index !== -1) {
        current.dirtyCustomDescriptions.splice(index, 1);
      }
    }
  },

  [types.MUTATE_TOGGLE_CUSTOM_DESCRIPTION_SHOW_TEXT_AREA]: (state, { id, showTextArea }) => {
    const current = state;
    if (showTextArea) {
      if (!current.showTextArea.includes(id)) {
        current.showTextArea.push(id);
      }
    } else {
      const index = current.showTextArea.findIndex(
        (customDescriptionId) => customDescriptionId === id,
      );

      if (index !== -1) current.showTextArea.splice(index, 1);
    }
  },

  [types.MUTATE_CLEAR_CUSTOM_DESCRIPTIONS_DIRTY]: (state) => {
    const current = state;
    current.dirtyCustomDescriptions = [];
  },

  [types.MUTATE_CLEAR_CUSTOM_DESCRIPTIONS_TEXT_AREA]: (state) => {
    const current = state;
    current.showTextArea = [];
  },
};

/**
 * ACTIONS
 */

const actions = {
  [types.ADD_NEW_CUSTOM_DESCRIPTION_TO_CARD]: (_, { cardId, title }) => {
    apolloClient
      .mutate({
        mutation: ADD_CUSTOM_DESCRIPTION,
        variables: { cardId, title },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.FETCH_CUSTOM_DESCRIPTIONS_FOR_CARD]: ({ commit }, cardId) => {
    apolloClient
      .query({
        query: FETCH_CUSTOM_DESCRIPTIONS,
        variables: { cardId },
      })
      .then(({ data }) => {
        commit(types.MUTATE_FETCH_CUSTOM_DESCRIPTIONS, data.customDescriptions);
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.UPDATE_CUSTOM_DESCRIPTION_TITLE]: (_, { cardId, customDescriptionId, title }) => {
    apolloClient
      .mutate({
        mutation: UPDATE_CUSTOM_DESCRIPTION_TITLE,
        variables: { cardId, customDescriptionId, title },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.DELETE_CUSTOM_DESCRIPTION_FROM_CARD]: (_, { cardId, customDescriptionId }) => {
    apolloClient
      .mutate({
        mutation: DELETE_CUSTOM_DESCRIPTION,
        variables: { cardId, customDescriptionId },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.UPDATE_CUSTOM_DESCRIPTION_TEXT]: (_, { cardId, customDescriptionId, description }) => {
    apolloClient
      .mutate({
        mutation: UPDATE_DESCRIPTION_IN_CUSTOM_DESCRIPTION,
        variables: { cardId, customDescriptionId, description },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.SUBSCRIBE_CUSTOM_DESCRIPTION_ADDED]: ({ commit }, customDescription) => {
    commit(types.MUTATE_SUBSCRIBE_CUSTOM_DESCRIPTION_ADDED, customDescription);
  },

  [types.SUBSCRIBE_CUSTOM_DESCRIPTION_DELETED]: ({ commit }, customDescription) => {
    commit(types.MUTATE_SUBSCRIBE_CUSTOM_DESCRIPTION_DELETED, customDescription);
  },

  [types.SUBSCRIBE_CUSTOM_DESCRIPTION_UPDATED]: ({ commit }, customDescription) => {
    commit(types.MUTATE_SUBSCRIBE_CUSTOM_DESCRIPTION_UPDATED, customDescription);
  },

  [types.SUBSCRIBE_CUSTOM_DESCRIPTION_TEXT_UPDATED]: ({ commit }, customDescription) => {
    commit(types.MUTATE_SUBSCRIBE_CUSTOM_DESCRIPTION_TEXT_UPDATED, customDescription);
  },

  [types.CLEAR_CUSTOM_DESCRIPTIONS_DIRTY]: ({ commit }) => {
    commit(types.MUTATE_CLEAR_CUSTOM_DESCRIPTIONS_DIRTY);
  },
  [types.CLEAR_CUSTOM_DESCRIPTIONS_TEXT_AREA]: ({ commit }) => {
    commit(types.MUTATE_CLEAR_CUSTOM_DESCRIPTIONS_TEXT_AREA);
  },
};
/**
 * STATES
 */

const state = {
  customDescriptions: [],
  dirtyCustomDescriptions: [],
  showTextArea: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
