export default {
  /**
   * Create the path for Breadcrumb for the toolbar
   *
   * @param {Object} route - The route the user is redirecting to
   * @param {Object} box - The box the breadcrumb belongs to
   * @param {Array} prevBreadcrumbState - Information about the
   * breadcrumb in the previous page
   *
   * @returns Array of Object(s) with breadcrumb name and path
   */
  createPath(route, box = undefined, prevBreadcrumbState = undefined) {
    const { breadcrumb } = route.meta;
    const { path } = route;
    const fullPath = [];
    if (!box) {
      fullPath.push({ name: breadcrumb, path, disabled: true });
      return fullPath;
    }

    if (route.name === 'BoxSettings' && prevBreadcrumbState.length === 2) {
      fullPath.push(
        {
          name: prevBreadcrumbState[0].name,
          path: prevBreadcrumbState[1].path,
          disabled: false,
        },
        {
          name: breadcrumb,
          path,
          disabled: true,
        },
      );
    } else if (['Workflow', 'Category', 'Label', 'Calendar'].includes(route.name)) {
      fullPath.push(
        { name: box.name, path, disabled: false },
        { name: breadcrumb, path, disabled: true },
      );
    } else {
      // When we have no information about the previous breadcrumb
      // the fall back path is to go back to the workflow view
      // This can happen when the user refreshes the page
      const fallBackPath = `/workflow/${route.params.boxId}`;
      fullPath.push(
        { name: box.name, path: fallBackPath, disabled: false },
        { name: breadcrumb, path, disabled: true },
      );
    }
    return fullPath;
  },
};
