<template>
  <div>
    <span>
      {{ $t(selectorString) }}
    </span>
    <div>
      <div v-if="list.length === 0">
        <span class="noData">{{ $t(noData) }}</span>
        <br>
        <slot name="no-data" />
      </div>
      <v-radio-group
        v-model="currentSelection"
        v-if="list.length > 0"
        mandatory
      >
        <v-radio
          v-if="!hasNoEmptyOption"
          :label="$t(selectNothing)"
          :value="NO_VALUE"
        />
        <v-radio
          v-for="item in list"
          :key="item[idAttribute]"
          :value="item[idAttribute]"
        >
          <template v-slot:label>
            <span>
              {{ item[nameAttribute] }}
            </span>
          </template>
        </v-radio>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import { NO_VALUE } from '@/scripts/common';

/**
 * @group
 * A component for a list of radiobuttons made from a list of choices
 */
export default {
  name: 'WListPicker',

  props: {
    // For use with v-model. The selection.
    value: {
      type: String,
      required: true,
    },

    // If true automatically selects the first element if there is one
    hasNoEmptyOption: {
      default: false,
      type: Boolean,
    },

    // The list of choices. Contains objects with idAttribute and nameAttribute
    list: {
      required: true,
      type: Array,
    },

    // Name attribute on the list, defaults to 'name'
    nameAttribute: {
      default: 'name',
      type: String,
    },

    // The Id attribute on the list.
    idAttribute: {
      default: 'id',
      type: String,
    },

    // The i18n string displayed when the list is empty
    noData: {
      required: true,
      type: String,
    },

    // The i18n string displayed at the checkbox that selects nothing
    selectNothing: {
      default: '',
      type: String,
    },

    // The i18n string displayed at the top of the selector
    selectorString: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      NO_VALUE,
    };
  },

  created() {
    this.updateActive();
  },

  watch: {
    list() {
      this.updateActive();
    },
  },

  computed: {

    currentSelection: {
      get() {
        return this.value;
      },
      set(newSelection) {
        if (this.currentSelection !== newSelection) {
          this.$emit('input', newSelection);
        }
      },
    },
  },

  methods: {
    updateActive() {
      const index = (this.list || []).findIndex(
        (item) => item[this.idAttribute] === this.currentSelection,
      );
      if (index === -1) {
        this.currentSelection = this.hasNoEmptyOption
          ? ((this.list || [])[0] || {})[this.idAttribute] || NO_VALUE
          : NO_VALUE;
      }
    },
  },
};
</script>

<style scoped>
.noData {
  color: red;
}
</style>
