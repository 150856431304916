export default {
  /**
   * Get the RGB values from the hex and based on
   * the Hue, Saturation, and Perceived Brightness (HSP)
   * we set the class for the font color
   *
   * @param {String} color - a HEX color string
   */
  shouldIUseDark(color) {
    const rgb = color.replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`,
    )
      .substring(1).match(/.{2}/g)
      .map((x) => parseInt(x, 16));

    const hsp = Math.sqrt(
      0.299 * (rgb[0] * rgb[0])
        + 0.587 * (rgb[1] * rgb[1])
        + 0.114 * (rgb[2] * rgb[2]),
    );

    // Change the threshold to apply the respective class
    // Sweet spot: 127.5
    if (hsp > 143) {
      return true;
    }
    return false;
  },

  getRandomColor() {
    const red = `${Math.floor(Math.random() * 16).toString(16)}${Math.floor(Math.random() * 16).toString(16)}`;
    const green = `${Math.floor(Math.random() * 16).toString(16)}${Math.floor(Math.random() * 16).toString(16)}`;
    const blue = `${Math.floor(Math.random() * 16).toString(16)}${Math.floor(Math.random() * 16).toString(16)}`;

    const randColor = `#${red}${green}${blue}`;

    return randColor;
  },
};
