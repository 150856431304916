import gql from 'graphql-tag';
import {
  MUTATION_MESSAGE,
} from './fragments';

// --------- MUTATIONS ---------
export const PIN_CARD = gql`
  mutation($cardId: ID!) {
    pinCard(cardId: $cardId){
      ...mutationMessage
    }
  }
  ${MUTATION_MESSAGE.mutationMessage}
`;

export const UNPIN_CARD = gql`
  mutation($cardId: ID!) {
    unpinCard(cardId: $cardId){
      ...mutationMessage
    }
  }
  ${MUTATION_MESSAGE.mutationMessage}
`;

// ----------------SUBSCRIPTIONS------------------

export const SUBSCRIBE_PINNED = gql`
subscription {
  cardPinned {
    boxId
    workflowId
    cardId 
  }
}
`;

export const SUBSCRIBE_UNPINNED = gql`
subscription {
  cardUnpinned {
    boxId
    workflowId
    cardId 
  }
}`;
