<template>
  <!-- Text box to add card -->
  <div
    v-if="isTextEnable"
  >
    <v-text-field
      v-model="name"
      :rules="[() => !!name || $t('require_box_group_name') ]"
      :label="$t('subtitle_box_group')"
      id="id"
      solo
      autofocus
      @keyup.enter="createBox"
    >
      <template #append>
        <!-- Color for the Cross Icon inside the Input Field -->
        <v-icon
          color="warning"
          @click="isTextEnable = false"
        >
          {{ $vuetify.theme.dark ? 'far fa-times-circle' : 'fa-times-circle' }}
        </v-icon>
      </template>
      <template #append-outer>
        <!-- Color for the Send Icon outside the Input Field -->
        <v-icon
          color="primary"
          @click="createBox"
        >
         fa-save
        </v-icon>
      </template>
    </v-text-field>
  </div>

  <!-- Add button -->
  <v-btn
    v-else
    color="button info"
    @click="isTextEnable = true"
  >
    <v-icon
      class="mx-2"
      small
    >
      fa-plus
    </v-icon>
    {{ $t('title_addBox_group') }}
  </v-btn>
</template>

<script>
import { mapActions } from 'vuex';
import * as types from '@/stores/types';

/**
 * @group Box
 * This component is for adding new Box
 */
export default {
  name: 'WAddBoxGroup',

  data() {
    return {
      name: '',
      isTextEnable: false,
    };
  },

  methods: {
    ...mapActions({
      addBoxGroup: types.NEW_BOX_GROUP,
    }),

    /**
     * @vuese
     * This method is to add new Box
     */
    createBox() {
      if (!this.name) {
        return;
      }
      this.addBoxGroup(this.name);
      this.isTextEnable = false;
      this.name = '';
    },
  },
};
</script>
