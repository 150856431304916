import gql from 'graphql-tag';
import { CARD_FRAGMENT } from './fragments';

// ----------------QUERIES------------------

export const GET_ALL = gql`
  query($boxId: ID){
    getCategoriesByBoxId(boxId: $boxId) {
      id
      name
      cards{
        ...card
      }
      
      propertiesSum {
        name
        total
      }
    }
  }
  ${CARD_FRAGMENT.cardOverview}
`;

export const GET_ALL_NAMES_IDS_CARD_IDS = gql`
  query($boxId: ID){
    getCategoriesByBoxId(boxId: $boxId) {
      id
      name
      cards{
        id
      }
    }
  }
`;

// ----------------MUTATIONS------------------

export const ADD = gql`
  mutation($name: String!, $boxId: ID!) {
    addCategory(name: $name, boxId: $boxId){
      id
      name
    }
  }
`;

export const UPDATE = gql`
  mutation($id: ID!,$name: String!) {
    updateCategory(id: $id, name: $name){
      id
      name
    }
  }
`;

export const DELETE = gql`
  mutation($id: ID!) {
    removeCategory(id: $id){
      id
      name
    }
  }
`;

export const MOVE_CARD = gql`
 mutation($from: ID, $to: ID, $card: ID!, $position: Int) {
    moveCardInCategory(
      from: $from,
      to: $to,
      card: $card,
      position: $position,
    ) {
      from
    }
  }
`;

export const MOVE_CATEGORY = gql`
 mutation($box: ID!, $newPosition: Int!, $categoryId: ID!, $oldPosition: Int!) {
    moveCategoryInBox(
      box: $box,
      newPosition: $newPosition,
      categoryId: $categoryId,
      oldPosition: $oldPosition,
    )
  }
`;

// ----------------SUBSCRIPTIONS------------------

export const SUBSCRIBE_ADDED = gql`
  subscription {
    categoryAdded {
      id
      boxId
      name
      cards {
        id
        title
      }
    }
  }
`;

export const SUBSCRIBE_UPDATED = gql`
  subscription {
    categoryUpdated {
      id
      boxId
      name
      cards {
        id
        title
      }
    }
  }
`;

export const SUBSCRIBE_DELETE = gql`
  subscription {
    categoryRemoved {
      boxId
      id
    }
  }
`;

export const SUBSCRIBE_MOVE_CARD = gql`
  subscription {
    cardMovedInCategory {
      from
      to
      card{
        ...card
      }
      position
    }
  }
  ${CARD_FRAGMENT.cardOverview}
`;

export const SUBSCRIBE_MOVE_CATEGORY = gql`
  subscription {
    categoryMoved {
      box
      newPosition
      oldPosition
    }
  }
`;

export const SUBSCRIBE_CATEGORY_PROPERTY_SUM_UPDATE = gql`
  subscription {
    categoryPropertiesSumUpdated {
      id
      propertiesSum {
        name
        total
      }
    }
  }
`;
