/*
    ----------------------------Getters----------------------
*/
// BOX
export const GET_BOXES = 'GET_BOXES';
export const GET_PENDING_BOXES = 'GET_PENDING_BOXES';
export const GET_SEARCH_BOX = 'GET_SEARCH_BOX';
export const GET_SELECTED_BOX = 'GET_SELECTED_BOX';
export const GET_BOX_MEMBERS = 'GET_BOX_MEMBERS';
export const GET_BOX_SETTINGS = 'GET_BOX_SETTINGS';
export const GET_BOX_GROUPS = 'GET_BOX_GROUPS';
export const GET_BOX_API_FLAG = 'GET_BOX_API_FLAG';

// USER SPECIFIC BOX SETTINGS
export const GET_USER_SPECIFIC_BOX_SETTINGS = 'GET_USER_SPECIFIC_BOX_SETTINGS';

// WORKFLOW
export const GET_ALL_WORKFLOWS = 'GET_ALL_WORKFLOWS';
export const GET_ALL_WORKFLOWS_NAME_ID_CARD_IDS = 'GET_ALL_WORKFLOWS_NAME_ID_CARD_IDS';
export const GET_ACTIVE_WORKFLOW = 'GET_ACTIVE_WORKFLOW';
export const GET_WORKFLOW = 'GET_WORKFLOW';
export const GET_ACTIVE_GROUP_IN_WORKFLOW = 'GET_ACTIVE_GROUP_IN_WORKFLOW';

// CATEGORY
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const GET_ALL_CATEGORIES_NAME_ID_CARD_IDS = 'GET_ALL_CATEGORIES_NAME_ID_CARD_IDS';

// Card
export const GET_SELECTED_CARD = 'GET_SELECTED_CARD';
export const GET_CARD_HEADER = 'GET_CARD_HEADER';
export const GET_DESCRIPTION = 'GET_DESCRIPTION';
export const GET_CARD_MEMBERS = 'GET_CARD_MEMBERS';
export const GET_DESC_DIRTY = 'GET_DESC_DIRTY';
export const GET_SHOW_TEXT_AREA = 'GET_SHOW_TEXT_AREA';
export const GET_CARD_PANEL = 'GET_CARD_PANEL';
export const GET_CARD_WAS_MODIFIED = 'GET_CARD_WAS_MODIFIED';
export const GET_TITLE_DIRTY = 'GET_TITLE_DIRTY';
export const GET_SUBTITLE_DIRTY = 'GET_SUBTITLE_DIRTY';

// CARD PIN
export const PIN_CARD_ACTION = 'PIN_CARD_ACTION';
export const UNPIN_CARD_ACTION = 'UNPIN_CARD_ACTION';
export const SUBSCRIBE_PIN_CARD = 'SUBSCRIBE_PIN_CARD';
export const SUBSCRIBE_UNPIN_CARD = 'SUBSCRIBE_UNPIN_CARD';

// CARD COMMENTS
export const GET_CARD_COMMENTS = 'GET_CARD_COMMENTS';

// CARD MULTI SELECT
export const GET_IS_CARD_MULTISELECT_ON = 'GET_IS_CARD_MULTISELECT_ON';
export const GET_SELECTED_CARDS_UPDATE = 'GET_SELECTED_CARDS_UPDATE';
export const GET_SELECTED_CARDS = 'GET_SELECTED_CARDS';

// DATE
export const GET_CARD_DATES = 'GET_CARD_DATES';
export const GET_DATE_NAME_DIRTY = 'GET_DATE_NAME_DIRTY';

// CALENDAR
export const GET_CARDS_CALENDAR_VIEW = 'GET_CARDS_CALENDAR_VIEW';
export const GET_CALENDAR_VIEW_TYPE = 'GET_CALENDAR_VIEW_TYPE';
export const GET_START_DATE = 'GET_START_DATE';
export const GET_WEEKDAY_TYPE = 'GET_WEEKDAY_TYPE';

// ATTACHMENT
export const GET_ATTACHMENTS = 'GET_ATTACHMENTS';
export const GET_ATTACHMENTS_DIRTY = 'GET_ATTACHMENTS_DIRTY';
export const GET_TRIGGER_ATTACHMENTS_UPLOAD = 'GET_TRIGGER_ATTACHMENTS_UPLOAD';

// CHECKLIST
export const GET_ALL_CHECKLIST = 'GET_ALL_CHECKLIST';

// LABEL
export const GET_BOX_LABELS = 'GET_BOX_LABELS';
export const GET_CARD_LABELS = 'GET_CARD_LABELS';
export const GET_LABEL_LOADING = 'GET_LABEL_LOADING';
export const GET_LABEL_EXPANSION_STATUS = 'GET_LABEL_EXPANSION_STATUS';

// LABEL VIEW
export const GET_CARDS_GROUP_BY_LABELS = 'GET_ALL_LABELS';

// SETTING
export const GET_SETTING = 'GET_SETTING';

// ACTIVITY
export const GET_ACTIVITIES_OF_BOX = 'GET_ACTIVITIES_OF_BOX';
export const GET_ACTIVITIES_OF_CARD = 'GET_ACTIVITIES_OF_CARD';
export const GET_TOTAL_BOX_ACTIVITIES = 'GET_TOTAL_BOX_ACTIVITIES';
export const GET_TOTAL_CARD_ACTIVITIES = 'GET_TOTAL_CARD_ACTIVITIES';

// BOX SETTINGS
export const GET_COLLABORATORS_FOR_BOX = 'GET_COLLABORATORS_FOR_BOX';

// USER
export const GET_USER = 'GET_USER';
export const GET_USER_AUTHENTICATION_STATUS = 'GET_USER_AUTHENTICATION_STATUS';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_FOR_ADMIN = 'GET_ALL_USERS_FOR_ADMIN';
export const GET_AVATAR_EXPIRY_CHECK = 'GET_AVATAR_EXPIRY_CHECK';
export const GET_TWO_FACTOR_INFO = 'GET_TWO_FACTOR_INFO';

// USER_TEMP
export const GET_USER_TEMP = 'GET_USER_TEMP';
export const GET_INVITE_LOADING = 'GET_INVITE_LOADING';
export const GET_TOKEN_VERIFICATION = 'GET_TOKEN_VERIFICATION';

// AVATAR
export const GET_AVATAR = 'GET_AVATAR';

// Filter
export const GET_FILTER_IS_ACTIVE = 'GET_FILTER_IS_ACTIVE';
export const GET_FILTER_DATE = 'GET_FILTER_DATE';
export const GET_FILTER_LABELS = 'GET_FILTER_LABELS';
export const GET_FILTER_TITLE = 'GET_FILTER_TITLE';
export const GET_FILTER_CHECKLIST = 'GET_FILTER_CHECKLIST';
export const GET_FILTER_WORKFLOW = 'GET_FILTER_WORKFLOW';
export const GET_FILTER_CARD_MEMBERS = 'GET_FILTER_CARD_MEMBERS';
export const GET_FILTER_FOR_BOX = 'GET_FILTER_FOR_BOX';

// Sidebar
export const GET_SIDEBAR_ACTIVE = 'GET_SIDEBAR_ACTIVE';

// BREADCRUMBS
export const GET_BREADCRUMB = 'GET_BREADCRUMB';

// PROPERTY
export const GET_PROPERTIES_FOR_CARD = 'GET_PROPERTIES_FOR_CARD';
export const GET_PROPERTY_DIRTY_FOR_CARD = 'GET_PROPERTY_DIRTY_FOR_CARD';

// CHECKLIST
export const GET_CHECKLISTS_FOR_CARD = 'GET_CHECKLISTS_FOR_CARD';
export const GET_DIRTY_CHECKLISTS = 'GET_DIRTY_CHECKLISTS';

// CUSTOM DESCRIPTION
export const GET_CUSTOM_DESCRIPTIONS_FOR_CARD = 'GET_CUSTOM_DESCRIPTIONS_FOR_CARD';
export const GET_DIRTY_CUSTOM_DESCRIPTIONS = 'GET_DIRTY_CUSTOM_DESCRIPTIONS';
export const GET_CUSTOM_DESCRIPTION_SHOW_TEXT_AREA = 'GET_CUSTOM_DESCRIPTION_SHOW_TEXT_AREA';

// TEMPLATE
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_ACTIVE_TEMPLATE = 'GET_ACTIVE_TEMPLATE';

// DATA_LOADING
export const GET_PAGE_LOADING = 'GET_PAGE_LOADING';

// BOX_ROLE
export const GET_USER_BOX_ROLE = 'GET_USER_BOX_ROLE';
export const GET_USER_TEMPLATE_RIGHTS = 'GET_USER_TEMPLATE_RIGHTS';
export const GET_USER_MEMBER_RIGHTS = 'GET_USER_MEMBER_RIGHTS';
export const GET_USER_BOX_RIGHTS = 'GET_USER_BOX_RIGHTS';
export const GET_ALL_ROLES_IN_BOX = 'GET_ALL_ROLES_IN_BOX';
export const GET_USER_ROLE_NAME = 'GET_USER_ROLE_NAME';
export const GET_USER_LABEL_RIGHTS = 'GET_USER_LABEL_RIGHTS';

// BOX SETTINGS
export const GET_ALL_SETTINGS_IN_BOX = 'GET_ALL_SETTINGS_IN_BOX';

// SNACKBAR
export const GET_ERROR = 'GET_ERROR';
export const GET_MESSAGE = 'GET_MESSAGE';

// ADMIN_PANEL
export const GET_LDAP_CONFIG = 'GET_LDAP_CONFIG';
export const GET_GENERAL_CONFIG = 'GET_GENERAL_CONFIG';
export const GET_ADMIN_AUTHENTICATION_STATUS = 'GET_ADMIN_AUTHENTICATION_STATUS';
export const GET_USER_STATISTICS = 'GET_USER_STATISTICS';

// PASSWORD_RECOVERY
export const GET_TOKEN_IS_VALID = 'GET_TOKEN_IS_VALID';

// ALL CARDS
export const GET_ALL_CARDS = 'GET_ALL_CARDS';
export const GET_ALL_CARDS_TOTAL_COUNT = 'GET_ALL_CARDS_TOTAL_COUNT';

/*
    --------------------------Mutation-----------------------
*/
// BOX
export const MUTATE_ADD_BOX = 'MUTATE_ADD_BOX';
export const MUTATE_DELETE_BOX = 'MUTATE_DELETE_BOX';
export const MUTATE_UPDATE_BOX = 'MUTATE_UPDATE_BOX';
export const MUTATE_FETCH_BOXES = 'MUTATE_FETCH_BOXES';
export const MUTATE_PENDING_BOXES = 'MUTATE_PENDING_BOXES';
export const MUTATE_SUBSCRIBE_PENDING_BOXES_USER_DELETED = 'MUTATE_SUBSCRIBE_PENDING_BOXES_USER_DELETED';
export const MUTATE_BOX_MEMBERS = 'MUTATE_BOX_MEMBERS';
export const MUTATE_SEARCH_BOX = 'MUTATE_SEARCH_BOX';
export const MUTATE_SELECTED_BOX = 'MUTATE_SELECTED_BOX';
export const MUTATE_ADDED_AS_COLLABORATOR = 'MUTATE_ADDED_AS_COLLABORATOR';
export const MUTATE_INVITED_AS_COLLABORATOR = 'MUTATE_INVITED_AS_COLLABORATOR';
export const MUTATE_INVITATION_ACCEPTED = 'MUTATE_INVITATION_ACCEPTED';
export const MUTATE_INVITATION_REJECTED = 'MUTATE_INVITATION_REJECTED';
export const MUTATE_INVITATION_ACCEPTED_FOR_COLLABORATORS = 'MUTATE_INVITATION_ACCEPTED_FOR_COLLABORATORS';
export const MUTATE_REMOVED_AS_COLLABORATOR = 'MUTATE_REMOVED_AS_COLLABORATOR';
export const MUTATE_REMOVED_AS_PENDING_COLLABORATOR = 'MUTATE_REMOVED_AS_PENDING_COLLABORATOR';
export const MUTATE_MUTE_BOX = 'MUTATE_MUTE_BOX';
export const MUTATE_COLOR_BOX = 'MUTATE_COLOR_BOX';
export const MUTATE_FETCH_BOX_SETTINGS_BY_ID = 'MUTATE_FETCH_BOX_SETTINGS_BY_ID';
export const MUTATE_NEW_BOX_GROUP = 'MUTATE_NEW_BOX_GROUP';
export const MUTATE_UPDATE_BOX_GROUP = 'MUTATE_UPDATE_BOX_GROUP';
export const MUTATE_BOX_API_FLAG = 'MUTATE_BOX_API_FLAG';
export const MUTATE_ARCHIVE_UNARCHIVE_BOX = 'MUTATE_ARCHIVE_UNARCHIVE_BOX';

// USER SPECIFIC BOX SETTINGS
export const MUTATE_USER_SPECIFIC_BOX_SETTINGS = 'MUTATE_USER_SPECIFIC_BOX_SETTINGS';

// BOX SETTINGS
export const MUTATE_FETCH_COLLABORATORS_FOR_BOX = 'MUTATE_FETCH_COLLABORATORS_FOR_BOX';
export const MUTATE_ADD_COLLABORATORS_FROM_SUBSCRIPTION = 'MUTATE_ADD_COLLABORATORS_FROM_SUBSCRIPTION';
export const MUTATE_REMOVE_COLLABORATOR_FROM_SUBSCRIPTION = 'MUTATE_REMOVE_COLLABORATOR_FROM_SUBSCRIPTION';
export const MUTATE_SUBSCRIBE_USER_BOX_ROLE_CHANGED = 'MUTATE_SUBSCRIBE_USER_BOX_ROLE_CHANGED';
export const MUTATE_SUBSCRIBE_MEMBERS_ROLES_CHANGED = 'MUTATE_SUBSCRIBE_MEMBERS_ROLES_CHANGED';
export const MUTATE_USERS_INVITED_BY_EMAIL = 'MUTATE_USERS_INVITED_BY_EMAIL';
export const MUTATE_USER_SIGNED_UP = 'MUTATE_USER_SIGNED_UP';
export const MUTATE_SUBSCRIBE_BOX_MEMBERS_USER_DELETED = 'MUTATE_SUBSCRIBE_BOX_MEMBERS_USER_DELETED';

// WORKFLOW
export const MUTATE_FETCH_WORKFLOW = 'MUTATE_FETCH_WORKFLOW';
export const MUTATE_FETCH_WORKFLOW_NAME_IDS_CARD_IDS = 'MUTATE_FETCH_WORKFLOW_NAME_IDS_CARD_IDS';
export const MUTATE_ADD_WORKFLOW = 'MUTATE_ADD_WORKFLOW';
export const MUTATE_UPDATE_WORKFLOW = 'MUTATE_UPDATE_WORKFLOW';
export const MUTATE_DELETE_WORKFLOW = 'MUTATE_DELETE_WORKFLOW';
export const MUTATE_MOVE_CARD_IN_WORKFLOW = 'MUTATE_MOVE_CARD_IN_WORKFLOW';
export const MUTATE_SUBSCRIBE_WORKFLOW = 'MUTATE_SUBSCRIBE_WORKFLOW';
export const MUTATE_SUBSCRIBE_MOVE_WORKFLOW = 'MUTATE_SUBSCRIBE_MOVE_WORKFLOW';
export const MUTATE_ACTIVE_WORKFLOW = 'MUTATE_ACTIVE_WORKFLOW';
export const MUTATE_SINGLE_WORKFLOW = 'MUTATE_SINGLE_WORKFLOW';
export const MUTATE_SUBSCRIBE_SINGLE_WORKFLOW = 'MUTATE_SUBSCRIBE_SINGLE_WORKFLOW';
export const MUTATE_SUBSCRIBE_SINGLE_WORKFLOW_CARD_ADDED = 'MUTATE_SUBSCRIBE_SINGLE_WORKFLOW_CARD_ADDED';
export const MUTATE_SUBSCRIBE_SINGLE_WORKFLOW_CARD_REMOVED = 'MUTATE_SUBSCRIBE_SINGLE_WORKFLOW_CARD_REMOVED';
export const MUTATE_SUBSCRIBE_SINGLE_WORKFLOW_CARD_MOVED = 'MUTATE_SUBSCRIBE_SINGLE_WORKFLOW_CARD_MOVED';
export const MUTATE_SUBSCRIBE_SINGLE_WORKFLOW_CARD_UPDATED = 'MUTATE_SUBSCRIBE_SINGLE_WORKFLOW_CARD_UPDATED';
export const MUTATE_SUBSCRIBE_SINGLE_WORKFLOW_CARDS_UNARCHIVED = 'MUTATE_SUBSCRIBE_SINGLE_WORKFLOW_CARDS_UNARCHIVED';
export const MUTATE_CARD_MEMBER_CHANGED_IN_WORKFLOW = 'MUTATE_CARD_MEMBER_CHANGED_IN_WORKFLOW';
export const MUTATE_WORKFLOW_PROPERTY_SUM_UPDATE = 'MUTATE_WORKFLOW_PROPERTY_SUM_UPDATE';

// GROUP
export const MUTATE_SUBSCRIBE_UPDATE_GROUP_NAME_EXPANDED_COLUMN = 'MUTATE_SUBSCRIBE_UPDATE_GROUP_NAME_EXPANDED_COLUMN';
export const MUTATE_SUBSCRIBE_UPDATE_GROUP_NAME = 'MUTATE_SUBSCRIBE_UPDATE_GROUP_NAME';
export const MUTATE_SUBSCRIBE_ADD_GROUP_EXPANDED_COLUMN = 'MUTATE_SUBSCRIBE_ADD_GROUP_EXPANDED_COLUMN';
export const MUTATE_SUBSCRIBE_ADD_GROUP = 'MUTATE_SUBSCRIBE_ADD_GROUP';
export const MUTATE_SUBSCRIBE_REMOVE_GROUP_EXPANDED_COLUMN = 'MUTATE_SUBSCRIBE_REMOVE_GROUP_EXPANDED_COLUMN';
export const MUTATE_SUBSCRIBE_MOVE_GROUP_EXPANDED_COLUMN = 'MUTATE_SUBSCRIBE_MOVE_GROUP_EXPANDED_COLUMN';
export const MUTATE_SUBSCRIBE_REMOVE_GROUP = 'MUTATE_SUBSCRIBE_REMOVE_GROUP';
export const MUTATE_SUBSCRIBE_MOVE_GROUP = 'MUTATE_SUBSCRIBE_MOVE_GROUP';
export const MUTATE_SUBSCRIBE_GROUP_CARD_MOVE_EXPANDED_COLUMN = 'MUTATE_SUBSCRIBE_GROUP_CARD_MOVE_EXPANDED_COLUMN';
export const MUTATE_SUBSCRIBE_GROUP_CARD_MOVE = 'MUTATE_SUBSCRIBE_GROUP_CARD_MOVE';
export const MUTATE_ACTIVE_GROUP_IN_WORKFLOW = 'MUTATE_ACTIVE_GROUP_IN_WORKFLOW';

// CATEGORY
export const MUTATE_FETCH_CATEGORY = 'MUTATE_FETCH_CATEGORY';
export const MUTATE_FETCH_CATEGORY_NAME_IDS_CARD_IDS = 'MUTATE_FETCH_CATEGORY_NAME_IDS_CARD_IDS';
export const MUTATE_ADD_CATEGORY = 'MUTATE_ADD_CATEGORY';
export const MUTATE_UPDATE_CATEGORY = 'MUTATE_UPDATE_CATEGORY';
export const MUTATE_DELETE_CATEGORY = 'MUTATE_DELETE_CATEGORY';
export const MUTATE_MOVE_CARD_IN_CATEGORY = 'MUTATE_MOVE_CARD_IN_CATEGORY';
export const MUTATE_SUBSCRIBE_CATEGORY = 'MUTATE_SUBSCRIBE_CATEGORY';
export const MUTATE_SUBSCRIBE_MOVE_CATEGORY = 'MUTATE_SUBSCRIBE_MOVE_CATEGORY';
export const MUTATE_CATEGORY_PROPERTY_SUM_UPDATE = 'MUTATE_CATEGORY_PROPERTY_SUM_UPDATE';

// Card
export const MUTATE_CARD_DIALOG = 'MUTATE_CARD_DIALOG';
export const MUTATE_CARD_HEADER = 'MUTATE_CARD_HEADER';
export const MUTATE_UPDATE_CARD_TITLE = 'MUTATE_UPDATE_CARD_TITLE';
export const MUTATE_UPDATE_CARD_SUBTITLE = 'MUTATE_UPDATE_CARD_SUBTITLE';
export const MUTATE_ADD_CARD_TO_WORKFLOW = 'MUTATE_ADD_CARD_TO_WORKFLOW';
export const MUTATE_ADD_CARDS_TO_WORKFLOW = 'MUTATE_ADD_CARDS_TO_WORKFLOW';
export const MUTATE_UPDATE_CARD_IN_WORKFLOW = 'MUTATE_UPDATE_CARD_IN_WORKFLOW';
export const MUTATE_DELETE_CARD_FROM_WORKFLOW = 'MUTATE_CARD_FROM_WORKFLOW';
export const MUTATE_ADD_CARD_TO_CATEGORY = 'MUTATE_ADD_CARD_TO_CATEGORY';
export const MUTATE_ADD_CARDS_TO_CATEGORY = 'MUTATE_ADD_CARDS_TO_CATEGORY';
export const MUTATE_UPDATE_CARD_IN_CATEGORY = 'MUTATE_UPDATE_CARD_IN_CATEGORY';
export const MUTATE_DELETE_CARD_FROM_CATEGORY = 'MUTATE_CARD_FROM_CATEGORY';
export const MUTATE_SUBSCRIBE_MOVE_CARD = 'MUTATE_SUBSCRIBE_MOVE_CARD';
export const MUTATE_SUBSCRIBE_MOVE_CARD_IN_CATEGORY = 'MUTATE_SUBSCRIBE_MOVE_CARD_IN_CATEGORY';
export const MUTATE_UPDATE_CARD_DIALOG_WORKFLOW = 'MUTATE_UPDATE_CARD_DIALOG_WORKFLOW';
export const MUTATE_UPDATE_CARD_DIALOG_CATEGORY = 'MUTATE_UPDATE_CARD_DIALOG_CATEGORY';
export const MUTATE_FETCH_DESCRIPTION = 'MUTATE_FETCH_DESCRIPTION';
export const MUTATE_UPDATE_CARD_HEADER = 'MUTATE_UPDATE_CARD_HEADER';
export const MUTATE_CARD_HEADER_WORKFLOW = 'MUTATE_CARD_HEADER_WORKFLOW';
export const MUTATE_CARD_HEADER_CATEGORY = 'MUTATE_CARD_HEADER_CATEGORY';
export const MUTATE_SUBSCRIBE_DESCRIPTION_UPDATE = 'MUTATE_SUBSCRIBE_DESCRIPTION_UPDATE';
export const MUTATE_CARD_MEMBERS = 'MUTATE_CARD_MEMBERS';
export const MUTATE_SUBSCRIBE_CARD_MEMBERS_USER_DELETED = 'MUTATE_SUBSCRIBE_CARD_MEMBERS_USER_DELETED';
export const MUTATE_TOGGLE_DESC_DIRTY = 'MUTATE_TOGGLE_DESC_DIRTY';
export const MUTATE_TOGGLE_SHOW_TEXT_AREA = 'MUTATE_TOGGLE_SHOW_TEXT_AREA';
export const MUTATE_CARD_PANEL = 'MUTATE_CARD_PANEL';
export const MUTATE_CARD_WAS_MODIFIED = 'MUTATE_CARD_WAS_MODIFIED';
export const MUTATE_SUBSCRIBE_PIN_CARD = 'MUTATE_SUBSCRIBE_PIN_CARD';
export const MUTATE_SUBSCRIBE_UNPIN_CARD = 'MUTATE_SUBSCRIBE_UNPIN_CARD';
export const MUTATE_TOGGLE_TITLE_DIRTY = 'MUTATE_TOGGLE_TITLE_DIRTY';
export const MUTATE_TOGGLE_SUBTITLE_DIRTY = 'MUTATE_TOGGLE_SUBTITLE_DIRTY';

// CARD COMMENTS
export const MUTATE_CARD_COMMENTS = 'MUTATE_CARD_COMMENTS';
export const MUTATE_SUBSCRIBE_ADDED_CARD_COMMENT = 'MUTATE_SUBSCRIBE_ADDED_CARD_COMMENT';
export const MUTATE_SUBSCRIBE_REMOVED_CARD_COMMENT = 'MUTATE_SUBSCRIBE_REMOVED_CARD_COMMENT';
export const MUTATE_SUBSCRIBE_EDITED_CARD_COMMENT = 'MUTATE_SUBSCRIBE_EDITED_CARD_COMMENT';
export const MUTATE_SUBSCRIBE_COMMENT_DELETED_USER = 'MUTATE_SUBSCRIBE_COMMENT_DELETED_USER';
export const MUTATE_CLEAR_COMMENTS = 'MUTATE_CLEAR_COMMENTS';

// CARD MULTISELECT
export const MUTATE_ADD_CARD_TO_SELECTION = 'MUTATE_ADD_CARD_TO_SELECTION';
export const MUTATE_ADD_CARDS_TO_SELECTION = 'MUTATE_ADD_CARDS_TO_SELECTION';
export const MUTATE_TOGGLE_MULTISELECT = 'MUTATE_TOGGLE_MULTISELECT';
export const MUTATE_CLEAR_MULTISELECT = 'MUTATE_CLEAR_MULTISELECT';
export const MUTATE_REMOVE_CARD_FROM_SELECTION = 'MUTATE_REMOVE_CARD_FROM_SELECTION';
export const MUTATE_REMOVE_CARDS_FROM_SELECTION = 'MUTATE_REMOVE_CARDS_FROM_SELECTION';
export const MUTATE_TOGGLE_CARD_MULTISELECTED = 'MUTATE_TOGGLE_CARD_MULTISELECTED';

// DATE
export const MUTATE_CARD_DATE = 'MUTATE_CARD_DATE';
export const MUTATE_TOGGLE_DATE_NAME_DIRTY = 'MUTATE_TOGGLE_DATE_NAME_DIRTY';

// CALENDAR
export const MUTATE_FETCH_CARDS_CALENDAR_VIEW = 'MUTATE_FETCH_CARDS_CALENDAR_VIEW';
export const MUTATE_CALENDAR_START_DATE = 'MUTATE_CALENDAR_START_DATE';
export const MUTATE_CALENDAR_TYPE = 'MUTATE_CALENDAR_TYPE';
export const MUTATE_ADD_CARD_TO_CALENDAR = 'MUTATE_ADD_CARD_TO_CALENDAR';
export const MUTATE_ADD_CARDS_TO_CALENDAR = 'MUTATE_ADD_CARDS_TO_CALENDAR';
export const MUTATE_UPDATE_CARD_IN_CALENDAR = 'MUTATE_UPDATE_CARD_IN_CALENDAR';
export const MUTATE_DELETE_CARD_FROM_CALENDAR = 'MUTATE_DELETE_CARD_FROM_CALENDAR';
export const MUTATE_WEEKDAY_TYPE = 'MUTATE_WEEKDAY_TYPE';

// ATTACHMENT
export const MUTATE_ATTACHMENTS = 'MUTATE_ATTACHMENTS';
export const MUTATE_ATTACHMENTS_DELETED_USER = 'MUTATE_ATTACHMENTS_DELETED_USER';
export const MUTATE_TOGGLE_ATTACH_DIRTY = 'MUTATE_TOGGLE_ATTACH_DIRTY';
export const MUTATE_TRIGGER_ATTACH_UPLOAD = 'MUTATE_TRIGGER_ATTACH_UPLOAD';

// LABEL
export const MUTATE_ADD_LABEL = 'MUTATE_ADD_LABEL';
export const MUTATE_UPDATE_LABEL = 'MUTATE_UPDATE_LABEL';
export const MUTATE_DELETE_LABEL = 'MUTATE_DELETE_LABEL';
export const MUTATE_FETCH_CARD_LABELS = 'MUTATE_FETCH_CARD_LABELS';
export const MUTATE_FETCH_BOX_LABELS = 'MUTATE_FETCH_BOX_LABELS';
export const MUTATE_DELETE_LABEL_FROM_BOX = 'MUTATE_DELETE_LABEL_FROM_BOX';
export const MUTATE_ADD_LABEL_TO_CARD = 'MUTATE_ADD_LABEL_TO_CARD';
export const MUTATE_SUBSCRIBE_DELETED_LABEL_FROM_BOX = 'MUTATE_SUBSCRIBE_DELETED_LABEL_FROM_BOX';
export const MUTATE_SUBSCRIBE_UPDATED_LABEL = 'MUTATE_SUBSCRIBE_UPDATED_LABEL';
export const MUTATE_SUBSCRIBE_CARD_LABEL_DELETED = 'MUTATE_SUBSCRIBE_CARD_LABEL_DELETED';
export const MUTATE_SUBSCRIBE_CARD_LABEL_ADDED = 'MUTATE_SUBSCRIBE_CARD_LABEL_ADDED';
export const MUTATE_SUBSCRIBE_ADDED_LABEL = 'MUTATE_SUBSCRIBE_ADDED_LABEL';
export const MUTATE_LABEL_LOADING = 'MUTATE_LABEL_LOADING';
export const MUTATE_LABEL_EXPANSION_IN_CARD_VIEW = 'MUTATE_LABEL_EXPANSION_IN_CARD_VIEW';
export const MUTATE_DELETE_BOX_GROUP = 'MUTATE_DELETE_BOX_GROUP';
export const MUTATE_LABEL_PROPERTY_SUM_UPDATE = 'MUTATE_LABEL_PROPERTY_SUM_UPDATE';

// LABEL VIEW
export const MUTATE_FETCH_CARDS_GROUP_BY_LABELS = 'MUTATE_FETCH_CARDS_GROUP_BY_LABELS';
export const MUTATE_MOVE_CARD_IN_LABEL = 'MUTATE_MOVE_CARD_IN_LABEL';
export const MUTATE_SUBSCRIBE_LABEL_ADDED_TO_LABELVIEW = 'MUTATE_SUBSCRIBE_LABEL_ADDED_TO_LABELVIEW';
export const MUTATE_SUBSCRIBE_LABEL_UPDATED_IN_LABELVIEW = 'MUTATE_SUBSCRIBE_LABEL_UPDATED_IN_LABELVIEW';
export const MUTATE_SUBSCRIBE_LABEL_DELETED_FROM_LABELVIEW = 'MUTATE_SUBSCRIBE_LABEL_DELETED_FROM_LABELVIEW';
export const MUTATE_SUBSCRIBE_ADD_CARD_IN_LABELVIEW = 'MUTATE_SUBSCRIBE_ADD_CARD_IN_LABELVIEW';
export const MUTATE_SUBSCRIBE_ADD_CARDS_IN_LABELVIEW = 'MUTATE_SUBSCRIBE_ADD_CARDS_IN_LABELVIEW';
export const MUTATE_SUBSCRIBE_UPDATE_CARD_IN_LABELVIEW = 'MUTATE_SUBSCRIBE_UPDATE_CARD_IN_LABELVIEW';
export const MUTATE_SUBSCRIBE_REMOVED_CARD_FROM_LABELVIEW = 'MUTATE_SUBSCRIBE_REMOVED_CARD_FROM_LABELVIEW';
export const MUTATE_SUBSCRIBE_MOVE_CARD_BETWEEN_LABELS = 'MUTATE_SUBSCRIBE_MOVE_CARD_BETWEEN_LABELS';

// SETTING
export const MUTATE_UPDATE_SETTING = 'MUTATE_UPDATE_SETTING';

// ACTIVITY
export const MUTATE_SUBSCRIBE_ADDED_ACTIVITY = 'MUTATE_SUBSCRIBE_ADDED_ACTIVITY';
export const MUTATE_SUBSCRIBE_ACTIVITY_DELETED_USER = 'MUTATE_SUBSCRIBE_ACTIVITY_DELETED_USER';
export const MUTATE_FETCH_ACTIVITIES_OF_BOX = 'MUTATE_FETCH_ACTIVITIES_OF_BOX';
export const MUTATE_FETCH_ACTIVITIES_OF_CARD = 'MUTATE_FETCH_ACTIVITIES_OF_CARD';
export const MUTATE_CLEAN_ACTIVITY = 'MUTATE_CLEAN_ACTIVITY';
export const MUTATE_CLEAR_CARD_ACTIVITIES = 'MUTATE_CLEAR_CARD_ACTIVITIES';
export const MUTATE_CLEAR_BOX_ACTIVITIES = 'MUTATE_CLEAR_BOX_ACTIVITIES';

// USER
export const MUTATE_LOGIN = 'MUTATE_LOGIN';
export const MUTATE_LOGOUT = 'MUTATE_LOGOUT';
export const MUTATE_EDIT_USER = 'MUTATE_EDIT_USER';
export const MUTATE_FETCH_ALL_USERS = 'MUTATE_FETCH_ALL_USERS';
export const MUTATE_USERS_FOR_ADMIN = 'MUTATE_USERS_FOR_ADMIN';
export const MUTATE_SUBSCRIBE_USER_ADDED = 'MUTATE_SUBSCRIBE_USER_ADDED';
export const MUTATE_SUBSCRIBE_USER_DELETED = 'MUTATE_SUBSCRIBE_USER_DELETED';
export const MUTATE_SAVE_AVATAR_IMAGE = 'MUTATE_SAVE_AVATAR_IMAGE';
export const MUTATE_IS_AUTHENTICATED = 'MUTATE_IS_AUTHENTICATED';
export const MUTATE_AVATAR_EXPIRY_CHECK = 'MUTATE_AVATAR_EXPIRY_CHECK';
export const MUTATE_START_TWO_FACTOR = 'MUTATE_START_TWO_FACTOR';
export const MUTATE_RESET_TWO_FACTOR = 'MUTATE_RESET_TWO_FACTOR';

// USER_TEMP
export const MUTATE_FETCH_USER_TEMP = 'MUTATE_FETCH_USER_TEMP';
export const MUTATE_DELETE_USER_TEMP = 'MUTATE_DELETE_USER_TEMP';
export const MUTATE_ADD_NEW_USER = 'MUTATE_ADD_NEW_USER';
export const MUTATE_DELETE_USER_TEMP_FROM_PUSHBOX = 'MUTATE_DELETE_USER_TEMP_FROM_PUSHBOX';
export const MUTATE_UPDATE_USER_TEMP = 'MUTATE_UPDATE_USER_TEMP';
export const MUTATE_INVITATION_LOADING = 'MUTATE_INVITATION_LOADING';
export const MUTATE_DELETE_USER_TEMP_FROM_BOX = 'MUTATE_DELETE_USER_TEMP_FROM_BOX';
export const MUTATE_USERNAME = 'MUTATE_USERNAME';
export const MUTATE_TOKEN_VERIFICATION = 'MUTATE_TOKEN_VERIFICATION';

// AVATAR
export const MUTATE_AVATAR = 'MUTATE_AVATAR';
export const MUTATE_AVATAR_DELETE = 'MUTATE_AVATAR_DELETE';
export const MUTATE_SUBSCRIBE_AVATAR_ADDED = 'MUTATE_SUBSCRIBE_AVATAR_ADDED';
export const MUTATE_SUBSCRIBE_AVATAR_DELETED = 'MUTATE_SUBSCRIBE_AVATAR_DELETED';
export const MUTATE_CLEAN_AVATARS = 'MUTATE_CLEAN_AVATARS';

// Filter
export const MUTATE_FILTER_DATE = 'MUTATE_FILTER_DATE';
export const MUTATE_FILTER_NAME = 'MUTATE_FILTER_NAME';
export const MUTATE_FILTER_LABELS = 'MUTATE_FILTER_LABELS';
export const MUTATE_FILTER_CHECKLIST = 'MUTATE_FILTER_CHECKLIST';
export const MUTATE_FILTER_WORKFLOW = 'MUTATE_FILTER_WORKFLOW';
export const MUTATE_FILTER_CARD_MEMBERS = 'MUTATE_FILTER_CARD_MEMBERS';
export const MUTATE_SUBSCRIBE_FILTER_CARD_MEMBERS_USER_DELETE = 'MUTATE_SUBSCRIBE_FILTER_CARD_MEMBERS_USER_DELETE';
export const MUTATE_FILTER_IS_ACTIVE = 'MUTATE_FILTER_IS_ACTIVE';
export const MUTATE_FILTER_ARCHIV = 'MUTATE_FILTER_ARCHIV';

// Sidebar
export const MUTATE_SIDEBAR_ACTIVE = 'MUTATE_SIDEBAR_ACTIVE';

// BREADCRUMBS
export const MUTATE_NEW_BREADCRUMB = 'MUTATE_NEW_BREADCRUMB';

// PROPERTY
export const MUTATE_FETCH_PROPERTIES = 'MUTATE_FETCH_PROPERTIES';
export const MUTATE_SUBSCRIBE_PROPERTY_ADDED = 'MUTATE_SUBSCRIBE_PROPERTY_ADDED';
export const MUTATE_SUBSCRIBE_PROPERTY_DELETED = 'MUTATE_SUBSCRIBE_PROPERTY_DELETED';
export const MUTATE_SUBSCRIBE_PROPERTY_UPDATED = 'MUTATE_SUBSCRIBE_PROPERTY_UPDATED';
export const MUTATE_TOGGLE_PROPERTY_DIRTY = 'MUTATE_TOGGLE_PROPERTY_DIRTY';

// CHECKLIST
export const MUTATE_FETCH_CHECKLISTS = 'MUTATE_FETCH_CHECKLISTS';
export const MUTATE_CHECKLIST_ITEM_POSITION = 'MUTATE_CHECKLIST_ITEM_POSITION';
export const MUTATE_SUBSCRIBE_CHECKLIST_ADDED = 'MUTATE_SUBSCRIBE_CHECKLIST_ADDED';
export const MUTATE_SUBSCRIBE_CHECKLIST_DELETED = 'MUTATE_SUBSCRIBE_CHECKLIST_DELETED';
export const MUTATE_SUBSCRIBE_CHECKLIST_UPDATED = 'MUTATE_SUBSCRIBE_CHECKLIST_UPDATED';
export const MUTATE_SUBSCRIBE_CHECKLIST_MOVED = 'MUTATE_SUBSCRIBE_CHECKLIST_MOVED';
export const MUTATE_TOGGLE_CHECKLIST_DIRTY = 'MUTATE_TOGGLE_CHECKLIST_DIRTY';
export const MUTATE_ADD_DIRTY_CHECKLISTS = 'MUTATE_ADD_DIRTY_CHECKLISTS';
export const MUTATE_REMOVE_DIRTY_CHECKLISTS = 'MUTATE_REMOVE_DIRTY_CHECKLISTS';

// CUSTOM DESCRIPTION
export const MUTATE_FETCH_CUSTOM_DESCRIPTIONS = 'MUTATE_FETCH_CUSTOM_DESCRIPTIONS';
export const MUTATE_SUBSCRIBE_CUSTOM_DESCRIPTION_ADDED = 'MUTATE_SUBSCRIBE_CUSTOM_DESCRIPTION_ADDED';
export const MUTATE_SUBSCRIBE_CUSTOM_DESCRIPTION_UPDATED = 'MUTATE_SUBSCRIBE_CUSTOM_DESCRIPTION_UPDATED';
export const MUTATE_SUBSCRIBE_CUSTOM_DESCRIPTION_TEXT_UPDATED = 'MUTATE_SUBSCRIBE_CUSTOM_DESCRIPTION_TEXT_UPDATED';
export const MUTATE_SUBSCRIBE_CUSTOM_DESCRIPTION_DELETED = 'MUTATE_SUBSCRIBE_CUSTOM_DESCRIPTION_DELETED';
export const MUTATE_TOGGLE_CUSTOM_DESCRIPTION_SHOW_TEXT_AREA = 'MUTATE_TOGGLE_CUSTOM_DESCRIPTION_SHOW_TEXT_AREA';
export const MUTATE_TOGGLE_CUSTOM_DESCRIPTION_DIRTY = 'MUTATE_TOGGLE_CUSTOM_DESCRIPTION_DIRTY';
export const MUTATE_CLEAR_CUSTOM_DESCRIPTIONS_DIRTY = 'MUTATE_CLEAR_CUSTOM_DESCRIPTIONS_DIRTY';
export const MUTATE_CLEAR_CUSTOM_DESCRIPTIONS_TEXT_AREA = 'MUTATE_CLEAR_CUSTOM_DESCRIPTIONS_TEXT_AREA';

// TEMPLATE
export const MUTATE_FETCH_TEMPLATES_FOR_BOX = 'MUTATE_FETCH_TEMPLATES_FOR_BOX';
export const MUTATE_SUBSCRIBE_TEMPLATE_UPDATED = 'MUTATE_SUBSCRIBE_TEMPLATE_UPDATED';
export const MUTATE_SUBSCRIBE_TEMPLATE_ADDED = 'MUTATE_SUBSCRIBE_TEMPLATE_ADDED';
export const MUTATE_SUBSCRIBE_TEMPLATE_REMOVED = 'MUTATE_SUBSCRIBE_TEMPLATE_REMOVED';
export const MUTATE_ACTIVE_TEMPLATE = 'MUTATE_ACTIVE_TEMPLATE';

// PAGE_LOADING
export const MUTATE_PAGE_LOADING = 'MUTATE_PAGE_LOADING';

// BOX_ROLE
export const MUTATE_FETCH_USER_BOX_ROLE = 'MUTATE_FETCH_USER_BOX_ROLE';
export const MUTATE_FETCH_ALL_ROLES_IN_BOX = 'MUTATE_FETCH_ALL_ROLES_IN_BOX';
export const MUTATE_SUBSCRIBE_BOX_ROLES_CHANGED = 'MUTATE_SUBSCRIBE_BOX_ROLES_CHANGED';

// SNACKBAR
export const MUTATE_ERROR = 'MUTATE_ERROR';
export const MUTATE_MESSAGE = 'MUTATE_MESSAGE';

// ADMIN_PANEL
export const MUTATE_FETCH_LDAP_CONFIG = 'MUTATE_FETCH_LDAP_CONFIG';
export const MUTATE_FETCH_GENERAL_CONFIG = 'MUTATE_FETCH_GENERAL_CONFIG';
export const MUTATE_SUBSCRIBE_LDAP_CONFIG_CHANGED = 'MUTATE_SUBSCRIBE_LDAP_CONFIG_CHANGED';
export const MUTATE_SUBSCRIBE_GENERAL_CONFIG_CHANGED = 'MUTATE_SUBSCRIBE_GENERAL_CONFIG_CHANGED';
export const MUTATE_ADMIN_AUTHENTICATION_STATUS = 'MUTATE_ADMIN_AUTHENTICATION_STATUS';
export const MUTATE_USER_STATISTICS = 'MUTATE_USER_STATISTICS';

// PASSWORD_RECOVERY
export const MUTATE_FETCH_TOKEN_VALID = 'MUTATE_FETCH_TOKEN_VALID';

// ALL_CARDS
export const MUTATE_FETCH_ALL_CARDS = 'MUTATE_FETCH_ALL_CARDS';
export const MUTATE_SUBSCRIBE_ARCHIVE_UNARCHIVE_CARDS = 'MUTATE_SUBSCRIBE_ARCHIVE_UNARCHIVE_CARDS';
export const MUTATE_ALLCARDS_CARD_ADDED = 'MUTATE_ALLCARDS_CARD_ADDED';
export const MUTATE_ALLCARDS_CARD_UPDATED = 'MUTATE_ALLCARDS_CARD_UPDATED';
export const MUTATE_ALLCARDS_CARD_REMOVED = 'MUTATE_ALLCARDS_CARD_REMOVED';
export const MUTATE_CLEAR_ALL_CARDS = 'MUTATE_CLEAR_ALL_CARDS';

/*
   ========================================= Actions =========================================
*/
// BOX
export const ADD_BOX = 'ADD_BOX';
export const DELETE_BOX = 'DELETE_BOX';
export const UPDATE_BOX = 'UPDATE_BOX';
export const ACCEPT_BOX_INVITATION = 'ACCEPT_BOX_INVITATION';
export const DECLINE_BOX_INVITATION = 'DECLINE_BOX_INVITATION';
export const FETCH_BOXES = 'FETCH_BOXES';
export const FETCH_PENDING_BOXES = 'FETCH_PENDING_BOXES';
export const FETCH_BOX_MEMBERS = 'FETCH_BOX_MEMBERS';
export const SEARCH_BOX = 'SEARCH_BOX';
export const SET_SELECTED_BOX = 'SET_SELECTED_BOX';
export const SUBSCRIBE_ADDED_BOX = 'SUBSCRIBE_ADDED_BOX';
export const SUBSCRIBE_UPDATED_BOX = 'SUBSCRIBE_UPDATED_BOX';
export const SUBSCRIBE_DELETED_BOX = 'SUBSCRIBE_DELETED_BOX';
export const SUBSCRIBE_ADDED_AS_COLLABORATOR = 'MUTATE_ADDED_AS_COLLABORATOR';
export const SUBSCRIBE_REMOVED_AS_COLLABORATOR = 'MUTATE_REMOVED_AS_COLLABORATOR';
export const CHANGE_USER_BOX_ROLE = 'CHANGE_USER_BOX_ROLE';
export const COPY_BOX = 'COPY_BOX';
export const MUTE_BOX = 'MUTE_BOX';
export const CHANGE_BOX_COLOR = 'CHANGE_BOX_COLOR';
export const FETCH_BOX_SETTINGS_BY_ID = 'FETCH_BOX_SETTINGS_BY_ID';
export const UPDATE_BOX_SETTINGS_BY_ID = 'UPDATE_BOX_SETTINGS_BY_ID';
export const NEW_BOX_GROUP = 'NEW_BOX_GROUP';
export const CHANGE_BOX_POSITION = 'CHANGE_BOX_POSITION';
export const MOVE_BOX_BETWEEN_GROUPS = 'MOVE_BOX_BETWEEN_GROUPS';
export const DELETE_BOX_GROUP = 'DELETE_BOX_GROUP';
export const UPDATE_BOX_GROUP = 'UPDATE_BOX_GROUP';
export const UPDATE_BOX_GROUP_POSITION = 'UPDATE_BOX_GROUP_POSITION';
export const CREATE_API = 'CREATE_API';
export const FETCH_BOX_API_FLAG = 'FETCH_BOX_API_FLAG';
export const REMOVE_API = 'REMOVE_API';
export const ARCHIVE_BOX = 'ARCHIVE_BOX';
export const UNARCHIVE_BOX = 'UNARCHIVE_BOX';
export const SUBSCRIBE_ARCHIVED_BOX = 'SUBSCRIBE_ARCHIVED_BOX';
export const SUBSCRIBE_UNARCHIVED_BOX = 'SUBSCRIBE_UNARCHIVED_BOX';
export const FETCH_SELECTED_BOX = 'FETCH_SELECTED_BOX';

// USER SPECIFIC BOX SETTINGS
export const FETCH_USER_SPECIFIC_BOX_SETTINGS = 'FETCH_USER_SPECIFIC_BOX_SETTINGS';
export const SAVE_SELECTED_TEMPLATE = 'SAVE_SELECTED_TEMPLATE';

// BOX SETTINGS
export const FETCH_COLLABORATORS_FOR_BOX = 'FETCH_COLLABORATORS_FOR_BOX';
export const ADD_COLLABORATORS_TO_BOX = 'ADD_COLLABORATORS_TO_BOX';
export const REMOVE_COLLABORATOR_FROM_BOX = 'REMOVE_COLLABORATOR_FROM_BOX';
export const ADD_COLLABORATORS_FROM_SUBSCRIPTION = 'ADD_COLLABORATORS_FROM_SUBSCRIPTION';
export const REMOVE_COLLABORATOR_FROM_SUBSCRIPTION = 'REMOVE_COLLABORATOR_FROM_SUBSCRIPTION';
export const SUBSCRIBE_USER_BOX_ROLE_CHANGED = 'SUBSCRIBE_USER_BOX_ROLE_CHANGED';
export const SUBSCRIBE_ROLES_CHANGED = 'SUBSCRIBE_ROLES_CHANGED';
export const SEND_INVITATIONS_BY_EMAIL = 'SEND_INVITATIONS_BY_EMAIL';
export const RESEND_INVITATIONS_BY_EMAIL_FROM_BOX = 'RESEND_INVITATIONS_BY_EMAIL_FROM_BOX';

// WORKFLOW
export const FETCH_WORKFLOWS_WITH_ID_NAME_CARD_IDS = 'FETCH_WORKFLOWS_WITH_ID_NAME_CARD_IDS';
export const FETCH_WORKFLOW = 'FETCH_WORKFLOW';
export const ADD_WORKFLOW = 'ADD_WORKFLOW';
export const UPDATE_WORKFLOW = 'UPDATE_WORKFLOW';
export const MOVE_CARD_IN_WORKFLOW = 'MOVE_CARD_IN_WORKFLOW';
export const SUBSCRIBE_WORKFLOW_ADD = 'SUBSCRIBE_WORKFLOW_ADD';
export const DELETE_WORKFLOW = 'DELETE_WORKFLOW';
export const SUBSCRIBE_WORKFLOW_UPDATE = 'SUBSCRIBE_WORKFLOW_UPDATE';
export const SUBSCRIBE_WORKFLOW_DELETE = 'SUBSCRIBE_WORKFLOW_DELETE';
export const ADD_CARD_TO_WORKFLOW = 'ADD_CARD_TO_WORKFLOW';
export const UPDATE_CARD_IN_WORKFLOW = 'UPDATE_CARD_IN_WORKFLOW';
export const DELETE_CARD_FROM_WORKFLOW = 'DELETE_CARD_FROM_WORKFLOW';
export const SUBSCRIBE_MOVE_CARD = 'SUBSCRIBE_MOVE_CARD';
export const MOVE_WORKFLOW_IN_BOX = 'MOVE_WORKFLOW_IN_BOX';
export const SUBSCRIBE_MOVE_WORKFLOW = 'SUBSCRIBE_MOVE_WORKFLOW';
export const SET_ACTIVE_WORKFLOW = 'SET_ACTIVE_WORKFLOW ';
export const FETCH_WORKFLOWS_NAMES_IDS = 'FETCH_WORKFLOWS_NAMES_IDS';
export const FETCH_SINGLE_WORKFLOW = 'FETCH_SINGLE_WORKFLOW';
export const ARCHIVE_CARD_FROM_WORKFLOW = 'ARCHIVE_CARD_FROM_WORKFLOW';
export const UNARCHIVE_CARD_IN_WORKFLOW = 'UNARCHIVE_CARD_IN_WORKFLOW';
export const SUBSCRIBE_CARD_MEMBER_CHANGED_IN_WORKFLOW = 'SUBSCRIBE_CARD_MEMBER_CHANGED_IN_WORKFLOW';
export const SUBSCRIBE_WORKFLOW_PROPERTY_SUM_UPDATE = 'SUBSCRIBE_WORKFLOW_PROPERTY_SUM_UPDATE';

// GROUP
export const UPDATE_GROUP_NAME = 'UPDATE_GROUP_NAME';
export const REMOVE_GROUP_FROM_WORKFLOW = 'REMOVE_GROUP_FROM_WORKFLOW';
export const MOVE_CARD_IN_GROUP = 'MOVE_CARD_IN_GROUP';
export const ADD_GROUP_IN_WORKFLOW = 'ADD_GROUP_IN_WORKFLOW';
export const UPDATE_GROUP_POSITION = 'UPDATE_GROUP_POSITION';
export const SET_ACTIVE_GROUP_IN_WORKFLOW = 'SET_ACTIVE_GROUP_IN_WORKFLOW ';

// CATEGORY
export const FETCH_CATEGORY_WITH_ID_NAME_CARD_IDS = 'FETCH_CATEGORY_WITH_ID_NAME_CARD_IDS';
export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const MOVE_CARD_IN_CATEGORY = 'MOVE_CARD_IN_CATEGORY';
export const SUBSCRIBE_CATEGORY_ADD = 'SUBSCRIBE_CATEGORY_ADD';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const SUBSCRIBE_CATEGORY_UPDATE = 'SUBSCRIBE_CATEGORY_UPDATE';
export const SUBSCRIBE_CATEGORY_DELETE = 'SUBSCRIBE_CATEGORY_DELETE';
export const ADD_CARD_TO_CATEGORY = 'ADD_CARD_TO_CATEGORY';
export const UPDATE_CARD_IN_CATEGORY = 'UPDATE_CARD_IN_CATEGORY';
export const DELETE_CARD_FROM_CATEGORY = 'DELETE_CARD_FROM_CATEGORY';
export const SUBSCRIBE_MOVE_CARD_IN_CATEGORY = 'SUBSCRIBE_MOVE_CARD_IN_CATEGORY';
export const MOVE_CATEGORY_IN_BOX = 'MOVE_CATEGORY_IN_BOX';
export const SUBSCRIBE_MOVE_CATEGORY = 'SUBSCRIBE_MOVE_CATEGORY';
export const ARCHIVE_CARD_FROM_CATEGORY = 'ARCHIVE_CARD_FROM_CATEGORY';
export const UNARCHIVE_CARD_IN_CATEGORY = 'UNARCHIVE_CARD_IN_CATEGORY';
export const SUBSCRIBE_CATEGORY_PROPERTY_SUM_UPDATE = 'SUBSCRIBE_CATEGORY_PROPERTY_SUM_UPDATE';

// CARD
export const ADD_CARD = 'ADD_CARD';
export const CARD_DIALOG = 'CARD_DIALOG';
export const FETCH_CARD_HEADER = 'FETCH_CARD_HEADER';
export const FETCH_CARD_MEMBERS = 'FETCH_CARD_MEMBERS';
export const REMOVE_CARD_MEMBER = 'REMOVE_CARD_MEMBER';
export const ADD_CARD_MEMBERS = 'ADD_CARD_MEMBERS';
export const UPDATE_CARD_TITLE = 'UPDATE_CARD_TITLE';
export const UPDATE_CARD_SUBTITLE = 'UPDATE_CARD_SUBTITLE';
export const DELETE_CARD = 'DELETE_CARD';
export const UPDATE_CARD_DIALOG_WORKFLOW = 'UPDATE_CARD_DIALOG_WORKFLOW';
export const UPDATE_CARD_DIALOG_CATEGORY = 'UPDATE_CARD_DIALOG_CATEGORY';
export const SUBSCRIBE_CARD_HEADER_UPDATE = 'SUBSCRIBE_CARD_HEADER_UPDATE';
export const SUBSCRIBE_CARD_MEMBERS_CHANGED = 'SUBSCRIBE_CARD_MEMBERS_CHANGED';
export const UPDATE_CARD_PANEL = 'UPDATE_CARD_PANEL';
export const NOTIFY_CARD_WATCHERS_ON_CHANGE = 'NOTIFY_CARD_WATCHERS_ON_CHANGE';
export const BULK_DELETE_CARDS = 'BULK_DELETE_CARDS';

// CARD COMMENTS
export const ADD_CARD_COMMENT = 'ADD_CARD_COMMENT';
export const REMOVE_CARD_COMMENT = 'REMOVE_CARD_COMMENT';
export const EDIT_CARD_COMMENT = 'EDIT_CARD_COMMENT';

// CARD MULTISELECT
export const MULTISELECT_ADD_WORKFLOW_COLUMN = 'MULTISELECT_ADD_WORKFLOW_COLUMN';
export const MULTISELECT_ADD_WORKFLOW_EXPANDED = 'MULTISELECT_ADD_WORKFLOW_EXPANDED';
export const MULTISELECT_ADD_CATEGORY_COLUMN = 'MULTISELECT_ADD_CATEGORY_COLUMN';
export const MULTISELECT_ADD_LABEL_COLUMN = 'MULTISELECT_ADD_LABEL_COLUMN';
export const MULTISELECT_REMOVE_WORKFLOW_COLUMN = 'MULTISELECT_REMOVE_WORKFLOW_COLUMN';
export const MULTISELECT_REMOVE_WORKFLOW_EXPANDED = 'MULTISELECT_REMOVE_WORKFLOW_EXPANDED';
export const MULTISELECT_REMOVE_CATEGORY_COLUMN = 'MULTISELECT_REMOVE_CATEGORY_COLUMN';
export const MULTISELECT_REMOVE_LABEL_COLUMN = 'MULTISELECT_REMOVE_LABEL_COLUMN';
export const ADD_CARD_TO_MULTISELECT = 'ADD_CARD_TO_MULTISELECT';
export const REMOVE_CARD_FROM_MULTISELECT = 'REMOVE_CARD_FROM_MULTISELECT';
export const TOGGLE_MULTISELECT = 'TOGGLE_MULTISELECT';
export const TOGGLE_CARD_MULTISELECTED = 'TOGGLE_CARD_MULTISELECTED';

// DATE
export const FETCH_CARD_DATE = 'FETCH_CARD_DATE';
export const CHANGE_CARD_DATE = 'CHANGE_CARD_DATE';
export const SUBSCRIBE_DATE_CHANGED = 'SUBSCRIBE_DATE_CHANGED';
export const DELETE_CARD_DATE = 'DELETE_CARD_DATE';

// CALENDAR
export const FETCH_CARDS_CALENDAR_VIEW = 'FETCH_CARDS_CALENDAR_VIEW';
export const CHANGE_CALENDAR_DATE = 'CHANGE_CALENDAR_DATE';
export const SUBSCRIBE_CARD_DATE_CHANGE = 'SUBSCRIBE_CARD_DATE_CHANGE';
export const SUBSCRIBE_ADD_CARD_TO_CALENDAR = 'SUBSCRIBE_ADD_CARD_TO_CALENDAR';
export const SUBSCRIBE_UPDATE_CARD_IN_CALENDAR = 'SUBSCRIBE_UPDATE_CARD_IN_CALENDAR';
export const SUBSCRIBE_DELETE_CARD_FROM_CALENDAR = 'SUBSCRIBE_DELETE_CARD_FROM_CALENDAR';
export const SUBSCRIBE_ARCHIVE_CARD_FROM_CALENDAR = 'SUBSCRIBE_ARCHIVE_CARD_FROM_CALENDAR';
export const COPY_CARD_COMPLETE = 'COPY_CARD_COMPLETE';
export const ARCHIVE_CARD_FROM_CALENDAR = 'ARCHIVE_CARD_FROM_CALENDAR';
export const UNARCHIVE_CARD_IN_CALENDAR = 'UNARCHIVE_CARD_IN_CALENDAR';

// ATTACHMENT
export const FETCH_ATTACHMENTS = 'FETCH_ATTACHMENTS';
export const UPLOAD_FILES = 'UPLOAD_FILES';
export const DELETE_FILE = 'DELETE_FILE';
export const ATTACHMENT_CHANGED_SUBSCRIPTION = 'ATTACHMENT_CHANGED_SUBSCRIPTION';
export const TOGGLE_ATTACH_DIRTY = 'TOGGLE_ATTACH_DIRTY';
export const TRIGGER_ATTACH_UPLOAD = 'TRIGGER_ATTACH_UPLOAD';

// LABEL
export const ADD_LABEL = 'ADD_LABEL';
export const UPDATE_LABEL = 'UPDATE_LABEL';
export const DELETE_LABEL = 'DELETE_LABEL';
export const FETCH_CARD_LABELS = 'FETCH_CARD_LABELS';
export const FETCH_BOX_LABELS = 'FETCH_BOX_LABELS';
export const DELETE_LABEL_FROM_CARD = 'DELETE_LABEL_FROM_CARD';
export const DELETE_LABEL_FROM_BOX = 'DELETE_LABEL_FROM_BOX';
export const ADD_LABEL_TO_CARD = 'ADD_LABEL_TO_CARD';
export const SUBSCRIBE_ADDED_LABEL = 'SUBSCRIBE_ADDED_LABEL';
export const SUBSCRIBE_DELETED_LABEL = 'SUBSCRIBE_DELETED_LABEL';
export const SUBSCRIBE_UPDATED_LABEL = 'SUBSCRIBE_UPDATED_LABEL';
export const SUBSCRIBE_CARD_LABEL_DELETED = 'SUBSCRIBE_CARD_LABEL_DELETED';
export const SUBSCRIBE_CARD_LABEL_ADDED = 'SUBSCRIBE_CARD_LABEL_ADDED';
export const LABEL_LOADING = 'LABEL_LOADING';
export const SUBSCRIBE_LABEL_PROPERTY_SUM_UPDATE = 'SUBSCRIBE_LABEL_PROPERTY_SUM_UPDATE';

// LABEL VIEW
export const FETCH_CARDS_GROUP_BY_LABELS = 'FETCH_CARDS_GROUP_BY_LABELS';
export const MOVE_CARD_IN_LABEL = 'MOVE_CARD_IN_LABEL';
export const SUBSCRIBE_LABEL_ADDED_TO_LABELVIEW = 'SUBSCRIBE_LABEL_ADDED_TO_LABELVIEW';
export const SUBSCRIBE_LABEL_UPDATED_IN_LABELVIEW = 'SUBSCRIBE_LABEL_UPDATED_IN_LABELVIEW';
export const SUBSCRIBE_LABEL_DELETED_FROM_LABELVIEW = 'SUBSCRIBE_LABEL_DELETED_FROM_LABELVIEW';
export const SUBSCRIBE_ADD_CARD_IN_LABELVIEW = 'SUBSCRIBE_ADD_CARD_IN_LABELVIEW';
export const SUBSCRIBE_UPDATE_CARD_IN_LABELVIEW = 'SUBSCRIBE_UPDATE_CARD_IN_LABELVIEW';
export const SUBSCRIBE_REMOVED_CARD_FROM_LABELVIEW = 'SUBSCRIBE_REMOVED_CARD_FROM_LABELVIEW';
export const SUBSCRIBE_MOVE_CARD_BETWEEN_LABELS = 'SUBSCRIBE_MOVE_CARD_BETWEEN_LABELS';
export const ARCHIVE_CARD_FROM_LABELVIEW = 'ARCHIVE_CARD_FROM_LABELVIEW';
export const UNARCHIVE_CARD_IN_LABELVIEW = 'UNARCHIVE_CARD_IN_LABELVIEW';

// SETTING
export const UPDATE_SETTING = 'UPDATE_SETTING';
export const UPDATE_SETTING_LOCAL = 'UPDATE_SETTING_LOCAL';
export const UPDATE_DARKTHEME = 'UPDATE_DARKTHEME';
export const UPDATE_TWO_FACTOR_AUTH = 'UPDATE_TWO_FACTOR_AUTH';

// ACTIVITY
export const FETCH_ACTIVITIES_OF_BOX = 'FETCH_ACTIVITIES_OF_BOX';
export const FETCH_MORE_ACTIVITIES_OF_BOX = 'FETCH_MORE_ACTIVITIES_OF_BOX';
export const FETCH_ACTIVITIES_OF_CARD = 'FETCH_ACTIVITIES_OF_CARD';
export const SUBSCRIBE_ADDED_ACTIVITY = 'SUBSCRIBE_ADDED_ACTIVITY';
export const CLEAN_ACTIVITY = 'CLEAN_ACTIVITY';

// USER
export const SIGNUP_TEMP = 'SIGNUP_TEMP';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_LOCAL = 'LOGOUT_LOCAL';
export const FETCH_USER = 'FETCH_USER';
export const EDIT_USER = 'EDIT_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const FETCH_BEFORE_DELETE_REPORT = 'FETCH_BEFORE_DELETE_REPORT';
export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';
export const FETCH_ALL_USERS_FOR_ADMIN = 'FETCH_ALL_USERS_FOR_ADMIN';
export const SUBSCRIBE_USER_ADDED = 'SUBSCRIBE_USER_ADDED';
export const VALIDATE_USER_TOKEN = 'VALIDATE_USER_TOKEN';
export const AVATAR_EXPIRY_CHECK = 'AVATAR_EXPIRY_CHECK';
export const SIGNUP_WITH_TOKEN = 'SIGNUP_WITH_TOKEN';
export const SUBSCRIBE_SIGNUP_WITH_TOKEN = 'SUBSCRIBE_SIGNUP_WITH_TOKEN';
export const SUBSCRIBE_USER_ACCOUNT_DELETION = 'SUBSCRIBE_USER_ACCOUNT_DELETION';
export const FETCH_USER_STATISTICS = 'FETCH_USER_STATISTICS';

// USER_TEMP
export const FETCH_USER_TEMP_BY_BOX = 'FETCH_USER_TEMP_BY_BOX';
export const DELETE_USER_TEMP_FROM_BOX = 'DELETE_USER_TEMP_FROM_BOX';
export const ADD_MISSING_USERNAME = 'ADD_MISSING_USERNAME';
export const FETCH_IS_USERNAME_IN_USE = 'FETCH_IS_USERNAME_IN_USE';
export const FETCH_USERS_TEMP = 'FETCH_USERS_TEMP';
export const INVITE_USER_TEMP_TO_PUSHBOX = 'INVITE_USER_TEMP_TO_PUSHBOX';
export const DELETE_USER_TEMP_FROM_PUSHBOX = 'DELETE_USER_TEMP_FROM_PUSHBOX';
export const RESEND_INVITATION = 'RESEND_INVITATION';
export const FETCH_USER_TEMP = 'FETCH_USER_TEMP';
export const VERIFY_TOKEN = 'VERIFY_TOKEN';
export const DELETE_USER_TEMP_BY_EMAIL = 'DELETE_USER_TEMP_BY_EMAIL';

// AVATAR
export const GET_AVATAR_BY_USER_ID = 'GET_AVATAR_BY_USER_ID';
export const UPLOAD_AVATAR = 'UPLOAD_AVATAR';
export const SUBSCRIBE_AVATAR_ADDED = 'SUBSCRIBE_AVATAR_ADDED';
export const DELETE_AVATAR = 'DELETE_AVATAR';
export const SUBSCRIBE_AVATAR_DELETED = 'SUBSCRIBE_AVATAR_DELETED';

// Filter
export const UPDATE_FILTER_DATE = 'UPDATE_FILTER_DATE';
export const UPDATE_FILTER_NAME = 'UPDATE_FILTER_NAME';
export const UPDATE_FILTER_LABELS = 'UPDATE_FILTER_LABELS';
export const UPDATE_FILTER_CHECKLIST = 'UPDATE_FILTER_CHECKLIST';
export const UPDATE_FILTER_WORKFLOW = 'UPDATE_FILTER_WORKFLOW';
export const UPDATE_FILTER_CARD_MEMBERS = 'UPDATE_FILTER_CARD_MEMBERS';
export const UPDATE_FILTER_IS_ACTIVE = 'UPDATE_FILTER_IS_ACTIVE';

// Sidebar
export const UPDATE_SIDEBAR_ACTIVE = 'UPDATE_SIDEBAR_ACTIVE';

// BREADCRUMBS
export const ADD_NEW_BREADCRUMB = 'ADD_NEW_BREADCRUMB';

// DESCRIPTION
export const FETCH_DESCRIPTION = 'FETCH_DESCRIPTION';
export const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION';
export const SUBSCRIBE_DESCRIPTION_UPDATE = 'SUBSCRIBE_DESCRIPTION_UPDATE';
export const TOGGLE_DESC_DIRTY = 'TOGGLE_DESC_DIRTY';
export const TOGGLE_SHOW_TEXT_AREA = 'TOGGLE_SHOW_TEXT_AREA';

// CUSTOM DESCRIPION
export const ADD_NEW_CUSTOM_DESCRIPTION_TO_CARD = 'ADD_NEW_CUSTOM_DESCRIPTION_TO_CARD';
export const FETCH_CUSTOM_DESCRIPTIONS_FOR_CARD = 'FETCH_CUSTOM_DESCRIPTIONS_FOR_CARD';
export const DELETE_CUSTOM_DESCRIPTION_FROM_CARD = 'DELETE_CUSTOM_DESCRIPTION_FROM_CARD';
export const UPDATE_CUSTOM_DESCRIPTION_TITLE = 'UPDATE_CUSTOM_DESCRIPTION_TITLE';
export const UPDATE_CUSTOM_DESCRIPTION_TEXT = 'UPDATE_CUSTOM_DESCRIPTION_TEXT';
export const SUBSCRIBE_CUSTOM_DESCRIPTION_ADDED = 'SUBSCRIBE_CUSTOM_DESCRIPTION_ADDED';
export const SUBSCRIBE_CUSTOM_DESCRIPTION_DELETED = 'SUBSCRIBE_CUSTOM_DESCRIPTION_DELETED';
export const SUBSCRIBE_CUSTOM_DESCRIPTION_UPDATED = 'SUBSCRIBE_CUSTOM_DESCRIPTION_UPDATED';
export const SUBSCRIBE_CUSTOM_DESCRIPTION_TEXT_UPDATED = 'SUBSCRIBE_CUSTOM_DESCRIPTION_TEXT_UPDATED';
export const CLEAR_CUSTOM_DESCRIPTIONS_DIRTY = 'CLEAR_CUSTOM_DESCRIPTIONS_DIRTY';
export const CLEAR_CUSTOM_DESCRIPTIONS_TEXT_AREA = 'CLEAR_CUSTOM_DESCRIPTIONS_TEXT_AREA';

// PROPERTY
export const ADD_PROPERTY_TO_CARD = 'ADD_PROPERTY_TO_CARD';
export const UPDATE_PROPERTY_TO_CARD = 'UPDATE_PROPERTY_TO_CARD';
export const ADD_ITEM_TO_PROPERTY = 'ADD_ITEM_TO_PROPERTY';
export const UPDATE_PROPERTY_NAME = 'UPDATE_PROPERTY_NAME';
export const UPDATE_SELECTED_ITEM = 'UPDATE_SELECTED_ITEM';
export const DELETE_PROPERTY_FROM_CARD = 'DELETE_PROPERTY_FROM_CARD';
export const DELETE_ITEM_FROM_PROPERTY = 'DELETE_ITEM_FROM_PROPERTY';
export const FETCH_PROPERTIES_FOR_CARD = 'FETCH_PROPERTIES_FOR_CARD';
export const SUBSCRIBE_PROPERTY_ADDED = 'SUBSCRIBE_PROPERTY_ADDED';
export const SUBSCRIBE_PROPERTY_DELETED = 'SUBSCRIBE_PROPERTY_DELETED';
export const SUBSCRIBE_PROPERTY_UPDATED = 'SUBSCRIBE_PROPERTY_UPDATED';

// CHECKLIST
export const ADD_CHECKLIST_TO_CARD = 'ADD_CHECKLIST_TO_CARD';
export const ADD_ITEM_TO_CHECKLIST = 'ADD_ITEM_TO_CHECKLIST';
export const UPDATE_ITEM_IN_CHECKLIST = 'UPDATE_ITEM_IN_CHECKLIST';
export const UPDATE_CHECKLIST_TITLE = 'UPDATE_CHECKLIST_TITLE';
export const DELETE_CHECKLIST_FROM_CARD = 'DELETE_CHECKLIST_FROM_CARD';
export const DELETE_ITEM_FROM_CHECKLIST = 'DELETE_ITEM_FROM_CHECKLIST';
export const FETCH_CHECKLISTS_FOR_CARD = 'FETCH_CHECKLISTS_FOR_CARD';
export const UPDATE_CHECKLIST_ITEM_POSITION = 'UPDATE_CHECKLIST_ITEM_POSITION';
export const SUBSCRIBE_CHECKLIST_ADDED = 'SUBSCRIBE_CHECKLIST_ADDED';
export const SUBSCRIBE_CHECKLIST_DELETED = 'SUBSCRIBE_CHECKLIST_DELETED';
export const SUBSCRIBE_CHECKLIST_UPDATED = 'SUBSCRIBE_CHECKLIST_UPDATED';
export const UPDATE_CHECKLISTS_POSITION = 'UPDATE_CHECKLISTS_POSITION';
export const SUBSCRIBE_CHECKLIST_MOVED = 'SUBSCRIBE_CHECKLIST_MOVED';

// TEMPLATE
export const FETCH_TEMPLATES_FOR_BOX = 'FETCH_TEMPLATES_FOR_BOX';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const SUBSCRIBE_TEMPLATE_UPDATED = 'SUBSCRIBE_TEMPLATE_UPDATED';
export const SUBSCRIBE_TEMPLATE_ADDED = 'SUBSCRIBE_TEMPLATE_ADDED';
export const SUBSCRIBE_TEMPLATE_REMOVED = 'SUBSCRIBE_TEMPLATE_REMOVED';
export const CREATE_TEMPLATE_FROM_CARD = 'CREATE_TEMPLATE_FROM_CARD';
export const SET_ACTIVE_TEMPLATE = 'SET_ACTIVE_TEMPLATE';

// PAGE_LOADING
export const PAGE_LOADING = 'PAGE_LOADING';

// BOX_ROLE
export const FETCH_USER_BOX_ROLE = 'FETCH_USER_BOX_ROLE';
export const FETCH_ALL_ROLES_IN_BOX = 'FETCH_ALL_ROLES_IN_BOX';
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';

// SNACKBAR
export const SET_ERROR = 'SET_ERROR';
export const SET_MESSAGE = 'SET_MESSAGE';

// ADMIN_PANEL
export const FETCH_ADMIN_CONFIG = 'FETCH_ADMIN_CONFIG';
export const UPDATE_ADMIN_CONFIG = 'UPDATE_ADMIN_CONFIG';
export const FETCH_GENERAL_CONFIG = 'FETCH_GENERAL_CONFIG';
export const VERIFY_ADMIN = 'VERIFY_ADMIN';
export const SUBSCRIBE_LDAP_CONFIG_CHANGED = 'SUBSCRIBE_LDAP_CONFIG_CHANGED';

// PASSWORD_RECOVERY
export const SEND_RECOVERY_EMAIL = 'SEND_RECOVERY_EMAIL';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const FETCH_VERIFY_TOKEN = 'FETCH_VERIFY_TOKEN';

// CLEAN_STATES
export const CLEAN_VIEW_STATE = 'CLEAN_VIEW_STATE';

// ALL_CARDS
export const ARCHIVE_CARDS_ACTION = 'ARCHIVE_CARDS_ACTION';
export const UNARCHIVE_CARDS_ACTION = 'UNARCHIVE_CARDS_ACTION';
export const FETCH_CARDS_BY_BOXID = 'FETCH_CARDS_BY_BOXID';
export const SUBSCRIBE_ARCHIVE_CARDS = 'SUBSCRIBE_ARCHIVE_CARDS';
export const SUBSCRIBE_UNARCHIVE_CARDS = 'SUBSCRIBE_UNARCHIVE_CARDS';
export const SUBSCRIBE_ALLCARDS_CARD_ADDED = 'SUBSCRIBE_ALLCARDS_CARD_ADDED';
export const SUBSCRIBE_ALLCARDS_CARD_UPDATED = 'SUBSCRIBE_ALLCARDS_CARD_UPDATED';
export const SUBSCRIBE_ALLCARDS_CARD_REMOVED = 'SUBSCRIBE_ALLCARDS_CARD_REMOVED';
export const CLEAR_ALL_CARDS = 'CLEAR_ALL_CARDS';
