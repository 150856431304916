import gql from 'graphql-tag';
import {
  MUTATION_MESSAGE, USER_TEMP, USER_INFO, MEMBER_WITH_EMAIL,
} from './fragments';

// ---------------QUERY--------------------
export const FETCH_USER_TEMP_BY_BOX = gql`
  query getUser($boxId: ID!){
    getUsersTempByBoxId(boxId: $boxId){
      ...userTemp
    }
  }
  ${USER_TEMP.userTemp}
`;

export const FETCH_USERS_TEMP = gql`
  query {
    getAllUsersTemp{
      ...userTemp

    }
  }
  ${USER_TEMP.userTemp}
`;

// ----------------MUTATIONS------------------

export const SIGNUP_TEMP = gql`
  mutation($email: String!, $locale: String!) {
    tempSignup(email: $email, locale: $locale){
      ...mutationMessage
    }
  }
  ${MUTATION_MESSAGE.mutationMessage}
`;

export const SIGNUP_WITH_TOKEN = gql`
mutation($firstname: String!, $lastname: String!, $username: String!, $password: String!, $locale: String!, $token: String!) {
  signupWithToken(firstname: $firstname, lastname: $lastname, username: $username, password: $password, locale: $locale, token: $token){
      ...user
    }
  }
  ${USER_INFO.user}
`;

export const DELETE_USER_TEMP_FROM_BOX = gql`
  mutation($id: ID!, $boxId: ID!) {
    deletePendingUserFromBox(id: $id, boxId: $boxId) {
      id
    }
  }
`;

export const INVITE_USER_TO_PUSHBOX = gql`
  mutation($email: String!) {
    inviteToPushbox(email: $email) {
      ...userTemp
    }
  }
  ${USER_TEMP.userTemp}
  `;

export const DELETE_USER_TEMP_FROM_PUSHBOX = gql`
mutation($id: ID!) {
  deleteUseTempFromPushbox(id: $id) {
    id
  }
}
`;

export const RESEND_INVITATION = gql`
mutation($id: ID!) {
  resendInvitation(id: $id) {
    ...userTemp
  }
}
${USER_TEMP.userTemp}
`;

export const VERIFY_TOKEN = gql`
  mutation($token: String!){
    verifySignupToken(token: $token)
  }
  `;

export const RESEND_INVITATION_FROM_BOX = gql`
  mutation($id: ID!, $boxId: ID!) {
    resendInvitationFromBox(id: $id, boxId: $boxId) {
      ...userTemp
    }
  }
  ${USER_TEMP.userTemp}
  `;
// ----------------SUBSCRIPTIONS------------------
export const SUBSCRIBE_USERS_INVITED_BY_EMAIL = gql`
  subscription($boxId: ID!) {
    invitedNewUserToTheBox (boxId: $boxId){
      ...userTemp
    }
  }
  ${USER_TEMP.userTemp}
`;

export const NEW_USER_JOINED = gql`
  subscription($boxId: ID!) {
    newUserJoinedPushbox (boxId: $boxId){
      ...memberWithEmail
    }
  }
  ${MEMBER_WITH_EMAIL.memberWithEmail}
`;
