import gql from 'graphql-tag';
import { LABEL_FRAGMENT, CARD_FRAGMENT } from './fragments';

// ----------------QUERIES------------------

export const GET_BOX_LABELS = gql`
  query($id: ID!) {
    getBoxLabelsById(id: $id) {
      ...label
    }
  }
  ${LABEL_FRAGMENT.label}
`;

export const GET_LABELS_BY_CARD_ID = gql`
  query($id: ID!) {
    getCardLabelsById(id: $id) {
      id
      title
      labels {
        ...label
      }
    }
  }
  ${LABEL_FRAGMENT.label}
  `;

export const GET_ALL_CARDS = gql`
query($box: ID!) {
  getCardsGroupByLabel(box: $box) {
    ...label
    cards {
      ...card
    }

    propertiesSum {
      name
      total
    }
  }
}
${LABEL_FRAGMENT.label}
${CARD_FRAGMENT.cardOverview}
`;

// ----------------MUTATIONS------------------

export const ADD = gql`
  mutation($name: String, $color: String, $icon: String, $box: ID!) {
    addLabel(name: $name, color: $color, icon: $icon, box: $box) {
      id
    }
  }
`;

export const UPDATE = gql`
  mutation($id: ID!, $name: String, $color: String, $icon: String) {
    updateLabel(id: $id, name: $name, color: $color, icon: $icon) {
      id
    }
  }
`;

export const DELETE_FROM_CARD = gql`
  mutation($label: ID!, $card: ID!) {
    removeLabelFromCard(label: $label, card: $card) {
      id
    }
  }
`;

export const ADD_LABEL_TO_CARD = gql`
mutation ($label: ID!, $card: ID!) {
  addLabelToCard(label: $label, card: $card)
  {
    id
  }
}`;

export const DELETE_FROM_BOX = gql`
mutation($id: ID!, $box: ID!) {
  removeBoxLabel(id: $id, box: $box)
  {
    id
  }
}`;

export const MOVE_CARD = gql`
 mutation($from: ID, $to: ID, $card: ID!, $position: Int) {
    moveCardInLabel(
      from: $from,
      to: $to,
      card: $card,
      position: $position,
    ) {
      from
    }
  }
  `;

// ----------------SUBSCRIPTIONS------------------

export const SUBSCRIBE_LABEL_ADDED = gql`
  subscription {
    labelAdded {
      ...label
      box
      cards {
        id
      }
    }
  }
  ${LABEL_FRAGMENT.label}
`;

export const SUBSCRIBE_LABEL_UPDATED = gql`
  subscription labelUpdated {
    labelUpdated {
      ...label
      box
    }
  }
  ${LABEL_FRAGMENT.label}
`;

export const SUBSCRIBE_LABEL_DELETED = gql`
  subscription labelRemoved {
    labelRemoved {
      id
      box
    }
  }
`;

export const SUBSCRIBE_CARD_LABEL_DELETED = gql`
subscription{
  cardLabelRemoved {
    id
    box
    labels {
      ...label
    }
  }
}
${LABEL_FRAGMENT.label}
`;

export const SUBSCRIBE_CARD_LABEL_ADDED = gql`
subscription{
  cardLabelAdded {
    id
    box
    labels {
      ...label
    }
  }
}
${LABEL_FRAGMENT.label}
`;

export const SUBSCRIBE_MOVE_CARD = gql`
subscription{
  cardMovedinLabel {
    from
    to
    card{
      ...card
    }
    position
  }
}
${CARD_FRAGMENT.cardOverview}
`;
export const SUBSCRIBE_LABEL_PROPERTY_SUM_UPDATE = gql`
  subscription {
    labelPropertiesSumUpdated {
      id
      propertiesSum {
        name
        total
      }
    }
  }
`;
