<template>
  <div class="d-flex">
    <div v-for="(member, index) in membersToDisplay" :key="index" class="mr-2">
      <wAvatar
        :user-id="member.user.id"
        :first-name="member.user.firstname"
        :last-name="member.user.lastname"
        :size="26"
      />
    </div>

    <wAvatar
      v-if="remainingMembers !== -1"
      :size="26"
      :first-name="'+'"
      :last-name="remainingMembers"
    />
  </div>
</template>

<script>
/**
 * @group box
 * This component is to show already added members
 */
import wAvatar from '@/components/userAccount/wAvatar.vue';

export default {
  name: 'WBoxUsers',

  components: {
    wAvatar,
  },

  props: {
    // box Members whose info is to be extracted
    boxMembers: {
      type: Array,
      required: true,
    },
    maxVisibleUser: {
      type: Number,
      default: 2,
    },
  },

  computed: {
    // We only show avatars of first two members
    membersToDisplay() {
      return this.boxMembers.slice(0, this.maxVisibleUser);
    },

    // If there are more than 2 members, we show the remaining amount
    remainingMembers() {
      if (this.boxMembers.length > this.maxVisibleUser) {
        return (this.boxMembers.length - this.maxVisibleUser).toString();
      }
      return -1;
    },
  },
};
</script>
