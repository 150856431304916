<template>
  <div v-if="pendingBoxes.length !== 0">
    <v-row
      key="1"
      no-gutters
    >
      <v-col>
        <h2>{{ $t('pending_boxes') }}</h2>
      </v-col>
    </v-row>
    <v-row key="2">
      <v-col
        class="d-flex flex-wrap"
        :class="$vuetify.breakpoint.smAndDown ? 'justify-center' : ''"
        cols="12"
      >
        <!-- All The Boxes -->
        <v-card
          v-for="box in pendingBoxes"
          :key="box.id"
          :color="$vuetify.theme.dark ? 'card' : ''"
          class="mx-3 mb-4"
          style="background-color: rgb(29, 62, 76, 0.5) !important;"
          min-width="250"
          max-width="250"
          max-height="135"
        >
          <v-card-title
            id="boxTitle"
            primary-title
            class="justify-center"
          >
            <p class="title-1 title-blue font-weight-bold text-truncate">
              {{ box.name }}
            </p>
          </v-card-title>

          <!-- Box Menu -->
          <WBoxInvitationControl :box="box" />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import WBoxInvitationControl from '@/components/box/wBoxInvitationControl.vue';

/**
 * @group Box
 *  A component containing the (pending) Boxes with
 *  edit and delete feature for each Box
 * @module components/box/wPendingBoxes.vue
 *
 * @param {Array} pendingBoxes - The Pending Boxes details
 */
export default {
  name: 'wPendingBoxes',

  components: {
    WBoxInvitationControl,
  },

  props: {
    pendingBoxes: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
