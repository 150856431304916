import gql from 'graphql-tag';
import { ROLE } from './fragments';

// --------------- Queries -----------------
export const GET_USER_BOX_ROLE = gql`
  query getUserBoxRole($boxId: ID!) {
    getUserBoxRole(boxId: $boxId) {
      ...role
    }
  }
  ${ROLE.role}
`;

export const GET_BOXES_USER_ROLE = gql`
  query getBoxRoles {
    id
    roles {
      ...role
    }
  }
  ${ROLE.role}
`;

export const GET_ALL_ROLES_IN_BOX = gql`
  query getAllRolesInBox($boxId: ID!) {
    getAllRolesInBox(boxId: $boxId) {
      ...role
    }
  }
  ${ROLE.role}
`;

// --------------- Mutations ---------------------

export const UPDATE_BOX_ROLE = gql`
  mutation changeBoxRole($roleId: ID!, $boxId: ID!, $newRole: Object!) {
    changeBoxRole(roleId: $roleId, boxId: $boxId, newRole: $newRole)
  }
`;

// --------------- Subscriptions -----------------

export const SUBSCRIBE_USER_BOX_ROLE_CHANGED = gql`
  subscription memberBoxRoleChanged($boxId: ID!){
    memberBoxRoleChanged(boxId: $boxId) {
      ...role
    }
  }
  ${ROLE.role}
`;
