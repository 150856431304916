<template>
  <v-app-bar app clipped-right dense color="header">
    <!-- LOGO and Home Button -->
    <router-link tag="button" :to="isAuthenticated ? '/' : '/login'">
      <v-tooltip right open-delay="800">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-img :src="require('@/assets/logo.svg')" aspect-ratio="3" contain />
          </v-btn>
        </template>
        <span>Home</span>
      </v-tooltip>
    </router-link>
    <img
      v-if="$vuetify.breakpoint.smAndUp"
      :src="require('@/assets/pushbox-white.svg')"
      alt="alt"
      height="43"
      class="mx-2"
    />

    <v-spacer />

    <!-- Route the System Admin Panel -->
    <router-link v-if="isAuthenticated && user.isAdmin" tag="button" to="/adminpanel">
      <v-btn icon color="white">
        <v-icon size="22">
          fa-tools
        </v-icon>
      </v-btn>
    </router-link>

    <!-- Change the box view to workflow/category/label etc. -->
    <wBoxViewMenu v-if="isInsideBox" />

    <!-- User Account menu -->
    <wAccountMenu />

    <!-- Change Language -->
    <wLocalization />
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import wLocalization from '@/components/header/wLocalization.vue';
import wAccountMenu from '@/components/header/wAccountMenu.vue';
import wBoxViewMenu from '@/components/header/wBoxViewMenu.vue';
import * as types from '@/stores/types';

/**
 * @group Header
 *
 * This header is share between all main pages
 */
export default {
  name: 'WHeader',

  components: {
    wLocalization,
    wAccountMenu,
    wBoxViewMenu,
  },

  computed: {
    ...mapGetters({
      isAuthenticated: types.GET_USER_AUTHENTICATION_STATUS,
      user: types.GET_USER,
    }),

    isInsideBox() {
      const { boxId } = this.$route.params;
      if (boxId) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
a {
  color: inherit;
}

.app-header {
  background: transparent !important;
  background-color: unset !important;
  border-color: unset !important;
}
</style>
