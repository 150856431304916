import gql from 'graphql-tag';
import {
  BOX_FRAGMENT, ROLE, MUTATION_MESSAGE, USER_SPECIFIC_BOX_SETTINGS,
} from './fragments';

// ----------------QUERIES------------------

export const GET_ALL = gql`
  query {
    getAllBoxes {
      boxes {
        box {
          ...box
          members {
            user {
              id
              firstname
              lastname
            }
            role {
              ...role
            }
          }
          settings {
            hideChecklistsBadge
            hidePropertiesBadge
            hideMembersOnColumnView
            hideMembersOnHorizontalView
            hideSubtitle
          }
          isArchive
        }
        ...settings
      }
      boxGroups {
        id
        name
        boxes {
          box {
            ...box
            members {
              user {
                id
                firstname
                lastname
              }
              role {
                ...role
              }
            }
            settings {
              hideChecklistsBadge
              hidePropertiesBadge
              hideMembersOnColumnView
              hideMembersOnHorizontalView
              hideSubtitle
            }
            isArchive
          }
          ...settings
        }
      }
    }
  }
  ${BOX_FRAGMENT.box}
  ${ROLE.role}
  ${USER_SPECIFIC_BOX_SETTINGS.settings}
`;

export const GET_BOX_DETAILS_BY_ID = gql`
  query getBoxById($id: ID!) {
    getBoxById(id: $id) {
      ...box
      members {
        user {
          id
          firstname
          lastname
        }
        role {
          ...role
        }
      }
      settings {
        hideChecklistsBadge
        hidePropertiesBadge
        hideMembersOnColumnView
        hideMembersOnHorizontalView
        hideSubtitle
      }
      isArchive
    }
  }
  ${BOX_FRAGMENT.box}
  ${ROLE.role}
`;

export const GET_ALL_INVITATIONS = gql`
  query {
    getAllInvitations {
      ...box
      members {
        user {
          id
        }
        role {
          ...role
        }
      }
    }
  }
  ${ROLE.role}
  ${BOX_FRAGMENT.box}
`;

export const GET_BY_ID = gql`
  query getBoxById($id: ID!) {
    getBoxById(id: $id) {
      name
    }
  }
`;

export const GET_BOX_SETTINGS_BY_ID = gql`
  query getBoxById($id: ID!) {
    getBoxById(id: $id) {
      settings {
        hideChecklistsBadge
        hidePropertiesBadge
        hideMembersOnColumnView
        hideMembersOnHorizontalView
        hideSubtitle
      }
    }
  }
`;

export const FETCH_BOX_API_FLAG = gql`
  query getBoxAPIFlag($id: ID!) {
    getBoxAPIFlag(id: $id)
  }
`;

// ----------------MUTATIONS------------------

export const ADD = gql`
  mutation addBox($name: String!) {
    addBox(name: $name) {
      name
    }
  }
`;

export const UPDATE = gql`
  mutation updateBox($id: ID!, $name: String!) {
    updateBox(id: $id, name: $name) {
      name
    }
  }
`;

export const REMOVE = gql`
  mutation removeBox($id: ID!) {
    removeBox(id: $id) {
      id
    }
  }
`;

export const COPY_BOX = gql`
  mutation ($boxId: ID!, $newName: String!) {
    copyBox(boxId: $boxId, newName: $newName) {
      ...mutationMessage
    }
  }
  ${MUTATION_MESSAGE.mutationMessage}
`;

export const UPDATE_BOX_SETTINGS = gql`
  mutation ($boxId: ID!, $settings: SettingsInput!) {
    updateBoxSettings(boxId: $boxId, settings: $settings) {
      id
    }
  }
`;

export const ADD_BOX_GROUP = gql`
  mutation ($name: String!) {
    addBoxGroup(name: $name) {
      id
      name
    }
  }
`;

export const DELETE_BOX_GROUP = gql`
  mutation ($groupId: ID!) {
    deleteBoxGroup(groupId: $groupId) {
      id
    }
  }
`;

export const UPDATE_BOX_POSITION = gql`
  mutation ($newIndex: Int!, $oldIndex: Int!, $groupId: ID) {
    updateBoxPosition(newIndex: $newIndex, oldIndex: $oldIndex, groupId: $groupId) {
      ... on MutationMessage {
        ...mutationMessage
      }
    }
  }
  ${MUTATION_MESSAGE.mutationMessage}
`;

export const MOVE_BOX_BETWEEN_GROUPS = gql`
  mutation ($boxId: ID!, $oldGroupId: String, $newGroupId: String, $newPosition: Int) {
    moveBoxBetweenGroups(
      boxId: $boxId
      oldGroupId: $oldGroupId
      newGroupId: $newGroupId
      newPosition: $newPosition
    ) {
      ... on MutationMessage {
        ...mutationMessage
      }
    }
  }
  ${MUTATION_MESSAGE.mutationMessage}
`;

export const UPDATE_BOX_GROUP_POSITION = gql`
  mutation updateBoxGroupPosition($groupId: ID!, $position: Int) {
    updateBoxGroupPosition(groupId: $groupId, position: $position) {
      ... on MutationMessage {
        ...mutationMessage
      }
    }
  }
  ${MUTATION_MESSAGE.mutationMessage}
`;

export const UPDATE_BOX_GROUP_NAME = gql`
  mutation ($groupId: ID!, $name: String!) {
    updateBoxGroupName(groupId: $groupId, name: $name) {
      ... on MutationMessage {
        ...mutationMessage
      }
    }
  }
  ${MUTATION_MESSAGE.mutationMessage}
`;

export const CREATE_API = gql`
  mutation createAPI($id: ID!) {
    createAPI(id: $id)
  }
`;

export const REMOVE_API = gql`
  mutation removeAPI($id: ID!) {
    removeAPI(id: $id)
  }
`;

// ----------------SUBSCRIPTIONS------------------

export const SUBSCRIBE_ADDED = gql`
  subscription {
    boxAdded {
      box {
        ...box
        members {
          user {
            id
          }
          role {
            ...role
          }
        }
        isArchive
      }
      ...settings
    }
  }
  ${ROLE.role}
  ${BOX_FRAGMENT.box}
  ${USER_SPECIFIC_BOX_SETTINGS.settings}
`;

export const SUBSCRIBE_UPDATED = gql`
  subscription {
    boxUpdated {
      ...box
      members {
        user {
          id
        }
        role {
          ...role
        }
      }
      isArchive
    }
  }
  ${ROLE.role}
  ${BOX_FRAGMENT.box}
`;

export const SUBSCRIBE_REMOVED = gql`
  subscription {
    boxRemoved {
      id
    }
  }
`;

export const SUBSCRIBE_API_CHANGED = gql`
  subscription {
    boxAPIChanged {
      doesBoxHaveAPI
    }
  }
`;
