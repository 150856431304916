<template>
  <div>
    <wToolbar>
      <template v-slot:search>
        <v-text-field
          v-model="search"
          :placeholder="$t('search_text_field')"
          clearable
          hide-details
          solo
          class="mx-2 toolbar-text-field"
        >
          <template v-slot:append>
            <v-icon
              size="22"
            >
              fa-search
            </v-icon>
          </template>
        </v-text-field>
      </template>
    </wToolbar>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import wToolbar from '@/components/common/wToolbar.vue';
import { SEARCH_BOX } from '@/stores/types';

/**
 * @group Box
 * A wHeader component is contained search and add controllers
 * @module components/box/wHeader.vue
 * @param {String} search - The search value
 */

export default {
  name: 'WBoxToolbar',

  components: {
    wToolbar,
  },

  data() {
    return {
      search: '',
    };
  },

  // wBoxBody component use the search input from this component,
  //  then we need to mutate the SEARCH_BOX action from here.
  watch: {
    search(val) {
      this.searching(val);
    },
  },

  methods: {
    ...mapActions({
      searching: SEARCH_BOX,
    }),
  },
};
</script>
