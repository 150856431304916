import { apolloClient } from '@/apolloConfig';
import { GET_USER_BOX_ROLE, GET_ALL_ROLES_IN_BOX, UPDATE_BOX_ROLE } from '@/queries/boxRole';
import * as types from '../types';

const getters = {
  [types.GET_USER_BOX_ROLE]: (state) => state.boxRole,
  [types.GET_USER_TEMPLATE_RIGHTS]: (state) => state.boxRole.cardTemplate || {},
  [types.GET_USER_MEMBER_RIGHTS]: (state) => state.boxRole.boxMember || {},
  [types.GET_USER_BOX_RIGHTS]: (state) => state.boxRole.box || {},
  [types.GET_USER_LABEL_RIGHTS]: (state) => state.boxRole.labels || {},
  [types.GET_ALL_ROLES_IN_BOX]: (state) => state.allRoles,
  [types.GET_USER_ROLE_NAME]: (state) => state.boxRole.name,
};

const mutations = {
  [types.MUTATE_FETCH_USER_BOX_ROLE]: (state, boxRole) => {
    const current = state;
    current.boxRole = boxRole;
  },

  [types.MUTATE_FETCH_ALL_ROLES_IN_BOX]: (state, allRoles) => {
    const current = state;
    current.allRoles = allRoles;
  },

  [types.MUTATE_SUBSCRIBE_USER_BOX_ROLE_CHANGED]: (state, newRole) => {
    const current = state;
    current.boxRole = newRole;
  },

  [types.MUTATE_SUBSCRIBE_BOX_ROLES_CHANGED]: (state, { roles }) => {
    const current = state;
    current.allRoles = roles;
  },
};

const actions = {
  [types.FETCH_USER_BOX_ROLE]: ({ commit }, boxId) => {
    apolloClient.query({
      query: GET_USER_BOX_ROLE,
      variables: { boxId },
    }).then(({ data, loading }) => {
      if (!loading) {
        commit(types.MUTATE_FETCH_USER_BOX_ROLE, data.getUserBoxRole);
      }
    }).catch((err) => {
      console.error(err);
    });
  },

  [types.FETCH_ALL_ROLES_IN_BOX]: ({ commit }, boxId) => apolloClient.query({
    query: GET_ALL_ROLES_IN_BOX,
    variables: { boxId },
  }).then(({ data, loading }) => {
    if (!loading) {
      commit(types.MUTATE_FETCH_ALL_ROLES_IN_BOX, data.getAllRolesInBox);
      commit(types.MUTATE_PAGE_LOADING, loading);
    }
  }),

  [types.SUBSCRIBE_USER_BOX_ROLE_CHANGED]: async ({ commit }, newRole) => {
    commit(types.MUTATE_SUBSCRIBE_USER_BOX_ROLE_CHANGED, newRole);
  },

  [types.UPDATE_USER_ROLE]: async (_, { roleId, boxId, newRole }) => {
    apolloClient.mutate({
      mutation: UPDATE_BOX_ROLE,
      variables: { roleId, boxId, newRole },
    }).catch((err) => {
      console.error(err);
    });
  },
};

const state = {
  boxRole: {},
  allRoles: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
