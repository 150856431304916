import gql from 'graphql-tag';
import {
  BOX_FRAGMENT,
  USER_INFO,
  ROLE,
  MUTATION_MESSAGE,
} from './fragments';

// ----------------QUERIES------------------
export const FETCH_BOX_MEMBERS = gql`
  query getBoxMembers($boxId: ID!) {
    getBoxMembers(boxId: $boxId) {
      ...boxMember
    }
  }
  ${USER_INFO.boxMember}
`;

export const FETCH_COLLABORATORS_FOR_BOX_ID = gql`
  query getBoxMembers($boxId: ID!) {
    getBoxMembers(boxId: $boxId) {
      ...boxMember
    }
  }
  ${USER_INFO.boxMember}
`;

// ----------------MUTATIONS------------------

export const ADD_MEMBERS_TO_BOX = gql`
mutation addMembersToBox($id: ID!, $members: [ID]!) {
  addMembersToBox(id: $id, members: $members) {
    ...mutationMessage
  }
}
${MUTATION_MESSAGE.mutationMessage}
`;

export const REMOVE_BOX_MEMBER = gql`
mutation removeBoxMember($id: ID!, $member: Object!) {
  removeBoxMember(id: $id, member: $member) {
    ...mutationMessage
  }
}
${MUTATION_MESSAGE.mutationMessage}
`;

export const INVITE_TO_BOX_WITH_EMAIL = gql`
mutation inviteToBox($boxId: ID, $emails: [String]) {
  inviteToBox(boxId: $boxId, emails: $emails) {
    ...mutationMessage
  }
}
${MUTATION_MESSAGE.mutationMessage}
`;

export const CHANGE_USER_BOX_ROLE = gql`
mutation changeUserBoxRole($boxId: ID!, $memberId: ID!, $roleId: ID!) {
  changeUserBoxRole(boxId: $boxId, memberId: $memberId, roleId: $roleId)
}
`;

export const ACCEPT_INVITATION = gql`
mutation acceptInvitation($boxId: ID!) {
  acceptInvitation(boxId: $boxId){
    ...mutationMessage
  }
}
${MUTATION_MESSAGE.mutationMessage}
`;

export const DECLINE_INVITATION = gql`
mutation declineInvitation($boxId: ID!) {
  declineInvitation(boxId: $boxId){
    ...mutationMessage
  }
}
${MUTATION_MESSAGE.mutationMessage}
`;

// ----------------SUBSCRIPTIONS------------------

export const SUBSCRIBE_COLLABORATORS_ADDED = gql`
  subscription boxMembersAddedToBox($id: ID!) {
    boxMembersAddedToBox(id: $id) {
      ...boxMember
    }
  }
  ${USER_INFO.boxMember}
`;

export const SUBSCRIBE_COLLABORATOR_REMOVED = gql`
  subscription boxMemberRemovedFromBox($id: ID!) {
    boxMemberRemovedFromBox(id: $id) {
      ...boxMember
    }
  }
  ${USER_INFO.boxMember}
`;

export const SUBSCRIBE_SELF_ADDED_AS_COLLABORATOR = gql`
  subscription {
    invitedMemberToTheBox{
      ...box
      members {
        user {
          id
        }
        role {
          ...role
        }
      }
    }
  }
  ${ROLE.role}
  ${BOX_FRAGMENT.box}
`;

export const SUBSCRIBE_SELF_REMOVED_AS_COLLABORATOR = gql`
  subscription {
    removedMemberFromTheBox
  }
`;

export const SUBSCRIBE_BOX_ROLES_CHANGED = gql`
  subscription {
    boxRolesChanged {
      ...box
      roles {
        ...role
      }
      members {
        ...boxMember
      }
    }
  }
  ${ROLE.role}
  ${BOX_FRAGMENT.box}
  ${USER_INFO.boxMember}
`;

export const SUBSCRIBE_BOX_INVITATION_ACCEPTED = gql`
subscription {
  boxInvitationAccepted
}
`;

export const SUBSCRIBE_BOX_MEMBER_ACCEPTED_INVITATION = gql`
subscription {
  boxMemberAcceptedInvitation
}
`;
