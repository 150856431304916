import { apolloClient } from '@/apolloConfig';
import {
  FETCH_COLLABORATORS_FOR_BOX_ID,
  ADD_MEMBERS_TO_BOX,
  REMOVE_BOX_MEMBER,
  FETCH_BOX_MEMBERS,
  CHANGE_USER_BOX_ROLE,
  ACCEPT_INVITATION,
  DECLINE_INVITATION,
  INVITE_TO_BOX_WITH_EMAIL,
} from '@/queries/boxMembers';
import * as types from '../types';

const getters = {
  [types.GET_COLLABORATORS_FOR_BOX]: (state) => state.members,
};

const mutations = {
  [types.MUTATE_FETCH_COLLABORATORS_FOR_BOX]: (state, newCollaborators) => {
    const current = state;
    current.members = newCollaborators.map(({ user }) => user);
  },

  [types.MUTATE_ADD_COLLABORATORS_FROM_SUBSCRIPTION]: (state, newMember) => {
    const current = state;
    newMember.forEach((member) => {
      if (current.members.findIndex(({ user }) => member.id === user.id) === -1) {
        current.members = [...state.members, member];
      }
    });
  },

  [types.MUTATE_INVITATION_ACCEPTED_FOR_COLLABORATORS]: (state, userId) => {
    const current = state;
    const index = current.members.findIndex(({ user }) => user.id === userId);

    if (index !== -1) {
      current.members.splice(index, 1, { ...current.members[index], isInvitation: false });
    }
  },

  [types.MUTATE_REMOVE_COLLABORATOR_FROM_SUBSCRIPTION]: (state, boxMembers) => {
    const current = state;
    current.members = boxMembers;
  },

  [types.MUTATE_BOX_MEMBERS]: (state, members) => {
    const current = state;
    current.members = members;
  },

  [types.MUTATE_SUBSCRIBE_MEMBERS_ROLES_CHANGED]: (state, updatedBox) => {
    const current = state;
    current.members = updatedBox.members;
  },

  [types.MUTATE_USER_SIGNED_UP]: (state, member) => {
    const current = state;
    current.members = [...current.members, member];
  },

  [types.MUTATE_SUBSCRIBE_BOX_MEMBERS_USER_DELETED]: (state, deletedUser) => {
    const current = state;

    current.members = current.members.filter(({ user }) => user.id !== deletedUser.id);
  },
};

const actions = {
  [types.FETCH_COLLABORATORS_FOR_BOX]: async ({ commit }, boxId) => {
    await apolloClient.query({
      query: FETCH_COLLABORATORS_FOR_BOX_ID,
      variables: {
        boxId,
      },
    }).then(({ data }) => {
      const { getBoxMembers } = data;
      commit(types.MUTATE_FETCH_COLLABORATORS_FOR_BOX, getBoxMembers);
    }).catch((err) => console.error(err));
  },

  [types.FETCH_BOX_MEMBERS]: ({ commit }, boxId) => {
    apolloClient
      .query({
        query: FETCH_BOX_MEMBERS,
        variables: {
          boxId,
        },
      })
      .then(({ data }) => {
        commit(types.MUTATE_BOX_MEMBERS, data.getBoxMembers);
      })
      .catch((err) => {
        console.error({ err });
      });
  },

  [types.ADD_COLLABORATORS_TO_BOX]: async ({ commit }, { boxId, userIds }) => {
    apolloClient.mutate({
      mutation: ADD_MEMBERS_TO_BOX,
      variables: {
        id: boxId,
        members: userIds,
      },
    })
      .then(({ data }) => {
        commit(types.MUTATE_MESSAGE, data.addMembersToBox);
      })
      .catch((err) => console.error(err));
  },

  [types.REMOVE_COLLABORATOR_FROM_BOX]: async ({ commit }, { boxId, member }) => {
    apolloClient
      .mutate({
        mutation: REMOVE_BOX_MEMBER,
        variables: {
          id: boxId,
          member,
        },
      })
      .then(({ data }) => {
        commit(types.MUTATE_MESSAGE, data.removeBoxMember);
      })
      .catch((err) => {
        console.error({ err });
      });
  },

  [types.SEND_INVITATIONS_BY_EMAIL]:
  async ({ commit }, { boxId, emails }) => new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: INVITE_TO_BOX_WITH_EMAIL,
        variables: {
          boxId,
          emails,
        },
      })
      .then(({ data }) => {
        commit(types.MUTATE_MESSAGE, data.inviteToBox);
        resolve();
      })
      .catch((err) => {
        console.error({ err });
        reject();
      });
  }),

  [types.ADD_COLLABORATORS_FROM_SUBSCRIPTION]: async ({ commit }, collaborators) => {
    if (collaborators) {
      commit(types.MUTATE_ADD_COLLABORATORS_FROM_SUBSCRIPTION, collaborators);
    }
  },

  [types.CHANGE_USER_BOX_ROLE]: async (_, { boxId, memberId, roleId }) => {
    apolloClient
      .mutate({
        mutation: CHANGE_USER_BOX_ROLE,
        variables: {
          boxId,
          memberId,
          roleId,
        },
      })
      .catch((err) => {
        console.error({ err });
      });
  },

  [types.ACCEPT_BOX_INVITATION]: async ({ commit }, { boxId }) => {
    await apolloClient
      .mutate({
        mutation: ACCEPT_INVITATION,
        variables: {
          boxId,
        },
      })
      .then(({ data }) => {
        commit(types.MUTATE_MESSAGE, data.acceptInvitation);
      })
      .catch((err) => {
        console.error({ err });
      });
  },

  [types.DECLINE_BOX_INVITATION]: async ({ commit }, { boxId }) => {
    await apolloClient
      .mutate({
        mutation: DECLINE_INVITATION,
        variables: {
          boxId,
        },
      })
      .then(({ data }) => {
        commit(types.MUTATE_MESSAGE, data.declineInvitation);
      })
      .catch((err) => {
        console.error({ err });
      });
  },

  [types.ADD_COLLABORATORS_FROM_SUBSCRIPTION]: async ({ commit }, boxMember) => {
    commit(types.MUTATE_ADD_COLLABORATORS_FROM_SUBSCRIPTION, boxMember);
  },

  [types.REMOVE_COLLABORATOR_FROM_SUBSCRIPTION]: async ({ commit }, collaborators) => {
    if (collaborators) {
      commit(types.MUTATE_REMOVE_COLLABORATOR_FROM_SUBSCRIPTION, collaborators);
    }
  },

  [types.SUBSCRIBE_ROLES_CHANGED]: async ({ commit }, updatedBox) => {
    commit(types.MUTATE_SUBSCRIBE_MEMBERS_ROLES_CHANGED, updatedBox);
    commit(types.MUTATE_SUBSCRIBE_BOX_ROLES_CHANGED, updatedBox);
  },
};

const state = {
  members: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
