import { apolloClient } from '@/apolloConfig';
import * as queries from '@/queries/label';
import * as types from '../types';

/**
 * ----------------------------GETTERS------------------------------------
 */
const getters = {
  [types.GET_BOX_LABELS]: (state) => state.boxLabels,
  [types.GET_CARD_LABELS]: (state) => state.cardLabels,
  [types.GET_LABEL_LOADING]: (state) => state.loading,
  [types.GET_LABEL_EXPANSION_STATUS]: (state) => state.isLabelExpanded,
};

/**
 * ----------------------------MUTATIONS-----------------------------------
 */
const mutations = {
  [types.MUTATE_LABEL_EXPANSION_IN_CARD_VIEW]: (state) => {
    const current = state;
    current.isLabelExpanded = !current.isLabelExpanded;
  },

  [types.MUTATE_FETCH_BOX_LABELS]: (state, labels) => {
    const current = state;
    current.boxLabels = labels;
  },

  [types.MUTATE_FETCH_CARD_LABELS]: (state, labels) => {
    const current = state;
    current.cardLabels = labels;
  },

  [types.MUTATE_SUBSCRIBE_CARD_LABEL_DELETED]: (state, label) => {
    const current = state;
    current.cardLabels = label;
  },

  [types.MUTATE_SUBSCRIBE_ADDED_LABEL]: (state, labelAdded) => {
    const current = state;
    current.boxLabels = [...current.boxLabels, labelAdded];
  },

  [types.MUTATE_SUBSCRIBE_DELETED_LABEL_FROM_BOX]: (state, label) => {
    const current = state;
    const index = current.boxLabels.findIndex((l) => l.id === label.id);
    if (index !== -1) {
      current.boxLabels.splice(index, 1);
    }

    const cardIndex = current.cardLabels.labels.findIndex((l) => l.id === label.id);
    if (cardIndex !== -1) {
      current.cardLabels.labels.splice(cardIndex, 1);
    }
  },

  [types.MUTATE_SUBSCRIBE_UPDATED_LABEL]: (
    state,
    {
      id, name, color, icon,
    },
  ) => {
    const current = state;
    const { boxLabels, cardLabels } = current;
    // Update label in Box
    const index = current.boxLabels.findIndex((c) => c.id === id);
    if (index !== -1) {
      boxLabels[index].id = id;
      boxLabels[index].name = name;
      boxLabels[index].color = color;
      boxLabels[index].icon = icon;
    }

    // Update label inside the card
    if (cardLabels.labels.length === 0) {
      return;
    }
    const indexCard = cardLabels.labels
      .findIndex((c) => c.id === id);
    if (indexCard !== -1) {
      cardLabels.labels[indexCard].id = id;
      cardLabels.labels[indexCard].name = name;
      cardLabels.labels[indexCard].color = color;
      cardLabels.labels[indexCard].icon = icon;
    }
  },

  [types.MUTATE_SUBSCRIBE_CARD_LABEL_ADDED]: (state, labelAdded) => {
    const current = state;
    current.cardLabels = labelAdded;
  },

  [types.MUTATE_LABEL_LOADING]: (state, loading) => {
    const current = state;
    current.loading = loading;
  },
};

/**
 * ---------------------ACTIONS-------------------------------
 */
const actions = {
  [types.FETCH_BOX_LABELS]: ({ commit }, box) => {
    apolloClient
      .query({
        query: queries.GET_BOX_LABELS,
        variables: { id: box },
      })
      .then(({ data, loading }) => {
        commit(types.MUTATE_LABEL_LOADING, loading);
        commit(types.MUTATE_FETCH_BOX_LABELS, data.getBoxLabelsById);
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.ADD_LABEL]: ({ dispatch, commit }, {
    name, color, icon, box, card,
  }) => {
    apolloClient
      .mutate({
        mutation: queries.ADD,
        variables: {
          name,
          color,
          icon,
          box,
        },
      })
      .then(({ data, loading }) => {
        if (card !== '' && card !== undefined) {
          commit(types.MUTATE_LABEL_LOADING, loading);
          dispatch(types.ADD_LABEL_TO_CARD, {
            label: data.addLabel.id,
            card,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.SUBSCRIBE_ADDED_LABEL]: ({ commit }, labelAdded) => {
    commit(types.MUTATE_SUBSCRIBE_ADDED_LABEL, labelAdded);
  },

  [types.SUBSCRIBE_DELETED_LABEL]: ({ commit }, labelRemoved) => {
    commit(types.MUTATE_SUBSCRIBE_DELETED_LABEL_FROM_BOX, labelRemoved);
  },

  [types.SUBSCRIBE_UPDATED_LABEL]: ({ commit }, labelUpdated) => {
    commit(types.MUTATE_SUBSCRIBE_UPDATED_LABEL, labelUpdated);
  },

  [types.SUBSCRIBE_CARD_LABEL_DELETED]: ({ commit }, labelRemoved) => {
    commit(types.MUTATE_SUBSCRIBE_CARD_LABEL_DELETED, labelRemoved);
  },

  [types.SUBSCRIBE_CARD_LABEL_ADDED]: ({ commit }, labelAdded) => {
    commit(types.MUTATE_SUBSCRIBE_CARD_LABEL_ADDED, labelAdded);
  },

  [types.FETCH_CARD_LABELS]: ({ commit }, card) => {
    apolloClient.query({
      query: queries.GET_LABELS_BY_CARD_ID,
      variables: {
        id: card,
      },
    })
      .then(({ data, loading }) => {
        commit(types.MUTATE_LABEL_LOADING, loading);
        commit(types.MUTATE_FETCH_CARD_LABELS, data.getCardLabelsById);
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.DELETE_LABEL_FROM_CARD]: ({ commit }, { label, card }) => {
    apolloClient
      .mutate({
        mutation: queries.DELETE_FROM_CARD,
        variables: { label, card },
      })
      .catch((err) => {
        console.error(err);
      });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.DELETE_LABEL_FROM_BOX]: (_, { id, box }) => {
    apolloClient
      .mutate({
        mutation: queries.DELETE_FROM_BOX,
        variables: { id, box },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.ADD_LABEL_TO_CARD]: ({ commit }, { label, card }) => {
    apolloClient
      .mutate({
        mutation: queries.ADD_LABEL_TO_CARD,
        variables: { label, card },
      })
      .catch((err) => {
        console.error(err);
      });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.UPDATE_LABEL]: ({ commit }, {
    id, name, color, icon,
  }) => {
    apolloClient
      .mutate({
        mutation: queries.UPDATE,
        variables: {
          id,
          name,
          color,
          icon,
        },
      })
      .catch((err) => {
        console.error(err);
      });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.LABEL_LOADING]: ({ commit }, loading) => {
    commit(types.MUTATE_LABEL_LOADING, loading);
  },
};

/**
 * STATES
 */
const state = {
  boxLabels: [],
  cardLabels: [],
  loading: true,
  isLabelExpanded: false,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
