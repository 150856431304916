import Vue from 'vue';
import Router from 'vue-router';
import { changeRoute } from '@/apolloConfig';
import { debugging } from '@/scripts/debugger';
import Box from './views/wBox.vue';
import store from './store';
import * as types from './stores/types';
import { ABOUT_US } from './scripts/common';

const log = (message) => {
  debugging('router.js', message);
};

Vue.use(Router);

/**
 * Fetch the user data if a valid
 * cookie exists in the browser
 */
const logMeInIfIHaveTo = async () => {
  const isAuth = store.getters.GET_USER_AUTHENTICATION_STATUS;
  if (!isAuth) {
    await store.dispatch(types.VALIDATE_USER_TOKEN);
  }

  return store.getters.GET_USER_AUTHENTICATION_STATUS;
};

const userHasUsername = () => {
  const user = store.getters.GET_USER;
  return !!user.username;
};

/**
 * The route that sent the user to the login route
 */

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Box',
      component: Box,
      meta: {
        requiresAuthentication: true,
        breadcrumb: 'boxes',
      },
    },
    {
      path: '/workflow/:boxId/:cardId?',
      name: 'Workflow',
      component: () => import('./views/wWorkflow.vue'),
      meta: {
        requiresAuthentication: true,
        breadcrumb: 'workflow',
      },
    },
    {
      path: '/category/:boxId/:cardId?',
      name: 'Category',
      component: () => import('./views/wCategory.vue'),
      meta: {
        requiresAuthentication: true,
        breadcrumb: 'category',
      },
    },
    {
      path: '/label/:boxId/:cardId?',
      name: 'Label',
      component: () => import('./views/wLabel.vue'),
      meta: {
        requiresAuthentication: true,
        breadcrumb: 'label',
      },
    },
    {
      path: '/calendar/:boxId/:cardId?',
      name: 'Calendar',
      component: () => import('./views/wCalendar.vue'),
      meta: {
        requiresAuthentication: true,
        breadcrumb: 'calendar',
      },
    },
    {
      path: '/allcards/:boxId/:cardId?',
      name: 'AllCards',
      component: () => import('./views/wAllCards.vue'),
      meta: {
        requiresAuthentication: true,
        breadcrumb: 'all_cards',
      },
    },
    {
      path: '/(workflow|category|label)/:boxId/column/:columnId/:cardId?',
      name: 'ColumnExpanded',
      component: () => import('./views/wColumnExpanded.vue'),
      meta: {
        requiresAuthentication: true,
        breadcrumb: 'workflow',
      },
    },
    {
      path: '/boxSettings/:boxId',
      name: 'BoxSettings',
      component: () => import('./views/wBoxSettings.vue'),
      meta: {
        authGard: true,
        requiresAuthentication: true,
        breadcrumb: 'box_settings',
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('./views/wLogin.vue'),
    },
    {
      path: '/signup/:token?',
      name: 'Signup',
      component: () => import('./views/wSignup.vue'),
      beforeEnter: async (to, from, next) => {
        log('beforeEnter signup router');
        await store.dispatch(types.FETCH_GENERAL_CONFIG);
        // if param has token, before enter to signup, verify the token
        if (to.params.token) {
          log('signup has Token');
          await store.dispatch(types.VERIFY_TOKEN, to.params.token);
          if (store.getters.GET_TOKEN_VERIFICATION) {
            log('token is verified');
            next();
          } else {
            log('token is not verified');
            next({
              path: '/login',
            });
          }
          // Do not enter Signup if global registration
        // is deactivated AND token is not available
        } else if (!store.getters.GET_GENERAL_CONFIG.isRegistrationAllowed) {
          log('can not use signup');
          next({
            path: '/login',
          });
        } else {
          log('allow to use signup');
          next();
        }
      },
    },
    {
      path: '/404Page',
      name: 'w404Page',
      component: () => import('./views/w404Page.vue'),
      meta: {
        requiresAuthentication: false,
      },
    },
    {
      path: '/settings',
      name: 'Settings',
      component: () => import('./views/wSettings.vue'),
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: '/aboutUs',
      name: ABOUT_US,
      component: () => import('./views/wAboutUS.vue'),
      meta: {
        requiresAuthentication: false,
      },
    },
    {
      path: '/adminpanel',
      name: 'AdminPanel',
      component: () => import('./views/wAdminPanel.vue'),
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: '/setusername',
      name: 'SetUsername',
      component: () => import('./views/wSetUsername.vue'),
      beforeEnter: (to, from, next) => {
        if (userHasUsername()) {
          next({
            path: from.path,
            replace: true,
          });
        } else {
          next();
        }
      },
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: '/passwordrecovery/:token',
      name: 'PasswordRecovery',
      component: () => import('./views/wPasswordRecovery.vue'),
      beforeEnter: (to, from, next) => {
        logMeInIfIHaveTo()
          .then((response) => {
            if (!response) {
              next();
            } else {
              next({
                path: from.path,
              });
            }
          });
      },
      meta: {
        requiresAuthentication: false,
      },
    },
  ],
});

// Global route guard
router.afterEach((to) => {
  if (to.matched.some((record) => record.meta.requiresAuthentication)) {
    if (to.meta.breadcrumb) {
      store.dispatch(types.ADD_NEW_BREADCRUMB, to);
    }
  }
});

router.beforeEach((to, from, next) => {
  // If the route is not available redirect the user to 404 page
  if (!to.name) {
    next({
      path: '/404Page',
      replace: true,
    });
  } else {
    next();
  }

  /**
   * This needs to be done to have the correct boxId in the subscription context
   */
  changeRoute(to);

  /**
   * Clean up the multiselection
   */
  if (store.getters.GET_IS_CARD_MULTISELECT_ON) {
    store.dispatch(types.TOGGLE_MULTISELECT);
  }

  if (!(to.params.cardId || from.params.cardId)) {
    store.dispatch(types.PAGE_LOADING, true);
  }

  if (to.matched.some((record) => record.meta.requiresAuthentication)) {
    logMeInIfIHaveTo()
      .then((response) => {
        if (response) {
          next();
        } else if (to.name === 'Login' || to.name === 'Signup') {
          next();
        } else {
          router.push('/login');
        }
      });
  } else {
    logMeInIfIHaveTo()
      .then((response) => {
        if (response && (to.name === 'Login' || to.name === 'Signup')) {
          next({ path: from.path });
        } else {
          next();
        }
      });
  }
});

export default router;
