<template>
  <v-dialog
    persistent
    no-click-animation
    :value="displayReloadDialog"
    max-width="400"
  >
    <v-card>
      <v-card-text class="title text-center black--text pt-2">{{
        $t("version_mismatch_info_text")
      }}</v-card-text>
      <v-card-actions>
        <v-btn text color="success" @click="reloadPage">
          {{ $t("reload_page") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel" color="warning">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { registerMismatchHandler } from '@/apolloConfig';
import { mapGetters, mapActions } from 'vuex';
import { GET_SELECTED_CARD, CARD_DIALOG } from '../../stores/types';

/**
 * @group Common
 * A component present everywhere that informs the user
 * when the backend and frontend use differing versions.
 * Forces an update afterwards
 */
export default {
  name: 'WReload',

  data() {
    return {
      needsReload: false,
      dontAskAgain: false,
    };
  },

  created() {
    registerMismatchHandler(() => {
      this.needsReload = true;

      if (this.getCard.isOpen && !this.dontAskAgain) {
        this.closeDialog({ isOpen: false });
        this.$router.replace(this.$route.path.substr(0, this.$route.path.lastIndexOf('/')));
      }
    });
  },

  computed: {
    ...mapGetters({
      getCard: GET_SELECTED_CARD,
    }),

    displayReloadDialog() {
      return !this.dontAskAgain && this.needsReload;
    },
  },

  methods: {
    ...mapActions({
      closeDialog: CARD_DIALOG,
    }),

    cancel() {
      this.dontAskAgain = true;
    },

    reloadPage() {
      window.location.reload(true);
    },
  },
};
</script>
