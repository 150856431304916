import { apolloClient } from '@/apolloConfig';
import {
  GET_DESCRIPTION,
  UPDATE_DESCRIPTION,
} from '@/queries/description';
import * as types from '../types';

/**
 * GETTERS
 */
const getters = {
  [types.GET_DESCRIPTION]: (state) => state.description,
  [types.GET_DESC_DIRTY]: (state) => state.descDirty,
  [types.GET_SHOW_TEXT_AREA]: (state) => state.showTextArea,
};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_FETCH_DESCRIPTION]: (state, newDescription = '') => {
    const current = state;
    current.description = newDescription;
  },
  [types.MUTATE_SUBSCRIBE_DESCRIPTION_UPDATE]: (state, newDescription) => {
    const current = state;
    current.description = newDescription;
  },
  [types.MUTATE_TOGGLE_DESC_DIRTY]: (state, isEditing) => {
    const current = state;
    current.descDirty = isEditing;
  },
  [types.MUTATE_TOGGLE_SHOW_TEXT_AREA]: (state, showTextArea) => {
    const current = state;
    current.showTextArea = showTextArea;
  },
};

/**
 * ACTIONS
 */
const actions = {
  // Description
  [types.FETCH_DESCRIPTION]: ({ commit }, cardID) => {
    apolloClient.query({
      query: GET_DESCRIPTION,
      variables: {
        id: cardID,
      },
    })
      .then(({ data, loading }) => {
        if (!loading) {
          commit(types.MUTATE_FETCH_DESCRIPTION, data.getCardById.description);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.UPDATE_DESCRIPTION]: ({ commit }, { cardId, description }) => {
    apolloClient.mutate({
      mutation: UPDATE_DESCRIPTION,
      variables: {
        id: cardId,
        description,
      },
    })
      .catch((err) => {
        console.error(err);
      });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.TOGGLE_DESC_DIRTY]: ({ commit }, { isEditing }) => {
    commit(types.MUTATE_TOGGLE_DESC_DIRTY, isEditing);
  },

  [types.TOGGLE_SHOW_TEXT_AREA]: ({ commit }, { showTextArea }) => {
    commit(types.MUTATE_TOGGLE_SHOW_TEXT_AREA, showTextArea);
  },

  [types.SUBSCRIBE_DESCRIPTION_UPDATE]: ({ commit }, description) => {
    commit(types.MUTATE_SUBSCRIBE_DESCRIPTION_UPDATE, description);
  },
};

/**
 * STATES
 */
const state = {
  description: '',
  descDirty: false,
  showTextArea: false,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
