<template>
    <v-menu
      v-if="isAuthenticated"
      offset-y
      transition="slide-y-transition"
      eager
    >
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          v-on="on"
        >
          <wAvatar
            :user-id="user.id"
            :first-name="user.firstname"
            :last-name="user.lastname"
          />
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <wAvatar
                :user-id="user.id"
                :first-name="user.firstname"
                :last-name="user.lastname"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ getName }}</v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider />

        <v-list dense>
          <v-list-item-group
            color="icon"
            no-action
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon size="20">fa-fill-drip</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('darkTheme') }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-switch
                  v-model="changeTheme"
                  color="icon"
                />
              </v-list-item-action>
            </v-list-item>

            <v-list-item
              v-for="(userMenu, i) in items"
              :key="i"
              @click="userMenu.method"
            >
              <v-list-item-icon>
                <v-icon size="20" v-text="userMenu.icon" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t(userMenu.text) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import wAvatar from '@/components/userAccount/wAvatar.vue';
import * as types from '@/stores/types';

/**
 * @group Common
 *
 * This header is share between all main pages
 */
export default {
  name: 'WAccountMenu',

  components: {
    wAvatar,
  },

  computed: {
    ...mapGetters({
      isAuthenticated: types.GET_USER_AUTHENTICATION_STATUS,
      user: types.GET_USER,
      getSetting: types.GET_SETTING,
    }),

    getName() {
      return `${this.user.firstname} ${this.user.lastname}`;
    },

    changeTheme: {
      get() {
        if (this.user.setting) {
          return this.user.setting.darkTheme;
        }
        return false;
      },
      async set(isDark) {
        this.$vuetify.theme.dark = isDark;
        await this.updateDarkTheme(isDark);
      },
    },
  },

  data() {
    return {
      items: [
        { text: 'settings', icon: 'fa-cog', method: () => this.$router.push({ path: '/settings' }) },
        { text: 'logout', icon: 'fa-sign-out-alt', method: this.logout },
      ],
    };
  },

  methods: {
    ...mapActions({
      logoutUser: types.LOGOUT,
      updateDarkTheme: types.UPDATE_DARKTHEME,
    }),

    /**
     * @vuese
     * Log the user out
     */
    async logout() {
      await this.logoutUser();
      this.$router.push('/login');
    },
  },
};
</script>
