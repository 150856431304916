<template>
  <div>
    <wToolbar />
    <wBody />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import wToolbar from '@/components/box/wToolbar.vue';
import wBody from '@/components/box/wBody.vue';
import { ADD } from '@/queries/box';
import { SEARCH_BOX } from '@/stores/types';

export default {
  name: 'WBox',

  components: {
    wToolbar,
    wBody,
  },

  data() {
    return {
      search: '',
      dialog: false,
      name: '',
    };
  },

  // wBoxBody component use the search input from this component,
  //  then we need to mutate the SEARCH_BOX action from here.
  watch: {
    search(val) {
      this.searching(val);
    },
  },

  methods: {
    ...mapActions({
      searching: SEARCH_BOX,
    }),

    addBox() {
      // Box name is mandetory
      if (this.name === '') {
        return;
      }
      // Mutation
      this.$apollo
        .mutate({
          mutation: ADD,
          variables: {
            name: this.name,
          },
        })
        .then((respose) => {
          const result = respose.data.addBox.name;
          if (result === null) {
            return;
          }
          this.name = '';
          this.dialog = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },

};
</script>
