import { apolloClient } from '@/apolloConfig';
import * as queries from '@/queries/setting';
import * as types from '../types';

/**
 * GETTERS
 */
const getters = {
  [types.GET_SETTING]: (state) => state.setting,
};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_UPDATE_SETTING]: (state, updatedSetting) => {
    const current = state;
    if (updatedSetting.locale) {
      current.setting.locale = updatedSetting.locale || current.setting.locale;
    }
    /**
     * Checking for undefined because
     * @param darkTheme is a boolean
     */
    if (updatedSetting.darkTheme !== undefined) {
      current.setting.darkTheme = updatedSetting.darkTheme;
    }

    if (updatedSetting.twoFactorEnabled !== undefined) {
      current.setting.twoFactorEnabled = updatedSetting.twoFactorEnabled;
    }
  },
};

/**
 * ACTIONS
 */
const actions = {
  [types.UPDATE_SETTING]: ({ commit }, { locale }) => {
    apolloClient
      .mutate({
        mutation: queries.UPDATE,
        variables: {
          locale,
        },
      })
      .then(({ data }) => {
        commit(types.MUTATE_UPDATE_SETTING, data.updateUserSetting);
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.UPDATE_SETTING_LOCAL]: ({ commit }, locale) => {
    commit(types.MUTATE_UPDATE_SETTING, locale);
  },

  [types.UPDATE_DARKTHEME]: ({ commit }, darkTheme) => {
    apolloClient
      .mutate({
        mutation: queries.UPDATE,
        variables: {
          darkTheme,
        },
      })
      .then(({ data }) => {
        commit(types.MUTATE_UPDATE_SETTING, data.updateUserSetting);
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.UPDATE_TWO_FACTOR_AUTH]: (_, newTwoFactorAuth) => {
    apolloClient.mutate({
      mutation: queries.CHANGE_TWO_FACTOR_AUTH_SETTING,
      variables: {
        newTwoFactorAuth,
      },
    }).catch((err) => { console.error(err); });
  },
};

/**
 * STATES
 */
const state = {
  setting: { locale: undefined, twoFactorEnabled: undefined },
  darkTheme: false,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
