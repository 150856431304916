import gql from 'graphql-tag';
import {
  MUTATION_MESSAGE,
} from './fragments';

// --------- MUTATIONS ---------

export const ARCHIVE_BOX = gql`
    mutation($boxId: ID!){
        archiveBox(boxId: $boxId){
            ...mutationMessage
        }
    }   
    ${MUTATION_MESSAGE.mutationMessage}
`;

export const UNARCHIVE_BOX = gql`
    mutation($boxId: ID!){
        unarchiveBox(boxId: $boxId){
            ...mutationMessage
        }
    }   
    ${MUTATION_MESSAGE.mutationMessage}
`;

// ----------------SUBSCRIPTIONS------------------

export const SUBSCRIBE_ARCHIVED_BOX = gql`
  subscription {
    boxArchived {
      box
    }
  }
`;

export const SUBSCRIBE_UNARCHIVED_BOX = gql`
  subscription {
    boxUnarchived {
      box
    }
  }
`;
