import gql from 'graphql-tag';

export const USER_TEMP = {
  userTemp: gql`
    fragment userTemp on UserTemp {
      id
      firstname
      lastname
      email
      boxes
      locale
      updatedAt
      expiration
    }
  `,
};

export const BOX_FRAGMENT = {
  box: gql`
    fragment box on Box {
      id
      name
    }
  `,
};

export const LABEL_FRAGMENT = {
  label: gql`
    fragment label on Label {
      id
      name
      icon
      color
    }
  `,
};

export const ATTACHMENT_FRAGMENT = {
  attachment: gql`
    fragment attachment on Attachment {
      id
      file
      fileId {
        id
        length
        thumbnail
        uploadDate
      }
      user {
        id
        firstname
        lastname
        isDeleted
      }
    }
  `,
};

export const CHECKLIST_FRAGMENT = {
  checklist: gql`
    fragment checklist on Checklist {
      id
      title
      items {
        id
        name
        select
      }
    }
  `,
};

export const ROLE = {
  role: gql`
    fragment role on BoxRoles {
      id
      name
      box {
        write
        remove
      }
      cardTemplate {
        write
        remove
      }
      labels {
        write
        remove
      }
    }
  `,
};

export const USER_INFO = {
  user: gql`
    fragment user on User {
      id
      firstname
      lastname
      username
      email
      passwordUpdatedOn
      setting {
        locale
        darkTheme
        twoFactorEnabled
      }
      isAdmin
    }
  `,
  publicUser: gql`
    fragment publicUser on PublicUser {
      id
      firstname
      lastname
      username
      isDeleted
    }
  `,
  userForAdmin: gql`
    fragment userForAdmin on UserForAdmin {
      id
      email
      firstname
      lastname
      username
      isDeleted
      isAdmin
      createdAt
    }
  `,
  fileInfo: gql`
    fragment fileInfo on FileInfo {
      id
      length
    }
  `,
  boxMember: gql`
    fragment boxMember on BoxMember {
      id
      user {
        id
        firstname
        lastname
        username
      }
      role {
        ...role
      }
      isInvitation
    }
    ${ROLE.role}
  `,
};

export const MEMBER_WITH_EMAIL = {
  memberWithEmail: gql`
    fragment memberWithEmail on MemberWithEmail {
      box
      email
      member {
        ...boxMember
      }
    }
    ${USER_INFO.boxMember}
  `,
};

export const CARD_DATES = {
  date: gql`
    fragment date on cardDate {
      id
      name
      color
      date
      time
      endDate
      endTime
      hasDueDate
    }
  `,
};

export const PROPERTY_FRAGMENT = {
  property: gql`
    fragment property on Property {
      id
      name
      type
      value
      addInTotal
      items {
        id
        value
        isSelected
      }
    }
  `,
  item: gql`
    fragment item on PropertyItem {
      id
      value
    }
  `,
};

export const MUTATION_MESSAGE = {
  mutationMessage: gql`
    fragment mutationMessage on MutationMessage {
      type
      message
    }
  `,
};

export const CARD_FRAGMENT = {
  card: gql`
    fragment cardSummary on Card {
      id
      title
      subtitle
      workflow
      workflowGroup
      category
      box
      members {
        id
        firstname
        lastname
        username
      }
      dates {
        ...date
      }
      labels {
        id
        color
        name
        icon
      }
      attachments {
        file
      }
      checklists {
        ...checklist
      }
      properties {
        ...property
      }
      isTemplate
      isArchive
      isPinned
    }
    ${CHECKLIST_FRAGMENT.checklist}
    ${PROPERTY_FRAGMENT.property}
    ${CARD_DATES.date}
  `,
  cardOverview: gql`
    fragment card on Card {
      id
      title
      subtitle
      box
      workflow
      workflowGroup
      members {
        id
        firstname
        lastname
        username
      }
      dates {
        ...date
      }
      labels {
        color
        id
        name
        icon
      }
      attachments {
        file
      }
      checklists {
        items {
          select
          name
        }
      }
      properties {
        name
        value
        items {
          id
          value
          isSelected
        }
      }
      isTemplate
      isArchive
      hasDescription
      isPinned
    }
    ${CARD_DATES.date}
  `,

  cardOverviewOnColumnExpansion: gql`
    fragment cardInExpandedView on Card {
      id
      title
      subtitle
      box
      workflow
      workflowGroup
      category
      members {
        id
        firstname
        lastname
        username
      }
      labels {
        color
        id
        name
        icon
      }
      checklists {
        items {
          select
          name
        }
      }
      dates {
        ...date
      }
      isTemplate
      isArchive
      isPinned
    }
    ${CARD_DATES.date}
  `,
};

export const ADMIN_PANEL_FRAGMENT = {
  adminConfig: gql`
    fragment adminConfig on AdminConfig {
      ldapConfig {
        adminGroup
        userGroup
        userIdentifier
        serviceAccount
        whitelistedIp
        baseDN {
          value
          x500type
        }
        emailDomains
        domainController
      }

      generalConfig {
        isRegistrationAllowed
        isInviteByNameAllowed
        adLoginEnabled
        forceTwoFactor
        attachmentSizePerCard
        passwordExpiry
        alertMessage {
          show
          messages {
            locale
            message
          }
        }
      }
    }
  `,

  generalConfig: gql`
    fragment generalConfig on AdminConfig {
      generalConfig {
        isRegistrationAllowed
        isInviteByNameAllowed
        adLoginEnabled
        forceTwoFactor
        attachmentSizePerCard
        passwordExpiry
        alertMessage {
          show
          messages {
            locale
            message
          }
        }
      }
    }
  `,
};

export const CARD_COMMENT_FRAGMENT = {
  comment: gql`
    fragment comment on Comment {
      id
      user {
        id
        firstname
        lastname
        isDeleted
      }
      message
      timestamp
      isEdited
    }
  `,
};

export const USER_SPECIFIC_BOX_SETTINGS = {
  settings: gql`
    fragment settings on UserSpecificBoxSettings {
      isMuted
      color
    }
  `,
};

export const ACTIVITY_FRAGMENT = {
  activity: gql`
    fragment activity on Activity {
      id
      messageType
      data
      boxId
      cardId
      createdAt
      user {
        firstname
        lastname
        id
        isDeleted
      }
    }
  `,
};

export const ACTIVITY_CHUNK_FRAGMENT = {
  activityChunk: gql`
    fragment activityChunk on ActivityEdge {
      totalCount
      node {
        ...activity
      }
    }
    ${ACTIVITY_FRAGMENT.activity}
  `,
};

export const CARD_CHUNK_FRAGMENT = {
  cardChunk: gql`
    fragment cardChunk on CardEdge {
      totalCount
      node {
        ...card
      }
    }
    ${CARD_FRAGMENT.cardOverview}
  `,
};

export const CUSTOM_DESCRIPTION_FRAGMENT = {
  customDescription: gql`
    fragment customDescription on CustomDescription {
      id
      title
      description
    }
  `,
};
