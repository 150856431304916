import gql from 'graphql-tag';
import { CHECKLIST_FRAGMENT } from './fragments';

export const FETCH_CHECKLISTS_FOR_CARD = gql`
query getCardById($id: ID!) {
  getCardById(id: $id) {
    checklists {
      ...checklist
    }
  }
}
${CHECKLIST_FRAGMENT.checklist}
`;

export const ADD_CHECKLIST_TO_CARD = gql`
mutation addChecklistToCard($cardId: ID!, $title: String!) {
  addChecklistToCard(cardId: $cardId, title: $title)
}
`;

export const ADD_ITEM_TO_CHECKLIST = gql`
mutation addItemToChecklist($cardId: ID!, $checklistId: ID!, $newItem: String!) {
  addItemToChecklist(cardId: $cardId, checklistId: $checklistId, newItem: $newItem)
}
`;

export const UPDATE_ITEM_IN_CHECKLIST = gql`
mutation updateItemInChecklist($cardId: ID!, $checklistId: ID!, $itemId: ID!, $itemName: String!, $itemSelected: Boolean!) {
  updateItemInChecklist(cardId: $cardId, checklistId: $checklistId, itemId: $itemId, itemName: $itemName, itemSelected: $itemSelected)
}
`;

export const UPDATE_CHECKLIST_ITEM_POSITION = gql`
mutation updateChecklistItemPosition($cardId: ID!, $checklistId: ID!, $itemId: ID!, $position: Int!, $checklistItem: ChecklistItemInput!) {
  updateChecklistItemPosition(cardId: $cardId, checklistId: $checklistId, itemId: $itemId, position: $position, checklistItem: $checklistItem)
}
`;

export const UPDATE_CHECKLISTS_POSITION = gql`
mutation updateChecklistsPosition($cardId: ID!, $checklistId: ID!, $position: Int!, $checklist: ChecklistInput!) {
  updateChecklistsPosition(cardId: $cardId, checklistId: $checklistId, position: $position, checklist: $checklist)
}
`;

export const UPDATE_CHECKLIST_TITLE = gql`
mutation updateChecklistTitle($cardId: ID!, $checklistId: ID!, $newTitle: String!) {
  updateChecklistTitle(cardId: $cardId, checklistId: $checklistId, newTitle: $newTitle)
}
`;

export const UPDATE_SELECTED_ITEM = gql`
mutation updateSelectedItem($cardId: ID!, $checklistId: ID!, $selectedItem: ID!) {
  updateSelectedItem(cardId: $cardId, checklistId: $checklistId, selectedItem: $selectedItem)
}
`;

export const DELETE_CHECKLIST_FROM_CARD = gql`
mutation deleteChecklistFromCard($cardId: ID!, $checklistId: ID!) {
  deleteChecklistFromCard(cardId: $cardId, checklistId: $checklistId)
}
`;

export const DELETE_ITEM_FROM_CHECKLIST = gql`
mutation deleteItemFromChecklist($cardId: ID!, $checklistId: ID!, $itemId: ID!) {
    deleteItemFromChecklist(cardId: $cardId, checklistId: $checklistId, itemId: $itemId)
}
`;

export const SUBSCRIBE_CHECKLIST_ADDED = gql`
subscription checklistAdded($cardId: ID!) {
  checklistAdded(cardId: $cardId) {
    ...checklist
  }
}
${CHECKLIST_FRAGMENT.checklist}
`;

export const SUBSCRIBE_CHECKLIST_UPDATED = gql`
subscription checklistUpdated($cardId: ID!) {
  checklistUpdated(cardId: $cardId) {
    ...checklist
  }
}
${CHECKLIST_FRAGMENT.checklist}
`;

export const SUBSCRIBE_CHECKLIST_DELETED = gql`
subscription checklistDeleted($cardId: ID!) {
    checklistDeleted(cardId: $cardId) {
    ...checklist
  }
}
${CHECKLIST_FRAGMENT.checklist}
`;

export const SUBSCRIBE_CHECKLIST_MOVED = gql`
subscription checklistMoved($cardId: ID!)  {
  checklistMoved(cardId: $cardId) {
    checklistId
    position
    checklist {
      ...checklist
    }
  }
}
${CHECKLIST_FRAGMENT.checklist}
`;
