<template>
  <v-col
    :ref="`boxesContainer-${groupId}`"
    class="d-flex flex-wrap mb-6"
    :class="$vuetify.breakpoint.smAndDown ? 'justify-center' : ''"
    cols="12"
    :id="groupId"
  >
    <!-- All The Boxes -->
    <v-card
      v-for="{ box, isMuted, color } in boxes"
      :key="box.id"
      @click="routeToCards(box)"
      :style="`background-color: ${color} !important;`"
      class="boxes mx-3 mb-4"
      min-width="250"
      max-width="250"
      max-height="135"
      :id="box.id"
    >
      <v-card-title id="boxTitle" primary-title class="justify-center">
        <p
          class="title-1 font-weight-bold text-truncate"
          :class="color ? `${fontColor(color)}--text` : 'title-blue'"
        >
          {{ box.name }}
        </p>
      </v-card-title>
      <v-card-actions>
        <v-tooltip bottom v-if="!disable">
          <template #activator="{ on }">
            <v-btn
              :color="color ? `${fontColor(color)}` : 'icon'"
              @click.stop="toggleMuteBox({ boxId: box.id, isMuted: isMuted })"
              @mousedown.stop
              icon
              v-on="on"
            >
              <v-icon small>
                {{ isMuted ? "fa-eye-slash" : "fa-eye" }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ isMuted ? $t("unmute_box") : $t("mute_box") }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="!disable">
          <template #activator="{ on }">
            <v-btn
              @click.stop=""
              icon
              class="handle"
              style="cursor: move"
              v-on="on"
              :color="color ? `${fontColor(color)}` : ''"
              :ripple="false"
            >
              <v-icon small> fa-arrows-alt </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("move_box") }}</span>
        </v-tooltip>
        <v-spacer />
        <!-- Box Users -->
        <wBoxUsers :boxMembers="box.members" />

        <!-- Box Menu -->
        <wBoxMenu :box="box" :boxColor="color" />
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import { mapActions } from 'vuex';
import * as types from '@/stores/types';
import wBoxMenu from '@/components/box/wBoxMenu.vue';
import colorController from '@/scripts/colorController';
import wBoxUsers from '@/components/box/wBoxUsers.vue';

/**
 * @group Box
 *  A component containing the (accepted) Boxes with
 *  edit and delete feature for each Box
 * @module components/box/wBoxes.vue
 *
 * @param {Array} boxes - The Boxes details
 */
export default {
  name: 'wBox',

  components: {
    wBoxMenu,
    wBoxUsers,
  },

  props: {
    boxes: {
      type: Array,
      default: () => [],
    },

    // disable dragging and mute option for archived cards
    disable: {
      type: Boolean,
      default: false,
    },

    groupId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      boxContainer: null,
    };
  },

  async mounted() {
    // Apply the sortableJS library whenever the list changes
    this.boxContainer = this.$refs[`boxesContainer-${this.groupId}`];
    this.applySortable(this.boxContainer);
  },

  methods: {
    ...mapActions({
      selectBox: types.SET_SELECTED_BOX,
      toggleMuteBox: types.MUTE_BOX,
      changeBoxPosition: types.CHANGE_BOX_POSITION,
      moveBoxBetweenGroups: types.MOVE_BOX_BETWEEN_GROUPS,
      fetchBoxes: types.FETCH_BOXES,
    }),

    /**
     * @vuese
     * Determines whether the color of the text
     * should be black or white
     */
    fontColor(color) {
      if (colorController.shouldIUseDark(color)) {
        return 'black';
      }
      return 'white';
    },

    /**
     * @vuese
     * This method is to route to Cards Screen
     * @arg Selected Box
     */
    async routeToCards(box) {
      await this.selectBox(box).then(() => this.$router.push(`/Workflow/${box.id}`));
    },

    applySortable(list) {
      this.$sortable.create(list, {
        animation: false,
        group: 'boxes',
        handle: '.handle',
        groupId: this.groupId,
        onUpdate: async (event) => {
          const { newIndex, oldIndex } = event;
          const groupId = event.from.id;
          await this.changeBoxPosition({ newIndex, oldIndex, groupId });
          await this.fetchBoxes();
        },
        onAdd: async (event) => {
          const boxId = event.item.id;
          const from = event.from.id;
          const to = event.to.id;
          await this.moveBoxBetweenGroups({
            boxId,
            oldGroupId: from,
            newGroupId: to,
            newPosition: event.newIndex,
          });
          await this.fetchBoxes();
        },
      });
    },
  },
};
</script>
