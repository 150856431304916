/**
 * Adds an array of cards to an array of columns
 */
export const addCardsToColumns = (columns, cards, identifier, checkColumn = true) => {
  const columnIds = new Set();

  cards.forEach((card) => {
    if (checkColumn) columnIds.add(card[identifier].toString());
  });

  return (columns || []).map((column) => {
    if (!checkColumn || columnIds.has(column.id)) {
      const addedCards = (cards || []).filter((card) => {
        if (Array.isArray(card[identifier])) {
          (card[identifier] || []).map((x) => {
            if (x.id.toString() === column.id.toString()) {
              column.cards.push(card);
            }
            return x;
          });
        } else if (card[identifier].toString() === column.id.toString()) {
          column.cards.push(card);
        }
        return { ...column, cards: addedCards };
      });
    }
    return column;
  });
};
/**
 * Removes an array of cards from an array of columns
 */
export const filterCardsFromColumns = (columns, cards, identifier, checkColumn = true) => {
  const cardIds = new Set();
  const columnIds = new Set();

  cards.forEach((card) => {
    cardIds.add(card.id.toString());
    if (checkColumn) columnIds.add(card[identifier].toString());
  });

  return (columns || []).map((column) => {
    if (!checkColumn || columnIds.has(column.id)) {
      const filteredCards = (column.cards || []).filter(({ id }) => !cardIds.has(id.toString()));

      return { ...column, cards: filteredCards };
    }
    return column;
  });
};

/**
 * Finds out if the mimeType of an attachment is
 *  1. non-standard (x-)
 *  2. vendor specific (vnd)
 *  3. zip file
 *
 * @param {string} mimeType the MIME of an attachment
 * that was uploaded to a card
 *
 * @returns {Boolean} false if MIME includes any of the above
 * 3 given conditions
 */
export const isMIMEOk = (mimeType) => !(mimeType.includes('x-') || mimeType.includes('vnd') || mimeType.includes('zip'));

/**
   * Remove '__typename'
   *
   * @param {object} Object The Object
   */
export const stripTypeNameFromObject = (object) => {
  // eslint-disable-next-line consistent-return
  const cleaned = JSON.parse(JSON.stringify(object, (name, val) => {
    if (name === '__typename') {
      // eslint-disable-next-line no-param-reassign
      delete val[name];
    } else {
      return val;
    }
  }));
  return cleaned;
};

export const PropertyType = {
  LIST: 'LIST',
  NUMBER: 'NUMBER',
};
