import { onError } from 'apollo-link-error';

const errorHandlers = {};

// we need to ignore the first network error since it is not accurate and
// apollo need time to stablish the connection
let countOfNetworkError = 0;

const registerErrorHandler = (errorCode, errorHandler) => {
  if (errorHandlers[errorCode] !== undefined) {
    errorHandlers[errorCode].push(errorHandler);
  } else {
    errorHandlers[errorCode] = [errorHandler];
  }
};

/**
 * All GraphQL errors that
 * happen throughout the
 * app land here
 */
const errorLink = onError(({
  networkError, graphQLErrors,
}) => {
  try {
    if (graphQLErrors) {
      const errors = graphQLErrors
        .filter(({ extensions }) => errorHandlers[extensions.code] !== undefined);

      errors.forEach((error) => {
        errorHandlers[error.extensions.code].forEach((handler) => {
          handler(error.extensions);
        });
      });
    }
    if (networkError) {
      if (errorHandlers.NETWORK_ERROR && countOfNetworkError > 0) {
        errorHandlers.NETWORK_ERROR[0]();
      }
      countOfNetworkError += 1;
    }
  } catch (e) {
    console.error(e);
  }
});

export { errorLink, registerErrorHandler };
