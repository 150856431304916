import gql from 'graphql-tag';
import { MUTATION_MESSAGE, USER_INFO } from './fragments';

// ----------------QUERIES------------------

export const GET_RECOVERY_TOKEN_VALID = gql`
  query($token: String!){
    recoveryTokenIsValid(token: $token)
  }
`;

// ----------------MUTATIONS------------------

export const CREATE_RECOVERY_REQUEST = gql`
mutation($email: String!) {
    createRecoveryRequest(email: $email) {
    ...mutationMessage
  }
}
${MUTATION_MESSAGE.mutationMessage}
`;

export const PERFORM_RECOVERY = gql`
mutation($token: String!, $newPassword: String!) {
    performRecovery(token: $token, newPassword: $newPassword) {
    message{
      ...mutationMessage
    }
    user {
      ...user
    }
  }
}
${MUTATION_MESSAGE.mutationMessage}
${USER_INFO.user}
`;
