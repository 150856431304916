import gql from 'graphql-tag';

// ----------------QUERIES------------------

export const GET_CARD_MEMBERS = gql`
  query getCardMembers($cardId: ID!) {
    getCardMembers(cardId: $cardId) {
        id
        firstname
        lastname
        avatarId
    }
  }
`;

// ----------------MUTATIONS------------------

export const ADD_CARD_MEMBERS = gql`
  mutation($cardId: ID!, $users: [ID]!) {
    addCardMembers(cardId: $cardId, users: $users)
  }
`;

export const REMOVE_CARD_MEMBER = gql`
  mutation($cardId: ID!, $userId: ID!) {
    removeCardMember(cardId: $cardId, userId: $userId)
  }
`;

// ----------------SUBSCRIPTIONS------------------

export const SUBSCRIBE_CARD_MEMBERS_CHANGED = gql`
  subscription cardMembersChanged($cardId: ID!) {
    cardMembersChanged(cardId: $cardId) {
      id
      firstname
      lastname
      avatarId
    }
  }
`;
