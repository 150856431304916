import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VuetifyConfirm from 'vuetify-confirm';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader

Vue.use(Vuetify);

const custom = {
  light: {
    text: '#003154',
    button: '#33B2E9',
    button_as_text: '#33B2E9',
    icon: '#33B2E9',
    card: '#CCECFA',
    column: '#CCECFA',
    header: '#009EE3',
  },

  dark: {
    text: '#FFFFFF',
    button: '#1d3e4c',
    button_as_text: '#33B2E9',
    icon: '#33B2E9',
    card: '#1d3e4c',
    column: '#1d3e4c',
    header: '#0083bc',
  },
};

// apply the theme
const vuetify = new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.blue.darken2,
        secondary: colors.grey.darken3,
        accent: colors.blue.accent1,
        error: colors.red.accent2,
        info: custom.light.icon,
        success: colors.green,
        warning: colors.orange.darken1,
        toolbar: colors.white,
        bw: colors.black,

        // Custom colors
        card: custom.light.card,
        text: custom.light.text,
        icon: custom.light.icon,
        button: custom.light.button,
        button_as_text: custom.light.button_as_text,
        column: custom.light.column,
        header: custom.light.header,
      },
      dark: {
        primary: colors.blue.darken2,
        secondary: colors.grey.darken3,
        accent: colors.blue.accent1,
        error: colors.red.accent2,
        info: custom.dark.icon,
        success: colors.green.lighten1,
        warning: colors.orange.darken1,
        toolbar: colors.grey.darken3,
        bw: colors.white,

        // Custom colors
        card: custom.dark.card,
        text: custom.dark.text,
        icon: custom.dark.icon,
        button: custom.dark.button,
        button_as_text: custom.dark.button_as_text,
        column: custom.dark.column,
        header: custom.dark.header,
      },
    },
    icons: {
      iconfont: 'fas',
    },
    options: {
      customProperties: true, // enables usage of colors in css
    },
  },
});

Vue.use(VuetifyConfirm, {
  vuetify,
  width: 400,
  property: '$confirm',
  persistent: true,
});

export default vuetify;
