import * as types from '@/stores/types';
import { apolloClient } from '@/apolloConfig';
import {
  GET_ALL,
  GET_ALL_NAMES_IDS_CARD_IDS,
  ADD,
  UPDATE,
  MOVE_CARD,
  DELETE,
  MOVE_CATEGORY,
} from '@/queries/category';
import { addCardsToColumns, filterCardsFromColumns } from '@/scripts/helper';

/**
 * GETTERS
 */
const getters = {
  [types.GET_ALL_CATEGORIES]: (state) => state.categories,
  [types.GET_ALL_CATEGORIES_NAME_ID_CARD_IDS]: (state) => state.categoriesNameIdsCardIds,
};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_FETCH_CATEGORY]: (state, categories) => {
    const current = state;
    current.categories = categories;
  },
  [types.MUTATE_FETCH_CATEGORY_NAME_IDS_CARD_IDS]: (state, categories) => {
    const current = state;
    current.categoriesNameIdsCardIds = categories;
  },

  [types.MUTATE_ADD_CATEGORY]: (state, categoryAdded) => {
    const current = state;

    // copying object to create a new reference in memory
    const categoryAddedCopy = JSON.parse(JSON.stringify(categoryAdded));

    current.categories = [...current.categories, categoryAdded];
    current.categoriesNameIdsCardIds = [...current.categoriesNameIdsCardIds, categoryAddedCopy];
  },

  [types.MUTATE_UPDATE_CATEGORY]: (state, categoryUpdated) => {
    const current = state;
    const index = current.categories.findIndex((w) => w.id === categoryUpdated.id);
    if (index !== -1) {
      current.categories[index].name = categoryUpdated.name;
      current.categoriesNameIdsCardIds[index].name = categoryUpdated.name;
    }
  },

  [types.MUTATE_DELETE_CATEGORY]: (state, categoryRemoved) => {
    const current = state;
    const index = current.categories.findIndex((w) => w.id === categoryRemoved.id);
    if (index !== -1) {
      current.categories.splice(index, 1);
      current.categoriesNameIdsCardIds.splice(index, 1);
    }
  },

  [types.MUTATE_ADD_CARD_TO_CATEGORY]: (state, card) => {
    const current = state;
    const { categories, categoriesNameIdsCardIds } = current;
    const { categoryPosition } = card;

    for (let i = 0; i < categories.length; i += 1) {
      if (categories[i].id === card.category) {
        /**
         * check if @param categoryPosition
         * is an unsigned integer, then splice on top
         */
        if (/^\d+$/.test(categoryPosition)) {
          categories[i].cards.splice(categoryPosition, 0, card);

          if (categoriesNameIdsCardIds.length === categories.length) {
            categoriesNameIdsCardIds[i].cards.splice(categoryPosition, 0, card.id);
          }
        } else {
          categories[i].cards.push(card);

          if (categoriesNameIdsCardIds.length === categories.length) {
            categoriesNameIdsCardIds[i].cards.push(card.id);
          }
        }

        // card was either spliced
        // or pushed so we break loop
        break;
      }
    }
  },

  [types.MUTATE_UPDATE_CARD_IN_CATEGORY]: (state, cardUpdated) => {
    if (cardUpdated.isTemplate) {
      return;
    }
    const current = state;
    const { categories, categoriesNameIdsCardIds } = current;

    for (let i = 0; i < categories.length; i += 1) {
      if (categories[i].id === cardUpdated.category) {
        const index = categories[i].cards.findIndex((c) => c.id === cardUpdated.id);
        if (index !== -1) {
          categories[i].cards.splice(index, 1, cardUpdated);

          if (categoriesNameIdsCardIds.length === categories.length) {
            categoriesNameIdsCardIds[i].cards.splice(index, 1, cardUpdated.id);
          }
        }
      }
    }
  },

  [types.MUTATE_ADD_CARDS_TO_CATEGORY]: (state, cards) => {
    const current = state;

    current.categories = addCardsToColumns(current.categories, cards, 'category');
    current.categoriesNameIdsCardIds = addCardsToColumns(current.categoriesNameIdsCardIds, cards, 'category');
  },

  [types.MUTATE_DELETE_CARD_FROM_CATEGORY]: (state, cardsRemoved) => {
    const current = state;

    current.categories = filterCardsFromColumns(current.categories, cardsRemoved, 'category');
    current.categoriesNameIdsCardIds = filterCardsFromColumns(current.categoriesNameIdsCardIds, cardsRemoved, 'category');
  },

  [types.MUTATE_SUBSCRIBE_MOVE_CARD_IN_CATEGORY]: (state, cardMoved) => {
    const current = state;
    const { categories, categoriesNameIdsCardIds } = current;
    const {
      from,
      to,
      card,
      position,
    } = cardMoved;

    for (let i = 0; i < categories.length; i += 1) {
      if (categories[i].id === from) {
        const index = categories[i].cards.findIndex((c) => c.id === card.id);
        if (index !== -1) {
          categories[i].cards.splice(index, 1);

          if (categoriesNameIdsCardIds.length === categories.length) {
            categoriesNameIdsCardIds[i].cards.splice(index, 1);
          }
        }
      }
    }

    for (let i = 0; i < categories.length; i += 1) {
      if (categories[i].id === to) {
        categories[i].cards.splice(position, 0, card);

        if (categoriesNameIdsCardIds.length === categories.length) {
          categoriesNameIdsCardIds[i].cards.splice(position, 0, card.id);
        }
      }
    }
  },
  [types.MUTATE_SUBSCRIBE_MOVE_CATEGORY]: (state, categoryMoved) => {
    const current = state;
    const { categories, categoriesNameIdsCardIds } = current;
    const {
      newPosition,
      oldPosition,
    } = categoryMoved;

    const category = categories[oldPosition];

    categories.splice(oldPosition, 1);
    categories.splice(newPosition, 0, category);

    categoriesNameIdsCardIds.splice(oldPosition, 1);
    categoriesNameIdsCardIds.splice(newPosition, 0, category);
  },

  [types.MUTATE_CATEGORY_PROPERTY_SUM_UPDATE]: (state, updatedPropertiesSum) => {
    const current = state;
    const index = current.categories.findIndex((w) => w.id === updatedPropertiesSum.id);

    if (index !== -1) {
      const oldSize = current.categories[index].propertiesSum ? current.categories[index].propertiesSum.length : 0;
      current.categories[index].propertiesSum.splice(0, oldSize);

      updatedPropertiesSum.propertiesSum.map((property, idx) => current.categories[index].propertiesSum.splice(idx, 0, property));
    }
  },
};

/**
 * ACTIONS
 */
const actions = {
  [types.FETCH_CATEGORY]: async ({ commit }, boxId) => {
    if (boxId === undefined) {
      throw new Error('Box id is empty.');
    }
    const { data, loading } = await apolloClient
      .query({
        query: GET_ALL,
        variables: { boxId },
      });
    commit(types.MUTATE_FETCH_CATEGORY, data.getCategoriesByBoxId);
    // Loading switch to false in order to show the category page
    commit(types.MUTATE_PAGE_LOADING, loading);
  },

  [types.FETCH_CATEGORY_WITH_ID_NAME_CARD_IDS]: async ({ commit }, boxId) => {
    if (boxId === undefined) {
      throw new Error('Box id is empty.');
    }
    const { data, loading } = await apolloClient.query({
      query: GET_ALL_NAMES_IDS_CARD_IDS,
      variables: { boxId },
    });
    commit(types.MUTATE_FETCH_CATEGORY_NAME_IDS_CARD_IDS, data.getCategoriesByBoxId);
    // Loading switch to false in order to show the category page
    commit(types.MUTATE_PAGE_LOADING, loading);
  },

  [types.ADD_CATEGORY]: (_, { name, boxId }) => {
    if (boxId === undefined && name === undefined) {
      throw new Error('Error boxId and workload name are mandatory');
    }
    apolloClient
      .mutate({
        mutation: ADD,
        variables: {
          name,
          boxId,
        },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.UPDATE_CATEGORY]: (_, { id, name }) => {
    if (id === undefined || name === undefined) {
      throw new Error('Error category Id and workload name are mandatory');
    }
    apolloClient
      .mutate({
        mutation: UPDATE,
        variables: {
          id,
          name,
        },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.MOVE_CARD_IN_CATEGORY]: ({ dispatch }, {
    from, to, card, position,
  }) => {
    apolloClient
      .mutate({
        mutation: MOVE_CARD,
        variables: {
          from,
          to,
          card,
          position,
        },
      })
      .catch((err) => {
        console.error(err);
      });
    dispatch(types.NOTIFY_CARD_WATCHERS_ON_CHANGE, card);
  },
  [types.MOVE_CATEGORY_IN_BOX]: (_, {
    box, newPosition, categoryId, oldPosition,
  }) => {
    apolloClient
      .mutate({
        mutation: MOVE_CATEGORY,
        variables: {
          box,
          newPosition,
          categoryId,
          oldPosition,
        },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.SUBSCRIBE_CATEGORY_ADD]: ({ commit }, newCategory) => {
    commit(types.MUTATE_ADD_CATEGORY, newCategory);
  },

  [types.SUBSCRIBE_CATEGORY_UPDATE]: ({ commit }, category) => {
    commit(types.MUTATE_UPDATE_CATEGORY, category);
  },

  [types.SUBSCRIBE_CATEGORY_DELETE]: ({ commit }, category) => {
    commit(types.MUTATE_DELETE_CATEGORY, category);
  },

  [types.DELETE_CATEGORY]: (_, category) => {
    apolloClient
      .mutate({
        mutation: DELETE,
        variables: { id: category },
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.ADD_CARD_TO_CATEGORY]: ({ commit }, card) => {
    commit(types.MUTATE_ADD_CARD_TO_CATEGORY, card);
  },

  [types.UPDATE_CARD_IN_CATEGORY]: ({ commit }, card) => {
    commit(types.MUTATE_UPDATE_CARD_IN_CATEGORY, card);
  },

  [types.DELETE_CARD_FROM_CATEGORY]: ({ commit }, card) => {
    commit(types.MUTATE_REMOVE_CARDS_FROM_SELECTION, card.map(({ id }) => id));
    commit(types.MUTATE_DELETE_CARD_FROM_CATEGORY, card);
  },

  [types.SUBSCRIBE_MOVE_CARD_IN_CATEGORY]: ({ commit }, card) => {
    commit(types.MUTATE_SUBSCRIBE_MOVE_CARD_IN_CATEGORY, card);
    commit(types.MUTATE_CARD_HEADER_CATEGORY, card);
  },

  [types.SUBSCRIBE_MOVE_CATEGORY]: ({ commit }, category) => {
    commit(types.MUTATE_SUBSCRIBE_MOVE_CATEGORY, category);
  },
  [types.ARCHIVE_CARD_FROM_CATEGORY]: ({ commit }, cards) => {
    commit(types.MUTATE_REMOVE_CARDS_FROM_SELECTION, cards.map(({ id }) => id));
    commit(types.MUTATE_DELETE_CARD_FROM_CATEGORY, cards);
  },

  [types.UNARCHIVE_CARD_IN_CATEGORY]: ({ commit }, cards) => {
    commit(types.MUTATE_ADD_CARDS_TO_CATEGORY, cards);
  },

  [types.SUBSCRIBE_CATEGORY_PROPERTY_SUM_UPDATE]: ({ commit }, updatedPropertiesSum) => {
    commit(types.MUTATE_CATEGORY_PROPERTY_SUM_UPDATE, updatedPropertiesSum);
  },
};

/**
 * STATES
 */
const state = {
  categories: [{
    category: {
      cards: [],
    },
  }],

  categoriesNameIdsCardIds: [{
    category: {
      cards: [],
    },
  }],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
