<template>
      <v-card>
        <v-card-title>
          {{ $t('sort_groups') }}
          <v-spacer />
          <v-btn
            @click="$emit('close')"
            text
            icon
          >
            <v-icon size="22">
              fa-times
            </v-icon>
            </v-btn>
        </v-card-title>
        <v-divider />
        <!-- GROUPS -->
        <div class="d-flex justify-space-between align-center" >
          <v-list dense :id="`groups_panel`">
            <v-list-item :id="group.id"
              v-for="(group) in groups"
              :key="group.id">
              <v-list-item-content>
                <span :class="{
                    'font-weight-regular title-1 custom-text-wrap': $vuetify.breakpoint.smAndUp,
                    'font-weight-regular xsTitle-1': $vuetify.breakpoint.xsOnly
                    }">
                  <v-btn icon small>
                    <v-icon small class="handleGroups" style="cursor: move;">
                      fa-arrows-alt-v
                    </v-icon>
                  </v-btn>
                    {{ group.name }}
                </span>
              </v-list-item-content>
              </v-list-item>
          </v-list>
        </div>
      </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import * as types from '../../stores/types';

export default {
  name: 'WSortGroupsForBoxes',

  components: {
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },

    groups: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {};
  },

  mounted() {
    this.$nextTick(() => {
      this.applySortableOnGroups();
    });
  },

  methods: {
    ...mapActions({
      updateBoxGroupPosition: types.UPDATE_BOX_GROUP_POSITION,
      fetchBoxes: types.FETCH_BOXES,
    }),

    applySortableOnGroups() {
      const groups = document.getElementById('groups_panel');
      const self = this;

      this.$sortable.create(groups, {
        animation: 50,
        handle: '.handleGroups',
        group: 'groups',
        delayOnTouchOnly: true,
        ghostClass: 'ghost-class',

        // important for drag otherwise the dragging animation glitches
        forceFallback: true,

        onUpdate: async (event) => {
          const { newIndex, item } = event;

          await self.updateBoxGroupPosition(
            {
              groupId: item.id,
              position: newIndex,
            },
          );

          await this.fetchBoxes();
        },
      });
    },

  },
};
</script>
