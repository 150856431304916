import { apolloClient } from '@/apolloConfig';
import {
  GET_BY_ID,
} from '@/queries/box';
import breadcrumbController from '@/scripts/breadcrumbController';
import * as types from '../types';

/**
 * GETTERS
 */
const getters = {
  [types.GET_BREADCRUMB]: (state) => state.breadcrumb,
};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_NEW_BREADCRUMB]: (state, payload) => {
    const current = state;
    const prevPath = state.breadcrumb;

    let path;
    if (!payload.box) {
      const { route } = payload;
      path = breadcrumbController.createPath(route);
    } else {
      const { box, route } = payload;
      path = breadcrumbController.createPath(route, box, prevPath);
    }

    current.breadcrumb = path;
  },
};

/**
 * ACTIONS
 */
const actions = {
  [types.ADD_NEW_BREADCRUMB]: ({ commit }, route) => {
    const { boxId } = route.params;
    if (boxId) {
      apolloClient
        .query({
          query: GET_BY_ID,
          variables: {
            id: boxId,
          },
        })
        .then(({ data, loading }) => {
          if (!loading) {
            const box = data.getBoxById;
            commit(types.MUTATE_NEW_BREADCRUMB, { box, route });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      commit(types.MUTATE_NEW_BREADCRUMB, { route });
    }
  },
};

/**
 * STATES
 */
const state = {
  breadcrumb: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
