import * as types from '@/stores/types';

/**
 * GETTERS
 */
const getters = {
  [types.GET_ERROR]: (state) => state.error,
  [types.GET_MESSAGE]: (state) => state.message,

};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_ERROR]: (state, error) => {
    const current = state;
    current.error = error;
  },

  [types.MUTATE_MESSAGE]: (state, message) => {
    const current = state;
    current.message = message;
  },
};

/**
 * Actions
 */
const actions = {
  [types.SET_ERROR]: ({ commit }, error) => {
    commit(types.MUTATE_ERROR, error);
  },

  [types.SET_MESSAGE]: ({ commit }, message) => {
    commit(types.MUTATE_MESSAGE, message);
  },
};

/**
 * States
 */
const state = {
  error: '',
  message: {
    type: 'ERROR',
    message: '',
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
