import * as types from '@/stores/types';
import { apolloClient } from '@/apolloConfig';
import {
  GET_ALL,
} from '@/queries/calendar';
import DateFormat from '@/lib/dateFormat';

const df = new DateFormat();

/**
 * GETTERS
 */
const getters = {
  [types.GET_CARDS_CALENDAR_VIEW]: (state) => state.cards,
  [types.GET_CALENDAR_VIEW_TYPE]: (state) => state.type,
  [types.GET_START_DATE]: (state) => state.startDate,
  [types.GET_WEEKDAY_TYPE]: (state) => state.weekday,
};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_FETCH_CARDS_CALENDAR_VIEW]: (state, cards) => {
    const current = state;
    current.cards = cards;
  },

  [types.MUTATE_CALENDAR_START_DATE]: (state, date) => {
    const current = state;
    current.startDate = date;
  },

  [types.MUTATE_CALENDAR_TYPE]: (state, type) => {
    const current = state;
    current.type = type;
  },

  [types.MUTATE_ADD_CARDS_TO_CALENDAR]: (state, { cards }) => {
    const current = state;
    current.cards = [...cards];
  },

  [types.MUTATE_ADD_CARD_TO_CALENDAR]: (state, { card }) => {
    const current = state;
    const { cards } = current;
    cards.push(card);
  },

  [types.MUTATE_UPDATE_CARD_IN_CALENDAR]: (state, cardUpdated) => {
    const current = state;
    const { cards } = current;
    const index = cards.findIndex((c) => c.id === cardUpdated.id);
    if (index !== -1) {
      cards.splice(index, 1, cardUpdated);
    }
  },

  [types.MUTATE_DELETE_CARD_FROM_CALENDAR]: (state, cardsRemoved) => {
    const current = state;
    const { cards } = current;
    cardsRemoved.forEach((removed) => {
      const index = cards.findIndex((c) => c.id === removed.id);
      if (index !== -1) {
        cards.splice(index, 1);
      }
    });
  },

  [types.MUTATE_WEEKDAY_TYPE]: (state, type) => {
    state.weekday = type;
  },

};

/**
 * ACTIONS
 */
const actions = {
  [types.FETCH_CARDS_CALENDAR_VIEW]: async ({ commit }, box) => {
    if (box === undefined) {
      throw new Error('Box id is empty.');
    }
    const { data, loading } = await apolloClient
      .query({
        query: GET_ALL,
        variables: { box },
      });
    commit(types.MUTATE_FETCH_CARDS_CALENDAR_VIEW, data.getDisplayCardsByBoxId);
    // Loading switch to false in order to show the workflow page
    commit(types.MUTATE_PAGE_LOADING, loading);
  },

  [types.CHANGE_CALENDAR_DATE]: ({ commit }, date) => {
    commit(types.MUTATE_CALENDAR_START_DATE, date);
  },

  [types.SUBSCRIBE_CARD_DATE_CHANGE]: ({ commit }, card) => {
    commit(types.MUTATE_UPDATE_CARD_IN_CALENDAR, card);
  },

  [types.SUBSCRIBE_ADD_CARD_TO_CALENDAR]: ({ commit }, card) => {
    commit(types.MUTATE_ADD_CARD_TO_CALENDAR, card);
  },

  [types.SUBSCRIBE_UPDATE_CARD_IN_CALENDAR]: ({ commit }, card) => {
    commit(types.MUTATE_UPDATE_CARD_IN_CALENDAR, card);
  },

  [types.SUBSCRIBE_DELETE_CARD_FROM_CALENDAR]: ({ commit }, card) => {
    commit(types.MUTATE_REMOVE_CARDS_FROM_SELECTION, card.map(({ id }) => id));
    commit(types.MUTATE_DELETE_CARD_FROM_CALENDAR, card);
  },
  [types.SUBSCRIBE_ARCHIVE_CARD_FROM_CALENDAR]: ({ commit }, card) => {
    commit(types.MUTATE_REMOVE_CARDS_FROM_SELECTION, card.map(({ id }) => id));
    commit(types.MUTATE_DELETE_CARD_FROM_CALENDAR, card);
  },
  [types.ARCHIVE_CARD_FROM_CALENDAR]: ({ commit }, cards) => {
    commit(types.MUTATE_REMOVE_CARDS_FROM_SELECTION, cards.map(({ id }) => id));
    commit(types.MUTATE_DELETE_CARD_FROM_CALENDAR, cards);
  },

  [types.UNARCHIVE_CARD_IN_CALENDAR]: ({ commit }, cards) => {
    commit(types.MUTATE_ADD_CARDS_TO_CALENDAR, { cards });
  },
};

/**
 * STATES
 */
const state = {
  cards: [],
  type: 'month',
  weekday: [1, 2, 3, 4, 5, 6, 0],
  startDate: df.dateTime(),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
