<template>
  <div id="app">
    <v-app>
      <wAboutUsHeader v-if="isInAboutUs" />
      <wHeader v-if="!isInAboutUs" />
      <wError />
      <v-main class="body-background">
        <transition name="fade">
          <router-view />
        </transition>
      </v-main>

      <wFooter v-if="isLoginOrSignUp" />
      <wReload />
      <wExpiringPasswordDialog :get-user="getUser" :general-config="generalConfig" />
    </v-app>
  </div>
</template>

<script>
import '@/assets/css/custom.css';
import 'typeface-roboto/index.css';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import wHeader from '@/components/header/wHeader.vue';
import wAboutUsHeader from '@/components/aboutUs/wAboutUsHeader.vue';
import wFooter from '@/components/common/wFooter.vue';
import wReload from '@/components/common/wReload.vue';
import wExpiringPasswordDialog from '@/components/common/wExpiringPasswordDialog.vue';
import * as types from '@/stores/types';
import { SUBSCRIBE_AVATAR_ADDED, SUBSCRIBE_AVATAR_DELETED } from '@/queries/avatar';
import { SUBSCRIBE_GENERAL_CONFIG_CHANGED } from '@/queries/adminPanel';
import { ABOUT_US } from '@/scripts/common';

export default {
  name: 'App',

  components: {
    wHeader,
    wFooter,
    wAboutUsHeader,
    wReload,
    wExpiringPasswordDialog,
  },

  computed: {
    ...mapGetters({
      isAuthenticated: types.GET_USER_AUTHENTICATION_STATUS,
      getSetting: types.GET_SETTING,
      getUser: types.GET_USER,
      generalConfig: types.GET_GENERAL_CONFIG,
    }),

    isLoginOrSignUp() {
      this.log('APP.Vue: called isLoginOrSignUp, current route is ', this.$route.name);
      return this.$route.name === 'Signup' || this.$route.name === 'Login';
    },

    /**
     * @vuese
     *
     * Informs if the users current route
     * is in the about us page
     *
     * @returns {Boolean} true if inside the AboutUs
     * route
     */
    isInAboutUs() {
      return this.$route.name === ABOUT_US;
    },
  },

  apollo: {
    $subscribe: {
      generalConfigChanged: {
        query: SUBSCRIBE_GENERAL_CONFIG_CHANGED,
        result({ data }) {
          const { generalConfigChanged } = data;
          const { generalConfig } = generalConfigChanged;

          this.mutateNewGeneralConfig(generalConfig);
        },
        skip() {
          return !this.isAuthenticated;
        },
      },

      avatarAdded: {
        query: SUBSCRIBE_AVATAR_ADDED,
        result({ data }) {
          this.addAvatarSub({ userId: data.avatarAdded.id, avatar: data.avatarAdded.avatarId });
          this.avatarExpiryCheckAction(data.avatarAdded.id);
        },
        skip() {
          return this.shouldSkipSubscription();
        },
      },
      avatarDeleted: {
        query: SUBSCRIBE_AVATAR_DELETED,
        result({ data }) {
          this.deleteAvatarSub(data.avatarDeleted.id);
          this.avatarExpiryCheckAction(data.avatarDeleted.id);
        },
        skip() {
          return this.shouldSkipSubscription();
        },
      },
    },
  },

  async created() {
    await this.fetchGeneralConfig();
    const setting = await this.fetchUser().catch(() => {});
    if (setting) {
      this.$vuetify.theme.dark = setting.darkTheme;
    }
    this.$dateFormat.setLocale(this.getSetting.locale);
  },

  watch: {
    isAuthenticated() {
      this.log('watch isAuthenticated is called');
      if (this.isAuthenticated) {
        if (this.getSetting.darkTheme) {
          this.$vuetify.theme.dark = this.getSetting.darkTheme;
        }
      } else {
        this.$vuetify.theme.dark = false;
      }
    },
  },

  methods: {
    ...mapActions({
      fetchUser: types.FETCH_USER,
      fetchGeneralConfig: types.FETCH_GENERAL_CONFIG,
      addAvatarSub: types.SUBSCRIBE_AVATAR_ADDED,
      deleteAvatarSub: types.SUBSCRIBE_AVATAR_DELETED,
      avatarExpiryCheckAction: types.AVATAR_EXPIRY_CHECK,
    }),

    ...mapMutations({
      mutateNewGeneralConfig: types.MUTATE_SUBSCRIBE_GENERAL_CONFIG_CHANGED,
    }),

    /**
     * @vuese
     *
     * Since we do not need to subscribe when
     * we are at the Login or Signup page,
     * therefore we determine whether the
     * subscription should be skipped or not
     */
    shouldSkipSubscription() {
      this.log(`shouldSkipSubscription is called an router ${this.$route.name}`);
      return (
        this.$route === null
        || this.$route.name === null
        || this.$route.name === 'Login'
        || this.$route.name === 'PasswordRecovery'
        || this.$route.name === 'VerifyEmail'
        || this.$route.name === 'Signup'
        || !this.isAuthenticated
      );
    },

    log(message) {
      this.$debugger('APP.vue', message);
    },
  },
};
</script>

<style>
* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
}
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.2s;
}
.fade-enter-active {
  transition-delay: 0.2s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.form-container {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  -webkit-box-shadow: 1px 2px 10px -3px rgba(0, 0, 0, 0.81);
  -moz-box-shadow: 1px 2px 10px -3px rgba(0, 0, 0, 0.81);
  box-shadow: 1px 2px 10px -3px rgba(0, 0, 0, 0.81);
}

.theme--dark .v-menu__content {
  background-color: #424242;
}
.theme--light .v-menu__content {
  background-color: #ffffff;
}
</style>
