<template>
  <wLocalization>
    <template #languageSwitcher="{ selectedLanguage, languages, changeLanguage }">
      <v-menu v-model="menu" button offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text icon color="white">
            {{ selectedLanguage }}
          </v-btn>
        </template>

        <v-card>
          <v-list v-for="(lang, i) in languages" :key="i" class="pa-0">
            <v-list-item @click="changeLanguage(lang)">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ lang.acronym }}</v-list-item-title>
                  <v-list-item-subtitle>{{ lang.name }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </template>
  </wLocalization>
</template>

<script>
import wLocalization from '@/components/common/wLocalization.vue';

/**
 * @group Header
 *
 * This component is to provide the HTML for
 * changing the locale in the header
 */
export default {
  name: 'WLocalization',

  components: {
    wLocalization,
  },

  data() {
    return {
      menu: false,
    };
  },
};
</script>
