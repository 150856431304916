import gql from 'graphql-tag';
import { ADMIN_PANEL_FRAGMENT, MUTATION_MESSAGE } from './fragments';

// ----------------QUERIES------------------

export const GET_ADMIN_CONFIG = gql`
  query {
    getAdminConfig {
      ...adminConfig
    }
  }
  ${ADMIN_PANEL_FRAGMENT.adminConfig}
`;

export const GET_GENERAL_CONFIG = gql`
  query {
    getGeneralConfig {
      isRegistrationAllowed
      isInviteByNameAllowed
      attachmentSizePerCard
      adLoginEnabled
      forceTwoFactor
      passwordExpiry
      alertMessage {
          show
          messages {
            locale
            message
          }
        }
    }
  }
`;

// ----------------MUTATIONS------------------

export const SET_ADMIN_CONFIG = gql`
  mutation changeAdminConfig($newValues: Object!) {
    changeAdminConfig(newValues: $newValues) {
      ...mutationMessage
    }
  }
  ${MUTATION_MESSAGE.mutationMessage}
`;

export const VERIFY_ADMIN = gql`
  mutation verifyAdmin($password: String!) {
    verifyAdmin(password: $password)
  }
`;

// ---------------SUBSCRIPTION----------------

export const SUBSCRIBE_ADMIN_CONFIG_CHANGED = gql`
  subscription {
    adminConfigChanged {
      ...adminConfig
    }
  }
  ${ADMIN_PANEL_FRAGMENT.adminConfig}
`;

export const SUBSCRIBE_GENERAL_CONFIG_CHANGED = gql`
  subscription {
    generalConfigChanged {
      ...generalConfig
    }
  }
  ${ADMIN_PANEL_FRAGMENT.generalConfig}
`;
