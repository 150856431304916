<template>
  <v-dialog
    persistent
    v-model="showChangePasswordDialog"
    width="450"
    transition="slide-y-reverse-transition"
  >
    <v-card>
      <v-card-text :class="$vuetify.theme.dark ? 'white--text' : 'black--text'">
        <wChangePassword
          @close="showChangePasswordDialog = false"
          :message="$t('password_expired')"
        >
          <template #close>
            <v-btn @click="showChangePasswordDialog = false" icon>
              <v-icon size="20">
                fa-times
              </v-icon>
            </v-btn>
          </template>
        </wChangePassword>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import wChangePassword from '@/components/userAccount/security/wChangePassword.vue';

/**
 * @group Common
 * A dialog box that shows a visual feedback for a user when
 * their password is expired and
 */

export default {
  name: 'wExpiringPasswordDialog',

  components: {
    wChangePassword,
  },

  props: {
    generalConfig: {
      type: Object,
      default: () => {},
    },

    getUser: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      showChangePasswordDialog: false,
    };
  },

  watch: {
    getUser: {
      handler() {
        // Find out if password is expired
        if (this.isPasswordExpired()) {
          this.showChangePasswordDialog = true;
        }
      },
      deep: true,
    },

    isAuthenticated() {
      if (this.isAuthenticated) {
        // Find out if user's password is expired/expiring
        if (this.getUser.passwordUpdatedOn && this.isPasswordExpired()) {
          this.showChangePasswordDialog = true;
        }
      }
    },
  },

  methods: {
    /**
     * @vuese
     *
     * Indicates whether the password
     * of the logged in user is expired or not
     *
     * @returns {Boolean}
     * - true if password is expired
     * - false if not expired OR no expiry date is set
     */
    isPasswordExpired() {
      const { passwordUpdatedOn } = this.getUser;
      const { passwordExpiry } = this.generalConfig;

      // Password is considered NOT expired if expiry is set to 0
      if (passwordExpiry === 0) return false;

      const isPasswordExpired = this.$dateFormat.daysUntilToday(passwordUpdatedOn) > passwordExpiry;

      return isPasswordExpired;
    },
  },
};
</script>
