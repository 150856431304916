import { apolloClient } from '@/apolloConfig';
import {
  ADD_CHECKLIST_TO_CARD,
  ADD_ITEM_TO_CHECKLIST,
  UPDATE_ITEM_IN_CHECKLIST,
  UPDATE_CHECKLIST_TITLE,
  DELETE_CHECKLIST_FROM_CARD,
  DELETE_ITEM_FROM_CHECKLIST,
  FETCH_CHECKLISTS_FOR_CARD,
  UPDATE_CHECKLIST_ITEM_POSITION,
  UPDATE_CHECKLISTS_POSITION,
} from '@/queries/checklist';
import * as types from '../types';

/**
 * GETTERS
 */
const getters = {
  [types.GET_CHECKLISTS_FOR_CARD]: ({ checklists }) => checklists,
  [types.GET_DIRTY_CHECKLISTS]: ({ dirtyChecklists }) => dirtyChecklists,

};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_FETCH_CHECKLISTS]: (state, newChecklists) => {
    const current = state;
    current.checklists = newChecklists;
  },

  [types.MUTATE_SUBSCRIBE_CHECKLIST_ADDED]: (state, newChecklist) => {
    const current = state;
    current.checklists = [...current.checklists, newChecklist];
  },

  [types.MUTATE_SUBSCRIBE_CHECKLIST_DELETED]: (state, deletedChecklist) => {
    const current = state;
    const position = current.checklists.findIndex((prop) => prop.id === deletedChecklist.id);
    current.checklists.splice(position, 1);
  },

  [types.MUTATE_SUBSCRIBE_CHECKLIST_UPDATED]: (state, updatedChecklist) => {
    const current = state;
    const position = current.checklists.findIndex((prop) => prop.id === updatedChecklist.id);
    current.checklists.splice(position, 1, updatedChecklist);
  },

  [types.MUTATE_SUBSCRIBE_CHECKLIST_MOVED]: (state, checklistMoved) => {
    const current = state;
    const {
      checklistId,
      position,
      checklist,
    } = checklistMoved;

    const oldPosition = current.checklists.findIndex((prop) => prop.id === checklistId);
    current.checklists.splice(oldPosition, 1);
    current.checklists.splice(position, 0, checklist);
  },

  [types.MUTATE_ADD_DIRTY_CHECKLISTS]: (state, id) => {
    const current = state;
    current.dirtyChecklists.push(id);
  },

  [types.MUTATE_REMOVE_DIRTY_CHECKLISTS]: (state, id) => {
    const current = state;

    const index = current.dirtyChecklists.findIndex((checklistId) => checklistId === id);

    if (index !== -1) current.dirtyChecklists.splice(index, 1);
  },
};

/**
 * ACTIONS
 */
const actions = {
  [types.ADD_CHECKLIST_TO_CARD]: ({ commit }, { cardId, title }) => {
    apolloClient.mutate({
      mutation: ADD_CHECKLIST_TO_CARD,
      variables: { cardId, title },
    }).catch((err) => { console.error(err); });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.ADD_ITEM_TO_CHECKLIST]: ({ commit }, { cardId, checklistId, newItem }) => {
    apolloClient.mutate({
      mutation: ADD_ITEM_TO_CHECKLIST,
      variables: { cardId, checklistId, newItem },
    }).catch((err) => { console.error(err); });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.UPDATE_ITEM_IN_CHECKLIST]: ({ commit }, {
    cardId, checklistId, itemId, itemName, itemSelected,
  }) => {
    apolloClient.mutate({
      mutation: UPDATE_ITEM_IN_CHECKLIST,
      variables: {
        cardId, checklistId, itemId, itemName, itemSelected,
      },
    }).catch((err) => { console.error(err); });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.UPDATE_CHECKLIST_TITLE]: ({ commit }, { cardId, checklistId, newTitle }) => {
    apolloClient.mutate({
      mutation: UPDATE_CHECKLIST_TITLE,
      variables: { cardId, checklistId, newTitle },
    }).catch((err) => { console.error(err); });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.DELETE_CHECKLIST_FROM_CARD]: ({ commit }, { cardId, checklistId }) => {
    apolloClient.mutate({
      mutation: DELETE_CHECKLIST_FROM_CARD,
      variables: { cardId, checklistId },
    }).catch((err) => { console.error(err); });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.DELETE_ITEM_FROM_CHECKLIST]: ({ commit }, { cardId, checklistId, itemId }) => {
    apolloClient.mutate({
      mutation: DELETE_ITEM_FROM_CHECKLIST,
      variables: { cardId, checklistId, itemId },
    }).catch((err) => { console.error(err); });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.FETCH_CHECKLISTS_FOR_CARD]: ({ commit }, cardId) => {
    apolloClient.query({
      query: FETCH_CHECKLISTS_FOR_CARD,
      variables: { id: cardId },
    }).then(({ data }) => {
      commit(types.MUTATE_FETCH_CHECKLISTS, data.getCardById.checklists);
    }).catch((err) => { console.error(err); });
  },

  [types.UPDATE_CHECKLIST_ITEM_POSITION]: ({ commit }, {
    cardId, checklistId, itemId, position, checklistItem,
  }) => {
    apolloClient.mutate({
      mutation: UPDATE_CHECKLIST_ITEM_POSITION,
      variables: {
        cardId, checklistId, itemId, position, checklistItem,
      },
    }).catch((err) => { console.error(err); });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.UPDATE_CHECKLISTS_POSITION]: ({ commit }, {
    cardId, checklistId, position, checklist,
  }) => {
    apolloClient.mutate({
      mutation: UPDATE_CHECKLISTS_POSITION,
      variables: {
        cardId, checklistId, position, checklist,
      },
    }).catch((err) => { console.error(err); });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.SUBSCRIBE_CHECKLIST_ADDED]: ({ commit }, checklist) => {
    commit(types.MUTATE_SUBSCRIBE_CHECKLIST_ADDED, checklist);
  },

  [types.SUBSCRIBE_CHECKLIST_DELETED]: ({ commit }, checklist) => {
    commit(types.MUTATE_SUBSCRIBE_CHECKLIST_DELETED, checklist);
  },

  [types.SUBSCRIBE_CHECKLIST_UPDATED]: ({ commit }, checklist) => {
    commit(types.MUTATE_SUBSCRIBE_CHECKLIST_UPDATED, checklist);
  },

  [types.SUBSCRIBE_CHECKLIST_MOVED]: ({ commit }, checklistMoved) => {
    commit(types.MUTATE_SUBSCRIBE_CHECKLIST_MOVED, checklistMoved);
  },
};

/**
 * STATES
 */
const state = {
  checklists: [],
  dirtyChecklists: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
