import { apolloClient } from '@/apolloConfig';
import {
  GET_DATE,
  CHANGE_DATE,
  DELETE_DATE,
} from '@/queries/cardDate';
import * as types from '../types';

/**
 * GETTERS
 */
const getters = {
  [types.GET_CARD_DATES]: (state) => state.dates,
  [types.GET_DATE_NAME_DIRTY]: (state) => state.nameDirty,
};

/**
 * MUTATIONS
 */
const mutations = {
  [types.MUTATE_CARD_DATE]: (state, dates) => {
    const current = state;
    current.dates = dates;
  },

  [types.MUTATE_TOGGLE_DATE_NAME_DIRTY]: (state, isEditing) => {
    const current = state;
    current.nameDirty = isEditing;
  },
};

/**
 * ACTIONS
 */
const actions = {
  // Card Date
  [types.FETCH_CARD_DATE]: ({ commit }, id) => {
    apolloClient.query({
      query: GET_DATE,
      variables: {
        id,
      },
    })
      .then(({ data, loading }) => {
        if (!loading) {
          commit(types.MUTATE_CARD_DATE, data.getCardById.dates);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },

  [types.CHANGE_CARD_DATE]: async ({ commit }, { id, date }) => {
    await apolloClient.mutate({
      mutation: CHANGE_DATE,
      variables: {
        id,
        date,
      },
    }).catch((err) => {
      console.error(err);
    });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.DELETE_CARD_DATE]: ({ commit }, { id, card }) => {
    apolloClient.mutate({
      mutation: DELETE_DATE,
      variables: {
        id,
        card,
      },
    })
      .catch((err) => {
        console.error(err);
      });
    commit(types.MUTATE_CARD_WAS_MODIFIED, true);
  },

  [types.SUBSCRIBE_DATE_CHANGED]: ({ commit }, dates) => {
    commit(types.MUTATE_CARD_DATE, dates);
  },
};

/**
 * STATES
 */
const state = {
  dates: [],
  nameDirty: false,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
