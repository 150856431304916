import gql from 'graphql-tag';
import {
  CARD_FRAGMENT,
  LABEL_FRAGMENT,
  CHECKLIST_FRAGMENT,
  ATTACHMENT_FRAGMENT,
  USER_INFO,
  CARD_DATES,
  PROPERTY_FRAGMENT,
  CARD_COMMENT_FRAGMENT,
  MUTATION_MESSAGE,
  CUSTOM_DESCRIPTION_FRAGMENT,
} from './fragments';

// ----------------QUERIES------------------

export const GET = gql`
  query getCardById($id: ID!){
    getCardById(id:$id){
      id
      title
      subtitle
      workflow
      category
      description
      customDescriptions {
        ...customDescription
      }
      isTemplate
      isArchive
      isPinned
      dates {
        ...date
      }
      labels {
        ...label
      }
      checklists {
        ...checklist
      }
      attachments {
        ...attachment
      }
      members {
        ...publicUser
      }
      properties {
        ...property
      }
      comments {
        ...comment
      }
    }
  }
  ${LABEL_FRAGMENT.label}
  ${CHECKLIST_FRAGMENT.checklist}
  ${ATTACHMENT_FRAGMENT.attachment}
  ${USER_INFO.publicUser}
  ${CARD_DATES.date}
  ${PROPERTY_FRAGMENT.property}
  ${CARD_COMMENT_FRAGMENT.comment}
  ${CUSTOM_DESCRIPTION_FRAGMENT.customDescription}
  `;

// ----------------MUTATIONS------------------

export const ADD = gql`
  mutation($title: String!, $box: ID!, $workflow: ID, $group: ID,  $category: ID, $labels: [ID], $template: ID, $onTop: Boolean) {
    addCard(title: $title, box: $box, workflow: $workflow, group: $group, category: $category, labels: $labels, template: $template, onTop: $onTop) {
      id
      title
    }
  }
`;

export const UPDATE_TITLE = gql`
mutation updateTitle($id: ID!, $title: String) {
  updateCard(id: $id, title: $title) {
    id
    title
  }
}
`;

export const UPDATE_SUBTITLE = gql`
mutation updateSubtitle($id: ID!, $subtitle: String) {
  updateCard(id:$id,subtitle: $subtitle) {
    id,
    subtitle
  }
}
`;

export const DELETE = gql`
mutation removeCard($cardId: ID!) {
  removeCard(
    cardId: $cardId
  ) {
    id
  }
}
`;

export const COMPLETE_COPY = gql`
mutation completeCopyCard($cardId: ID!, $index: Int, $route: String, $boxId: String, $newTitle: String) {
  completeCopyCard(cardId: $cardId, index: $index, route: $route, boxId: $boxId, newTitle: $newTitle) {
    ...mutationMessage
  }
}
${MUTATION_MESSAGE.mutationMessage}
`;

export const NOTIFY_WATCHERS_ON_CHANGE = gql`
mutation ($cardId: ID!) {
  notifyWatchersOnCardChange(cardId: $cardId)
}
`;

export const BULK_DELETE = gql`
mutation ($cardIds: [ID!]!) {
  deleteCards(cardIds: $cardIds) {
    ...mutationMessage
  }
}
${MUTATION_MESSAGE.mutationMessage}
`;

// ----------------SUBSCRIPTIONS------------------

export const SUBSCRIBE_ADDED = gql`
subscription {
  cardAdded {
    ...cardSummary
    categoryPosition
    workflowPosition
    labelPosition
  }
}
${CARD_FRAGMENT.card}
`;

export const SUBSCRIBE_ADDED_EXPANDED_COLUMN = gql`
subscription {
  cardAdded {
    ...cardInExpandedView
    categoryPosition
    workflowPosition
    labelPosition
  }
}
${CARD_FRAGMENT.cardOverviewOnColumnExpansion}
`;

export const SUBSCRIBE_UPDATED = gql`
subscription {
  cardUpdated {
    ...cardSummary,
  }
}
${CARD_FRAGMENT.card}
`;

export const SUBSCRIBE_UPDATED_EXPANDED_COLUMN = gql`
subscription {
  cardUpdated {
    ...cardInExpandedView,
  }
}
${CARD_FRAGMENT.cardOverviewOnColumnExpansion}
`;

export const SUBSCRIBE_DELETED = gql`
subscription {
  cardsRemoved {
    id
    workflow
    category
    box
    workflowGroup
    labels {
      id
    }
  }
}`;
