<template>
  <div>
    <slot
      name="languageSwitcher"
      :selectedLanguage="selectedLanguage"
      :languages="languages"
      :changeLanguage="changeLanguage"
    ></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as types from '@/stores/types';
import i18n from '@/i18n';
import { LANGUAGES } from '../../scripts/common';

/**
 * @group Common
 *
 * This component holds the functions that support
 * changing the locale of the app
 */
export default {
  name: 'WLocalization',

  computed: {
    ...mapGetters({
      getSetting: types.GET_SETTING,
      isAuthenticated: types.GET_USER_AUTHENTICATION_STATUS,
    }),

    languages() {
      return LANGUAGES;
    },

    /**
     * The currently selected language
     */
    selectedLanguage() {
      const setting = this.getSetting;
      return (
        this.trySetLanguage(setting.locale)
        || this.trySetLanguage(navigator.language)
        || this.trySetLanguage('en')
        || i18n.language
      );
    },
  },

  data() {
    return {
      menu: false,
    };
  },

  methods: {
    ...mapActions({
      updateLocale: types.UPDATE_SETTING,
      localUpdateLocale: types.UPDATE_SETTING_LOCAL,
    }),

    /**
     * @vuese
     * This method is change locale of the app
     */
    changeLanguage(language) {
      const locale = {
        locale: language.value,
      };
      if (this.isAuthenticated) {
        this.$dateFormat.setLocale(language.value);
        this.updateLocale(locale);
      } else {
        // Only updates the store and not the backend. Needed on login/signup page
        this.localUpdateLocale(locale);
      }
    },

    /**
     * @vuese
     * Tries to set the language shorthand and returns it. Returns false on failure.
     * @arg {String} language The language shorthand string to try
     */
    trySetLanguage(language) {
      const foundLanguage = this.languages.find((lang) => lang.value === language);
      if (foundLanguage) {
        i18n.locale = foundLanguage.value;
        return foundLanguage.acronym;
      }
      return false;
    },
  },
};
</script>
