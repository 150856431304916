<template>
  <div>
    <v-tabs background-color="transparent" class="mb-4 flex-grow-0" v-model="activeTab">
      <v-tab href="#my-boxes">{{ $t("my_boxes") }} </v-tab>
      <v-tab href="#archived-boxes">{{ $t("archived_boxes") }}</v-tab>
    </v-tabs>

    <v-row class="mt-3 mb-3" no-gutters>
      <v-spacer></v-spacer>
      <!-- Add New Box Button -->
      <wAddBoxGroup v-if="activeTab === 'my-boxes'" class="mr-2" />

      <wAddBox v-if="activeTab === 'my-boxes'" class="mr-2" />

      <v-divider
        v-if="groups && groups.length > 1 && activeTab === 'my-boxes'"
        vertical
      ></v-divider>

      <!-- Button for Sort groups of boxes-->
      <v-btn
        v-if="groups && groups.length > 1 && activeTab === 'my-boxes'"
        class="ml-2"
        color="button info"
        @click="sortDialog = true"
      >
        <v-icon class="mx-2" small> fa-sort </v-icon>
        {{ $t("sort_groups") }}
      </v-btn>
    </v-row>

    <!-- Content for My Boxes tab -->
    <v-row v-if="activeTab === 'my-boxes'">
      <wBox :boxes="unarchivedBoxes" :disable="false" />
    </v-row>

    <!-- Content for Archived Boxes tab -->
    <v-row v-if="activeTab === 'archived-boxes'">
      <wBox :boxes="archivedBoxes" :disable="true" />
    </v-row>

    <!-- GROUPS in My Boxes-->
    <v-row v-for="group in filteredGroups" :key="group.id" dense>
      <wGroup :group="group" />

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <wBox :boxes="group.boxes" :groupId="group.id"></wBox>
      </v-col>
    </v-row>

    <v-dialog
      v-model="sortDialog"
      max-width="500"
      :fullscreen="$vuetify.breakpoint.xsOnly ? true : false"
      @keydown.esc="sortDialog = false"
    >
      <wSortGroups
        :groups="groups"
        :dialog="sortDialog"
        v-if="sortDialog"
        @close="sortDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import wAddBox from '@/components/box/wAddBox.vue';
import colorController from '@/scripts/colorController';
import wAddBoxGroup from '@/components/box/wAddGroup.vue';
import wBox from '@/components/box/wBox.vue';
import wGroup from '@/components/box/wGroup.vue';
import wSortGroups from '@/components/box/wSortGroupsForBoxes.vue';
/**
 * @group Box
 *  A component containing the (accepted) Boxes with
 *  edit and delete feature for each Box
 * @module components/box/wBoxes.vue
 *
 * @param {Array} boxes - The Boxes details
 */
export default {
  name: 'wBoxes',

  components: {
    wAddBox,
    wAddBoxGroup,
    wBox,
    wGroup,
    wSortGroups,
  },

  data() {
    return {
      activeTab: 'my-boxes',
      sortDialog: false,
    };
  },

  props: {
    archivedBoxes: {
      type: Array,
      default: () => [],
    },
    unarchivedBoxes: {
      type: Array,
      default: () => [],
    },

    groups: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    filteredGroups() {
      if (this.activeTab === 'my-boxes') {
        return this.groups;
      }
      return [];
    },
  },
  methods: {
    /**
     * @vuese
     * Determines whether the color of the text
     * should be black or white
     */
    fontColor(color) {
      if (colorController.shouldIUseDark(color)) {
        return 'black';
      }
      return 'white';
    },
  },
};
</script>
