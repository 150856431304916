import * as types from '@/stores/types';
import commonController from '@/scripts/commonController';

const { mapToBoxId } = commonController;

const getters = {
  [types.GET_FILTER_IS_ACTIVE]: (state) => state.filterIsActive,

  [types.GET_FILTER_DATE]: (state) => state.filterDate,

  [types.GET_FILTER_LABELS]: (state) => state.filterLabels,

  [types.GET_FILTER_TITLE]: (state) => state.filterTitle,

  [types.GET_FILTER_CHECKLIST]: (state) => state.filterChecklist,

  [types.GET_FILTER_WORKFLOW]: (state) => state.filterWorkflow,

  [types.GET_FILTER_CARD_MEMBERS]: (state) => state.filterCardMembers,

  /**
   *  Done this way so that you can have a getter with parameters.
   *  Caveat: Vue can not listen to changes on this getter.
   */
  [types.GET_FILTER_FOR_BOX]: (state) => (boxId) => ({
    labels: mapToBoxId(state.filterLabels, boxId),
    date: mapToBoxId(state.filterDate, boxId),
    title: mapToBoxId(state.filterTitle, boxId),
    checklist: mapToBoxId(state.filterChecklist, boxId),
    workflow: mapToBoxId(state.filterWorkflow, boxId),
  }),
};

const mutations = {
  [types.MUTATE_FILTER_IS_ACTIVE]: (state, { boxId, filterIsActive }) => {
    const current = state;
    const pos = current.isActive.findIndex((boxToContent) => boxToContent[0] === boxId);

    if (pos === -1) {
      current.isActive = [...current.isActive, [boxId, filterIsActive]];
    } else {
      current.isActive[pos].splice(1, 1, filterIsActive);
    }
  },

  [types.MUTATE_FILTER_DATE]: (state, { boxId, newFilterDate }) => {
    const current = state;
    const pos = current.filterDate.findIndex((boxToContent) => boxToContent[0] === boxId);

    if (pos === -1) {
      current.filterDate = [...current.filterDate, [boxId, newFilterDate]];
    } else {
      current.filterDate[pos].splice(1, 1, newFilterDate);
    }
  },

  [types.MUTATE_FILTER_LABELS]: (state, { boxId, newFilterLabels }) => {
    const current = state;
    const pos = current.filterLabels.findIndex(
      (boxToContent) => boxToContent[0] === boxId,
    );

    if (pos === -1) {
      current.filterLabels = [...current.filterLabels, [boxId, newFilterLabels]];
    } else {
      current.filterLabels[pos].splice(1, 1, newFilterLabels);
    }
  },

  [types.MUTATE_FILTER_NAME]: (state, { boxId, newFilterTitle }) => {
    const current = state;
    const pos = current.filterTitle.findIndex(
      (boxToContent) => boxToContent[0] === boxId,
    );

    if (pos === -1) {
      current.filterTitle = [...current.filterTitle, [boxId, newFilterTitle]];
    } else {
      current.filterTitle[pos].splice(1, 1, newFilterTitle);
    }
  },

  [types.MUTATE_FILTER_CHECKLIST]: (state, { boxId, newFilterChecklist }) => {
    const current = state;
    const pos = current.filterChecklist.findIndex(
      (boxToContent) => boxToContent[0] === boxId,
    );

    if (pos === -1) {
      current.filterChecklist = [...current.filterChecklist, [boxId, newFilterChecklist]];
    } else {
      current.filterChecklist[pos].splice(1, 1, newFilterChecklist);
    }
  },

  [types.MUTATE_FILTER_WORKFLOW]: (state, { boxId, newFilterWorkflow }) => {
    const current = state;
    const pos = current.filterWorkflow.findIndex(
      (boxToContent) => boxToContent[0] === boxId,
    );

    if (pos === -1) {
      current.filterWorkflow = [...current.filterWorkflow, [boxId, newFilterWorkflow]];
    } else {
      current.filterWorkflow[pos].splice(1, 1, newFilterWorkflow);
    }
  },

  [types.MUTATE_FILTER_CARD_MEMBERS]: (state, { boxId, newFilterCardMember }) => {
    const current = state;
    const pos = current.filterCardMembers.findIndex(
      (boxToContent) => boxToContent[0] === boxId,
    );

    if (pos === -1) {
      current.filterCardMembers = [...current.filterCardMembers, [boxId, newFilterCardMember]];
    } else {
      current.filterCardMembers[pos].splice(1, 1, newFilterCardMember);
    }
  },

  [types.MUTATE_SUBSCRIBE_FILTER_CARD_MEMBERS_USER_DELETE]: (state, deletedUser) => {
    const current = state;

    current.filterCardMembers.forEach((filter) => {
      /**
       * Retrieving the element at index 1
       * because inside every filter object
       * we have two array elemets
       *
       * 1. The box ID
       * 2. The array of userIDs
       */
      const members = filter[1];

      const index = members.find((member) => member.id === deletedUser.id);

      if (index !== -1) {
        filter[1].splice(index, 1);
      }
    });
  },
};

const actions = {
  [types.UPDATE_FILTER_IS_ACTIVE]: ({ commit }, [boxId, filterIsActive]) => {
    commit(types.MUTATE_FILTER_IS_ACTIVE, { boxId, filterIsActive });
  },

  [types.UPDATE_FILTER_DATE]: ({ commit }, [boxId, newFilterDate]) => {
    commit(types.MUTATE_FILTER_DATE, { boxId, newFilterDate });
  },

  [types.UPDATE_FILTER_LABELS]: ({ commit }, [boxId, newFilterLabels]) => {
    commit(types.MUTATE_FILTER_LABELS, { boxId, newFilterLabels });
  },

  [types.UPDATE_FILTER_NAME]: ({ commit }, [boxId, newFilterTitle]) => {
    commit(types.MUTATE_FILTER_NAME, { boxId, newFilterTitle });
  },

  [types.UPDATE_FILTER_CHECKLIST]: ({ commit }, [boxId, newFilterChecklist]) => {
    commit(types.MUTATE_FILTER_CHECKLIST, { boxId, newFilterChecklist });
  },

  [types.UPDATE_FILTER_WORKFLOW]: ({ commit }, [boxId, newFilterWorkflow]) => {
    commit(types.MUTATE_FILTER_WORKFLOW, { boxId, newFilterWorkflow });
  },

  [types.UPDATE_FILTER_CARD_MEMBERS]: ({ commit }, [boxId, newFilterCardMember]) => {
    commit(types.MUTATE_FILTER_CARD_MEMBERS, { boxId, newFilterCardMember });
  },
};

const state = {
  isActive: [],
  filterDate: [],
  filterLabels: [],
  filterTitle: [],
  filterChecklist: [],
  filterWorkflow: [],
  filterCardMembers: [],
};

export default {
  state,
  getters,
  mutations,
  actions,
};
