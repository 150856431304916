import gql from 'graphql-tag';
import { PROPERTY_FRAGMENT } from './fragments';

export const FETCH_PROPERTIES_FOR_CARD = gql`
query getCardById($id: ID!) {
  getCardById(id: $id) {
    properties {
      ...property
    }
  }
}
${PROPERTY_FRAGMENT.property}
`;

export const ADD_PROPERTY_TO_CARD = gql`
mutation addPropertyToCard($cardId: ID!, $name: String!, $type: PropertyType!, $value: Float, $addInTotal: Boolean, $items: [PropertyUpdateSelection] ) {
  addPropertyToCard(cardId: $cardId, name: $name, type: $type, value: $value, addInTotal: $addInTotal, items: $items) {
    id
  }
}
`;

export const UPDATE_PROPERTY_TO_CARD = gql`
mutation updatePropertyToCard($cardId: ID!, $propertyId: ID!, $name: String!, $type: PropertyType!, $value: Float, $addInTotal: Boolean, $items: [PropertyUpdateSelection] ) {
  updatePropertyToCard(cardId: $cardId, propertyId: $propertyId, name: $name, type: $type, value: $value, addInTotal: $addInTotal, items: $items) {
    id
  }
}
`;

export const ADD_ITEM_TO_PROPERTY = gql`
mutation addItemToProperty($cardId: ID!, $propertyId: ID!, $newItem: String!) {
  addItemToProperty(cardId: $cardId, propertyId: $propertyId, newItem: $newItem) {
    id
  }
}
`;

export const UPDATE_PROPERTY_NAME = gql`
mutation updatePropertyName($cardId: ID!, $propertyId: ID!, $name: String!) {
  updatePropertyName(cardId: $cardId, propertyId: $propertyId, name: $name) {
    id
  }
}
`;

export const UPDATE_SELECTED_ITEM = gql`
mutation updatePropertyItem($cardId: ID!, $propertyId: ID!, $selectedItem: PropertyUpdateSelection!) {
  updatePropertyItem(cardId: $cardId, propertyId: $propertyId, selectedItem: $selectedItem) {
    id
  }
}
`;

export const DELETE_PROPERTY_FROM_CARD = gql`
mutation deletePropertyFromCard($cardId: ID!, $propertyId: ID!) {
  deletePropertyFromCard(cardId: $cardId, propertyId: $propertyId) {
    id
  }
}
`;

export const DELETE_ITEM_FROM_PROPERTY = gql`
mutation deleteItemFromProperty($cardId: ID!, $propertyId: ID!, $itemId: ID!) {
    deleteItemFromProperty(cardId: $cardId, propertyId: $propertyId, itemId: $itemId) {
    id
  }
}
`;

export const SUBSCRIBE_PROPERTY_ADDED = gql`
subscription propertyAdded($cardId: ID!) {
  propertyAdded(cardId: $cardId) {
    ...property
  }
}
${PROPERTY_FRAGMENT.property}
`;

export const SUBSCRIBE_PROPERTY_UPDATED = gql`
subscription propertyUpdated($cardId: ID!) {
  propertyUpdated(cardId: $cardId) {
    ...property
  }
}
${PROPERTY_FRAGMENT.property}
`;

export const SUBSCRIBE_PROPERTY_DELETED = gql`
subscription propertyDeleted($cardId: ID!) {
    propertyDeleted(cardId: $cardId) {
    ...property
  }
}
${PROPERTY_FRAGMENT.property}
`;
